import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap'
import airtable from '../../../airtables';
import Loading from '../../../components/Loading';
import SessionInfo from '../../../components/SessionInfo';
import "./styles.scss";

const PastSessions = ({ onToggle, teacherInfo }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);

    const initialize = async () => {
        const sessionIds = teacherInfo["Sessions Completed"];
        
        if (sessionIds && sessionIds.length) {
            const sessList = await airtable.sessions.listByIds(sessionIds);
            setSessions(sessList);
        }
        setLoading(false);
    }

    useEffect(() => {
        initialize();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            className="modal-dialog-centered assign-presenter-modal past-sessions"
            isOpen={true}
            toggle={() => onToggle()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">{t("sb-past-sessions", { name: teacherInfo["Teacher Name"] })}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <div style={{ padding: "0 20px", maxHeight: 528, marginBottom: 20, overflow: 'scroll' }}>
                    {(!!sessions && sessions.length > 0) && (
                        sessions.map((session, i) => (
                            <SessionInfo
                                key={i}
                                session={session}
                                displayInfos={[
                                    "Provider Name",
                                    "Grade(s)",
                                    "Teacher Rating"
                                ]}
                            />
                        ))
                    )}
                    {loading && <div style={{ padding: 20 }} align="center"><Loading size={20} /></div>}
                </div>
            </div>
        </Modal>
    )
}

export default PastSessions;