import { useEffect, useState } from "react";
import Airtable from "../../../airtables";
import s from "../style.module.scss";
import { useTranslation } from "react-i18next";
import StepSlider from "../../../components/StepSlider";

/**
 * @param {Object} params
 * @param {string[]} params.creditIDs
 */
export function AchievementCredit({ creditIDs }) {
	const { credit } = useCredit(creditIDs);
	const { t } = useTranslation();

	return (
		<div className={s.card}>
			<h2>{t("dashboard-page.credits-earned.heading")}</h2>
			<p>{t("dashboard-page.credits-earned.text", { credit: 65 })}</p>
			<StepSlider
				max={60}
				onChange={(_) => undefined}
				value={credit}
				step={5}
			/>
		</div>
	);
}

/**
 * @param {string[]} creditIDs
 */
function useCredit(creditIDs) {
	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [credit, setCredit] = useState(0);

	useEffect(() => {
		queryCredits(creditIDs)
			.then((credits) => {
				let c = 0;
				for (let i = 0; i < credits.length; i++) {
					c += credits[i];
				}
				setCredit(() => (c > 0 ? c : 0));
			})
			.catch((e) => console.log(e));
	}, [creditIDs]);

	return { credit };
}

/**
 * @param {string[]} creditIDs
 * @returns {Promise<number[]>}
 */
async function queryCredits(creditIDs) {
	if (creditIDs) {
		const ids = creditIDs.map((id) => `RECORD_ID()="${id}"`).join(",");
		const filterByFormula = `AND(OR(${ids}),{Action}="Credits Earned from Achievement")`;
		const result = await Airtable.credit.list({
			filterByFormula,
			fields: ["Credits"],
		});

		/** @type {number[]} */
		const buf = new Array(result.length);
		for (let i = 0; i < result.length; i++) {
			buf[i] = result[i]["Credits"];
		}
		return buf;
	} else {
		return false;
	}
}
