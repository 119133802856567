import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import greatLogo from "../../../assets/img/great-g.png";
import Commitment from '../components/Commitment';
import airtable from "../../../airtables";
import { DEFAULT_SESSION_FOR_GREET_SECTION } from '../../../utils/constants';
import SessionInfo from '../../../components/SessionInfo';
import { getActionMenusForExample, getDisplayInfosForExample } from '../../SessionInfoDisplay';
import { getProviderSessionIDFromSession } from '../../../utils/session';

const NavContent = ({
  defaultReflection,
  commitment,
  width
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [videoHeight, setVideoHeight] = useState(0);
  const [session, setSession] = useState(null);
  const { userInfo } = useSelector(state => state.appInfo);

  useEffect(() => {
    airtable.sessions.select(DEFAULT_SESSION_FOR_GREET_SECTION).then(res => {
      setSession(res);
    })
  }, [])

  useEffect(() => {
    setVideoHeight(width / 1.69)
  }, [width])

  const encodedName = encodeURIComponent(userInfo["Name"]);

  return (
    <div className="nav-content-wrap">
      <div className="main-content">
        <h4>{t("session-host-bp.greet.title")}</h4>
        <img
          src={greatLogo}
          className="great-logo sh-img"
          alt=""
        />
        <p>{t("session-host-bp.greet.p1")}</p>
        <p>{t("session-host-bp.greet.p2")}</p>
        <p>{t("session-host-bp.greet.p3")}</p>
        <p>
          <Trans
            i18nKey="session-host-bp.greet.p4"
            components={{ b: <b /> }}
          />
        </p>

        <ul className="session-host">
          <li>{t("session-host-bp.greet.li1")}</li>
          <li>
            <Trans
              i18nKey="session-host-bp.greet.li2"
              components={{ b: <b /> }}
            />
          </li>
          <li>
            <Trans
              i18nKey="session-host-bp.greet.li3"
              components={{ b: <b /> }}
            />
          </li>
          <li>{t("session-host-bp.greet.li4")}</li>
          <li>
            <Trans
              i18nKey="session-host-bp.greet.li5"
              components={{ b: <b /> }}
            />
          </li>
          <li>{t("session-host-bp.greet.li6")}</li>
          <li>{t("session-host-bp.greet.li7")}</li>
        </ul>

        <p>{t("session-host-bp.greet.p5")}</p>

        <div>
          {!!width && (
            <iframe
              src="https://player.vimeo.com/video/990283624?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Session Host Training - Greet Example"
              width={width}
              height={videoHeight}
            />
          )}
        </div>

        <p>{t("session-host-bp.greet.p6")}</p>
        <p>{t("session-host-bp.greet.p7")}</p>

        <div className="example-session">
          {!!session && <SessionInfo
            displayInfos={getDisplayInfosForExample(session)}
            actionMenu={getActionMenusForExample(session)}
            session={session}
            dateType="full"
            hideActionContacts="true"
            sessionDetailLink={getProviderSessionIDFromSession(session)}
          />}
        </div>

        <p>{t("session-host-bp.greet.p8")}</p>
        <p>{t("session-host-bp.greet.p9")}</p>

        <ul className="session-host ul-minus">
          <li>{t("session-host-bp.greet.vli1")}</li>
          <li>{t("session-host-bp.greet.vli2")}</li>
        </ul>

        <p>{t("session-host-bp.greet.p10")}</p>

        <iframe
          src={`https://www.videoask.com/fgb5bzez5#contact_name=${encodedName}&recordid=${userInfo.id}`}
          title="www"
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          width="100%"
          height="600px"
        />

        <Commitment
          label={t("session-host-bp.greet.reflection-label")}
          confirmLabel={t("session-host-bp.greet.agree")}
          btnLabel={t("session-host-bp.greet.continue")}
          defaultReflection={defaultReflection}
          submitConfirm={async (reflection) => {
            await commitment(reflection);
            history.push("review");
          }}
        />
      </div>
    </div>
  )
}

export default NavContent;
