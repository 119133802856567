export const getClusterLeadName = (ui) => {
    if (ui["Cluster Lead Display Name"]) return ui["Cluster Lead Display Name"][0];
    return ""
}

export const getCoreCompetenciesString = (competencies) => {
    if (!competencies || competencies.length === 0) return '';
    var sResult = ""
    competencies.forEach((c, i) => {
        sResult += (i > 0 ? ", " : "") + c;
    })

    return sResult;
}

export const getStrandTopicLabel = (regionMap, regionId) => {
    if (!regionId || !regionMap[regionId]) {
        return {
            strand: "Strand",
            topic: "Topic"
        };
    }

    const regionName = regionMap[regionId].Name;

    if (regionName === "BC" || regionName === "YT") {
        return {
            strand: "Area of Learning",
            topic: "Content"
        }
    } else if (regionName === "SK") {
        return {
            strand: "Indicator",
            topic: "Outcome"
        }
    } else {
        return {
            strand: "Strand",
            topic: "Topic"
        };
    }
}

export const getOrdinal = (number) => {
    const remainder10 = number % 10;
    const remainder100 = number % 100;

    // Special case for numbers ending with 11, 12, and 13
    if (remainder10 === 1 && remainder100 !== 11) {
        return number + "st";
    } else if (remainder10 === 2 && remainder100 !== 12) {
        return number + "nd";
    } else if (remainder10 === 3 && remainder100 !== 13) {
        return number + "rd";
    } else {
        return number + "th";
    }
}

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}