import React from 'react';
import Loading from './Loading';

const OverlayLoading = ({ visible, title }) => {

    if (!visible) return null;

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: 'black',
                    opacity: 0.7
                }}
            />
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <div style={{
                    background: 'white',
                    borderRadius: 5,
                    maxWidth: 150,
                    padding: 25,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    <Loading size={40} />
                    <div style={{ height: 20 }} />
                    <span>{title}</span>
                </div>
            </div>
        </>
    )
}

export default OverlayLoading;