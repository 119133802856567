import { useState } from "react";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import { useUpdateUserInfo } from "./use-update-user-info";
import { reminderOptions } from "../constants/select-options";

// We need to explicitly return false for the reminder option that isn't in
// the user's saved info, or else it will default to true and check the option
const getIsSetReminder = (userInfo, reminder) => {
	if (userInfo && Object.keys(userInfo).includes(TEACHER_FIELDS.REMINDERS)) {
		return userInfo[TEACHER_FIELDS.REMINDERS].includes(reminder);
	}
	return true;
};

export const useSetReminders = () => {
	const { loading, userInfo, updateUserInfo } = useUpdateUserInfo();
	const [formValues, setFormValues] = useState({
		oneDay: getIsSetReminder(userInfo, reminderOptions[0]),
		oneHour: getIsSetReminder(userInfo, reminderOptions[1]),
	});

	const updateReminders = async (nextLink = "") => {
		const reminders = [];
		if (formValues.oneDay) reminders.push(reminderOptions[0]);
		if (formValues.oneHour) reminders.push(reminderOptions[1]);

		const updateBody = {
			[TEACHER_FIELDS.REMINDERS]: reminders,
		};

		updateUserInfo(updateBody, nextLink);
	};

	return { loading, formValues, setFormValues, updateReminders };
};
