import { useDispatch, useSelector } from "react-redux";
import airtable from '../../../airtables';
import { useEffect, useState } from "react";
import { setGroupTeachers } from "../../../redux/actions";

const useHook = (teacher) => {
  const dispatch = useDispatch();
  const {
    userInfo,
    groupTeachers: {
      loaded: teachersLoaded,
      teachers: teamTeachers
    }
  } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(true);
  const [completing, setCompleting] = useState(false);
  const [from, setFrom] = useState(teacher);
  const [target, setTarget] = useState(null);
  const [credits, setCredits] = useState(0);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    if (!teachersLoaded) {
      const ts = await airtable.teachers.getTeachersForTeam(userInfo["Email"]);
      dispatch(setGroupTeachers({
        loaded: true,
        teachers: ts
      }));
    }

    setLoading(false)
  }
  
  const getErrors = () => {
    if (from && target && from.id === target.id) {
      return "FROM and TO teachers are the same."
    }

    if (from && credits > from["Credit Balance"]) {
      return "Credits to Transfer is greater than Teacher's Credit Balance"
    }

    return ""
  }

  const canComplete = () => {
    if (loading) return false;

    if (!from) return false;
    if (!target) return false;
    if (!credits) return false;
    if (!notes) return false;
    return true;
  }

  const complete = async () => {
    if (getErrors()) return ;

    setCompleting(true);
    const record = await airtable.credit.create({
      "Teacher": [target.id],
      "Transferred To/From": [from.id],
      "Credits": credits,
      "Display Note": notes,
      "Platform Transfer": true,
      "Action": "Credits Transferred to a Teacher"
    })
    await airtable.credit.create({
      "Teacher": [from.id],
      "Transferred To/From": [target.id],
      "Credits": -credits,
      "Display Note": notes,
      "Platform Transfer": true,
      "Action": "Credits Transferred from a Teacher",
      "Transfer Record": [record.id]
    })
    setCompleting(false);
  }

  return {
    loading,
    completing,
    teachers: teamTeachers,
    complete,
    from,
    setFrom,
    target,
    setTarget,
    credits,
    setCredits,
    notes,
    setNotes,
    getErrors,
    canComplete
  }
}

export default useHook;
