const getAddition = (framework) => {
    switch (framework) {
        case 'Inuit Societal Values':
            return "Please incorporate some of the Inuit societal values: ᐃᓅᖃᑎᒌᑦᓯᐊᕐᓂᖅ - Inuuqatigiitsiarniq - Respecting others, relationships and caring for people. ᑐᙵᓇᕐᓂᖅ - Tunnganarniq - Fostering good spirit by being open, welcoming and inclusive. ᐱᔨᑦᓯᕐᓂᖅ - Pijitsirniq Serving and providing for family and/or community. ᐋᔩᖃᑎᒌᓐᓂᖅ - Aajiiqatigiinniq - Decision making through discussion and consensus. ᐱᓕᒻᒪᒃᓴᕐᓂᖅ - Pilimmaksarniq - Development of skills through observation, mentoring, practice, and effort. ᐃᑲᔪᖅᑎᒌᓐᓂᖅ - Ikajuqtigiinniq - Working together for a common cause. ᖃᓄᖅᑑᕐᓂᖅ - Qanuqtuurniq - Being innovative and resourceful. ᐊᕙᑎᑦᑎᓐᓂᒃ ᑲᒪᑦᓯᐊᕐᓂᖅ - Avatittinnik Kamatsiarniq - Respect and care for the land, animals and the environment.";
        case 'Seven Grandfather Teachings':
            return "Please incorporate some of the Anishinaabe Seven Grandfather Teachings. Below lists each teaching in Anishinaabemowin and the linguistic breakdown of each word: 1. Debwewin, To speak only to the extent, we have lived or experienced. Commonly referred to as Truth. Truth is represented by the turtle as the turtle was here during the creation of Earth and carries the teachings of life on his back. 2. Dabasendiziwin, To think lower of oneself in relation to all that sustains us. Commonly referred to as Humility. Humility is represented by the wolf. The wolf lives for his pack and the ultimate shame is to be an outcast.  3. Manaaji'idiwin, To go easy on one another and all of Creation. Commonly referred to as Respect. Respect is represented by the buffalo.  The buffalo gives every part of his being to sustain the human way of living. The buffalo respects the balance and needs of others. 4. Zaagi'idiwin. Unconditional love between one another including all of Creation, humans and non-humans. Commonly referred to as Love. The eagle represents love because he has the strength to carry all of the teachings. 5. Gwayakwaadiziwin. To live correctly and with virtue. Commonly referred to as Honesty. Honesty is represented by either the raven or the sabe.  They both understand who they are and how to walk in their life. 6. Zoongide'ewin. To live with a solid, strong heart. Commonly referred to as Bravery or Courage. Bravery is represented by the bear.  The mother bear has the courage and strength to face her fears and challenges while protecting her young. To face life with courage is bravery. 7. Nibwaakaawin. To live with vision. Commonly referred to as Wisdom. The beaver represents wisdom because he uses his natural gift by altering the environment for his family's survival.";
        case 'Haida Ethics and Values':
            return "Please incorporate some of the six Haida Ethics and Values (respect, responsibility, interconnectedness, balance, seeking wise counsel, and giving and receiving). There are all elements that define the Haida world view. 1. Yahguudang (Respect): Respect for each other and all living things is rooted in our culture. We take only what we need, we give thanks, and we acknowledge those who behave accordingly. 2. 'Laa guu ga kanhllns (Responsibility): We accept the responsibility passed on by our ancestors to manage and care for our sea and land. We will ensure that our heritage is passed onto future generations. 3. Gina 'waadluxan gud ad kwaagiida (Interconnectedness): Everything depends on everything else. The principle of interconnectedness is fundamental to integrated planning and management. This comprehensive approach considers the relationships between species and habitats, and accounts for short-term, long-term and cumulative effects of human activities on the environment. Interrelationships are accounted for across spatial and temporal scales and across agencies and jurisdictions. 4. Giid tll'juus (Balance): The world is as sharp as the edge of a knife. Balance is needed in our interactions with the natural world. If we aren't careful in everything we do, we can easily reach a point of no return. Our practices and those of others must be sustainable. 5. Gina k'aadang.nga gii uu tl' k'anguudang. (Seeking Wise Counsel) Our elders teach us about traditional ways and how to work in harmony. Like the forests, the roots of our people are intertwined. Together we consider new ideas and information in keeping with our culture, values and laws. 6. Isda ad diigii isda (Giving and Receiving) Reciprocity is a respected practice in our culture, essential in our interactions with each other and the natural world. We continually give thanks to the natural world for the gifts that we receive.";
        default:
            return "Please keep in mind the students are primarily Indigenous and reference Indigenous ways of knowing where possible.";
    }
}

export const generateQuery = (
    provider,
    session,
    activityType,
    framework,
    readingLevel,
    optionalText
) => {
    const addition = getAddition(framework);

    return `
My students will have a virtual classroom visit with "${provider["Name"]}" titled "${session["Session Title"]}".

Please help me design a "${activityType}" about the presentation?
${addition}

Here's an overview of the description of the presentation:\n${session["Description"]}
${readingLevel ? `\nPlease develop this for a ${readingLevel} reading level.\n${optionalText || ''}` : ''}
${provider["Introduction/Bio"] ? `\nHere's a bio of ${provider["Name"]}\n${provider["Introduction/Bio"]}` : ``}
`;
}