import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RequestForm from './modals/Request';
import { setRequestSessionModal } from '../redux/modalActions';
import airtable from '../airtables';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

const ModalList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        requestSession
    } = useSelector(state => state.modal);
    const [prerequisiteSession, setPrerequisiteSession] = useState(null);

    useEffect(() => {
        if (!requestSession.providerSession) {
            setPrerequisiteSession(null);
            return ;
        }

        if (requestSession.providerSession.Title) {
            airtable.providerSessions.selectPrerequisiteSession(requestSession.providerSession.Title).then(res => {
                if (res) setPrerequisiteSession(res);
            })
        } else {
            setPrerequisiteSession(null)
        }
    }, [requestSession.providerSession])

    const renderRequestSession = () => {
        const {
            visible,
            providerSession,
            onSubmitted,
            dayRestrictionConfig,
            isEdit,
            prefillSession,
        } = requestSession;

        if (!visible || !providerSession) return null;

        const onToggle = () => dispatch(setRequestSessionModal({}))

        const onDone = () => {
            if (isEdit) onSubmitted();
            else {
                if (prerequisiteSession) {
                    const session = { ...prerequisiteSession };
                    onSubmitted();

                    confirmAlert({
                        title: t("prerequisite-dyk"),
                        message: t("prerequisite", { sessiontitle: session["Title"] }),
                        buttons: [{
                            label: t("request-session"),
                            onClick: () => {
                                dispatch(setRequestSessionModal({
                                    providerSession: session,
                                    visible: true,
                                    onSubmitted: () => dispatch(setRequestSessionModal({}))
                                }))
                            },
                        }, {
                            label: t("session-details"),
                            onClick: () => window.open(`/cn/session/${session.id}`, "_blank")
                        }, {
                            label: t("close"),
                            onClick: () => { },
                        }],
                    });
                } else onSubmitted();
            }
        }

        return (
            <RequestForm
                session={providerSession}
                dayRestrictionConfig={dayRestrictionConfig}
                onSubmitted={onDone}
                edit={isEdit}
                onToggle={onToggle}
                requestedSession={prefillSession}
            />
        )
    }

    return (
        <Fragment>
            {renderRequestSession()}
        </Fragment>
    )
}

export default ModalList;
