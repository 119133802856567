import AirtableTable from "../AirtableTable";

export class SubjectTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Subjects")

    this.fields = {
      "Subject": "fldyezAcnuLcaX7yJ",
      "Subject (FR)": "fldPHBbY4xZmweBSY",
      "# Curriculum Links": "fldLqisu9fXljMZkR"
    }
  }

  getAllSubjects = () => {
    return this.list({
      filterByFormula: "{Subject} != 'Test'",
      sort: [
        { field: 'Subject', direction: 'asc' }
      ]
    })
  }

  getSubjectsWithCLinks = () => {
    return this.list({
      fields: ["Subject", "Subject"],
      filterByFormula: "AND({# Curriculum Links} > 0, {Subject} != 'Test')",
      sort: [
        { field: 'Subject', direction: 'asc' }
      ]
    })
  }
}