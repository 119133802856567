import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import introImg from '../../../assets/img/sh-introduction.jpg';
import { useHistory } from 'react-router-dom';

const NavContent = ({ width }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [videoHeight, setVideoHeight] = useState(0);

  useEffect(() => {
    setVideoHeight(width / 1.69)
  }, [width])

  return (
    <div className="nav-content-wrap">
      <div className="main-content">
        <h4>{t("session-host-bp.introduction.title")}</h4>
        <iframe
          src="https://player.vimeo.com/video/1000928015?h=7dc9e05a79&title=0&byline=0&portrait=0"
          width={width}
          height={videoHeight}
          frameBorder="0"
          title="Session Host Platform Welcome"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
        <p>{t("session-host-bp.introduction.p1")}</p>
        <p>{t("session-host-bp.introduction.p2")}</p>
        <p>{t("session-host-bp.introduction.p3")}</p>
        <p><b>{t("session-host-bp.introduction.p4")}</b></p>
        <p>{t("session-host-bp.introduction.p5")}</p>
        <p>{t("session-host-bp.introduction.p6")}</p>
        <img src={introImg} alt="Introduction" className="introduction sh-img" />

        <p>{t("session-host-bp.introduction.p7")}</p>
        <iframe
          src="https://player.vimeo.com/video/871045767?h=7dc9e05a79&title=0&byline=0&portrait=0"
          width={width}
          height={videoHeight}
          frameBorder="0"
          title="Session Host Title"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
        <span
          className="nav-action"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            history.push("how-to-host-a-great-session");
          }}
        >
          {t("session-host-bp.introduction.continue")}
        </span>
      </div>
    </div>
  )
}

export default NavContent;