import AirtableTable from "../AirtableTable";

export class SchoolTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Schools");

    this.fields = {
      "School": "fldoOAEpdf4Y2c6fz",
      "Indigenous % TY": "fldEucXWvgRz3DvKb",
      "Teacher List": "fldg2HyDoIU6hRQV5"
    }
  }

  getAllSchools = (schoolIds) => {
    if (!schoolIds || schoolIds.length === 0) {
      return this.list({
        filterByFormula: `AND(Type = 'School', OR({Participation Status} = 'Active & Engaged', {Participation Status} = 'Planning in Progress'))`,
        sort: [{ field: 'School', direction: 'asc' }]
      })
    }

    const filter = schoolIds.map(id => `RECORD_ID() = '${id}'`).join(',');

    return this.list({
      filterByFormula: `OR(${filter})`,
      sort: [{ field: 'School', direction: 'asc' }]
    })
  }

  getSchoolsFromString = (str) => {
    if (!str) return []
    const divider = ',';
    const result = str.split(divider)
    if (!result || result.length === 0) return [];
    for (var i = result.length - 1; i >= 0; i--) {
      if (result[i].trim() === '') {
        result.splice(i, 1);
      }
      result[i] = result[i].trim();
    }
    return result;
  }
}
