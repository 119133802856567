export const APP_LOGIN = "/login";
export const APP_ROOT = "/cn";

export const ONBOARD_TEACHER_ROOT = "/onboard";

const ONBOARD_TEACHER_ROUTES = {
	START: {
		path: ONBOARD_TEACHER_ROOT,
		name: "OnboardStart",
		exact: true,
	},
	CREATE: { path: `${ONBOARD_TEACHER_ROOT}/create`, name: "CreateAccount" },
	DETAILS: {
		path: `${ONBOARD_TEACHER_ROOT}/details`,
		name: "AccountDetails",
	},
	SCHOOL: { path: `${ONBOARD_TEACHER_ROOT}/school`, name: "SelectSchool" },
	SUBJECTS: {
		path: `${ONBOARD_TEACHER_ROOT}/subjects`,
		name: "GradesSubjects",
	},
	SESSIONS: {
		path: `${ONBOARD_TEACHER_ROOT}/sessions`,
		name: "SessionsMenu",
	},
	CONDUCT: { path: `${ONBOARD_TEACHER_ROOT}/conduct`, name: "CodeOfConduct" },
	TERMS: {
		path: `${ONBOARD_TEACHER_ROOT}/terms`,
		name: "OptionalTerms",
	},
	WELCOME: {
		path: `${ONBOARD_TEACHER_ROOT}/welcome`,
		name: "WelcomeTeacher",
	},
};

const UPDATE_TEACHER_ROUTES = {
	START: ONBOARD_TEACHER_ROUTES.START,
	DETAILS: ONBOARD_TEACHER_ROUTES.DETAILS,
	SCHOOL: ONBOARD_TEACHER_ROUTES.SCHOOL,
	SUBJECTS: ONBOARD_TEACHER_ROUTES.SUBJECTS,
	SESSIONS: ONBOARD_TEACHER_ROUTES.SESSIONS,
	CONDUCT: ONBOARD_TEACHER_ROUTES.CONDUCT,
	TERMS: ONBOARD_TEACHER_ROUTES.TERMS,
};

// Are we using the optional terms page at this time?
export const ONBOARD_TEACHER_OPTIONAL_TERMS_ENABLED = false;

if (!ONBOARD_TEACHER_OPTIONAL_TERMS_ENABLED) {
	delete ONBOARD_TEACHER_ROUTES.TERMS;
	delete UPDATE_TEACHER_ROUTES.TERMS;
}

export { ONBOARD_TEACHER_ROUTES, UPDATE_TEACHER_ROUTES };
