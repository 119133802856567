import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Modal } from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import airtable from '../../../airtables';
import { setTags } from '../../../redux/actions';
import AddTagModal from '../NewTag';
import Loading from '../../../components/Loading';
import { toast } from "react-toastify";

const AssignTagForSession = ({ session, onToggle }) => {
  const { tagsData: { loaded, tags }, userInfo } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();

  const [showNewTagModal, setShowNewTagModal] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!loaded) {
      loadTags();
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const loadTags = async () => {
    const ts = await airtable.tags.getTeamTags(userInfo.Name);
    dispatch(setTags(ts));
  }

  const onSelectOption = async (opt) => {
    if (!opt || !opt.value) return;


    if (opt.value === 'new-tag') {
      setShowNewTagModal(true);
    } else {
      try {
        setUpdating(true)
        const tagId = opt.value;
        const updatedTag = await airtable.tags.select(tagId);
        airtable.tags.update(tagId, {
          "Provider Sessions": [
            ...(updatedTag["Provider Sessions"] || []),
            session.id
          ]
        });
        onToggle();
        toast.success("Successfully added this session to the specified collection!")
      } catch (error) {
        toast.error("Error!")
      }
    }
  }

  const creatAndAssign = async (name, desc) => {
    try {
      setUpdating(true)
      await airtable.tags.create({
        Tag: name,
        Description: desc,
        "Provider Sessions": [session.id],
        "Creator on Platform": userInfo.Name
      })
      await loadTags();
      setUpdating(false);
      onToggle();
      toast.success("Successfully created your new Collection with this session added to it!")
    } catch (error) {
      toast.error("Error!")
    }
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-body">
        <h5>Which Collection do you want to add this Session to?</h5>
        <Select
          className="form-style"
          placeholder="Select a Tag"
          onChange={onSelectOption}
          options={[
            ...tags.map(t => ({ value: t.id, label: t.Tag })),
            { value: "new-tag", label: "Create New Collection" }
          ]}
        />
      </div>

      {showNewTagModal && (
        <AddTagModal
          onToggle={() => setShowNewTagModal(!showNewTagModal)}
          addNewTag={creatAndAssign}
        />
      )}

      {updating && (
        <div className="overlap-modal-processing">
          <Loading size={24} />
        </div>
      )}
    </Modal>
  )
}

export default AssignTagForSession;