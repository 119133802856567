import cx from "classnames";
/**
 * @typedef {object} CreditTableProps
 * @property {import("../useCreditRecord").CreditRecord[]} credits
 *
 * @param {CreditTableProps} props
 */
export function CreditTable({ credits }) {
	
	return (
		<div style={{ minHeight: "50vh", overflowX: 'auto', whiteSpace: 'nowrap'  }}>
			<table className="w-100">
				<thead>
					<tr style={{ backgroundColor: "#F3F9FF" }}>
						<Th>Date</Th>
						<Th>Type</Th>
						<Th>On Hold</Th>
						<Th>Amount</Th>
						<Th>Balance</Th>
					</tr>
				</thead>

				<tbody>
					{credits
						.sort((i, j) => Date.parse(j.Date) - Date.parse(i.Date))
						.map((c) => {
							return (
								<tr key={c.id}>
									<Cell style={{ width: "18ch" }}>
										{formatDateTime(new Date(c["Date"]))}
									</Cell>
									<TypeCell
										type={c["Action"]}
										desc={
											(c["Action"] === "Credits Earned from Achievement" || c["Action"] === "Credits Issued by Program")
												? c["Display Note"]
												: (c["Session"] ? c["Session"][0] : null)
										}
									/>
									<Cell>{formatCredit(c.onHold)}</Cell>
									<Cell>{formatCredit(c.amount)}</Cell>
									<Cell>{c.balance}</Cell>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}

function Th({ children }) {
	return (
		<th>
			<p className="px-3 py-2 font-weight-bold m-0">{children}</p>
		</th>
	);
}

/**
 * @param {object} prop
 * @param {string} prop.type
 * @param {string?} prop.desc
 */
function TypeCell({ type, desc }) {
	return (
		<td style={{ borderBottom: "1px solid #1094ce" }}>
			<p
				className={cx([
					"px-3 py-2 mt-3 font-weight-bold",
					{ "mb-0": desc },
				])}
			>
				{type}
			</p>
			{desc && (
				<p
					className="px-3 font-weight-normal"
					style={{ maxWidth: "60ch", textWrap: "wrap" }}
				>
					{desc}
				</p>
			)}
		</td>
	);
}

/**
 * @param {React.HTMLAttributes<HTMLParagraphElement>} props
 */
function Cell({ children, className, ...r }) {
	return (
		<td style={{ borderBottom: "1px solid #1094ce" }}>
			<p
				className={cx(["px-3 py-2 my-3 font-weight-normal", className])}
				{...r}
			>
				{children}
			</p>
		</td>
	);
}

/**
 * @param {number} c
 * @returns {string}
 */
function formatCredit(c) {
	if (c <= 0) return String(c);
	return "+" + String(c);
}

/**
 * @param {Date} d
 * @returns {string} str
 *
 * "2023-06-23T22:41:34.000Z" will return "June 6th, 2023"
 */
function formatDateTime(d) {
	const year = d.getFullYear();

	const date = d.getDate();

	const monthStr = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const month = monthStr[d.getMonth()];
	return `${month} ${date}, ${year}`;
}
