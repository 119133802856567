import React, { useState } from 'react';
import { Button, FormGroup, Modal } from 'reactstrap';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import airtable from '../../airtables';
import axios from 'axios';

const AddMyClass = ({ session, showModal, onDone, onToggle }) => {
    const { userInfo } = useSelector(state => state.appInfo);
    const [updating, setUpdating] = useState(false);
    const [checked, setChecked] = useState(false)
    const { t } = useTranslation();

    const complete = async () => {
        setUpdating(true);
        await axios.get(`https://hooks.zapier.com/hooks/catch/89715/39brzhw/?user=${userInfo.id}&session=${session.id}&splitcredits=${checked ? 'true' : ''}`);

        if (checked) {
            await airtable.sessions.update(session.id, {
                Teacher: [...session.Teacher, userInfo.id],
                'Do Not Share Credits Automatically': false
            });
        } else {
            await airtable.sessions.update(session.id, {
                Teacher: [...session.Teacher, userInfo.id],
                'Do Not Share Credits Automatically': true
            });
        }

        setUpdating(false)
        onDone();
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => onToggle()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">Add My Class</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <p>I confirm that I have asked {session["Teacher Name"]} and they have given permission for my class to join them in this session.</p>
                {(session["Credit Cost"] > 1 && session["Do Not Share Credits Automatically"] !== true && userInfo["Credit Balance"] > 0) ? (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox onChange={(e) => setChecked(e.target.checked)} />
                            }
                            label="I will share the cost of this session, please charge my portion of the Session cost to my Credit balance."
                        />
                    </FormGroup>
                ) : null}
            </div>
            <div className="modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => complete()}
                >
                    {updating ? (<Loading size={14} />) : (null)}Confirm
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >{t("cancel")}</Button>
            </div>
        </Modal>
    )
}

export default AddMyClass;
