import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter, LinkList, TeacherFocus } from './components';
import { useSelector } from 'react-redux';
import { useCurriculum } from './hooks';
import './styles.scss'
import { Pagination } from '../../../components/Pagination';

const Screen = () => {
  const { userType } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const {
    handleSelectGrade,
    subjectFilter,
    handleSelectSubject,
    gradeFilter,
    handleClearFilter,
    handleApplyFilter,
    showAll,
    handleShowAll,

    loading,
    links,
    pageNo,
    pageCount,
    changePage,

    regions,
    grades,
    subjects
  } = useCurriculum();

  useEffect(() => {
    window.document.title = "Browse by Curriculum - Connected North";
  }, []);

  return (
    <div className="main-container">
      <div className="curriculum-list-container">
        <h2 className="curriculum-list-header">
          {t("browse-by-curriculum-linkages")}
        </h2>

        <div className="curriculum-list-filter">
          <Filter
            handleSelectGrade={handleSelectGrade}
            subjectFilter={subjectFilter}
            handleSelectSubject={handleSelectSubject}
            gradeFilter={gradeFilter}
            handleClearFilter={handleClearFilter}
            handleApplyFilter={handleApplyFilter}
            showAll={showAll}
            handleShowAll={handleShowAll}
          />
        </div>

        <div className="curriculum-list-teacher-focus">
          {userType === 'Teacher' && (
            <TeacherFocus
              regions={regions}
              grades={grades}
              subjects={subjects}
            />
          )}
        </div>
      </div>

      <section style={{ minHeight: "500px", marginTop: "40px" }}>
        <LinkList
          loading={loading}
          links={links}
        />

        <div>
          {pageCount > 1 && (
            <Pagination
              page={pageNo}
              setPage={changePage}
              pageCount={pageCount}
            />
          )}
        </div>
      </section>
    </div>
  )
}

export default Screen;