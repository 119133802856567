import React from 'react';
import { isImage } from '../../utils/url';

const Thumbnail = ({ file, onRemove }) => {
    const { name, type, thumbs } = file;

    const renderContent = () => {
        if (isImage(type)) {
            return <img src={file.url ? file.url : URL.createObjectURL(file)} alt="" />
        }

        if (thumbs) {
            return <img src={thumbs.url} alt="" />
        }

        return <span>{name}</span>;
    }

    return (
        <div className="file-view">
            <div className="file-view-container">
                {renderContent()}
            </div>

            <div
                className="btn-remove"
                onClick={(e) => {
                    e.preventDefault();

                    onRemove();
                }}
            >
                <i className="fa fa-trash-o"></i>
            </div>
        </div>
    )
}

export default Thumbnail;