import React, { Component } from 'react';
import Loading from '../components/Loading';
import uploadFile from '../libs/aws/uploadfile';

export default class CustomImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: props.attachment.loading,
            file: props.attachment.file,
            error: ''
        }
    }

    componentDidMount = async () => {
        if (this.state.loading === true ) {
            try {
                var uRes = await uploadFile(this.state.file);
                if (uRes) {
                    this.props.onUploadDone(uRes);
                    this.setState({ loading: false })
                }
            } catch (error) {
                this.setState({ error: 'Error' });
            }
        }
    }

    render() {
        return (
            <div style={{ position: 'relative', width: 100, height: 100, borderRadius: 5, marginRight: 2 }}>
                {
                    <img src={URL.createObjectURL(this.state.file)} style={{ width: 100, height: 100, borderRadius: 5, backgroundColor: 'lightgray' }} alt="" />
                }
                {
                    this.state.loading ? (
                        <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 0.4, backgroundColor: 'black', borderRadius: 5 }}>
                            <Loading size={20} />
                        </div>
                    ) : (null)
                }
            </div>
        )
    }
}