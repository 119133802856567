import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { check2023BestPractices } from '../../utils/bp-utils';
import { toast } from 'react-toastify';
import airtable from '../../airtables';
import { setAppUserInfo } from '../../redux/actions';
import Loading from '../../components/Loading';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import StepProgress from '../../components/StepProgress';
import { useTranslation } from 'react-i18next';

const BPCodeConduct = () => {
  const { userInfo, userType, isNewProvider } = useSelector(state => state.appInfo);
  const [confirm, setConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = "Code of Conduct - Policies - Connected North"
  }, [])

  useEffect(() => {
    const redir = check2023BestPractices(userInfo, userType, isNewProvider, "codeConduct");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType, isNewProvider])

  const updateBestPractices = async () => {
    if (!confirm) return;

    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, { "2023 Code of Conduct Reviewed": confirm });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  const steps = [];
  if (isNewProvider && !userInfo["Waive Best Practices"]) {
    steps.push(t("best-practices"));
  } else if (!isNewProvider) {
    steps.push(t("provider-step-progress.survey"));
    steps.push(t("provider-step-progress.reflection"));
  } else {
    steps.push("Introduction");
  }
  steps.push(t("provider-step-progress.code-of-conduct"));
  if (userInfo["Cost/Session"]) {
    steps.push(t("provider-step-progress.cancellation-policy"));
  }
  steps.push(t("provider-step-progress.confirm-session-offerings"));

  return (
    <div className="main-container">
      <StepProgress
        steps={steps}
        activeStep={isNewProvider ? 1 : 2}
        completedSteps={isNewProvider ? [0] : [0, 1]}
      />
      <div style={{ height: 20 }} />
      <h1>{t("bestPractices.code-conduct.title")}</h1>
      <div className="main-content">
        <p>{t("bestPractices.code-conduct.p1")}</p>
        <p>{t("bestPractices.code-conduct.p2")}</p>
        <p>{t("bestPractices.code-conduct.p3")}</p>
        <p>{t("bestPractices.code-conduct.p4")}</p>
        <p>{t("bestPractices.code-conduct.p5")}</p>
        <p>{t("bestPractices.code-conduct.p6")}</p>
        <p>{t("bestPractices.code-conduct.p7")}</p>

        <FormGroup check>
          <Input type='checkbox' id="check1" onChange={(e) => setConfirm(e.target.checked)} />
          <Label check for="check1" style={{ fontSize: 16 }}><strong>{t("bestPractices.code-conduct.p8")}</strong></Label>
        </FormGroup>

        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={saving || !confirm}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;Submit and Continue</Button>
        </div>
      </div>
    </div>
  )
}

export default BPCodeConduct;
