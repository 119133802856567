import { useState } from "react";
import { useSelector } from "react-redux";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import { useUpdateUserInfo } from "./use-update-user-info";
import { CurrentGradeLevels } from "../../../utils/constants";
import { getAvailableSubjects } from "../utils/get-available-subjects";

export const useGradesSubjects = () => {
	const { loading, userInfo, updateUserInfo } = useUpdateUserInfo();
	const { totalSubjects } = useSelector((state) => state.appInfo);

	const allGrades = CurrentGradeLevels;
	const allSubjects = getAvailableSubjects(totalSubjects).map(
		({ Subject, id }) => ({
			id,
			label: Subject,
		}),
	);

	const [formValues, setFormValues] = useState({
		grades: userInfo[TEACHER_FIELDS.GRADES] || [],
		subjects: userInfo[TEACHER_FIELDS.SUBJECTS] || [],
	});

	const updateGradesSubjects = async (nextLink = "") => {
		const { grades, subjects } = formValues;

		const updateBody = {
			[TEACHER_FIELDS.GRADES]: grades,
			[TEACHER_FIELDS.SUBJECTS]: subjects,
		};

		updateUserInfo(updateBody, nextLink);
	};

	return {
		formValues,
		setFormValues,
		loading,
		updateGradesSubjects,
		allGrades,
		allSubjects,
	};
};
