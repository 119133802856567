import React from "react";
import spinnerImg from "../assets/img/spinner.png";

/**
 * @param {object} props
 * @param {string | number} size
 */
const Loading = ({ size }) => (
	<img
		src={spinnerImg}
		className="op-loading"
		style={{ width: size, height: size, margin: "0 5px" }}
		alt=""
	/>
);

export default Loading;
