import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, Typography } from "@mui/material";
import { FormGroup, Input, Label } from "reactstrap";
import { ONBOARD_TEACHER_ROUTES } from "../routing";
import { OnboardPageInner } from "../components/OnboardPageInner";
import { StepProgress } from "../components/StepProgress";
import { useAccountDetails } from "../hooks/use-account-details";
import { getNextBackLinks } from "../utils/get-next-back-links";
import {
	indigenousGroupOptions,
	languageOptions,
} from "../constants/select-options";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.accountDetails`;
const formId = "account-details-form";

export const AccountDetails = ({
	isNewTeacher,
	isIncompleteTeacher,
	stepRoutes = [],
	currentRouteName = "",
}) => {
	const { t } = useTranslation();
	const { formValues, setFormValues, loading, updateTeacher } =
		useAccountDetails();

	const showNewTeacherFlow = isNewTeacher || isIncompleteTeacher;

	const { currentStep, nextLink } = getNextBackLinks(
		stepRoutes,
		currentRouteName,
	);

	const backLink = isNewTeacher
		? ONBOARD_TEACHER_ROUTES.CREATE.path
		: ONBOARD_TEACHER_ROUTES.START.path;

	const languageLabel = showNewTeacherFlow
		? "newPreferredLanguageLabel"
		: "updatePreferredLanguageLabel";
	const statusLabel = showNewTeacherFlow
		? "newIndigenousStatusLabel"
		: "updateIndigenousStatusLabel";

	const onChangeInput = (e) => {
		const { name, value } = e.target;
		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const onSubmitForm = (e) => {
		e.preventDefault();
		updateTeacher(nextLink);
	};

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	});

	return (
		<OnboardPageInner
			formId={formId}
			onSubmit={onSubmitForm}
			heading={
				<>
					<Typography variant="h1">
						{t(`${pageKey}.mainHeading`)}
					</Typography>
					<Typography variant="body1">
						{t(`${pageKey}.introText`)}
					</Typography>
				</>
			}
			actions={
				<StepProgress
					formId={formId}
					backLink={backLink}
					nextLabel={
						showNewTeacherFlow
							? `${tKey}.nextButton`
							: `${tKey}.confirmButton`
					}
					currentStep={currentStep}
					totalSteps={stepRoutes.length}
					disabled={loading}
				/>
			}
		>
			<>
				<FormGroup>
					<Label for="pronouns">{t(`${tKey}.pronounsLabel`)}</Label>
					<Input
						id="pronouns"
						name="pronouns"
						type="text"
						value={formValues.pronouns}
						onChange={onChangeInput}
						disabled={loading}
						placeholder={t(`${tKey}.pronounsPlaceholder`)}
					/>
				</FormGroup>
				<FormGroup>
					<Label for="preferredLanguage">
						{`${t(`${pageKey}.${languageLabel}`)} (${t(
							`${tKey}.requiredField`,
						)})`}
					</Label>

					<Select
						id="preferredLanguage"
						name="preferredLanguage"
						className="form-control"
						value={formValues.preferredLanguage}
						onChange={onChangeInput}
						disabled={loading}
						required
					>
						{languageOptions.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				</FormGroup>
				<FormGroup>
					<Label for="indigenousStatus">
						{t(`${pageKey}.${statusLabel}`)}
					</Label>
					<Select
						id="indigenousStatus"
						name="indigenousStatus"
						className="form-control"
						value={formValues.indigenousStatus}
						onChange={onChangeInput}
						disabled={loading}
						multiple
					>
						{indigenousGroupOptions.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
						<MenuItem value="">
							{t("teacherOnboard.noneOfTheAbove")}
						</MenuItem>
					</Select>
				</FormGroup>
			</>
		</OnboardPageInner>
	);
};
