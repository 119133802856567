import React from 'react';
import { useTranslation } from 'react-i18next';

const BPCodeConduct = () => {
    const { t } = useTranslation();

    return (
        <div>
            <h1>Code of Conduct</h1>
            <div className="main-content">
                <p>{t("bestPractices.code-conduct.p1")}</p>
                <p>{t("bestPractices.code-conduct.p2")}</p>
                <p>{t("bestPractices.code-conduct.p3")}</p>
                <p>{t("bestPractices.code-conduct.p4")}</p>
                <p>{t("bestPractices.code-conduct.p5")}</p>
                <p>{t("bestPractices.code-conduct.p6")}</p>
                <p>{t("bestPractices.code-conduct.p7")}</p>
            </div>
        </div>
    )
}

export default BPCodeConduct;
