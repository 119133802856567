import AirtableTable from "../AirtableTable";

export class ReviewTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Reviews")
    this.fields = {
      "Teacher Grade(s)": "fldniHTVFjmJEqPzb",
      "Teacher Location": "fldtNHz4UyXHKKM35",
      "Quote": "fld2KqABZiQ6LA2a9",
      "Session": "fldvuEpH9omzmy0Pp",
      "Session Title": "fldNgKflklIdrgegz"
    }
  }

  getReviewsByIds = (ids) => {
    if (!ids || ids.length === 0) return [];

    const filter = ids.map((id) => `RECORD_ID() = "${id}"`).join(",");

    return this.list({
      filterByFormula: `OR(${filter})`,
    })
  }
}