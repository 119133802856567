import { nanoid } from "nanoid";
import React from "react";
import { TokenBlue } from "../../assets/token-blue";

export function PointBreakdown({ pointBreakdown }) {
	return (
		<ul className="px-0 pb-2 w-100">
			{pointBreakdown.map(({ points, description }) => (
				<li
					className="row d-flex align-items-center my-2 mx-0 px-0 w-100"
					key={nanoid(10)}
				>
					<div className="d-flex align-items-center justify-content-center">
						<div
							className="m-0 font-weight-normal mr-3"
							style={{ width: "3ch" }}
						>
							{points}
						</div>
						<TokenBlue />
						<div className="col-md m-0 ml-2 font-weight-normal">
							{description}
						</div>
					</div>


				</li>
			))}
		</ul>
	);
}
