import { BestPracticeLen } from './constants';

export const isCompletedBestPractices = (userInfo, userType) => {
  if (userType !== "Provider") return false;

  const BPInfo = userInfo["Best Practice Commitments"];
  if (BPInfo && (
    BPInfo.indexOf("Special Exemption") >= 0 ||
    BPInfo.indexOf("Exemption for Indigenous Elders") >= 0 ||
    BPInfo.length === BestPracticeLen
  )) {
    if (!userInfo["Recording Allowed"]) return false;
    return true;
  } else {
    return false;
  }
}

export const isFirstVisitAfterBPCompleted = (userInfo, userType) => {
  if (!isCompletedBestPractices(userInfo, userType)) return false;

  if (userInfo["All BP Modules Completed"]) return false;
  return true;
}

export const status2023BestPractices = (userInfo, userType, isNewProvider) => {
  if (userType !== "Provider") return false;

  if (userInfo["23-24 Provider Reviewed"]) return "completed";

  if (!userInfo["23-24 Renewal Started"]) return "introduction";

  if (isNewProvider) {
    if (!userInfo["All BP Modules Completed"] && !userInfo["Waive Best Practices"]) {
      return "bestpractice";
    }
  } else {
    if (!userInfo["2023 Survey"]) return "survey";
    if (!userInfo["2023 Reflection Completed"]) return "reflection";
  }

  if (!userInfo["2023 Code of Conduct Reviewed"]) return "codeConduct";
  if (!userInfo["2023 Cancellation Policy Reviewed"]) {
    if (userInfo["Cost/Session"]) return "cancellationPolicy";
  }
  return "completed";
}

export const check2023BestPractices = (userInfo, userType, isNewProvider, target, reflectionStatus) => {
  const status = status2023BestPractices(userInfo, userType, isNewProvider);
  if (status !== 'reflection' && status === target) return "";

  switch (status) {
    case "completed": return "/mysessions";
    case "reflection":
      const renewalActions = userInfo["Renewal Actions"] || [];
      if (renewalActions.indexOf("Automatic renewal (interactivity resource)") >= 0) {
        if (reflectionStatus === "facilitation-of-student-interactivity") return "";
        return "/resources/facilitation-of-student-interactivity";
      } else if (renewalActions.indexOf("Automatic renewal (customization resource)") >= 0) {
        if (reflectionStatus === "creatively-customized-content") return "";
        return "/resources/creatively-customized-content";
      } else if (renewalActions.indexOf("Automatic renewal (trauma-informed resource)") >= 0) {
        if (reflectionStatus === "trauma-informed-approaches") return "";
        return "/resources/trauma-informed-approaches";
      } else if (status === target) return "";

      if (userInfo["All BP Modules Completed"]) return "/best-practices-2023/reflection";
      return "/best-practices/introduction"
    case "codeConduct": return "/best-practices-2023/code-of-conduct";
    case "cancellationPolicy": return "/best-practices-2023/cancellation-reschedule-policy";
    case "introduction": return "/best-practices-2023/introduction";
    case "bestpractice": return "/best-practices/introduction";
    case "survey":
    default:
      return "/survey";
  }
}
