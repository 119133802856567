import { Box, Paper } from "@mui/material";

export const OnboardPageInner = ({
	children,
	heading,
	actions,
	formId,
	...props
}) => (
	<Paper
		sx={{
			background: "#9DE0F8",
			padding: "3.5rem 2.5rem 4rem",
			borderRadius: "1rem",
			border: "solid 1px #0E6BA8",
			margin: "1rem 0 3rem",
			minHeight: "52vh",
			width: "80vw",
			maxWidth: "55rem",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			alignItems: "center",
			".form-field-wrap": {
				background: "#fff",
				padding: "1.25rem 1.5rem 0.5rem",
				borderRadius: "0.75rem",
				border: "solid 1px #0E6BA8",
				width: "calc(100% + 3rem)",
				transform: "translateX(-1.5rem)",
				"+ .form-field-wrap": {
					marginTop: "1rem",
				},
			},
			".form-label": {
				fontWeight: "bold",
				margin: "0.25rem 0",
				"&[disabled]": {
					opacity: 0.5,
				},
			},
			".form-control": {
				color: "#0D1120 !important",
				"&:focus, &:focus-within": {
					outline: "solid 0.2em #0e6ba8",
					outlineOffset: "0.05em",
				},
			},
			".form-check-input": {
				margin: 0,
				position: "inherit",
				"+ .form-label": {
					marginLeft: "0.75rem",
				},
			},
		}}
		{...props}
	>
		<Box sx={{ flex: "0 0 auto", textAlign: "center" }}>{heading}</Box>
		<Box
			component="form"
			id={formId}
			sx={{
				flex: "1 1 auto",
				margin: "1rem auto 1.5rem",
				width: "70%",
			}}
		>
			{children}
		</Box>
		<Box sx={{ flex: "0 0 auto", textAlign: "center" }}>{actions}</Box>
	</Paper>
);
