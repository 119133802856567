import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

import Login from "./pages/Login";
import Main from "./pages/Main";
import GlobalRate from "./pages/GlobalRate/teacher";
import GlobalProviderRate from "./pages/GlobalRate/provider";
import GlobalTeamRate from "./pages/GlobalRate/team";
import Unsubscribe from "./pages/Unsubscribe";
import ExternalProviderAccept from "./pages/AcceptSession/Provider";
import ExternalTeacherAccept from "./pages/AcceptSession/Teacher";
import RegisterPresenter from "./pages/RegisterPresenter";
import RegisterTeacher from "./pages/RegisterTeacher";
import { ToastContainer } from "react-toastify";
import { getSession } from "./utils/session";
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from "./config";
import {
  setAppUserInfo,
  setAppUserType,
  setAppLocale,
  setSubjectMap,
  setTotalSubjects,
  setMyFavorites,
  setCommunities,
  setAppIsSessionHost,
} from "./redux/actions";
import airtable from "./airtables";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./firebase";
import "react-toastify/dist/ReactToastify.css";
import { useIntercom } from "react-use-intercom";
import { isMobile } from "react-device-detect";
import * as Sentry from "@sentry/react";
import PendingSessionManager from "./pages/PendingSessionManager";
import { ONBOARD_TEACHER_ROOT, OnboardTeacher } from "./pages/OnboardTeacher";
import { getIsTeacherOnboardEnabled } from "./pages/OnboardTeacher/utils/get-is-teacher-onboard-enabled";

if (process.env.NODE_ENV === 'production') {
  const tracePropagationTarget = new RegExp(process.env.REACT_APP_SENTRY_DOMAIN_REGEX)

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [tracePropagationTarget],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const isTeacherOnboardEnabled = getIsTeacherOnboardEnabled();

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

const App = () => {
  const {
    boot: initialIntercom,
    update: updateIntercom,
    shutdown: shutdownIntercom
  } = useIntercom();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [intercomShown, isIntercomShown] = useState(false);

  useEffect(() => {
    const init = async () => {
      let ui = await getSession(CONNECTEDNORTH_SIGNINFO);
      let ut = await getSession(CONNECTEDNORTH_SIGNTYPE);

      if (ut && ui?.id) {
        try {
          switch (ut) {
            case "Teacher":
              ui = await airtable.teachers.select(ui.id);
              delete ui["24 Report Message"];
              break;
            case "Team":
              ui = await airtable.teams.select(ui.id);
              break;
            case "Provider":
              ui = await airtable.providers.select(ui.id);
              break;
            case "Presenter":
              ui = await airtable.presenters.select(ui.id);
              break;
            default:
              break;
          }

          if (ui) {
            dispatch(setAppUserInfo(ui));
            dispatch(setAppUserType(ut));

            if (ut === 'Team') {
              dispatch(setAppIsSessionHost(ui["Status"] === "Session Host"));
            } else if (ut === 'Teacher') {
              dispatch(setMyFavorites(ui['Favourites'] ? ui['Favourites'] : []));
            }
          }
        } catch (error) { }
      }

      setLoading(false);
    }

    airtable.communities.getAllCommunities().then(vms => {
      dispatch(setCommunities(vms));
    });

    airtable.subjects.getAllSubjects().then(subjectList => {
      dispatch(setTotalSubjects(subjectList));

      var subjectMap = {};
      subjectList.forEach((subject, i) => {
        subjectMap[subject.id] = subject;
      })
      dispatch(setSubjectMap(subjectMap));
    })

    init();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userInfo && userInfo.hasOwnProperty("Preferred Language") && userInfo["Preferred Language"] === 'French') {
      dispatch(setAppLocale("fr"));
      i18n.changeLanguage("fr");
    } else {
      dispatch(setAppLocale("en"));
      i18n.changeLanguage("en");
    }

    if (userInfo && userType) {
      if (process.env.NODE_ENV === 'production') {
        Sentry.setTags({
          UserType: userType,
          Email: userInfo["Email"]
        });
      }

      let isMobileBrowser = isMobile ? "true" : "false";
      const params = {
        email: userInfo.Email,
        user_id: userInfo.id,
        signed_up_at: Math.floor(new Date(userInfo["Date Added"]).getTime() / 1000),
        name: userType === 'Teacher' ? userInfo["Teacher Name"] : userInfo["Name"],
        customAttributes: { is_mobile_browser: isMobileBrowser, user_type: userType, Region: userInfo["School Province or Territory"] }
      };

      if (intercomShown) {
        updateIntercom(params);
      } else {
        initialIntercom(params);
        isIntercomShown(true);
      }
    } else {
      if (!intercomShown) return;
      shutdownIntercom();
      isIntercomShown(false);
    }
  }, [userInfo, userType])  // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return null;

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Switch>
          <Redirect exact path="/" to="/cn" />
          <Route path="/cn" render={(props) => <Main {...props} />} />
          <Route
            path="/rate/:teacherId/:sessionId/:rating"
            render={(props) => <GlobalRate {...props} />}
          />
          <Route
            path="/prorate/:providerId/:sessionId/:initialRating"
            render={(props) => <GlobalProviderRate {...props} />}
          />
          <Route
            path="/teamrate/:teamId/:sessionId/:rating"
            render={(props) => <GlobalTeamRate {...props} />}
          />
          <Route
            path="/accept/:providerId/:sessionId/:option"
            render={(props) => (
              <ExternalProviderAccept {...props} />
            )}
          />
          <Route
            path="/accept-session/provider/:providerId/:sessionId/:option"
            render={(props) => (
              <ExternalProviderAccept {...props} />
            )}
          />
          <Route
            path="/accept-session/teacher/:teacherId/:sessionId/:option"
            render={(props) => <ExternalTeacherAccept {...props} />}
          />
          <Route
            path="/rate/:teacherId/unsubscribe"
            render={(props) => <Unsubscribe {...props} />}
          />
          <Route
            path="/login"
            exact
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/register-presenter/:username/:providerId"
            exact
            render={(props) => <RegisterPresenter {...props} />}
          />
          <Route
            path="/register-teacher/:username/:clusterId"
            exact
            render={(props) => <RegisterTeacher {...props} />}
          />
          <Route
            path="/accept-session/:provider/:session/:type"
            exact
            render={(props) => <PendingSessionManager {...props} />}
          />
          {isTeacherOnboardEnabled && (
            <Route
              path={ONBOARD_TEACHER_ROOT}
              render={(props) => <OnboardTeacher {...props} />}
            />
          )}
        </Switch>
      </BrowserRouter>
      <ToastContainer 
        style={{
					width: "28.5rem",
					maxWidth: "80vw",
				}} 
      />
    </Suspense>
  )
}

export default App;
