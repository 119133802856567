
/**
 * @param {Date} date
 * @param {string} providerTimezone
 */
export const checkProviderWorkingTime = (date, providerTimezone, earliestTime = "08:30") => {
  if (!date) return false;
  if (typeof date === 'string') date = new Date(date);

  const formatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: providerTimezone,
  });

  const formatter1 = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: providerTimezone,
  });

  const formatedTime = formatter.format(date);
  return {
    isInWorkingTime: formatedTime >= earliestTime,
    providerTime: formatter1.format(date)
  }
}

export const checkValidation = (arr) => {
  return arr.findIndex(item => item.startsWith("invalid"));
}

export const getDefaultPreferredTime = (dayRestrictionConf, bookingRequirementDays, dayLimitations) => {
  let offset = 14;
  if (dayRestrictionConf) offset = dayRestrictionConf;
  else if (bookingRequirementDays && bookingRequirementDays.length) offset = bookingRequirementDays[0]

  const now = new Date();

  // Adjust start date to avoid weekends initially, set to next Monday if weekend
  if (now.getDay() === 6) { // Saturday
    now.setDate(now.getDate() + 2); // Set to Monday
  } else if (now.getDay() === 0) { // Sunday
    now.setDate(now.getDate() + 1); // Set to Monday
  }

  // Check for the next valid date within the week range from the adjusted date
  if (dayLimitations && dayLimitations.length) {
    for (let i = 0; i <= 7; i++) { // Check the next 7 days to find a valid date
      const testDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset + i, 10, 0, 0);
      const d = testDate.getDay();
      if (dayLimitations.indexOf(d) >= 0 && d !== 6 && d !== 0) { // Ensure it's a valid day and not a weekend
        return testDate;
      }
    }
  } else {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset, 10, 0, 0);
  }
}

export const getDefaultAlternativeTime = (dayRestrictionConf, bookingRequirementDays, dayLimitations) => {
  let offset = 28;
  if (dayRestrictionConf) offset = dayRestrictionConf + 14;
  else if (bookingRequirementDays && bookingRequirementDays.length) offset = bookingRequirementDays[0] + 14;

  const now = new Date();

  // Adjust start date to avoid weekends initially, set to next Monday if weekend
  if (now.getDay() === 6) { // Saturday
    now.setDate(now.getDate() + 2); // Set to Monday
  } else if (now.getDay() === 0) { // Sunday
    now.setDate(now.getDate() + 1); // Set to Monday
  }

  if (dayLimitations && dayLimitations.length) {
    for (let i = 0; i <= 7; i++) {
      const testDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset + i, 10, 0, 0);
      const d = testDate.getDay();
      if (dayLimitations.indexOf(d) >= 0 && d !== 6 && d !== 0) { // Ensure it's a valid day and not a weekend
        return testDate;
      }
    }
  } else {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset, 10, 0, 0);
  }
}

/**
 * 
 * @param {string} timezone 
 */
export const getJustTimeZone = (timezone) => {
  const arr = timezone.split("/");
  if (arr.length > 1) return arr[1];
  return timezone;
}

/**
 * 
 * @param {any} session
 */
export const getEarliestStartTimeFromProviderSession = (session) => {
  const startTime = session["Provider Earliest Start Time"] ? session["Provider Earliest Start Time"][0] : "8:30";
  if (startTime.length === 4) return "0" + startTime;
}