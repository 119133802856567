import AirtableTable from "../AirtableTable";

export class TimezoneTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Time Zones")

    this.fields = {
      "Connected North System": "fld9eRX49iVvQyXkw",
      "Zapier": "fld6HbfpxwllJUbTA",
      "CILC ID": "fldfvy3BLeTnB5VDD",
      "CILC": "fldbeQK7fRYJlJGNC",
      "Shortform": "fldlQKLs1WLa8bcgt",
      "Team Members": "fldAaNhcSGiJFDCGU"
    }
  }
}