import React from 'react';
import { Card } from '../../../../components/Card';
import Spinner from "../../../../components/Loading";

export const LinkList = ({ loading, links }) => {

  if (loading) return (
    <div className="d-flex justify-content-center align-items-center mt-5">
      <Spinner size={20} />
    </div>
  )

  if (!links || !links.length) {
    return (
      <div className="d-flex justify-content-center">
        <p>
          We haven't yet mapped Sessions to any Curriculum Expectations
          that match your filter. Please try again!
        </p>
      </div>
    )
  }

  return (
    <ul style={{ listStyle: "none", padding: 0 }} className="curriculum-list-ul">
      {links.map((link) => (
        <li key={link.id}>
          <Card style={{ background: "white", height: "100%" }}>
            <h6 className="font-weight-bold h3">{link["Strand"]}</h6>
            <Row header="Topic" content={link["Topic"]} />
            <Row
              header="Subject Area"
              content={link["Subject Text"]}
            />
            <Row header="Grade" content={link["Grade(s)"].join(",")} />
            <Row header="Region" content={link["Region Name"].join(",")} />
            <a
              href={`/cn/curriculum/${link.id}`}
              className="btn btn-primary mt-4"
            >
              View {link["Linked Sessions"]} Aligned Session(s)
            </a>
          </Card>
        </li>
      ))}
    </ul>
  )
}

/**
 * @param {Object} props
 * @param {string} props.header
 * @param {string} [props.content]
 */
function Row({ header, content }) {
  return (
    <>
      {content && header && (
        <div className="mb-2">
          <h6 className="h6 font-weight-bold d-inline">{header}:</h6>
          <p className="d-inline">&nbsp;{content}</p>
        </div>
      )}
    </>
  );
}
