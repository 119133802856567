/**
 * @param {object} props
 * @param {number?} props.size
 */
export function Token({ size }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="41"
			viewBox="0 0 40 41"
			fill="none"
			style={{
				transform: `scale(${size ?? 1})`,
				transformOrigin: "center",
				zIndex: 1000,
			}}
		>
			<path
				d="M17.4845 16.2052L14.1692 13.3043"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M17.6914 12.061V16.4123"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M17.8987 12.2681L21.6284 16.2051"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M21.7744 12V16.266"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M22.1067 16.2436L25.3965 13.4473"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M25.4046 13.535L24.553 18.6452"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M27.4299 15.5475L24.9075 18.0699"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M12.0969 16.2051L17.6915 16.4123"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M12.0969 16.4124L15.6194 19.106"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M11.0605 19.3133H15.6191"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M24.6755 18.7526H28.7344"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M28.466 18.8988L24.5291 22.4213"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M24.7166 22.5269L28.9859 22.8553"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M24.4893 22.5002L27.5089 26.1909"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M22.4507 25.651L27.3443 26.1573"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M11.0605 23.2502L13.3398 21.2817L15.6191 19.3132"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M11 23.3111H15.4733"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M12.3481 26.3954L15.4495 23.6205"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M15.3322 23.4148L15.1624 28.5103"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M18.2919 25.7004L15.1689 28.6589"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M18.252 25.6756V29.7345"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M18.5203 25.5294L22.5132 29.8888"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M22.4165 25.6546L22.5822 30.1286"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M22.6279 26.1255L25.1252 28.4667"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M38.4875 24.8909C40.898 14.6805 34.5749 4.44926 24.3645 2.03885C14.1541 -0.371581 3.92289 5.9515 1.51248 16.1619C-0.897944 26.3723 5.42513 36.6034 15.6355 39.0139C25.8459 41.4243 36.0771 35.1012 38.4875 24.8909Z"
				stroke="white"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M26.1136 34.9788C34.0956 31.6726 37.886 22.5217 34.5798 14.5397C31.2735 6.5578 22.1227 2.76741 14.1407 6.07364C6.15877 9.37983 2.36839 18.5308 5.67462 26.5126C8.98087 34.4946 18.1317 38.285 26.1136 34.9788Z"
				stroke="white"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</svg>
	);
}
