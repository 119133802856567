import { useEffect, useState } from "react";
import airtable from '../../airtables';
import { useSelector } from "react-redux";

const useQuestions = (sessionId) => {
    const { userInfo } = useSelector(state => state.appInfo)

    const [loading, setLoading] = useState(true)
    const [session, setSession] = useState(null);
    const [error, setError] = useState("");
    const [questions, setQuestions] = useState([]);
    const [refresher, setRefresher] = useState(0);

    useEffect(() => {
        airtable.sessions.select(sessionId).then(sess => {
            if (sess) {
                setSession(sess);
                setError("");
            } else {
                setError("There is no such session");
            }
        }).catch(error => {
            setError(error.toString())
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (session) {
            setLoading(true)
            airtable.questions.getQuestionsOfSession(session["Session"]).then(res => {
                setQuestions(res)
            }).catch(error => {
                setError(error.toString())
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [refresher, session])

    const refresh = () => setRefresher(refresher + 1);

    const insertQuestion = (q) => {
        return airtable.questions.create({
            "Question": q,
            "Session": [sessionId],
            "Added By": [userInfo.id]
        })
    }

    const likeQuestion = async (qId) => {
        const question = await airtable.questions.select(qId);
        const favourites = question["Favourites"] || [];
        if (favourites.indexOf(userInfo.id) < 0) favourites.push(userInfo.id);

        const qInfo = await airtable.questions.update(qId, {
            "Favourites": favourites
        })

        return qInfo;
    }

    return {
        loading,
        session,
        questions,
        updateQuestions: setQuestions,
        error,
        likeQuestion,
        insertQuestion,
        refresh
    }
}

export default useQuestions;