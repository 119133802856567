import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SessionInfo from "../../../components/SessionInfo";
import { Pagination } from "../../../components/Pagination";
import {
	getActionMenusForSchoolLead,
	getDisplayInfosForSchoolLead
} from "../../SessionInfoDisplay";
import { getProviderSessionIDFromSession } from "../../../utils/session";
import Loading from "../../../components/Loading";

const RenderSchoolSessions = ({
	loading,
	noMessage,
	sessions: sessList,
	type
}) => {
	const { t } = useTranslation();
	const NUM_SESS_PER_PAGE = 6;

	const pageCount = Math.ceil(sessList.length / NUM_SESS_PER_PAGE);
	const [curPage, setCurPage] = useState(0);
	const [sessionsToShow, setSessionToShow] = useState(sessList.slice(0, NUM_SESS_PER_PAGE));
	const [sessions, setSessions] = useState([])

	useEffect(() => {
		setSessions(sessList);
	}, [sessList]);

	useEffect(() => {
		setSessionToShow(
			sessions.slice(
				curPage * NUM_SESS_PER_PAGE,
				(curPage + 1) * NUM_SESS_PER_PAGE,
			),
		);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [curPage, sessions]);

	if (loading) {
		return (
			<div className="upcoming-loader">
				<Loading size={30} />
			</div>
		)
	}

	if (!sessions || sessions.length === 0) {
		if (noMessage) {
			return (
				<div className="nolist-placeholder" align="center">
					<span>{noMessage}</span>
				</div>
			);
		} else {
			return (
				<div className="nolist-placeholder" align="center">
					<span>{t("no-upcoming-sessions-at-school")}</span>
				</div>
			)
		}
	}

	return (
		<>
			<div className="session-grid">
				{sessionsToShow.map((session, i) => (
					<SessionInfo
						key={i}
						displayInfos={getDisplayInfosForSchoolLead(session, type)}
						actionMenu={getActionMenusForSchoolLead(session)}
						noMenu
						session={session}
						sessionDetailLink={getProviderSessionIDFromSession(session)}
						dateType={type !== "Past" ? "normal" : "full"}
					/>
				))}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				className="mb-5"
			>
				{sessions.length > NUM_SESS_PER_PAGE && (
					<Pagination
						setPage={setCurPage}
						pageCount={pageCount}
						page={curPage}
					/>
				)}
			</div>
		</>
	);
};

export default RenderSchoolSessions;
