import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import airtable from '../../../../airtables';
import { setAppUserInfo } from '../../../../redux/actions';
import Loading from '../../../../components/Loading';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { checkBestPractices } from '../utils';
import BP2024ProgressBar from '../components/BP2024ProgressBar';

const CancellationReschedulePolicy = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [saving, setSaving] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [waived, setWaived] = useState(false);
  const [feedback, setFeedback] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = "Cancellation Policy - Policies - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "cancellationPolicy");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const updateBestPractices = async () => {
    if (!reviewed) return;

    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "2023 Cancellation Exemption Request": (feedback || ""),
        "2023 Cancellation Policy Reviewed": true,
        "2023 Cancellation Fee Waived": waived
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="main-container best-practices">
      <BP2024ProgressBar current="cancellationPolicy" />
      <div style={{ height: 20 }} />
      <h1>{t("bestPractices.cr-policy.title")}</h1>
      <div className="main-content">
        <p>{t("bestPractices.cr-policy.p1")}</p>
        <p>{t("bestPractices.cr-policy.p2.lead")}</p>

        <b>A. {t("bestPractices.cr-policy.p2.a.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.a.li1")}</p></li>
        </ul>

        <b>B. {t("bestPractices.cr-policy.p2.b.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.b.li1")}</p></li>
          <li><p>{t("bestPractices.cr-policy.p2.b.li2")}</p></li>
        </ul>

        <b>C. {t("bestPractices.cr-policy.p2.c.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.c.li1")}</p></li>
        </ul>

        <b>D. {t("bestPractices.cr-policy.p2.d.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.d.li1")}</p></li>
        </ul>

        <FormGroup check>
          <Input type='checkbox' id="check1" onChange={(e) => setReviewed(e.target.checked)} />
          <Label check for="check1" style={{ fontSize: 16 }}><strong>{t("bestPractices.cr-policy.check.first")}</strong></Label>
        </FormGroup>

        <br />
        <FormGroup check>
          <Input type='checkbox' id="check2" onChange={(e) => setWaived(e.target.checked)} />
          <Label check for="check2" style={{ fontSize: 16 }}><strong>{t("bestPractices.cr-policy.check.second")}</strong></Label>
        </FormGroup>

        <br />
        <b>{t("bestPractices.cr-policy.p3-title")}</b>
        <p className="no-margin">
          <Trans 
            i18nKey={"bestPractices.cr-policy.p3"}
            // eslint-disable-next-line
            components={{a: <a href="https://calendly.com/annemarie-tig/renewal" target="_blank" rel="noreferrer" />}}
          />
        </p>
        <Input
          type="textarea"
          rows={4}
          disabled={saving}
          onChange={e => setFeedback(e.target.value)}
          defaultValue={feedback}
        />

        <div align="center" style={{ marginTop: 20, marginBottom: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={saving || !reviewed}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;Submit and Continue</Button>
        </div>
      </div>
    </div>
  )
}

export default CancellationReschedulePolicy;
