import s from '../style.module.scss'
import { useTranslation } from "react-i18next";

export function EmptyGraph({disclaimer, label, children, buttonLabel}){
  const { t } = useTranslation();
  return (
    <div>
      <div className={s.EmptyModalGraph}>
        <div className={s.TextBox}>
          <p>{disclaimer}</p>
          <a
          type="button"
          className={`btn'}`}
          href="/cn/search"
          >
            {t("dashboard-page.search-sessions")}
          </a>
        </div>
      </div>
      {children}
    </div>
    
  )
}