import cx from "classnames";
/**
 * @param {React.HtmlHTMLAttributes<HTMLDivElement>} props
 */
export function Card(props) {
	const { children, className, style, ...r } = props;

	return (
		<div
			className={cx(["px-4 py-4", className])}
			style={{
				borderRadius: "16px",
				border: "1px solid rgba(54, 192, 240, 0.49)",
				boxShadow: "10px 12px 24px 0px rgba(206, 222, 238, 0.47)",
				flexGrow: 1,
				...style,
			}}
			{...r}
		>
			{children}
		</div>
	);
}
