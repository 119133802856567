export const getNextBackLinks = (stepRoutes = [], currentRouteName = "") => {
	const currentStep = stepRoutes.find(
		({ name }) => name === currentRouteName,
	)?.step;

	const backLink = stepRoutes.find(
		({ step }) => step === currentStep - 1,
	)?.path;

	const nextLink = stepRoutes.find(
		({ step }) => step === currentStep + 1,
	)?.path;

	return {
		currentStep,
		backLink,
		nextLink,
	};
};
