import { useEffect, useState } from "react"
import airtable from '../../../airtables';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export function useUpcoming() {
    const { t } = useTranslation()
    const { userInfo } = useSelector((state) => state.appInfo);

    const [upcoming, setUpcoming] = useState({
        loading: true,
        sessions: [],
        type: "Upcoming",
        name: "upcoming",
        label: t('my-upcoming'),
        noMessage: t("no-upcoming-sessions")
    });
    const [past, setPast] = useState({
        loading: true,
        sessions: [],
        type: "Past",
        name: "past",
        label: t('view-past-sessions'),
        noMessage: t("no-past-sessions")
    });

    const [filterKeyword, setFilterKeyword] = useState("")
    const [filterDate, setFilterDate] = useState(null);
    const [filterCILC, setFilterCILC] = useState(false);
    const [fresh, setfresh] = useState(0);

    useEffect(() => {
        refreshPage();
    }, [fresh]) // eslint-disable-line react-hooks/exhaustive-deps

    const refresh = () => setfresh(fresh + 1);

    const refreshPage = async () => {
        airtable.sessions.getTeamUpcomingSessions(
            userInfo.id,
            userInfo['Name'],
            {
                filterKeyword,
                filterDate,
                filterCILC
            }
        ).then(uts => {
            setUpcoming({
                ...upcoming,
                loading: false,
                sessions: uts
            })
        });

        airtable.sessions.getTeamUpcomingSessions(
            userInfo.id,
            userInfo['Name'],
            {
                filterKeyword,
                filterDate,
                filterCILC
            },
            true
        ).then(pts => {
            setPast({
                ...past,
                loading: false,
                sessions: pts
            })
        });
    }

    return {
        data: [upcoming, past],
        filterKeyword,
        setFilterKeyword,
        filterDate,
        setFilterDate,
        filterCILC,
        setFilterCILC,
        refresh
    }
}