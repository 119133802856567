import React from 'react';
import { DropdownRadio } from './DropdownRadio';
import { CurriculumGradeLevels } from '../../../../utils/constants';
import { useSelector } from 'react-redux';

export const Filter = ({
  handleSelectGrade,
  subjectFilter,
  handleSelectSubject,
  gradeFilter,
  handleClearFilter,
  handleApplyFilter,
  showAll,
  handleShowAll,
}) => {
  const { userType, totalSubjects } = useSelector((s) => s.appInfo);

  return (
    <section
      style={{ height: "100%", overflowY: "visible !important" }}
      className="d-flex flex-column justify-content-start py-4"
    >
      <div>
        <div className="d-flex mb-3" style={{ gap: 3 }}>
          <DropdownRadio
            header="Grade Level(s)"
            values={CurriculumGradeLevels}
            filters={gradeFilter}
            handleChange={handleSelectGrade}
          />

          <DropdownRadio
            header="Subject Area(s)"
            values={totalSubjects.map((s) => s.Subject)}
            filters={subjectFilter}
            handleChange={handleSelectSubject}
          />
        </div>

        {userType === "Team" ? (
          <div className="d-flex flex-column mb-4">
            <label htmlFor="show-all">
              Show curriculum outside of your Territory/Province
            </label>

            <div>
              <label htmlFor="show-all-yes" className="mr-3">
                <input
                  id="show-all-yes"
                  type="radio"
                  name="show-all"
                  value="true"
                  checked={showAll}
                  onChange={handleShowAll}
                />
                &nbsp;Yes
              </label>

              <label htmlFor="show-all-no">
                <input
                  id="show-all-no"
                  type="radio"
                  name="show-all"
                  value="false"
                  checked={!showAll}
                  onChange={handleShowAll}
                />
                &nbsp;No
              </label>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="d-flex">
          <button
            onClick={handleApplyFilter}
            className="btn btn-primary w-50"
          >
            Apply Filter
          </button>
          <button
            onClick={handleClearFilter}
            className="btn btn-outline-primary w-50"
          >
            Clear Filter
          </button>
        </div>
      </div>
    </section>
  )
}