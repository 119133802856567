import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import airtable from '../../../../airtables';
import { setAppUserInfo } from '../../../../redux/actions';
import Loading from '../../../../components/Loading';
import { checkBestPractices } from '../utils';
import BP2024ProgressBar from '../components/BP2024ProgressBar';

const BPReflection = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [reflectionIW, setReflectionIW] = useState("");
  const [reflectionTI, setReflectionTI] = useState("");
  const [reflectionI, setReflectionI] = useState("");
  const [reflectionRUC, setReflectionRUC] = useState("");
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = "Reflection - Best Practices - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "reflection");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const canGoNext = () => {
    if (userInfo["Renewal Actions 24-25"].includes("Indigenous Worldviews Reflection") && !reflectionIW) return false;
    if (userInfo["Renewal Actions 24-25"].includes("Trauma-Informed Reflection") && !reflectionTI) return false;
    if (userInfo["Renewal Actions 24-25"].includes("Interactivity Reflection") && !reflectionI) return false;
    if (userInfo["Renewal Actions 24-25"].includes("Refreshing & Updating Content") && !reflectionRUC) return false;
    return true;
  }

  const updateBestPractices = async () => {
    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "ReflectionIW": reflectionIW,
        "ReflectionTI": reflectionTI,
        "ReflectionI": reflectionI,
        "ReflectionRUC": reflectionRUC,
        "2024 Reflection Completed": true
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  const renderContentIW = () => {
    if (!userInfo["Renewal Actions 24-25"].includes("Indigenous Worldviews Reflection")) return null;

    return (
      <div className="reflection-item-content">
        <h4>Indigenous Worldviews</h4>
        <p>When students are presented with content that highlights Indigenous worldviews, perspectives, and culture, it helps to strengthen their sense of <b>identity and belonging</b>. When students are able to share aspects of their culture and community with external audiences, this also ignites a sense of <b>self-confidence and purpose</b>. Hearing from Indigenous role models and being presented with narratives of Indigenous resilience and excellence provides an important source of <b>encouragement, motivation, and hope for the future</b>.</p>
        <p>We invite you to review the <a href="https://sessions.connectednorth.org/cn/resources#Creatively%20Customized%20Content" target="_blank" rel="noreferrer">following resources</a> which include many helpful strategies and simple ideas to include Indigenous perspectives and content in sessions.</p>
        <p>This past school year, teachers and team members have highlighted the following information:</p>
        <ul>
          <li><p>Frequently invite students to share examples or knowledge from their community or culture</p></li>
          <li><p>Visit the School Profiles created by Connected North as they highlight relevant community information and sometimes some vocabulary in the community language. Greeting students in their community language is a simple and thoughtful way to show respect and interest.</p></li>
        </ul>

        <div style={{ marginTop: 30 }}>
          <p>Question: How does your session incorporate Indigenous knowledge, language, or perspectives?</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflectionIW(e.target.value)}
            value={reflectionIW}
          />
        </div>
      </div>
    )
  }

  const renderContentTI = () => {
    if (!userInfo["Renewal Actions 24-25"].includes("Trauma-Informed Reflection")) return null;
    return (
      <div className="reflection-item-content">
        <h4>Trauma-informed Approaches</h4>
        <p>As you continue to learn about and be aware of the various challenges and ways trauma continues to affect Indigenous students in schools today, we also encourage you to find joy, strength, and resilience in the students you connect with. Being compassionate and curious to the complexities of many differing lived realities across the Connected North network fosters reciprocity in relationships and ultimately strengthens wellbeing. It is important to understand how students in the Connected North program are demonstrating resilience and hope by being participants in your session.</p>
        <p>We invite you to review the <a href="https://sessions.connectednorth.org/cn/resources#Creatively%20Customized%20Content" target="_blank" rel="noreferrer">following resources</a> which include many helpful strategies and simple ideas to ensure sessions offer a safe space that promotes wellbeing. </p>
        <p>This past school year, teachers and team members have highlighted the following information:</p>
        <ul>
          <li><p>It is important to understand and respect our role as a guest in the classroom, and to approach all interactions with curiosity and compassion</p></li>
          <li><p>Be mindful of the language you use and the content you share. Use terms and vocabulary that are inclusive of all people and consider sharing only what is necessary and appropriate </p></li>
        </ul>

        <div style={{ marginTop: 30 }}>
          <p>Question: How does your session foster an inclusive, calm, and respectful environment?</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflectionTI(e.target.value)}
            value={reflectionTI}
          />
        </div>
      </div>
    )
  }

  const renderContentI = () => {
    if (!userInfo["Renewal Actions 24-25"].includes("Interactivity Reflection")) return null;
    return (
      <div className="reflection-item-content">
        <h4>Interactivity</h4>
        <p>Interactivity in the classroom enhances attention span, understanding, and knowledge retention. Interactive learning creates a sense of fun and enjoyment, which helps children stay engaged, while opportunities to laugh, share, and collaborate promote wellbeing.</p>
        <p>We invite you to review the <a href="https://sessions.connectednorth.org/cn/resources#Creatively%20Customized%20Content" target="_blank" rel="noreferrer">following resources</a> which include many helpful strategies and simple ideas to increase interactivity and engagement in sessions. </p>
        <p>This past school year, teachers and team members have highlighted the following information:</p>
        <ul>
          <li><p>Students can have a difficult time simply listening to someone for 10+ minutes at a time. Consider ways to engage students throughout your stories or explanations (pausing to check for understanding, incorporating a worksheet, adding a movement break, asking for reflections and input, having students draw a concept, etc.) </p></li>
          <li><p>Always have supporting visuals and focus on images rather than text. Visuals can help support both engagement and understanding, especially for English language learners. </p></li>
        </ul>

        <div style={{ marginTop: 30 }}>
          <p>Question: What interactive components does your session include?</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflectionI(e.target.value)}
            value={reflectionI}
          />
        </div>
      </div>
    )
  }

  const renderContentRUC = () => {
    if (!userInfo["Renewal Actions 24-25"].includes("Refreshing & Updating Content")) return null;
    return (
      <div className="reflection-item-content">
        <h4>Refreshing & updating content</h4>
        <div style={{ marginTop: 10 }}>
          <p>Question: As one of our most booked Content Providers, how can you continue to grow your skills and refresh your content to connect with the same classrooms and provide a new experience?</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflectionRUC(e.target.value)}
            value={reflectionRUC}
          />
        </div>
      </div>
    )
  }

  if (!userInfo["Renewal Actions 24-25"]) return null;

  return (
    <div className="main-container best-practices">
      <BP2024ProgressBar current="reflection" />
      <div style={{ height: 20 }} />
      <h1>{t("bestPractices.reflection.title")}</h1>
      <div className="main-content">
        {renderContentIW()}
        {renderContentTI()}
        {renderContentI()}
        {renderContentRUC()}



        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={saving || !canGoNext()}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;Continue to Next Step</Button>
        </div>
      </div>
    </div>
  )
}

export default BPReflection;
