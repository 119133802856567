import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import airtable from "../../airtables";

const useHook = () => {
  const { userInfo, totalTimezones } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sessions, setSessions] = useState([]);
  const [exploredSessions, setExploredSessions] = useState([]);
  const [error, setError] = useState("");
  const availability = JSON.parse(userInfo["Availability"]) || [];

  useEffect(() => {
    if (!totalTimezones || totalTimezones.length === 0) return;

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTimezones])

  useEffect(() => {
    if (!search) {
      setExploredSessions([...sessions]);
    } else { }
  }, [search, sessions])

  const initialize = async () => {
    if (!userInfo["Time Zone"] || !userInfo["Availability"]) {
      setError("Please configure your availability.");
      return;
    }

    const tz = await airtable.timezones.select(userInfo["Time Zone"][0]);
    const timeZone = tz["Connected North System"];
    airtable.sessions.getAvailableSessions(availability, timeZone).then(res => {
      setSessions(res);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setSessions([]);
    })
  }

  const hostSession = async (session) => {
    await airtable.sessions.update(session.id, {
      "{Session Host(s)}": [userInfo.id]
    });

    const newSessions = sessions.filter(s => s.id !== session.id);
    setSessions([...newSessions]);
  }

  return {
    error,
    loading,
    search,
    changeKeyword: setSearch,
    sessions,
    exploredSessions,
    hostSession
  }
}

export default useHook;