/**
 * @param {object} props
 * @param {number?} props.size
 * @param {object?} props.style
 * @param {string?} props.className
 */
export function TokenBlue({ size, style, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			style={{
				transform: `scale(${size ?? 1})`,
				transformOrigin: "center",
				...style,
			}}
			className={className}
		>
			<path
				d="M8.37441 7.56684L6.89124 6.26907"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M8.4668 5.71286V7.65951"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M8.55969 5.80554L10.2282 7.56682"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M10.2939 5.68555V7.59404"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M10.4424 7.58402L11.9141 6.33304"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M11.9176 6.37228L11.5366 8.65841"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M12.824 7.27264L11.6956 8.40109"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M5.96411 7.5668L8.46695 7.65951"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M5.96411 7.65955L7.53995 8.86461"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M5.50049 8.95731H7.53984"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M11.5918 8.70648H13.4076"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M13.2873 8.77185L11.526 10.3477"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M11.6099 10.395L13.5198 10.5419"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M11.5085 10.383L12.8595 12.0341"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M10.5959 11.7926L12.7852 12.0191"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M5.50049 10.7186L6.52015 9.83792L7.53984 8.95728"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M5.47388 10.7458H7.47508"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M6.07654 12.1256L7.46397 10.8842"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M7.4118 10.7922L7.33582 13.0717"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M8.73596 11.8147L7.33887 13.1383"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M8.71826 11.8036V13.6194"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M8.83826 11.7382L10.6246 13.6885"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M10.5809 11.7942L10.6551 13.7957"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M10.6758 12.0049L11.793 13.0522"
				stroke="#0E6BA8"
				strokeLinecap="round"
			/>
			<path
				d="M17.7707 11.4525C18.8491 6.88473 16.0203 2.30761 11.4525 1.22927C6.88473 0.150918 2.30761 2.97967 1.22927 7.54747C0.15092 12.1153 2.97966 16.6924 7.54747 17.7707C12.1153 18.8491 16.6924 16.0203 17.7707 11.4525Z"
				stroke="#0E6BA8"
				strokeMiterlimit="10"
			/>
			<path
				d="M12.235 15.9656C15.8058 14.4865 17.5015 10.3927 16.0225 6.82178C14.5433 3.2509 10.4495 1.5552 6.87866 3.03431C3.30779 4.51339 1.61209 8.60723 3.09119 12.1781C4.5703 15.7489 8.66411 17.4447 12.235 15.9656Z"
				stroke="#0E6BA8"
				strokeMiterlimit="10"
			/>
		</svg>
	);
}
