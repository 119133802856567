import React, { useState } from 'react';
import TextEllipsis from './TextEllipsis';

const ShowMoreText = ({ text, lines }) => {
    const [showFullText, setShowFullText] = useState(false);

    return (
        <div style={{ paddingBottom: 20 }}>
            <TextEllipsis text={text} lines={lines} fullText={showFullText} />
            {lines > 0 && (
                <button
                    onClick={() => setShowFullText(!showFullText)}
                    style={{
                        float: 'right',
                        fontSize: 15,
                        color: '#525f7f',
                        textDecoration: 'underline'
                    }}
                >
                    {showFullText ? 'Show Less' : 'Show More'}
                </button>
            )}
        </div>
    );
};

export default ShowMoreText;