import AirtableTable from "../AirtableTable";

export class VideoTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Videos")

    this.fields = {
      "Title": "fldnqPr9qxw5UBODJ",
      "VimeoID": "fldRZffH9paGkh6yL",
      "Date Added": "fldsbQbCOfv3lTRFr"
    }
  }

  getVideosByIds = (ids) => {
    if (!ids || ids.length === 0) return [];

    const filter = ids.map((id) => `RECORD_ID() = '${id}'`).join(",");
    return this.list({
      filterByFormula: `OR(${filter})`,
      sort: [{ field: "Date Added", direction: "desc" }]
    });
  }
}