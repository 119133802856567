import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);

export function IndigenousGraph({ percentage }) {
	const percent = percentage !== null && percentage !== undefined ? percentage : 0
	const data = {
		datasets: [
			{
				data: [percent, 1 - percent],
				backgroundColor: ["#336699", "#ffffff"],
				display: true,
				borderColor: "#D1D6DC",
			},
		],
	};
	const { t } = useTranslation();

	return (
		<>

			<h2 style={{ fontSize: 25 }}>
				{t("dashboard-page.indigenous-graph.heading")}
			</h2>
			<p>{t("dashboard-page.indigenous-graph.text")}</p>
			<div style={{ position: "relative", margin: "10px auto" }}>
				<Doughnut
					data={data}
					options={{
						plugins: {
							legend: {
								display: false,
							},
							tooltip: {
								enabled: false,
							},
						},
						rotation: -90,
						circumference: 180,
						cutout: "60%",
						maintainAspectRatio: false,
						responsive: true,
					}}
				/>
				<div
					style={{
						position: "absolute",
						top: "65%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						textAlign: "center",
						width: "100%",
					}}
				>
					<p>
						{Math.round(percent * 100)}%
					</p>
				</div>
			</div>

			<a
				href="/cn/search?indigenousGroups=First+Nations&indigenousGroups=Inuit&indigenousGroups=Métis"
				style={{
					zIndex: 2,
					position: "relative",
					color: "#0c4d78",
					fontWeight: 500,
					textDecoration: "underline",
				}}
			>
				{t("dashboard-page.indigenous-graph.search")}
			</a>
		</>
	);
}
