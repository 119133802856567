import { useEffect, useState } from "react";
import airtable from '../../../airtables';
import { getDefaultPreferredTime } from "../Request/utils";
import { getOrdinal } from "../../../utils/string";

const useHook = (session) => {
    const [loading, setLoading] = useState(true);
    const [provider, setProvider] = useState(null);
    const [times, setTimes] = useState(1);

    const [startDates, setStartDates] = useState([]);
    const [internalErrors, setInternalErrors] = useState([]);
    const [validations, setValidations] = useState([]);

    const [providerSession, setProviderSession] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [processing, setProcessing] = useState(false);

    const length = session["Length (Minutes)"]

    useEffect(() => {
        (async () => {
            const providerInfo = await airtable.providers.select(session["Provider"][0]);
            setProvider(providerInfo);

            const pSession = await airtable.providerSessions.select(session["Session Title"][0]);
            setProviderSession(pSession);
            setLoading(false);
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (startDates.length > times) {
            const dt = startDates.slice(0, times); setStartDates([...dt]);
            const dt1 = internalErrors.slice(0, times); setInternalErrors([...dt1]);
            const dt2 = validations.slice(0, times); setValidations([...dt2]);
        } else if (startDates.length < times) {
            const dt = [...startDates];
            const dt1 = [...internalErrors];
            const dt2 = [...validations];
            for (let i = 0; i < times - startDates.length; i++) {
                dt.push(getDefaultPreferredTime());
                dt1.push("");
                dt2.push(false);
            }
            setStartDates(dt);
            setInternalErrors(dt1);
            setValidations(dt2);
        }
    }, [times]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const invalids = validations.filter(v => !v);
        if (invalids.length) {
            setIsValid(false);
            return;
        }

        const errors = internalErrors.filter(error => error);
        if (errors.length) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    }, [internalErrors, validations]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (startDates.length <= 1) return;

        const sts = startDates.map((item, i) => ({
            no: i,
            startTime: item
        }));
        sts.sort((a, b) => a.startTime - b.startTime);

        const iErrors = [...internalErrors];
        for (let i = 1; i < sts.length; i++) {
            const gap = Math.abs(sts[i - 1].startTime.getTime() - sts[i].startTime.getTime());

            if (gap < length * 60000) {
                iErrors[sts[i].no] = "Overlapping with " + getOrdinal(sts[i - 1].no + 1) + " item";
            } else {
                iErrors[sts[i].no] = ""
            }
        }
        setInternalErrors(iErrors)
    }, [startDates]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateStartDates = (sd, nth) => {
        const dts = [...startDates];
        dts[nth] = sd;

        setStartDates(dts);
    }

    const updateValidations = (isValid, nth) => {
        const dts = [...validations];
        dts[nth] = isValid;

        setValidations(dts);
    }

    const checkProviderAndTeacherValidation = (date) => {
        return new Promise((resolve, reject) => {
            airtable.sessions.getSessionsInSpecificPeriod(
                date,
                length,
                'Provider',
                provider.Name,
                provider.id
            ).then(sessions => {
                if (sessions.length) {
                    resolve("Sorry, the provider is already scheduled for a Connected North session at this time. Please try another date or time!");
                } else {
                    resolve("")
                }
            }).catch(error => {
                resolve("Error!")
            })
        })
    }

    const duplicateSession = async (onFinish) => {
        setProcessing(true);
        const promises = startDates.map(d => checkProviderAndTeacherValidation(d))
        const result = await Promise.all(promises);

        const hasErrors = result.filter(r => r);
        if (hasErrors.length) {
            setInternalErrors(result);
            setProcessing(false);
            return;
        }

        const cloneFields = [
            "Session Title",
            "School(s)",
            "Length (Minutes)",
            "Provider",
            "School Lead",
            "Session Host(s)",
            "Teacher",
            "Well-being Link",
            "Grade(s)",
            "Students",
            "Cost",
            "Subject/Curriculum",
            "Type of Session",
            "Notes",
            "Send Meeting Invite to:",
            "Connection Type",
            "Zoom Account",
            "Supplies Shipment Not Required",
            "Presenters",
            "Series",
            "Cost Override"
        ]

        let commonParam = {};
        cloneFields.forEach(field => {
            commonParam[field] = session[field];
        })

        const paramList = startDates.map(startDate => ({
            'Session Start Date/Time': startDate,
            Status: "Booked",
            ...commonParam
        }))

        await airtable.sessions.createMultiple(paramList);
        setProcessing(false);

        onFinish();
    }

    return {
        loading,
        provider,
        providerSession,
        times,
        changeTimes: setTimes,
        startDates,
        internalErrors,
        updateStartDates,
        updateValidations,
        duplicateSession,
        isValid,
        processing
    }
}

export default useHook;