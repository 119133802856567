import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import airtable from '../../../../airtables';
import { setAppUserInfo } from '../../../../redux/actions';
import Loading from '../../../../components/Loading';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import BP2024ProgressBar from '../components/BP2024ProgressBar';
import { checkBestPractices } from '../utils';

const BPCodeConduct = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [confirm, setConfirm] = useState(false);
  const [reflection, setReflection] = useState("");
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = "Role Expectations - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "roleExpectations");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const updateBestPractices = async () => {
    if (!confirm || !reflection ) return;

    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "2024 Role Expectations Reviewed": confirm,
        "ReflectionRoles": reflection
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="main-container best-practices">
      <BP2024ProgressBar current="role" />
      <div style={{ height: 20 }} />
      <h1>Content Provider Role Expectations</h1>
      <div className="main-content role-expectation">
        <div>
          <h3>Shared Roles & Responsibilities for Facilitating Sessions</h3>

          <p>Facilitating meaningful Connected North sessions is a shared responsibility and requires involvement from classroom teachers, content providers and session hosts. The following document outlines the expectations of the different professional roles that support the learning.</p>

          <div>
            <h4>Content Providers</h4>

            <span><b>Before the session:</b></span>
            <ul>
              <li><b>Preparation & customization:</b>
                <ul className="mb0">
                  <li>Review the school profile to understand who you are connecting with ahead of time, including the community and grade level </li>
                  <li>Ensure your presentation is prepared and rehearsed, and you will be able to include moments that acknowledge this specific group of students’ identities and context</li>
                </ul>
              </li>
              <li><b>Set-up:</b>
                <ul className="mb0">
                  <li>
                    Show up a minimum of 5 minutes early to each session to ensure there are no connection or technical issues
                    <ul className="mb0">
                      <li>Ensure your audio/video quality is good and you have reliable internet connection. If at any time you plan to deliver sessions outside of your home environment/usual location, you need to conduct a tech test with the Connected North team prior to the session</li>
                    </ul>
                  </li>
                  <li>
                    Ensure you have a professional appearance and set up
                    <ul className="mb0">
                      <li>Minimize distractions and noise in your environment/background to remain present</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

            <span><b>During the session:</b></span>
            <ul>
              <li><b>Best Practices:</b>
                <ul className="mb0">
                  <li>Adhere to <a href="https://sessions.connectednorth.org/cn/best-practices/introduction" target="_blank" rel="noopener noreferrer">Connected North’s Best Practices</a> when delivering your content; start with a land acknowledgement, consider continuous student interactivity with a variety of engagement methods, have helpful supporting visuals, customize the content to the specific group of students and incorporate Indigenous worldviews, and ensure your approaches are trauma-informed</li>
                </ul>
              </li>
              <li><b>Role as a guest:</b>
                <ul className="mb0">
                  <li>Remember that you are a guest being invited into a classroom</li>
                  <li>Practice kindness if you see that students are making a mistake or not following directions. Remain curious and avoid making assumptions</li>
                  <li>Classroom management remains the responsibility of the teacher</li>
                  <li>If you need support with any aspect of the session, reach out to the Connected North host or classroom teacher for assistance. Some examples include:
                    <ul>
                      <li>Telepresence unit microphones are very sensitive. If the classroom seems loud, you can ask the Connected North host or teacher to help mute and unmute throughout the session</li>
                      <li>If you are asking students questions, you can ask for the teacher’s help to choose/identify students to answer</li>
                      <li>If you encounter a difficult or uncomfortable moment, please refer to the Session Situation Response Guidelines below</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div>
            <h4>Classroom Teachers</h4>

            <span><b>Before the session:</b></span>
            <ul>
              <li><b>Materials</b>: Ensure any required materials are prepared and ready to go. This could include:
                <ul className="mb0">
                  <li>Printing resource sheets</li>
                  <li>If a shipment of materials was sent to you, please open it ahead of time to ensure all the required materials are included and intact</li>
                  <li>Review the instructions provided in the session listing by clicking the session name under your “<a href="https://sessions.connectednorth.org/cn/upcoming" target="_blank" rel="noopener noreferrer">Upcoming Bookings</a>” and/or the instructions sent by your School Lead</li>
                  <li>If you are unsure how to best prepare for an upcoming session, do not hesitate to reach out to your School Lead</li>
                </ul>
              </li>
              <li><b>Welcoming guests</b>: Prepare students to welcome guests with respect, kindness, and curiosity
                <ul className="mb0">
                  <li>In preparing students for their session, let them know about how they can offer respect, kindness and curiosity in the way they welcome guests, in the way they ask questions and in the way they participate in the activities.  We recognize that your role is to guide the students in their learning and inquiry, support their experience in growing their knowledge and skills.</li>
                  <li>Confirm a classroom agreement to engage in sessions with these three components in mind.</li>
                </ul>
              </li>
            </ul>

            <span><b>During the session: </b></span>
            <ul>
              <li><b>Support</b>: Be actively involved in the session by supporting student engagement. This could include:
                <ul className="mb0">
                  <li>Providing clarifying examples where relevant</li>
                  <li>Repeating student questions as it may sometimes be difficult for the provider to hear</li>
                </ul>
              </li>
              <li>
                <b>Classroom management</b>: Even when Connected North sessions are running, they are still operating in your classroom. Please feel free to continue the same routines and procedures students are used to.
              </li>
            </ul>
          </div>

          <div>
            <h4>Session Hosts</h4>

            <span><b>Before the session:</b></span>
            <ul>
              <li>Preparation & set-up:
                <ul className="mb0">
                  <li>Review the school profile to understand who you are connecting with ahead of time, including the community and grade level</li>
                  <li>Review the session listing to understand who the provider is and what the goals and objectives of this particular session are</li>
                  <li>Show up a minimum of 5 minutes early to each session to ensure there are no connection or technical issues
                    <ul className="mb0">
                      <li>Have the connection details and relevant contact information for all parties involved handy so that you are able to troubleshoot if the session does not start as planned</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>Minimize distractions and noise in your environment/background to remain present</li>
            </ul>

            <span><b>During the session:</b></span>
            <ul>
              <li>Introductions:
                <ul className="mb0">
                  <li>Introduce the classroom and content provider to one another, ensuring you provide an overview of the topic of today’s session which includes highlighting what students will be learning and doing during the session
                    <ul className="mb0">
                      <li>You can also open the session Welcome Map</li>
                      <li>Confirm the session end time with the classroom teacher and Content Provider</li>
                      <li>Have the Rubric open and ready to complete</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>Observations & engagement:
                <ul className="mb0">
                  <li>Practice Active Observation; consider volume & clarity, pace & energy, interactivity, differentiation, accommodations & modifications, as well as evidence of student engagement and learning.</li>
                  <li>Support the participation of students in the session by jumping in to ask clarifying questions if there is confusion, helping to build excitement in the topic; learning alongside.</li>
                  <li>Pay attention to the participant experience. If a difficult situation arises, be ready to intervene and support.</li>
                  <li>If technical issues arise, jump in to support with troubleshooting. Remain calm and kind if your encounter difficulties.</li>
                </ul>
              </li>
              <li>Wrap-up:
                <ul className="mb0">
                  <li>Be sure to extend thanks on behalf of Connected North to the content provider and classroom for joining the session. Highlight some meaningful observations related to content in your closing.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h3>Session Situation Response Guidelines</h3>
          <p>As much as we prepare for each and every session, there is a chance to encounter uncomfortable and/or difficult moments during Connected North sessions. We have developed a framework for Connected North classroom teachers, content providers and session hosts outlining recommendations for  when difficult situations arise. The three pillars for all to consider involve <b>Respect, Kindness and Curiosity.</b></p>

          <p>Here is how it can apply specifically to the Content Provider role. If you would like to read the whole framework, which outlines expectations for classroom teachers and session hosts, please visit the <a href="http://link" target="_blank" rel="noopener noreferrer">Policies section.</a></p>

          <div>
            <h4>Content Providers</h4>

            <span><b>Guiding Principles</b></span>
            <ul>
              <li>Remain child-centered in your interactions, recognizing that Connected North content providers are guests for students who are located in remote communities where there may be limited interactions with diverse populations.</li>
              <li>Practice kindness if you see that students are making a mistake or not following directions.  Remain curious and avoid making assumptions.</li>
              <li>We ask that providers remain open minded and self-reflective in their professional practice.</li>
            </ul>

            <span><b>Intervention</b></span>
            <ul>
              <li>If a student makes a comment or exhibits behaviour that you find to be inappropriate, disrespectful, hurtful or offensive, <b>remain grounded in your role as an educator in their classroom</b> and consider the options available related to addressing the issue. Practice situational awareness, self awareness and open mindedness, inviting students to remain curious in a shared learning environment.
              </li>
              <li>Connected North session hosts are there to help lead and support any intervention required.
                <ul className="mb0">
                  <li>If you wish to address the behaviour directly, <b>please notify the session host in a private communication</b> prior to addressing the group, and confirm consent from the teacher in order to ensure readiness of both the classroom teacher and the host as preparedness is important. </li>
                  <li>Consider how interactions can lead to teachable moments, as catalysts for learning that will extend for years to come. </li>
                  <li>It is your right to choose to end a session early if this is the best decision for you at that time. <b>Please notify the session host</b> who can help to wrap-up the session*. </li>
                </ul>
              </li>
              <li>Please be mindful that sometimes there are busy days for our session hosts who are booked at overlapping times. It is not always feasible for hosts to hear every word as different role functions are being completed at the same time during peak booking times. For this reason, we ask for patience and guidance with regards to how active and hands-on you would like the host to be during your session.</li>
            </ul>
          </div>

          <p>
            <i>*Similarly, there may be times when the classroom teacher asks for a session to end before it’s scheduled time for a variety of reasons. We ask that you remain respectful of this decision, and open-minded to any follow-up by the Connected North host or school lead. </i>
          </p>
        </div>

        <FormGroup check>
          <Input type='checkbox' id="check1" onChange={(e) => setConfirm(e.target.checked)} />
          <Label check for="check1" style={{ fontSize: 16 }}>
            <strong>I confirm that I have read and reviewed the shared responsibilities above</strong>
          </Label>
        </FormGroup>

        <div style={{ marginTop: 30 }}>
          <p>Do you have any reflections about the roles and responsibilities of Content Providers, Classroom Teachers, and Session Hosts outlined above?</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflection(e.target.value)}
            value={reflection}
          />
        </div>

        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={saving || !confirm || !reflection}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;Submit and Continue</Button>
        </div>
      </div>
    </div>
  )
}

export default BPCodeConduct;
