import { Breadcrumb as BC, BreadcrumbItem } from "reactstrap";

export function Breadcrumb({ pageTitle }) {
	return (
		<section>
			<BC>
				<BreadcrumbItem>
					<a href="/">Home</a>
				</BreadcrumbItem>
				<BreadcrumbItem>
					<a href="/cn/collection">Collections</a>
				</BreadcrumbItem>
				<BreadcrumbItem active>{pageTitle}</BreadcrumbItem>
			</BC>
		</section>
	);
}
