import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import airtable from '../../../airtables'
import { SELECTBOX_STYLE } from '../../../utils/constants';
import Select from 'react-select';
import Loading from '../../../components/Loading';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

const AssignFundingPoolModal = ({
  session,
  onToggle
}) => {
  const { userInfo } = useSelector(state => state.appInfo);
  const [funder, setFunder] = useState(null);
  const [funders, setFunders] = useState([]);
  const [saving, setSaving] = useState(false)
  const [insufficientBudget, setInsufficientBudget] = useState(false);

  useEffect(() => {
    airtable.funds.getFunders(userInfo, session).then(res => {
      const result = res.filter(item => {
        if (!session["Indigenous?"] && item["Indigenous Provider Exclusive?"]) return false;
        return true;
      })
      setFunders(result);
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!session || !funder) return;

    const firstName = userInfo["First Name"];
    if (!firstName) return ;
    if (!funder[`${firstName}'s Budget Remaining`]) return ;

    setInsufficientBudget(session["Credit Cost"] * 20 > funder[`${firstName}'s Budget Remaining`])
  }, [session, funder, userInfo])

  const saveAssignment = async () => {
    setSaving(true);
    await airtable.funds.update(session.id, {
      "Assign to Funding Pool": [funder.id]
    });
    setSaving(false);
    onToggle();
  }

  const renderTeamBalance = () => {
    const firstName = userInfo["First Name"];
    if (!firstName) return null;

    if (!funder[`${firstName}'s Budget Remaining`]) return null;

    return (
      <Fragment>
        <span>Session Cost: ${session["Credit Cost"] * 20}</span><br />
        <span>Your Budget Remaining: ${funder[`${firstName}'s Budget Remaining`]}</span>
      </Fragment>
    )
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Assign to Funding Pool</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Select
          className="form-style"
          placeholder=""
          value={funder ? { value: funder.id, label: funder["Funder"] } : null}
          onChange={selectedOption => {
            const t = funders.find(f => f.id === selectedOption.value)
            setFunder(t);
          }}
          isDisabled={saving}
          styles={SELECTBOX_STYLE}
          options={funders.map((funder) => ({ value: funder.id, label: funder["Funder"] }))}
        />
        {!!funder && (
          <div className={styles.selectedFunderInfo}>
            <span>Focus: {funder["Focus"]}</span>

            <div>{renderTeamBalance()}</div>
            {insufficientBudget && (
              <div className={styles.errors}>
                <span>Your budget remaining in this funding pool isn't enough to cover the costs of this session</span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >Cancel</Button>
        <Button
          color="success"
          type="button"
          disabled={saving || insufficientBudget}
          onClick={() => saveAssignment()}
        >{saving && (<Loading size={14} />)}Save Assignment</Button>
      </div>
    </Modal>
  )
}

export default AssignFundingPoolModal;