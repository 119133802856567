import React, { useEffect, useState } from 'react';
import './styles.scss';

import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Loading from '../../../../components/Loading';
import airtable from '../../../../airtables';
import { useDispatch, useSelector } from 'react-redux';
import { setAppUserInfo } from '../../../../redux/actions';
import { useTranslation } from 'react-i18next';
import { checkBestPractices } from '../utils';
import BP2024ProgressBar from '../components/BP2024ProgressBar';

const RateValues = [
  { "id": "very-satisfied", "label": "Very Satisfied" },
  { "id": "satisfied", "label": "Satisfied" },
  { "id": "neutral", "label": "Neutral" },
  { "id": "unsatisfied", "label": "Unsatisfied" },
  { "id": "very-unsatisfied", "label": "Very Unsatisfied" }
];
const PreferredCommunicationMethods = [
  { "id": "email", "label": "E-mail" },
  { "id": "phone", "label": "Phone" },
  { "id": "text-message", "label": "Text Message" }
]

const BPSurvey = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [survey1, setSurvey1] = useState("");
  const [survey2, setSurvey2] = useState("");
  const [survey3, setSurvey3] = useState("");
  const [survey4, setSurvey4] = useState("");
  const [survey5, setSurvey5] = useState(userInfo["Preferred Communication Method"]);
  const [survey6, setSurvey6] = useState(userInfo.Phone);
  const [confirm1, setConfirm1] = useState(false);
  const [confirm2, setConfirm2] = useState(false);
  const [confirm3, setConfirm3] = useState(false);
  const [error, setError] = useState({});
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = "Survey - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "survey");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const onSave = async () => {
    if (saving) return;

    let nError = {};
    if (!survey1) nError.survey1 = "This field is required."
    if (!survey2) nError.survey2 = "This field is required."
    if (!survey3) nError.survey3 = "This field is required."
    if (!survey4) nError.survey4 = "This field is required."
    if (!survey5) nError.survey5 = "This field is required."
    if (!survey6) nError.survey6 = "This field is required."
    if (!confirm1) nError.confirm1 = true;
    if (!confirm2) nError.confirm2 = true;
    if (!confirm3) nError.confirm3 = true;

    if (Object.keys(nError).length > 0) {
      setError(nError);
      return;
    } else {
      setError({});
    }

    setSaving(true);
    try {
      await airtable.otherSurveys.create({
        "Provider": [userInfo.id],
        "OverallExperience": survey1,
        "LevelSupport": survey3,
        "Highlight": survey2,
        "Effective": survey4
      });

      await airtable.providers.update(userInfo.id, {
        "Phone": survey6,
        "Preferred Communication Method": survey5
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="main-container best-practices">
      <span className="page-topic"></span>
      <div style={{ height: 20 }} />
      <BP2024ProgressBar current="survey" />
      <div className="survey-form">
        <Form>
          <FormGroup tag="fieldset">
            <Label>{t("survey.phase1.label")}</Label>
            {
              RateValues.map((item, i) => (
                <FormGroup check key={i}>
                  <Label check>
                    <Input
                      type="radio"
                      checked={survey1 === item.label}
                      onChange={(e) => setSurvey1(item.label)}
                      disabled={saving}
                    />{' '}{t("survey.phase1." + item.id)}
                  </Label>
                </FormGroup>
              ))
            }
            <span className="error">{error.survey1}</span>
          </FormGroup>

          <FormGroup>
            <Label for="survery2">{t("survey.phase2")}</Label>
            <Input
              type="textarea"
              id="survery2"
              onChange={e => setSurvey2(e.target.value)}
              invalid={error.survey2 ? true : false}
            />
            <span className="error">{error.survey2}</span>
          </FormGroup>

          <FormGroup tag="fieldset">
            <Label>{t("survey.phase3.label")}</Label>
            {
              RateValues.map((item, i) => (
                <FormGroup check key={i}>
                  <Label check>
                    <Input
                      type="radio"
                      checked={survey3 === item.label}
                      onChange={(e) => setSurvey3(item.label)}
                    />{' '}{t("survey.phase1." + item.id)}
                  </Label>
                </FormGroup>
              ))
            }
            <span className="error">{error.survey3}</span>
          </FormGroup>

          <FormGroup>
            <Label for="survery4">{t("survey.phase4")}</Label>
            <Input
              type="textarea"
              id="survery4"
              onChange={e => setSurvey4(e.target.value)}
              invalid={error.survey4 ? true : false}
            />
            <span className="error">{error.survey4}</span>
          </FormGroup>

          <FormGroup>
            <Label for="survery5">{t("survey.phase5.label")}</Label>
            <div className="multicheck-container">
              <div className="multicheck">
                {
                  PreferredCommunicationMethods.map((item, i) => (
                    <div
                      className={survey5 !== item.label ? "multicheck-item" : "multicheck-item item-checked"}
                      key={i}
                      onClick={() => setSurvey5(item.label)}
                    ><span>{t("survey.phase5." + item.id)}</span></div>
                  ))
                }
              </div>
            </div>
            <span className="error">{error.survey5}</span>
          </FormGroup>

          <FormGroup>
            <Label for="survery6">{t("survey.phase6")}</Label>
            <Input
              type="text"
              id="survery6"
              defaultValue={survey6}
              onChange={e => setSurvey6(e.target.value)}
              invalid={error.survey6 ? true : false}
            />
            <span className="error">{error.survey6}</span>
          </FormGroup>

          {t("bestPractices.survey.engagements")}
          <FormGroup check>
            <Input type='checkbox' id="check1" onChange={(e) => setConfirm1(e.target.checked)} invalid={error.confirm1} />
            <Label check for="check1">{t("bestPractices.survey.li1")}</Label>
          </FormGroup>

          <FormGroup check>
            <Input type='checkbox' id="check2" onChange={(e) => setConfirm2(e.target.checked)} invalid={error.confirm2} />
            <Label check for="check2">{t("bestPractices.survey.li2")}</Label>
          </FormGroup>

          <FormGroup check>
            <Input type='checkbox' id="check3" onChange={(e) => setConfirm3(e.target.checked)} invalid={error.confirm3} />
            <Label check for="check3">{t("bestPractices.survey.li3")}</Label>
          </FormGroup>
        </Form>

        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            onClick={onSave}
            disabled={saving}
          >{saving && <Loading size={14} />}&nbsp;Submit</Button>
        </div>
      </div>
    </div>
  )
}

export default BPSurvey;
