import React, { useEffect, useState } from 'react';
import Loading from '../../../components/Loading';
import { Button, Modal } from 'reactstrap'
import { toast } from 'react-toastify';
import airtable from '../../../airtables';
import './styles.scss'
import { useTranslation } from 'react-i18next';
import { checkFreeBusy } from '../../../libs/apis';

const ReassignSchoolLead = ({ session, onDone, onToggle }) => {
    const [loading, setLoading] = useState(true)
    const [avaiablePrimaryHosts, setAvaiablePrimaryHosts] = useState([]);
    const [unavaiablePrimaryHosts, setUnavaiablePrimaryHosts] = useState([]);
    const [nPrimanyHosts, setNPrimaryHosts] = useState([]);

    const [saving, setSaving] = useState(false);
    const [leads, setLeads] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        if (session["Session Host(s)"] && session["Session Host(s)"].length) {
            const ls = session["Session Host(s)"].reduce((result, hostId) => {
                return {
                    ...result,
                    [hostId]: true
                }
            }, {});

            setLeads(ls);
        }

        initialize();
    }, [session]) // eslint-disable-line react-hooks/exhaustive-deps

    const initialize = async () => {
        try {
            const startDate = new Date(session["Session Start Date/Time"]);
            let endDate = new Date(startDate);
            endDate.setMinutes(startDate.getMinutes() + session["Length (Minutes)"]);

            const psh = [], npsh = [];
            const fbStatusPromise = [], hostingStatusPromise = [];
            const ts = await airtable.teams.getAllTeams();
            ts.forEach(team => {
                if (team["Primary Session Host"]) {

                    psh.push(team)
                    fbStatusPromise.push(checkFreeBusy(
                        team["Email"],
                        startDate.toISOString(),
                        endDate.toISOString()
                    ));

                    hostingStatusPromise.push(airtable.sessions.getTeamSessionsOnDay(
                        team.id,
                        team["Name"],
                        startDate.getFullYear(),
                        startDate.getMonth() + 1,
                        startDate.getDate()
                    ));
                } else {
                    npsh.push(team)
                }
            });
            setNPrimaryHosts(npsh);

            const fbRes = await Promise.all(fbStatusPromise);
            const hsRes = await Promise.all(hostingStatusPromise);

            const unavailable = [], available = []
            psh.forEach((host, i) => {
                if (fbRes[i] === 'free') {
                    available.push({
                        ...host,
                        "bookedSessions": hsRes[i].length
                    })
                } else {
                    unavailable.push({
                        ...host,
                        "bookedSessions": hsRes[i].length
                    })
                }
            })
            setAvaiablePrimaryHosts(available);
            setUnavaiablePrimaryHosts(unavailable);
            setLoading(false)
        } catch (error) {
            console.log("Error:", error);
            setLoading(false)
        }
    }

    const onSave = () => {
        const ls = Object.keys(leads);
        setSaving(true);

        airtable.sessions.update(session.id, { 'Session Host(s)': ls }).then(() => {
            setSaving(false)
            toast.success("Session Host is assigned successfully! They will receive an invite within 10 minutes.")
            onDone();
        }).catch(error => {
            setSaving(false)
            toast.error(error.toString());
        })
    }

    const renderBookedSessions = (host) => {
        if (host.bookedSessions === undefined || host.bookedSessions === null) return null;

        return ` (${host.bookedSessions} sessions hosted this day)`
    }

    const renderHosts = (label, nav, hosts) => {
        if (!hosts || hosts.length === 0) return null;

        return (
            <div className="host-group">
                <div className="group-title">{label}</div>
                {hosts.map((team, i) => (
                    <div className="custom-control custom-checkbox mb-3" key={i}>
                        <input
                            className="custom-control-input"
                            id={`customcheckbox-${nav}${i}`}
                            type="checkbox"
                            defaultChecked={leads[team.id] ? true : false}
                            disabled={saving}
                            onChange={
                                (e) => {
                                    let ls = { ...leads };
                                    if (e.target.checked) {
                                        ls[team.id] = true
                                    } else {
                                        delete ls[team.id];
                                    }
                                    setLeads(ls)
                                }
                            }
                        />
                        <label className="custom-control-label" htmlFor={`customcheckbox-${nav}${i}`}>
                            {team.Name}
                            {renderBookedSessions(team)}
                        </label>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={true}
            toggle={() => onToggle()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">Reassign Session Host</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    loading ? (
                        <div className='loading-teams'>
                            <Loading size={24} />
                        </div>
                    ) : (
                        <div className='teams'>
                            {renderHosts("Available Primary Hosts", "available", avaiablePrimaryHosts)}
                            {renderHosts("Unavailable Primary Hosts", "unavailable", unavaiablePrimaryHosts)}
                            {renderHosts("Other Team Members", "others", nPrimanyHosts)}
                        </div>
                    )
                }
            </div>
            <div className="modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => onSave()}
                >
                    {saving ? (<Loading size={14} />) : (null)}{t("save")}
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >
                    {t("close")}
                </Button>
            </div>
        </Modal >
    )
}

export default ReassignSchoolLead