import { nanoid } from "nanoid";
import { useState } from "react";
import StepSlider from "../../../components/StepSlider";
import s from "../styles.module.scss";
import "rc-slider/assets/index.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FormGroup, Checkbox, FormControlLabel } from "@mui/material";

/**
 * @param {import("../useFilter").FilterProps} props
 */
export function Filter({
	credit,
	handleCreditSlider,
	wellBeingLinks,
	groups,
	onGroups,
	groupFilter,
	wellBeingLinkFilter,
	onWellBeingLinks,
}) {

	return (
		<div className={s.filterInner}>
			{wellBeingLinks.length > 1 && (
				<Item title="Well-Being Link(s)">
					<FormGroup>
						{wellBeingLinks.map((wbl, i) => {
							return (
								<FormControlLabel
									key={nanoid(10)}
									control={
										<Checkbox
											sx={{
												paddingTop: 0,
												paddingBottom: 0,
											}}
											checked={wellBeingLinkFilter[i]}
											onClick={onWellBeingLinks(i)}
										/>
									}
									label={wbl}
								/>
							);
						})}
					</FormGroup>
				</Item>
			)}

			{groups.length > 1 && (
				<Item title="Indigenous Group(s)">
					<div className="d-flex flex-column">
						{groups.map((g, i) => {
							const key = nanoid(10);
							return (
								<label htmlFor={key} key={key}>
									<input
										type="checkbox"
										style={{ marginRight: 5 }}
										checked={groupFilter[i]}
										onChange={onGroups(i)}
									/>
									{g}
								</label>
							);
						})}
					</div>
				</Item>
			)}
			{/*
			<Accordion
				onChange={() => null}
				expanded={true}
				className={s.item}
				style={{ margin: 0 }}
			>
				<AccordionSummary
					aria-controls="panel1a-content"
					id="panel1a-header"
					style={{ fontWeight: 700, cursor: "auto" }}
				>
					Community
				</AccordionSummary>
				<AccordionDetails>{community}</AccordionDetails>
			</Accordion>
      */}
			<div className={s.item} style={{ borderBottom: "none" }}>
				<h2 style={{ padding: "20px 10px 5px" }}>Credits</h2>
				<div className={s.slider} style={{ padding: "0 10px" }}>
					<StepSlider
						max={30}
						value={credit}
						onChange={handleCreditSlider}
					/>
				</div>
			</div>
		</div>
	);
}

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 */
function Item({ title, children }) {
	/** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
	const [open, setOpen] = useState(false);
	function handleOpen() {
		setOpen((o) => !o);
	}
	return (
		<Accordion onChange={handleOpen} expanded={open} className={s.item}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				style={{ fontWeight: 700 }}
			>
				{title}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
}
