import React, { useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Modal
} from 'reactstrap';
import Loading from '../../components/Loading';

const AddCILCRequestIDModal = ({ session, onToggle, onDone }) => {
  const [updating, setUpdating] = useState(false);
  const [id, setId] = useState(session["CILC Request ID"]);
  const [error, setError] = useState('');

  const confirm = async () => {
    if (!id) return;

    try {
      setError("");
      setUpdating(true);
      await onDone(id);
      onToggle();
    } catch (error) {
      setError("Operation was failed.");
    }

    setUpdating(false);
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">{session["CILC Request ID"] ? "Edit" : "Add"} CILC Request ID</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <FormGroup>
          <label>Request ID</label>
          <Input
            type="text"
            className="form-style"
            defaultValue={id}
            onChange={e => setId(e.target.value)}
          />
        </FormGroup>

        <div style={{ width: '100%', height: 20, color: 'red' }}>
          <span>{error}</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >Cancel</Button>
        <Button
          color="success"
          type="button"
          onClick={confirm}
          disabled={!id}
        >{updating && (<Loading size={20} />)}Save</Button>
      </div>
    </Modal>
  )
}

export default AddCILCRequestIDModal;