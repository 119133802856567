import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { OnboardPageOuter } from "./components/OnboardPageOuter";
import {
	getIsIncompleteTeacher,
	getIsNewTeacher,
} from "./utils/get-is-new-teacher";
import {
	ONBOARD_TEACHER_ROOT,
	ONBOARD_TEACHER_ROUTES,
	UPDATE_TEACHER_ROUTES,
} from "./routing";
import * as steps from "./steps";

const { START, CREATE, WELCOME } = ONBOARD_TEACHER_ROUTES;

export const OnboardTeacher = () => {
	const { userInfo } = useSelector((state) => state.appInfo);

	// Intentionally set these states once (on init) and do not update
	// as user moves through create/update steps in order
	const [isNewTeacher] = useState(getIsNewTeacher(userInfo));
	const [isIncompleteTeacher] = useState(getIsIncompleteTeacher(userInfo));

	const routesList =
		isNewTeacher || isIncompleteTeacher
			? ONBOARD_TEACHER_ROUTES
			: UPDATE_TEACHER_ROUTES;

	const allStepRoutes = Object.values(routesList).map(({ name, path }) => ({
		name,
		path,
	}));

	const countStepRoutes = allStepRoutes
		.filter(
			({ name }) =>
				name !== START.name &&
				name !== CREATE.name &&
				name !== WELCOME.name,
		)
		.map(({ name, path }, index) => ({
			step: index + 1,
			name,
			path,
		}));

	return (
		<OnboardPageOuter>
			<Switch>
				{Object.values(routesList).map(({ name, ...routeProps }) => {
					const StepPage = steps[name];
					return (
						<Route
							key={name}
							render={(props) => (
								<StepPage
									currentRouteName={name}
									allRoutes={allStepRoutes}
									isNewTeacher={isNewTeacher}
									isIncompleteTeacher={isIncompleteTeacher}
									stepRoutes={countStepRoutes}
									{...props}
								/>
							)}
							{...routeProps}
						/>
					);
				})}
				<Route path={`${ONBOARD_TEACHER_ROOT}/*`}>
					<Redirect to={ONBOARD_TEACHER_ROOT} />
				</Route>
			</Switch>
		</OnboardPageOuter>
	);
};
