import { nanoid } from "nanoid";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import "./filter.css";

/**
 * @param {object} props
 * @param {boolean} props.open
 * @param {() => void} props.toggleOpen
 * @param {string} props.filterValues
 * @param {(i: number) => React.ChangeEventHandler<HTMLInputElement>} props.handleSelect
 * @param {Array<Array<string>>} props.filterOpts
 */
export function Filter({
	open,
	toggleOpen,
	filterValues,
	handleSelect,
	filterOpts,
}) {
	return (
		<Dropdown
			isOpen={open}
			toggle={toggleOpen}
			direction="down"
			style={{ zIndex: 900 }}
		>
			<DropdownToggle
				caret
				style={{
					width: open ? "245px" : "max-content",
					borderRadius: open ? "5px 5px 0 0" : "100px",
					backgroundColor: open ? "#ffffff" : "#F3F9FF",
					border: open
						? "1px solid rgba(54, 192, 240, 0.49)"
						: "none",
					boxShadow: "none",
				}}
			>
				Filter by
			</DropdownToggle>
			<DropdownMenu
				style={{
					width: "245px",
					border: "1px solid rgba(54, 192, 240, 0.49)",
					borderTop: "none",
					borderRadius: "0 0 5px 5px",
				}}
			>
				{filterOpts.map(([k, v], i) => (
					<DropdownItem
						toggle={false}
						key={nanoid(10)}
						className="d-flex align-items-center"
					>
						<input
							type="checkbox"
							value={v}
							id={k}
							onChange={handleSelect(i)}
							checked={filterValues[i]}
						/>
						<label className="p-0 m-0 ml-2" htmlFor={k}>
							{k}
						</label>
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
}
