import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Vimeo from '@u-wave/react-vimeo';
import { getVimeoIDFromUrl } from '../../../utils/url';
import Loading from '../../../components/Loading';
import './styles.scss';

const VimeoPlayer = ({ onToggle, title, vimeo, onPlay }) => {
  const [videoWidth, setVideoWidth] = useState(720);
  const [loading, setLoading] = useState(true);
  const [vimeoId, setVimeoId] = useState("")

  const resizeListener = () => {
    if (window.innerWidth < 768) setVideoWidth(window.innerWidth - 40);
    else setVideoWidth(720)
  }

  useEffect(() => {
    setTimeout(() => {
      resizeListener();
    })

    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener("resize", resizeListener);
    }
  }, [])

  useEffect(() => {
    if (vimeo.startsWith("https://")) {
      setVimeoId(getVimeoIDFromUrl(vimeo));
    } else {
      setVimeoId(vimeo)
    }
  }, [vimeo])

  if (!vimeoId) return null;

  return (
    <Modal
      className="modal-dialog-centered video-player"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <ModalHeader toggle={onToggle}>{title}</ModalHeader>
      <ModalBody className='vimeo-player-body'>
        {loading && (
          <div className="player-loader">
            <Loading size={80} />
          </div>
        )}
        <Vimeo
          video={vimeoId}
          width={videoWidth}
          onPlay={onPlay}
          autoplay
          onProgress={() => setLoading(false)}
        />
      </ModalBody>
    </Modal>
  )
}

export default VimeoPlayer;