const unavailableSubjectNames = [
	"Premium Session",
	"Therapy (SLP/OT)",
	"Holiday Themed",
	"Future Pathways",
	"ASL",
];

export const getAvailableSubjects = (allSubjects = []) =>
	allSubjects.filter(
		({ Subject }) => !unavailableSubjectNames.includes(Subject),
	);
