import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "../../assets/style.scss";

/**
 * @typedef {Object} Props
 * @property {number} credits
 *
 * @param {import("../useRequestInvoice").UseRequestInvoice & Props} props
 */
export function RequestInvoice({
	showModal,
	toggleModal,
	addressedTo,
	handleAddressedTo,
	invoiceNumber,
	handleInvoiceNumber,
	error,
	loading,
	handleRequestInvoice,
	credits,
}) {
	return (
		<Modal isOpen={showModal} toggle={toggleModal} centered={true}>
			<ModalHeader
				toggle={toggleModal}
				close={<CloseButton onClick={toggleModal} />}
			>
				Request Invoice
			</ModalHeader>
			<ModalBody>
				<p
					className="font-weight-normal"
					style={{ alignSelf: "flex-start" }}
				>
					You are requesting an invoice for the purchase of {credits} {credits > 1 ? "credits" : "credit"}. A link to view and download a PDF of the invoice will be e-mailed to you within 1 hour. You may edit the "Address Invoice to" field if you will be submitting it to an education authority, external sponsor, etc.
				</p>

				<form
					onSubmit={handleRequestInvoice}
					className="d-flex flex-column justify-content-center align-items-center"
					style={{ gap: 25 }}
				>
					<FormFields>
						<label htmlFor="invoice-address">Address Invoice to: *</label>
						<input
							required
							id="invoice-address"
							type="text"
							className="input-field"
							value={addressedTo}
							onChange={handleAddressedTo}
						/>
					</FormFields>

					<FormFields>
						<label htmlFor="invoice-number">Purchase Order Number (optional)</label>
						<input
							type="text"
							id="invoice-number"
							className="input-field"
							value={invoiceNumber}
							onChange={handleInvoiceNumber}
						/>
					</FormFields>

					<button
						type="submit"
						className="btn btn-primary mt-2"
						style={{ width: 200 }}
						disabled={loading}
					>
						{loading ? "Requesting..." : "Submit"}
					</button>
					{!error && <p className="text-danger">{error}</p>}
				</form>
			</ModalBody>
		</Modal>
	);
}

function FormFields({ children }) {
	return <div className="d-flex flex-column w-100">{children}</div>;
}

/**
 * @param {React.HTMLAttributes<HTMLButtonElement>}
 */
function CloseButton(props) {
	return (
		<button {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="19"
				height="19"
				viewBox="0 0 19 19"
				fill="none"
			>
				<path
					d="M14.4398 1.18161L9.46796 6.15345L4.49612 1.18161C3.88603 0.571523 2.89649 0.571523 2.28641 1.18161L1.18155 2.28646C0.571466 2.89655 0.571466 3.88609 1.18155 4.49617L6.1534 9.46802L1.18155 14.4399C0.571466 15.0499 0.571466 16.0395 1.18155 16.6496L2.28641 17.7544C2.89649 18.3645 3.88603 18.3645 4.49612 17.7544L9.46796 12.7826L14.4398 17.7544C15.0499 18.3645 16.0394 18.3645 16.6495 17.7544L17.7544 16.6496C18.3645 16.0395 18.3645 15.0499 17.7544 14.4399L12.7825 9.46802L17.7544 4.49617C18.3645 3.88609 18.3645 2.89655 17.7544 2.28646L16.6495 1.18161C16.0394 0.571523 15.0499 0.571523 14.4398 1.18161Z"
					fill="#2B4F70"
				/>
			</svg>
		</button>
	);
}
