import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Input, Label, Modal } from 'reactstrap';
import * as EmailValidator from 'email-validator';

const PresenterFormModal = ({ presenter, onToggle, onSave }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        if (presenter) {
            setEmail(presenter.Email);
            setName(presenter.Name);
            setPhone(presenter.Phone);
        }
    }, [presenter])

    const savePresenter = () => {
        if (!email || !EmailValidator.validate(email)) {
            alert("Email field should be non-empty and valid.");
            return;
        }
        if (!name) {
            alert("You should input your full name.");
            return;
        }

        onSave(email, name, phone);
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={true}
            toggle={onToggle}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">{presenter ? "Edit" : "Add"} Presenter</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={onToggle}
                ><span aria-hidden={true}>×</span></button>
            </div>
            <div className="modal-body">
                <div className="">
                    <FormGroup className="registering-item">
                        <Label for="email">Email</Label>
                        <Input
                            id="email"
                            className="form-style"
                            placeholder={t("email")}
                            defaultValue={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="registering-item">
                        <Label for="name">Name</Label>
                        <Input
                            id="name"
                            className="form-style"
                            placeholder={t("full-name")}
                            defaultValue={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="registering-item">
                        <Label for="phone">Phone Number</Label>
                        <Input
                            id="phone"
                            className="form-style"
                            placeholder={t("phone-number")}
                            defaultValue={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => savePresenter()}
                >Save</Button>
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={onToggle}
                >Close</Button>
            </div>
        </Modal>
    )
}

export default PresenterFormModal;