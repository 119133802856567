import { useState } from "react";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import { useUpdateUserInfo } from "./use-update-user-info";
import { ONBOARD_TEACHER_OPTIONAL_TERMS_ENABLED } from "../routing";

export const useCodeOfConduct = () => {
	const { loading, updateUserInfo, confirmUserInfoUpdated } =
		useUpdateUserInfo();
	const [formValues, setFormValues] = useState({
		codeOfConduct: false,
	});

	const updateCodeOfConduct = async (
		nextLink = "",
		isNewOrIncompleteTeacher,
	) => {
		const { codeOfConduct } = formValues;

		const updateBody = {
			[TEACHER_FIELDS.CODE_OF_CONDUCT]: codeOfConduct,
		};

		updateUserInfo(updateBody, nextLink, () => {
			if (
				!isNewOrIncompleteTeacher &&
				!ONBOARD_TEACHER_OPTIONAL_TERMS_ENABLED
			) {
				confirmUserInfoUpdated();
			}
		});
	};

	return { loading, formValues, setFormValues, updateCodeOfConduct };
};
