import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// TODO: change this after you deploy the function
const ENDPOINT = "https://helpemail-raj5gg67oa-uc.a.run.app";

export const INQUIRY_OPTS = [
	"There is an error in my transaction list.",
	"I have a request for my school lead.",
	"I'd like to buy more credits.",
	"Another issue or question.",
];

/**
 * @typedef {Object} UseHelpModal
 * @property {boolean} openModal
 * @property {() => void} toggleModal
 * @property {string} inquiry
 * @property {React.ChangeEventHandler<HTMLSelectElement>} handleInquiry
 * @property {string} message
 * @property {React.ChangeEventHandler<HTMLTextAreaElement>} handleMessage
 * @property {React.ChangeEventHandler<HTMLFormElement>} handleSubmit
 *
 * @property {string | undefined} error
 * @property {boolean} ok
 * @property {boolean} loading
 *
 * @returns {UseHelpModal}
 */
export function useHelp() {
	const [inquiry, setInquiry] = useState(INQUIRY_OPTS[0]);
	/** @type {React.ChangeEventHandler<HTMLSelectElement>} */
	const handleInquiry = (e) => setInquiry(() => e.target.value);

	const [message, setMessage] = useState("");
	/** @type {React.ChangeEventHandler<HTMLTextAreaElement>} */
	const handleMessage = (e) => setMessage(() => e.target.value);

	/** @type {[string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]} */
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [ok, setOK] = useState(false);

	// success toast opens for `delayed` mil-seconds then closes automatically
	const delayed = 2000;
	useEffect(() => {
		if (!ok) return;
		const id = setTimeout(() => {
			setOK(() => false);
		}, delayed);
		return () => clearTimeout(id);
	}, [ok]);

	const [openModal, setOpenModal] = useState(false);
	function resetState() {
		setMessage(() => "");
		setInquiry(() => INQUIRY_OPTS[0]);
		setError(() => undefined);
	}
	function toggleModal() {
		if (!openModal) resetState();
		setOpenModal((o) => !o);
	}

	const { userInfo } = useSelector((state) => state.appInfo);
	/** @type {React.ChangeEventHandler<HTMLFormElement>} */
	async function handleSubmit(e) {
		e.preventDefault();
		/** @type {string} */
		const name = userInfo["Teacher Name"];
		/** @type {string} */
		const email = userInfo["Email"];
		try {
			setLoading(() => true);
			const res = await fetch(ENDPOINT, {
				method: "POST",
				headers: new Headers({
					"content-type": "application/json",
				}),
				body: JSON.stringify({ name, email, message, inquiry }),
			});
			const { status } = res;
			if (status !== 202) {
				const err = await res.json();
				throw new Error(err);
			}
			setOK(() => true);
			setOpenModal(() => false);
			resetState();
		} catch (e) {
			setError(() => "Something went wrong, try again later.");
			console.error(e);
		} finally {
			setLoading(() => false);
		}
	}

	return {
		openModal,
		toggleModal,
		inquiry,
		handleInquiry,
		message,
		handleMessage,
		handleSubmit,
		error,
		ok,
		loading,
	};
}
