import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Customization from "../../../assets/img/icons/categories/customization.svg"
import Acknowledgements from "../../../assets/img/icons/categories/acknowledgements.svg"
import Indigenous from "../../../assets/img/icons/categories/indigenous.svg"
import Interactivity from "../../../assets/img/icons/categories/interactivity.svg"
import Wellbeing from "../../../assets/img/icons/categories/wellbeing.svg"
import './../styles.scss';


export const ResourceBrowse = ({ resources }) => {
  const { t } = useTranslation();
  return (
    <section className="resource_browse">
      <div>
        <h2>{t('resources-hub.headers.category')}</h2>
        <div className="categories">
          {Object.keys(resources).map((key, i) => {
            const category = getCategory(key);
            if (!category) return (<Fragment key={i}></Fragment>)
            return (
              <a href={`#${key}`} key={i} className="category">
                <div className="icon-wrap">
                  <img src={category.icon} alt="" />
                </div>

                <p>{t(category.label)}</p>
              </a>
            )
          })}
        </div>

      </div>
    </section>
  )
}

export function getCategory(id) {
  const category = Categories.find(x => x.id === id);
  if (category !== null && category !== undefined) return category;
  return null;
}

export const Categories = [
  {
    id: 'Creatively Customized Content',
    title: 'customized-content.title',
    label: 'customized-content.label',
    icon: Customization,
  },
  {
    id: 'Meaningful Land Acknowledgement',
    title: 'land-acknowledgement.title',
    label: 'land-acknowledgement.label',
    icon: Acknowledgements,
  },
  {
    id: 'Indigenous Histories & Contributions',
    title: 'indigenous-worldviews.title',
    label: 'indigenous-worldviews.label',
    icon: Indigenous,
  },
  {
    id: 'Facilitation of Student Interactivity',
    title: 'interactivity.title',
    label: 'interactivity.label',
    icon: Interactivity,
  },
  {
    id: 'Trauma-informed Approaches and a Focus on Well-Being',
    title: 'focus-on-wellbeing.title',
    label: 'focus-on-wellbeing.label',
    icon: Wellbeing,
  },
]

