import React, { useEffect } from 'react';
import Player from '@vimeo/player';

const EmbedVimeoPlayer = ({
  videoId,
  title,
  onPlay,
  onEnd,
  ...others
}) => {
  useEffect(() => {
    var iframe = document.getElementById(`vimeo${videoId}`);
    var player = new Player(iframe);

    // Event listener for when the video starts playing
    player.on('play', function () {
      if (onPlay) onPlay();
    });

    // Event listener for when the video ends
    player.on('ended', function () {
      if (onEnd) onEnd();
    });

    return () => player.destroy()
  }, [videoId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <iframe
      id={`vimeo${videoId}`}
      title={title}
      src={`https://vimeo.com/showcase/${videoId}/embed`}
      {...others}
    ></iframe>
  )
}

export default EmbedVimeoPlayer;