import AirtableTable from "../AirtableTable";

export class ProviderTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Providers");

    this.fields = {
      "Name": "fld6v0d0qbFxvpyWq",
      "Phone": "fldGtz5S5dABr5qbD",
      "Email": "fld2H1zEgSGtxce57",
      "Earliest Start Time": "fldcujDhd02dt2H4o",
      "Advance Booking Requirement (Days)": "fldf3V4OSkW0TF6nA",
      "Other Limitations": "fldVsKYKVsMyRkQcs"
    }
  }

  getProviderByEmail = (email) => {
    const em = email.toUpperCase();
    return this.selectOneByCondition({
      filterByFormula: `OR("${em}" = UPPER({Email}), "${em}" = UPPER({Secondary Email}), "${em}" = UPPER({Leadership Email}))`,
    });
  }

  getAllProviders = () => {
    return this.list({
      filterByFormula: `AND({Hide from Directory} = FALSE(), {Status} = "Active")`,
      sort: [{ field: 'Name', direction: 'asc' }]
    })
  }

  getProviderByDomain = (domain) => {
    const dm = domain.toUpperCase();
    return this.selectOneByCondition({
      filterByFormula: `"${dm}" = UPPER({Domain})`
    });
  }

  getAllProvidersWithPagination = (params, listener) => {
    const { pageSize } = params;
    return this.listItems({
      filterByFormula: `AND({Hide from Directory} = FALSE(), {Status} = "Active")`,
      sort: [{ field: 'Name', direction: 'asc' }],
      pageSize: pageSize
    }, listener);
  }
}
