import React, { useEffect, useState } from "react";
import Airtable from "../../../airtables";
import LoadingBar from "../../../components/LoadingBar";
import { Breadcrumb } from "./components/Breadcrumbs";
import SessionInfo from "../../../components/SessionInfo";
import { useSelector } from "react-redux";
import { getDisplayInfos, getActionMenus } from "../../SessionInfoDisplay";
import Placeholder from "../../../assets/img/login-back.jpg";
import { Row, Col, Button } from "reactstrap";
import { Pagination } from "../../../components/Pagination";
import "./styles.scss"
import SendToTeacher from "../../modals/SendToTeacher";
import BookSessionModal from "../../modals/BookSession";
import { PDFManager } from "../../../libs/pdf";
import { toast } from "react-toastify";

const SHOW_PER_PAGE = 10;

const Screen = (props) => {
	const tagId = props.match.params.tagId;
	const [loading, setLoading] = useState(true);
	const [tag, setTag] = useState(null);
	const [sessions, setSessions] = useState([]);
	const { userType, userInfo } = useSelector((state) => state.appInfo);

	const [showSendMsgModal, setShowSendMsgModal] = useState(false);
	const [showBookSessionModal, setShowBookSessionModal] = useState(false);
	const [activeSession, setActiveSession] = useState(null);

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const t = await Airtable.tags.select(tagId);
				setTag(t);
				const data = await Airtable.providerSessions.getTagSessions(t);
				const ss = data.filter((s) => {
					return (
						s.Status === "Consider Another Option" ||
						s.Status === "Active (Open for booking)"
					);
				});
				setSessions(() => ss);
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const downloadPdf = () => {
		const pdfGenerator = new PDFManager();
		const p = pdfGenerator.generateCollectionContent(tag, sessions, `Collection - ${tag["Tag"]}.pdf`)
		toast.promise(p, {
			pending: 'Please wait while downloading is in progress. Do not change the current page.',
			success: 'The PDF has been downloaded successfully. 👌',
			error: 'Sorry, PDF download failed. Please try again later. 🤯'
		})
	}

	let thumbnail = tag ? tag["Thumbnail Image"] : undefined;
	if (thumbnail && thumbnail.length) {
		thumbnail = thumbnail[0]["url"];
	} else {
		thumbnail = Placeholder;
	}

	const { data, page, setPage, pageCount } = usePaginateSession(sessions);

	// console.log(tag, sessions)
	return (
		<div className="main-container collection-listing">
			<Row className="align-items-center">
				<Col md="8">
					<Breadcrumb pageTitle={tag?.Tag || ""} />
				</Col>
				<Col md="4" align="right"></Col>
			</Row>
			<Row className="align-items-center">
				<Col lg="5">
					<div className="image-wrap mb-3 mb-lg-0">
						<img src={thumbnail} alt="" />
					</div>
				</Col>
				<Col lg="7">
					<h1>{tag?.Tag}</h1>
					<p>{tag?.Description}</p>

					{!!tag && (userType === 'Team' || userInfo["Cluster Leadership"]) && (
						<Button
							color="primary"
							size="md"
							type="button"
							onClick={() => setShowSendMsgModal(true)}
						><i className="fa fa-envelope" />&nbsp;&nbsp;Send To Teacher(s)</Button>
					)}

					{(!!tag && !loading) && (
						<Button
							color="outline-primary"
							size="md"
							type="button"
							onClick={() => downloadPdf()}
						><i className="fa fa-file-pdf-o" />&nbsp;&nbsp;Download as PDF</Button>
					)}
				</Col>
			</Row>
			<div className="collection-listing-list mt-4">
				{loading ? (
					<LoadingBar />
				) : (
					<div>
						{data.map((s) => (
							<SessionInfo
								noMenu
								key={s["id"]}
								session={s}
								displayInfos={getDisplayInfos(s, userType)}
								actionMenu={getActionMenus(s, userType)}
								sessionDetailLink={s["id"]}
								bookSession={() => {
									setActiveSession(s);
									setShowBookSessionModal(true);
								}}
							/>
						))}
					</div>
				)}
			</div>

			{pageCount > 1 && (
				<Pagination
					pageCount={pageCount}
					setPage={setPage}
					page={page}
				/>
			)}

			{showSendMsgModal && !!tag && (
				<SendToTeacher
					data={tag}
					onToggle={() => setShowSendMsgModal(!showSendMsgModal)}
					type="Collection"
				/>
			)}

			{activeSession && showBookSessionModal && (
				<BookSessionModal
					providerSessionId={activeSession.id}
					providerName={activeSession["Provider Name Text"][0]}
					providerId={activeSession["Provider"[0]]}
					onToggle={() => {
						setShowBookSessionModal(false);
						setActiveSession(null);
					}}
				/>
			)}
		</div>
	);
};

export default Screen;

/**
 * @param {any[]} s
 */
function usePaginateSession(s) {
	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [page, setPage] = useState(0);

	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [pageCount, setPageCount] = useState(0);
	useEffect(() => {
		setPageCount(() => Math.ceil(s.length / SHOW_PER_PAGE));
	}, [s]);

	/** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
	const [data, setData] = useState([]);
	useEffect(() => {
		const l = page * SHOW_PER_PAGE;
		const h = l + SHOW_PER_PAGE;
		setData(() => s.slice(l, h));
	}, [s, page]);

	return { page, pageCount, setPage, data };
}
