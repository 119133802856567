/*eslint-disable*/
import React, { Fragment, useState } from "react";
import { Modal, Container, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

const CardsFooter = () => {
  const [acknowledgementsModal, setAcknowledgementsModal] = useState(false);
  const { userType } = useSelector(state => state.appInfo);
  const { t } = useTranslation();

  return (
    <Fragment>
      <footer className="footer">
        <div className="align-items-center justify-content-md-center">
          <div className="copyright">
            © {new Date().getFullYear()}{" "}
            <a href="https://www.tigweb.org/about/" target="_blank">TakingITGlobal</a>. {t(`thanks-for-being-connectnorth.${userType}`)}&nbsp;
            <span
              className="acknowledgements"
              onClick={() => setAcknowledgementsModal(true)}
            >{t("acknowledgements")}</span>
          </div>
        </div>
      </footer>
      <Modal
        className="modal-dialog-centered"
        isOpen={acknowledgementsModal}
        toggle={() => setAcknowledgementsModal(!acknowledgementsModal)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="modal-title-default">{t("acknowledgements")}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setAcknowledgementsModal(!acknowledgementsModal)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t("acknowledgements-desc1")}</p>
          <p>{t("acknowledgements-desc2")}</p>
          <p>
            <Trans
              i18nKey="acknowledgements-desc3"
              components={{ 1: <a href="https://www.scotiabank.com/ca/en/about/responsibility-impact/scotiarise.html" target="_blank" /> }}
            />
          </p>
        </div>
      </Modal>
    </Fragment>
  );
}

export default CardsFooter;
