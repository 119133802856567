import React, { Fragment } from 'react';
import { Button, FormGroup, Modal } from 'reactstrap';
import useHook from './useHook';
import Loading from '../../../components/Loading';
import Select from 'react-select';
import { Box } from '@mui/material';

const AddTeacherToEventModal = ({
  session,
  onToggle
}) => {
  const {
    loading,
    matchedTeachers,
    otherTeachers,

    selectedMatchedTeachers,
    setSelectedMatchedTeachers,
    selectedOtherTeachers,
    setSelectedOtherTeachers,
    processing,
    addTeachers
  } = useHook(session);

  const handleFinish = async () => {
    await addTeachers();
    onToggle();
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="modal-body min-body">
          <Loading size={40} />
        </div>
      )
    }

    return (
      <div className='select-teacher no-margin'>
        <div className='matched-teachers-selector'>
          <FormGroup>
            <label>{matchedTeachers.length !== 0 ? "Teachers Matching Subject(s) and Grade(s) Taught" : "No Teachers Matching Subject(s) and Grade(s)"}</label>

            {matchedTeachers.length !== 0 && (
              <Select
                isMulti
                className="form-style"
                value={selectedMatchedTeachers}
                onChange={options => setSelectedMatchedTeachers(options)}
                options={matchedTeachers.map(teacher => ({
                  value: teacher.id,
                  label: `${teacher["Teacher Name"]} (${teacher["School Name Text"]})`
                }))}
                isDisabled={processing}
              />
            )}
          </FormGroup>

          {matchedTeachers.length !== 0 && (
            <Button
              color="inherit"
              sx={{ mr: 1 }}
              onClick={() => setSelectedMatchedTeachers(matchedTeachers.map(t => ({
                value: t.id,
                label: `${t["Teacher Name"]} (${t["School Name Text"]})`
              })))}
              disabled={processing}
            >
              Select all matched teachers
            </Button>
          )}
        </div>

        {otherTeachers.length !== 0 && (
          <FormGroup>
            <label>Other Teachers</label>

            {otherTeachers.length !== 0 && (
              <Select
                isMulti
                className="form-style"
                value={selectedOtherTeachers}
                onChange={opts => setSelectedOtherTeachers(opts)}
                options={otherTeachers.map(teacher => ({
                  value: teacher.id,
                  label: `${teacher["Teacher Name"]} (${teacher["School Name Text"]})`
                }))}
                isDisabled={processing}
              />
            )}
          </FormGroup>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            onClick={onToggle}
            sx={{ mr: 1 }}
            disabled={processing}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />

          <Button
            onClick={handleFinish}
            disabled={processing}
          >
            {processing && (
              <Fragment>
                <Loading size={20} />{' '}
              </Fragment>
            )}Add {selectedMatchedTeachers.length + selectedOtherTeachers.length} Teachers
          </Button>
        </Box>
      </div>
    )
  }

  return (
    <Modal
      isOpen
      className="modal-dialog-centered"
      toggle={() => {
        onToggle()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Add Teacher(s) To Event</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {renderContent()}
      </div>
    </Modal>
  )
}

export default AddTeacherToEventModal;