import React, { useState } from 'react';
import { Button, FormGroup, Input, Modal } from 'reactstrap';

const AddTagModal = ({ onToggle, addNewTag }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const addTag = () => {
    addNewTag(name, description);
    onToggle();
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title">New Collection</h5>
      </div>
      <div className="modal-body">
        <FormGroup>
          <Input
            type="text"
            placeholder="Tag Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="textarea"
            rows={4}
            placeholder="Description"
            onChange={e => setDescription(e.target.value)}
            value={description}
          />
        </FormGroup>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >Close</Button>
        <Button
          color="primary"
          type="button"
          disabled={!name}
          onClick={addTag}
        >Add & Select</Button>
      </div>
    </Modal>
  )
}

export default AddTagModal;