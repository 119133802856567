import React from 'react';
import {
    Modal,
    Button
} from 'reactstrap';

export default class SessionResultModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            session: props.session
        }

        this.ratings = [1, 2, 3, 4, 5];
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            showModal: nextProps.showModal
        }
    }

    render() {
        const { session } = this.state;
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.showModal}
                toggle={() => this.props.onToggle()}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="modal-title-default">Session Results</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.onToggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="">
                        <div style={{ marginTop: 20 }}>
                            <label style={style.lbl}>Teacher: <span style={style.desc}>{`  ${session['Teacher Name']}`}</span></label>
                        </div>

                        {
                            session['Rating'] ? (
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                                    <label style={style.lbl}>Teacher Rating:</label>
                                    <div style={{ marginLeft: 15, flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                                        {
                                            this.ratings.map((rating, i) => (
                                                <i className="fa fa-star" style={{ fontSize: 20, color: rating > session['Rating'] ? "white" : "#FF5D5D", marginLeft: 3 }} key={i} />
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : null
                        }

                        <div style={{ marginTop: 20 }}>
                            <label style={style.lbl}>School Lead: <span style={style.desc}>{`  ${session['School Lead Text']}`}</span></label>
                        </div>

                        {
                            session['Team Rating'] ? (
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                                    <label style={style.lbl}>School Lead Rating: </label>
                                    <div style={{ marginLeft: 15, flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                                        {
                                            this.ratings.map((rating, i) => (
                                                <i className="fa fa-star" style={{ fontSize: 20, color: rating > session['Rating'] ? "white" : "#FF5D5D", marginLeft: 3 }} key={i} />
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            (session['Feedback'] || session['Team Feedback']) ? (
                                <>
                                    <div style={{ marginTop: 20 }}>
                                        <label style={style.lbl}>Session Feedback</label>
                                    </div>
                                    {
                                        session['Feedback'] ? (
                                            <div style={{ marginTop: 10, padding: '0 10px' }}>
                                                <label style={style.lbl}>Teacher's Feedback:<span style={style.desc}>{`  ${session['Feedback']}`}</span></label>
                                            </div>
                                        ) : (null)
                                    }

                                    {
                                        session['Team Feedback'] ? (
                                            <div style={{ marginTop: 10, padding: '0 10px' }}>
                                                <label style={style.lbl}>School Lead's Feedback:<span style={style.desc}>{`  ${session['Team Feedback']}`}</span></label>
                                            </div>
                                        ) : (null)
                                    }
                                </>
                            ) : (null)
                        }

                        {
                            (session['Highlight Photos'] && session['Highlight Photos'].length > 0) ? (
                                <>
                                    <div style={{ marginTop: 20 }}>
                                        <label style={style.lbl}>Highlight Photos</label>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {
                                            session['Highlight Photos'].map((attachment, index) => (
                                                <a href={attachment.url} target="_blank" rel="noopener noreferrer" key={index}>
                                                    <div style={{ width: 100, height: 100, borderRadius: 3, marginRight: 5, marginBottom: 5, background: 'lightgray' }}>
                                                        <img src={attachment.url} style={{ width: 100, height: 100, borderRadius: 3 }} alt="" />
                                                    </div>
                                                </a>
                                            ))
                                        }
                                    </div>
                                </>
                            ) : (null)
                        }
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.onToggle()}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        )
    }
}

const style = {
    subtitle: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 15
    },
    lbl: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    desc: {
        fontSize: 20,
        fontWeight: 'normal',
    }
};