import React, { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Modal
} from 'reactstrap';
import Loading from '../../../components/Loading';
import ReactDatetime from 'react-datetime';
import airtable from '../../../airtables';
import { getDateAndTimeString } from '../../../utils/time';
import Axios from 'axios';
import { useSelector } from 'react-redux';

const SessionReject = ({ sessionId, onToggle, onPost }) => {
  const { userInfo } = useSelector(state => state.appInfo);

  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [startDate, setStartDate] = useState();
  const [alterDate, setAlterDate] = useState();
  const [preferredTimeValid, setPreferredTimeValid] = useState([]);
  const [alternativeTimeValid, setAlternativeTimeValid] = useState([]);
  const [commentValid, setCommentValid] = useState();
  const [calcing, setCalcing] = useState(false);

  const isCalendarToken = userInfo["Calendar Token"] ? true : false;

  const validDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const isTodayOrFuture = current.isSameOrAfter(today, 'day');
    const isWeekday = current.day() !== 0 && current.day() !== 6; // 0 is Sunday, 6 is Saturday in moment.js (used by ReactDatetime)

    // Ensure it's at least 3 business days ahead
    let businessDaysCount = 0;
    let tempDate = new Date(today);

    while (businessDaysCount < 3) {
      tempDate.setDate(tempDate.getDate() + 1); // Increment day by day
      if (tempDate.getDay() !== 0 && tempDate.getDay() !== 6) { // Check if it's a weekday
        businessDaysCount++;
      }
    }

    const isAtLeast3BusinessDays = current.isSameOrAfter(tempDate, 'day');

    return isTodayOrFuture && isWeekday && isAtLeast3BusinessDays;
  };

  const checkValidationOfSelectedTime = (selectedTime, isPreferred, valids) => {
    setCalcing(true)
    if (isCalendarToken) {
      const providerId = userInfo.id;
      const dateInfo = getDateAndTimeString(selectedTime);
      Axios.get(`https://research.tigweb.org/connectednorth/provider-availability/events.html?provider=${providerId}&date=${dateInfo.day}&time=${dateInfo.time}`).then(res => {
        if (res.data === "No events found.") {
          valids.push("valid")
        } else {
          valids.push("invalid")
        }
        if (isPreferred) setPreferredTimeValid(valids);
        else setAlternativeTimeValid(valids);
        setCalcing(false)
      }).catch(error => {
        setCalcing(false)
      })
    } else {
      setCalcing(false)
    }
  }

  const renderTimeValidation = (timeValid) => {
    const renderTimeValidationItem = (item) => {
      if (item === 'valid') {
        return (
          <span style={{ color: 'green', fontSize: 12 }}>This time appears to be available in the provider's calendar.</span>
        )
      }

      if (item === 'invalid') {
        return (
          <span style={{ color: 'red', fontSize: 12 }}>The provider's calendar appears busy at this time. We suggest selecting an alternate time to propose!</span>
        )
      }

      if (item === 'incorrect') {
        return (
          <span style={{ color: '#cda535', fontSize: 12 }}>Warning: This time seems to be very early or very late. Please ensure you have selected the correct AM or PM option!</span>
        )
      }

      if (item === 'past-time') {
        return (
          <span style={{ color: 'red', fontSize: 12 }}>Oops! You've selected a date that has already passed.</span>
        )
      }

      return null;
    }

    return (
      <>
        {
          timeValid.map((item, i) => <div key={i}>
            {renderTimeValidationItem(item)}
          </div>)
        }
      </>
    )
  }

  const onSubmit = async () => {
    if (submitting || calcing) return;
    if (commentValid === 'invalid') return;
    if (!startDate) return;
    if (!alterDate) return;

    if (preferredTimeValid.indexOf('invalid') >= 0) {
      alert("The Preferred Date & Time is invalid.");
      return;
    }

    setSubmitting(true);

    const recentSessionInfo = await airtable.sessions.select(sessionId);
    const schedulingAttempts = (recentSessionInfo["Scheduling Attempts"] || 0) + 1

    airtable.sessions.update(
      sessionId,
      {
        "Provider Booking Response": `${comment}`,
        "Session Start Date/Time": `${startDate.toString()}`,
        "Alternative Date/Time": `${alterDate.toString()}`,
        "Status": "Pending Teacher Response",
        "Notify Teacher of Provider Response": true,
        "Scheduling Attempts": schedulingAttempts
      }
    ).then(async (res) => {
      await fetch(`https://hooks.zapier.com/hooks/catch/89715/3nxpioj?sessionID=${sessionId}&action=reject`, {
        method: "GET", 
        mode: "no-cors", 
      });
      onToggle();
      if (onPost) onPost();
    }).catch(error => {
      alert(error.toString());
    });
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={onToggle}
      backdrop={false}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Reject (Neither time is available)</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            if (submitting) return;
            onToggle()
          }}
        ><span aria-hidden={true}>×</span></button>
      </div>
      <div className="modal-body">
        <label>Please provide details on future availability/ideal booking times for the teacher:</label>
        <Form>
          <FormGroup>
            <Input
              type="textarea"
              style={{ minHeight: 100 }}
              onChange={(e) => {
                setComment(e.target.value)
                setCommentValid(!e.target.value ? 'invalid' : 'valid')
              }}
              invalid={commentValid === 'invalid'}
              disabled={submitting}
            />
          </FormGroup>
          <label>
            Can you propose two available dates for the class? If possible, try offering the same time of day they requested, as they may teach the same class at that time.
          </label>
          <FormGroup>
            <InputGroup
              className="input-group-alternative"
              style={{ border: preferredTimeValid.indexOf('invalid') > 0 ? '1px solid #fb6340' : 0 }}
            >
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
              <ReactDatetime
                inputProps={{
                  placeholder: "Date/Time 1",
                  style: {
                    color: 'black',
                    fontSize: 14,
                  },
                  disabled: submitting
                }}
                timeConstraints={{
                  minutes: {step: 5}
                }}
                timeFormat={true}
                isValidDate={validDate}
                onChange={(e) => {
                  if (typeof e !== 'string') {
                    const valids = [];
                    const selectedDate = e.toDate();
                    const selectedHour = selectedDate.getHours();
                    if (selectedDate < new Date()) valids.push("past-time")
                    if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                    setPreferredTimeValid(valids)

                    checkValidationOfSelectedTime(selectedDate, true, valids);
                    setStartDate(selectedDate);
                  }
                }}
              />
            </InputGroup>
            {renderTimeValidation(preferredTimeValid)}
          </FormGroup>
          <FormGroup>
            <InputGroup
              className="input-group-alternative"
              style={{ border: alternativeTimeValid.indexOf('invalid') > 0 ? '1px solid #fb6340' : 0 }}
            >
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
              <ReactDatetime
                inputProps={{
                  placeholder: "Date/Time 2",
                  style: {
                    color: 'black',
                    fontSize: 14,
                  },
                  disabled: submitting
                }}
                timeConstraints={{
                  minutes: {step: 5}
                }}
                timeFormat={true}
                isValidDate={validDate}
                onChange={(e) => {
                  if (typeof e !== 'string') {
                    const valids = [];
                    const selectedDate = e.toDate();
                    const selectedHour = selectedDate.getHours();
                    if (selectedDate < new Date()) valids.push("past-time")
                    if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                    setAlternativeTimeValid(valids);

                    checkValidationOfSelectedTime(selectedDate, false, valids);
                    setAlterDate(selectedDate);
                  }
                }}
              />
            </InputGroup>
            {renderTimeValidation(alternativeTimeValid)}
          </FormGroup>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >Close</Button>
        <Button
          color="success"
          type="button"
          onClick={() => onSubmit()}
          disabled={submitting || calcing}
        >{submitting && (<Loading size={14} />)}Submit Response</Button>
      </div>
    </Modal>
  )
}

export default SessionReject;
