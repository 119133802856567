import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import Credit from "../../../assets/img/icons/common/credit.svg"
import '../style.scss'

export const SessionSideBar = ({session, grades}) => {
  const {
    userInfo,
    userType,
    subjectMap,
    appLocale,
	} = useSelector((state) => state.appInfo);
  const { t } = useTranslation();
  const translatedSubjects = (subjects) => {
    const translated = subjects.map((sub) => {
      if (!subjectMap[sub]) return null;
      const subjectName =
        appLocale === "fr"
          ? subjectMap[sub]["Subject (FR)"]
          : subjectMap[sub]["Subject"];
      return subjectName ?? null;
    });
    
    return translated.join(", ");
  };


  return (
    <Col xs="12" lg="3" className="session-sidebar">
      <div className="d-flex flex-column gap-3 highlight-box px-3 py-4 mb-4 side-bar">
        <div className="sidebar-icon-wrap">
          <i className={"fa fa-solid fa-user-circle"} />
          {session["Provider Name Text"] &&
            session["Provider Name Text"].length > 0 && (
              <a
                href={`/cn/provider/${session.Provider[0]}`}
                rel="noopener noreferrer"
                className="info-link"
              >
                <span className="info-content">
                  {session["Language"] === "French" &&
                  session["Provider (FR)"]
                    ? session["Provider (FR)"]
                    : session["Provider Name Text"]}
                </span>
              </a>
            )}
        </div>

        <div className="sidebar-icon-wrap">
          <i className={"fa fa-globe"}/>
          <span className="info-title">
            {t("language")}:{" "}
          </span>
          <span className="info-content">
            {session["Language"]}
          </span>
        </div>

        {session["Length (Minutes)"] ? (
          <div className="sidebar-icon-wrap">
            <i className="fa fa-clock-o"/>
            <span className="info-title">
              {t("duration")}:{" "}
            </span>
            <span className="info-content">
              {session["Length (Minutes)"]} min
            </span>
          </div>
        ) : null}
        {session["Credits"] ? (
          <div className="sidebar-icon-wrap">
              <img src={Credit} alt="" />
              <span className="info-content">
                  {
                      // Check if both "Indigenous Group(s)" and "School Indigenous Group(s)" exist
                      session["Indigenous Group(s)"] && userInfo["School Indigenous Group(s)"] && (typeof userInfo["School Indigenous Group(s)"] === 'string' || Array.isArray(userInfo["School Indigenous Group(s)"]))
                      ? session["Indigenous Group(s)"].some(group => userInfo["School Indigenous Group(s)"].includes(group))
                          ? session["Discounted Credits"]
                          : session["Credits"]
                      : session["Credits"]
                  }{" "}
                  {t("credits")}
              </span>
          </div>
        ) : (
          <div className="sidebar-icon-wrap">
              <img src={Credit} alt="" />
              <span className="info-content">
                  {t("free")}
              </span>
          </div>
        )}     
        
        {session["Indigenous Group(s)"] && session["Indigenous Group(s)"].length > 0 && (
        <div className="session-info-item mt-4">
          <span className="font-weight-bold">
            {t("indigenous-groups")}:{" "}
          </span>
          <div>
            {Array.isArray(session["Indigenous Group(s)"])
              ? session["Indigenous Group(s)"].join(", ")
              : null}
          </div>
        </div>
        )}
        <div className="session-info-item">
          <span className="font-weight-bold">
            {t("subjects")}:{" "}
          </span>
          <div>
            {session.Subject && session.Subject.length > 0 &&
              translatedSubjects(session.Subject)
            }
          </div>
        </div>
        <div className="session-info-item">
          <span className="font-weight-bold">
            {t("grades")}:{" "}
          </span>
          <div>
            {grades.length
              ? grades
                  .filter(
                    (grade) =>
                      !grade.startsWith(
                        "Premium Session",
                      ),
                  )
                  .join(", ")
              : null}
          </div>
          {session["Cluster-specific"] && (
            <>
              <div className="session-info-item mt-4">
                <span className="font-weight-bold">
                  {t("availability")}:{" "}
                </span>
                <div>
                {Array.isArray(session["Cluster-specific Cluster Names"]) && session["Cluster-specific Cluster Names"].length > 0 && (
                  session["Cluster-specific Cluster Names"].map((item, index, arr) => {
                    if (index === arr.length - 1 && arr.length > 1) {
                      return `and ${item}`;
                    } else if (index === arr.length - 2 && arr.length > 2) {
                      return `${item}, `;
                    } else if (index === arr.length - 2 && arr.length === 2) {
                      return `${item} `;
                    } else if (index < arr.length - 2) {
                      return `${item}, `;
                    }
                    return item;
                  })
                )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {userType === "Team" && session["# Booked"] > 0 && (
        <div className="d-flex flex-column gap-3 highlight-box px-3 py-4 mb-4 side-bar">
          <div className="session-info-item">
            <span className="info-title" style={{fontSize: 25, color: '#32325d', fontWeight: 500}}>
              Team-only Data
            </span>
            <div>
              <div className="session-info-item">
                <span className="font-weight-bold">
                  {t("session-booked")}:{" "}
                </span>
                <span className="info-content">
                  {session["# Booked"]}
                </span>
              </div>
              <div className="session-info-item">
                <span className="font-weight-bold">
                  Average Rating:{" "}
                </span>
                <span className="info-content">
                  {Math.round(session["Average Rating"] * 100) / 100}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {session["Resource Sheets"] && 
      (userType === "Team" || (session["Sessions Booked Tags Text"] && session["Sessions Booked Tags Text"].includes("PD Week")) || (userInfo["Sessions Completed"] && userInfo["Provider Sessions Completed"].includes(session.id))) &&
      Array.isArray(session["Resource Sheets"]) ? (
        <div className="d-flex flex-column gap-3 highlight-box px-3 py-4 mb-4 side-bar">
          <div className="session-info-item">
            <span className="info-title" style={{fontSize: 25, color: '#32325d', fontWeight: 500}}>
              {t("resource-sheets")}
            </span>
            <div className="info-content">
              {session["Resource Sheets"].map(
                (resource, i) => (
                  <a
                    href={resource.url}
                    target="_blank"
                    key={i}
                    rel="noopener noreferrer"
                  >
                    <div
                      style={{
                        backgroundColor:
                          "lightgray",
                        borderRadius: 5,
                        margin: 10,
                      }}
                    >
                      <img
                        src={
                          resource?.thumbnails
                            ?.large?.url
                        }
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 5,
                        }}
                        alt=""
                      />
                    </div>
                  </a>
                ),
              )}
            </div>
          </div>
        </div>
      ) : null}
      {session["PD Series Resources"] && (userType === "Team" || (userInfo["Sessions Completed"] && userInfo["Provider Sessions Completed"].includes(session.id))) && Array.isArray(session["PD Series Resources"]) ? (
        <div className="d-flex flex-column gap-3 highlight-box px-3 py-4 mb-4 side-bar">
          <div className="session-info-item">
            <span className="info-title" style={{fontSize: 25, color: '#32325d', fontWeight: 500}}>
              Series Resources
            </span>
            <div className="info-content">
              {session["PD Series Resources"].map(
                (resource, r) => (
                  <React.Fragment key={r}>
                    <a
                      href={resource.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      >
                    <div
                      style={{
                        backgroundColor:
                          "lightgray",
                        borderRadius: 5,
                        margin: 10,
                      }}
                    >
                      <img
                        src={
                          resource?.thumbnails
                            ?.large?.url
                        }
                        style={{
                          width: 150,
                          height: 100,
                          borderRadius: 5,
                        }}
                        alt=""
                      />
                    </div>
                  </a>
                  <p style={{ color: 'black' }}>{resource.filename.split('.').slice(0, -1).join('.')}</p>
                  </React.Fragment>
                ),
              )}
            </div>
          </div>
        </div>
      ) : null}
    </Col>
  )
}