import React, { useState } from "react";
import { Button, FormGroup, Input, Modal } from "reactstrap";
import "./styles.scss";
import Loading from "../../../components/Loading";
import airtable from "../../../airtables";

const CancelBookRequest = ({
  session,
  onToggle,
  toChangeEdit,
  onDone
}) => {
  const [reason, setReason] = useState("");
  const [processing, setProcessing] = useState(false);

  const goToEdit = () => {
    toChangeEdit && toChangeEdit(session);
  }

  const confirmProcess = async () => {
    setProcessing(true);
    let status = "Request Cancelled by Teacher";
    if (session.Status === "Booked") status = "Cancelled without Payment";
    await airtable.sessions.update(session.id, {
      Status: status,
      "Teacher Cancellation Reason": reason
    })
    setProcessing(false);
    onDone && onDone();
  }
  
  return (
    <Modal
      className="modal-dialog-centered request-booked-session-cancellation-modal"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-body">
        <div className="m-heading">
          <span>{session.Status === "Booked" ? "Cancel Session Booking" : "Cancel Session Request"}</span>
        </div>
        {session.Status !== 'Booked' && (
          <div className="m-title">
            <span>Do you want to <span className="link" onClick={goToEdit}>request a new date or time</span> for this Session instead?</span>
          </div>
        )}
        <div className="modal-form">
          <FormGroup>
            <label>{session.Status === "Booked" ? "Please let us know why you need to cancel this Session:" : "Please let us know why you are cancelling this request:"}</label>
            <Input
              type="textarea"
              className="form-control"
              placeholder="This note will be shared with your school lead and the content provider to support any follow-up that might be helpful."
              rows={5}
              onChange={e => setReason(e.target.value)}
            />
          </FormGroup>
        </div>
      </div>
      <div className="modal-footer">
          <Button
            className="ml-auto"
            color="default"
            outline
            data-dismiss="modal"
            type="button"
            onClick={() => onToggle()}
          >{session.Status === "Booked" ? "Keep Session Booking" : "Keep Session Request"}</Button>
          <Button
            color="danger"
            type="button"
            disabled={!reason || processing}
            onClick={() => confirmProcess()}
          >{processing && (<Loading size={14} />)}Confirm Cancellation</Button>
        </div>
    </Modal>
  )
}

export default CancelBookRequest;