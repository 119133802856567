import { useEffect, useState } from "react"
import airtable from '../../../airtables';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getSession, setSession } from "../../../utils/session";
import { confirmAlert } from "react-confirm-alert";
import { openURL } from "../../../utils/url";

const getSchoolLbl = (
  isDistrictLeader,
  isSchoolLeader,
  userInfo,
  t
) => isDistrictLeader && userInfo["Cluster Lead Display Name"]
    ? `Upcoming across ${userInfo["Cluster Lead Display Name"]}`
    : userInfo["Cluster"] && userInfo["Cluster"].includes('recFTIgJRWDh0JgXu') && !isSchoolLeader
      ? 'Upcoming across KPDSB'
      : userInfo["Cluster"] && userInfo["Cluster"].includes('rec8Vx2F8H3fnZyBe') && !isSchoolLeader
        ? 'Upcoming across RRDSB'
        : t('view-sessions-in-same-school')

export function useUpcoming() {
  const { t } = useTranslation()
  const { userInfo, userType } = useSelector((state) => state.appInfo);

  const isSchoolLeader = (userInfo["School Leader?"] ?? "").toLowerCase() === "true";
  const isDistrictLeader = (userInfo["District Leader?"] ?? "").toLowerCase() === "true";

  const [upcoming, setUpcoming] = useState({
    loading: true,
    sessions: [],
    type: "Upcoming",
    name: "upcoming",
    label: t('my-upcoming'),
    noMessage: t("no-upcoming-sessions")
  });
  const [past, setPast] = useState({
    loading: true,
    sessions: [],
    type: "Past",
    name: "past",
    label: t('view-past-sessions'),
    noMessage: t("no-past-sessions")
  });
  const [school, setSchool] = useState({
    loading: true,
    sessions: [],
    type: "School",
    name: "school",
    label: getSchoolLbl(isDistrictLeader, isSchoolLeader, userInfo, t),
    noMessage: t("no-upcoming-sessions-at-school")
  });
  const [fresh, setFresh] = useState(0);

  useEffect(() => {
    if (!userInfo) return;

    if (userType === 'Teacher') loadTeacherUpcomingData();
    else if (userType === 'Provider') loadProviderUpcomingData();
  }, [fresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = () => setFresh(fresh + 1);

  const loadTeacherUpcomingData = () => {
    airtable.sessions.getSessions(
      'Teacher',
      userInfo['Teacher Name'],
      userInfo.id,
      'Booked'
    ).then(uts => {
      setUpcoming({
        ...upcoming,
        loading: false,
        sessions: uts
      })
    });

    airtable.sessions.getPastSessions({
      type: 'Teacher',
      name: userInfo['Teacher Name'],
      status: 'Completed'
    }).then(tps => {
      setPast({
        ...past,
        loading: false,
        sessions: tps
      })
    })

    let schoolSessionsPromise = null;
    if (isDistrictLeader && userInfo["Cluster Lead Display Name"]) {
      schoolSessionsPromise = airtable.sessions.getAllUpcomingInSameCluster(userInfo.id, userInfo["Cluster Lead Text"][0]);;
    } else if (userInfo["Cluster"] && userInfo["Cluster"].includes('recFTIgJRWDh0JgXu') && !isSchoolLeader) {
      schoolSessionsPromise = airtable.sessions.getAllUpcomingInSameCluster(userInfo.id, 'KPDSB');
    } else if (userInfo["Cluster"] && userInfo["Cluster"].includes('recJhT57bDhENWOGJ') && !isSchoolLeader) {
      schoolSessionsPromise = airtable.sessions.getAllUpcomingInSameCluster(userInfo.id, 'RRDSB (K-8)');
    } else if (userInfo["Cluster"] && userInfo["Cluster"].includes('recep9nOPRxgceeEK') && !isSchoolLeader) {
      schoolSessionsPromise = airtable.sessions.getAllUpcomingInSameCluster(userInfo.id, 'RRDSB (9-12)');
    } else {
      const scs = userInfo['School Name Text'];
      if (userInfo['School Name Text'] && userInfo['School Name Text'].length) {
        schoolSessionsPromise = airtable.sessions.getAllUpcomingInTeacherSchools(
          userInfo.id,
          userInfo["School Name"],
          scs
        );
      }
    }

    if (schoolSessionsPromise) {
      schoolSessionsPromise.then(scs => {
        setSchool({
          ...school,
          loading: false,
          sessions: scs
        })
      })
    }
  }

  const loadProviderUpcomingData = () => {
    airtable.sessions.getProviderUpcomingSessions(
      userInfo.id,
      userInfo['Name']
    ).then(uts => {
      setUpcoming({
        ...upcoming,
        loading: false,
        sessions: uts
      })

      if (uts && uts.length) {
        const session = uts[0];
        const cancelledActiveSessionId = getSession("provider-active-session");
        if (cancelledActiveSessionId === session.id) {
          return;
        }

        const sessionTime = new Date(session["Session Start Date/Time"]).getTime();
        const currentTime = Date.now();

        if (sessionTime - currentTime < 300000 /* 10 mins */) {
          const schoolName = session["School Name Text"][0]
          confirmAlert({
            title: 'Join your session',
            message: `You have a session with ${schoolName} happening now. Ready to join?`,
            buttons: [{
              label: t("join-session"),
              onClick: () => openURL(session["WebEx/Zoom Link"])
            },
            {
              label: "Cancel",
              onClick: () => setSession("provider-active-session", session.id)
            }]
          });
        }
      }
    });

    airtable.sessions.searchSessions(
      userInfo.id,
      userInfo['Name'],
      '',
      ['Completed', 'Cancelled with Payment']
    ).then(tps => {
      setPast({
        ...past,
        loading: false,
        sessions: tps
      })
    })
  }

  if (userType === 'Teacher') {
    return {
      data: [upcoming, school, past],
      refresh
    }
  }

  return {
    data: [upcoming, past],
    refresh
  }
}