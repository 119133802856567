import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { removeSession } from '../utils/session';
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../config';
import { useDispatch } from 'react-redux';
import { setAppUserInfo, setAppUserType } from '../redux/actions';

const Screen = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        confirmAlert({
            title: 'Connected North',
            message: 'Are you sure you want to sign out?',
            buttons: [{
                label: 'OK',
                onClick: async () => {
                    removeSession(CONNECTEDNORTH_SIGNINFO)
                    removeSession(CONNECTEDNORTH_SIGNTYPE)
                    dispatch(setAppUserInfo(null))
                    dispatch(setAppUserType(null))

                    window.location.href = '/login'
                }
            },
            {
                label: "Cancel",
                onClick: () => { }
            }]
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div></div>
    )
}

export default Screen;