
export default class AirtableTable {
  constructor(instance, tableName) {
    this.instance = instance
    this.tableName = tableName;
    this.table = this.instance(this.tableName);
    this.fields = null;
    this.pageSize = 100;
  }

  create = (param) => {
    return new Promise((resolve, reject) => {
      this.table.create([{
        "fields": param
      }]).then(res => {
        const record = res[0];
        resolve({
          ...record.fields,
          id: record.id
        });
      }).catch(error => {
        reject(error)
      })
    })
  }

  createMultiple = (paramList) => {
    return new Promise((resolve, reject) => {
      this.table.create(paramList.map(param => ({ fields: param })))
        .then(res => {
          const records = res.map(item => ({
            ...item.fields,
            id: item.id
          }))
          resolve(records);
        }).catch(error => {
          reject(error)
        })
    })
  }

  select = (id) => {
    if (!id) return null;

    return new Promise((resolve, reject) => {
      this.table.find(id).then(record => {
        if (record && record._rawJson) {
          resolve({
            ...record.fields,
            id: record.id
          })
        } else {
          resolve(null)
        }
      }).catch(error => {
        if (error.error === 'NOT_FOUND') resolve(null);
        else reject(error.toString())
      })
    })
  }

  delete = (id) => {
    if (!id) return;
    return new Promise((resolve, reject) => {
      this.table.destroy([id]).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  update = (id, param) => {
    if (!id || !param || Object.values(param).length === 0) return;

    return new Promise((resolve, reject) => {
      this.table.update([{
        id, fields: param
      }]).then(res => {
        const record = res[0];
        resolve({
          ...record.fields,
          id: record.id
        })
      }).catch(error => {
        reject(error)
      })
    })
  }

  multipleUpdate = (infoList) => {
    return new Promise((resolve, reject) => {
      this.table.update(infoList.map(info => ({
        id: info.id, fields: info.param
      }))).then(res => {
        const record = res[0];
        resolve({
          ...record.fields,
          id: record.id
        })
      }).catch(error => {
        reject(error)
      })
    })
  }

  list = (condition, filter = null, allFields = false) => {
    return new Promise((resolve, reject) => {
      var result = [];
      let con = { ...condition };
      if (!condition.fields && this.fields && !allFields) {
        con = {
          ...condition,
          fields: Object.values(this.fields)
        }
      }

      this.table.select(con).eachPage((records, fetchNextPage) => {
        records.forEach(record => {
          var item = {
            id: record.id,
            ...record.fields
          }
          if (!filter || filter(item)) {
            result.push(item);
          }
        })
        fetchNextPage()
      }).then(() => {
        resolve(result)
      }).catch(error => {
        reject(error)
      })
    });
  }

  listByIds = (ids, sort) => {
    const filter = ids.map(id => `RECORD_ID() = '${id}'`).join(',');
    let condition = { filterByFormula: `OR(${filter})` };
    if (sort) condition.sort = sort;

    if (this.fields) condition.fields = Object.values(this.fields);
    return this.list(condition)
  }

  selectOneByCondition = async (condition) => {
    const items = await this.list({
      ...condition, maxRecords: 1
    }, null, true);

    return (items && items.length) ? items[0] : null;
  }

  getFirstPage = (condition) => {
    return new Promise((resolve, reject) => {
      var result = [];
      let con = { ...condition };
      if (!condition.fields && this.fields) {
        con = {
          ...condition,
          fields: Object.values(this.fields)
        }
      }

      this.table.select(con)
        .firstPage()
        .then((records) => {
          records.forEach(record => {
            var item = {
              id: record.id,
              ...record.fields
            }
            result.push(item);
          })
          resolve(result)
        }).catch(error => {
          reject(error)
        })
    });
  }

  listAll = () => {
    let condition = {}
    if (this.fields) condition.fields = Object.values(this.fields);
    return this.list(condition);
  }

  /**
   * 
   * @typedef ConditionProp
   * @property {string} filterByFormula
   * @property {Array<Object>} sort
   * @property {number} pageSize
   * 
   * @param {ConditionProp} condition 
   * @param {*} loadFunc 
   * @returns 
   */
  listItems = (condition, loadFunc) => {
    return new Promise((resolve, reject) => {

      let pageNum = 0;
      const result = [];
      const totalResult = [];

      let con = { ...condition };
      if (!condition.fields && this.fields) {
        con = {
          ...condition,
          fields: Object.values(this.fields)
        }
      }

      if (!con.pageSize) con.pageSize = this.pageSize;

      this.table.select(con).eachPage((records, fetchNextPage) => {
        const pageItems = [];
        records.forEach(record => {
          var item = {
            id: record.id,
            ...record.fields
          }
          pageItems.push(item);
          totalResult.push(item);
        });

        const newPage = {
          pageNo: pageNum,
          items: pageItems
        }
        result.push(newPage);
        loadFunc && loadFunc([...result]);
        pageNum++;

        fetchNextPage()
      }).then(() => {
        resolve(totalResult)
      }).catch(error => {
        reject(error)
      })
    });
  }
}