import { useEffect, useState } from "react";

const ENDPOINT = "https://requestinvoice-raj5gg67oa-uc.a.run.app";

/**
 * @typedef {object} UseRequestInvoice
 * @property {string} addressedTo
 * @property {React.ChangeEventHandler<HTMLInputElement>} handleAddressedTo
 * @property {string} invoiceNumber
 * @property {React.ChangeEventHandler<HTMLInputElement>} handleInvoiceNumber
 * @property {boolean} loading
 * @property {string | undefined} error
 * @property {React.ChangeEventHandler<HTMLFormElement>} handleRequestInvoice
 * @property {boolean} showModal
 * @property {boolean} purchaseOK
 * @property {() => void} toggleModal
 *
 *
 * @param {number} credit
 * @param {string} name
 * @param {string} email
 * @param {string} school
 * @returns {UseRequestInvoice}
 */
export function useRequestInvoice(credit, name, email, school) {
	const [addressedTo, setAddressedTo] = useState(school);
	/** @type {React.ChangeEventHandler<HTMLInputElement>} */
	const handleAddressedTo = (e) => setAddressedTo(e.target.value);

	const [invoiceNumber, setInvoiceNumber] = useState("");
	const handleInvoiceNumber = (e) => setInvoiceNumber(e.target.value);

	const [loading, setLoading] = useState(false);

	/** @type {[string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]} */
	const [error, setError] = useState(undefined);

	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal((s) => !s);

	const [purchaseOK, setPurchaseOK] = useState(false);
	/** @type {React.ChangeEventHandler<HTMLFormElement>} */
	async function handleRequestInvoice(e) {
		e.preventDefault();
		try {
			setLoading(() => true);
			const res = await fetch(ENDPOINT, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify({
					addressedTo,
					invoiceNumber,
					name,
					email,
					credit,
				}),
			});
			if (res.status !== 200) {
				const p = await res.json();
				console.log(`request to zapier failed: ${p}`);
				throw new Error(
					"Cannot process your request right now, try again later.",
				);
			}
			setShowModal(() => false);
			setPurchaseOK(() => true);
		} catch (e) {
			console.error(e);
			setError(e.message);
		} finally {
			setLoading(() => false);
		}
	}

	useEffect(() => {
		if (showModal) return;
		setAddressedTo(() => school);
		setInvoiceNumber(() => "");
	}, [showModal, school]);

	useEffect(() => {
		if (!purchaseOK) return;
		const id = setTimeout(() => {
			setPurchaseOK(() => false);
		}, 3000);
		return () => clearTimeout(id);
	}, [purchaseOK]);

	return {
		addressedTo,
		handleAddressedTo,
		invoiceNumber,
		handleInvoiceNumber,
		loading,
		handleRequestInvoice,
		error,
		showModal,
		toggleModal,
    purchaseOK
	};
}
