import React from 'react';

const Gap = ({ width, height }) => {
    if (width) {
        return (
            <div style={{ width }} />
        )
    }

    if (height) {
        return (
            <div style={{ height }} />
        )
    }

    return null;
}

export default Gap;