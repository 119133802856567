import React from 'react';
import { GoogleMap, KmlLayer, useJsApiLoader } from '@react-google-maps/api';

const GOOGLE_MAP_KEY = "AIzaSyAS-DIcFXcADccoiSNoM533jtzGa06hJzI"

const MapWithAKmlLayer = ({ recId, type }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAP_KEY,
    })

    if (!isLoaded) return null;
    return (
        <GoogleMap
            mapContainerStyle={{ width: '100%', height: 472, marginTop: 10 }}
            center={{ lat: 66, lng: -106 }}
            mapTypeId="terrain"
            zoom={3}
            options={{
                streetViewControl: false,
                mapTypeControl: false
            }}
        >
            <KmlLayer
                options={{
                    suppressInfoWindows: false,
                    preserveViewport: true
                }}
                url={`https://research.tigweb.org/connectednorth/get_map_points/${type}.html?rand=999&recID=${recId}`}
            />
        </GoogleMap>
    )
}

export default React.memo(MapWithAKmlLayer);
