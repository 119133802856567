import React, { useEffect, useState } from 'react';
import airtable from '../airtables';
import { hideLoading, showLoading } from '../utils/loading';
import { Redirect } from "react-router";
import { useDispatch } from 'react-redux';
import { setAppUserInfo, setAppUserType } from '../redux/actions';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { setSession as saveSession } from "../utils/session";
import { analytics } from '../firebase';
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../config';

const PendingSessionManager = (props) => {
    const { match: { params: { provider: providerId, session: sessionId, type } } } = props;
    const [error, setError] = useState('');
    const [redirect, setRedirect] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        initialize();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const logSignin = async (user, userType) => {
        await Promise.all([
            setUserId(analytics, user),
            setUserProperties(analytics, 'user_type', userType),
            logEvent(analytics, 'login', {
                email: user,
                userType
            })
        ]);
    }

    const initialize = async () => {
        showLoading();
        const session = await airtable.sessions.select(sessionId);
        if (!session) {
            setError("Non-existing session.");
            return;
        }

        if (session["Status"] !== 'Requested' && session["Status"] !== 'Pending Provider Response') {
            setError("This Session request was already actioned in the system. If you believe this is incorrect, please contact connectednorth@takingitglobal.org and forward the e-mail you clicked on and/or the link you have tried to access so we can look into it right away!");
            return;
        }

        if (providerId !== session["Provider"][0]) {
            setError("Our apologies! The Session you tried to access does not appear to be linked to your provider record in our database. Please contact connectednorth@takingitglobal.org and forward the e-mail you clicked on and/or the link you have tried to access so we can look into it right away!");
            return;
        }

        if (type !== 'accept1' && type !== 'accept2' && type !== 'reject') {
            setError("Unsupported type!");
            return;
        }

        const provider = await airtable.providers.select(providerId);
        dispatch(setAppUserInfo(provider));
        dispatch(setAppUserType("Provider"));
        await logSignin(provider['Email'], 'Provider');
        await saveSession(CONNECTEDNORTH_SIGNINFO, provider);
        await saveSession(CONNECTEDNORTH_SIGNTYPE, "Provider");

        setRedirect(true);
    }

    if (redirect) {
        hideLoading();
        return (<Redirect to={`/cn/pending?sessionId=${sessionId}&type=${type}`} />)
    }

    if (error) {
        hideLoading();
        return (
            <div className="error-message">
                <div className="center-layout">
                    <span className="error">{error}</span>
                </div>
            </div>
        )
    }

    return (<div />)
}

export default PendingSessionManager;
