import { useState } from "react";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import { useUpdateUserInfo } from "./use-update-user-info";

export const useSessionsMenu = () => {
	const { loading, userInfo, updateUserInfo } = useUpdateUserInfo();
	const [formValues, setFormValues] = useState({
		sessionsMenu: userInfo[TEACHER_FIELDS.SESSION_MENU] ? "yes" : "no",
	});

	const updateSessionsMenu = async (nextLink = "") => {
		const updateBody = {
			[TEACHER_FIELDS.SESSION_MENU]: formValues.sessionsMenu === "yes",
		};

		updateUserInfo(updateBody, nextLink);
	};

	return { loading, formValues, setFormValues, updateSessionsMenu };
};
