import { isSameDay } from "../utils/time";

export const getActionMenus = (session, userType) => {
  if (!session) return [];

  let res;
  switch (userType) {
    case "Session-Page":
      return [];
    case "Teacher":
      res = [
        "copy-link",
        "request-support",
        "open-detail"
      ]
      if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")
      if (session["Session Host(s)"]) {
        res = [
          ...res,
          "call-session-host",
          "email-session-host"
        ]
      } else {
        res = [
          ...res,
          "call-school-lead",
          "email-school-lead"
        ]
      }

      return res;
    case "Team":
      res = [
        "copy-link",
        "join-session",
        "call-teacher",
        "email-teacher",
        "call-provider",
        "email-provider",
        "reassign-session-lead",
        "request-support",
        "open-detail"
      ]
      if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")

      return res;
    case "Provider":
      res = [
        "copy-link",
        "join-session",
        "request-support",
        "internal-id",
        "assign-presenter"
      ]
      if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")
      if (session["Session Host(s)"]) {
        res = [
          ...res,
          "call-session-host",
          "email-session-host"
        ]
      } else {
        res = [
          ...res,
          "call-school-lead",
          "email-school-lead"
        ]
      }
      return res;
    default:
      return [];
  }
}

export const getActionUpcomingMenus = (session, userType, type) => {
  if (!session) return [];

  let res;
  let isRatedSession;
  switch (userType) {
    case "Teacher":
      isRatedSession = session['Rating'] ? true : false;
      res = [
        ...(type === 'Past' ? [] : [
          "copy-link",
          "request-support",
        ]),
        "open-detail",
        ...(type === 'School' ? [] : [
          "student-survey",
        ]),
      ]
      if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")
      if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) {
        res = [
          ...res,
          "call-session-host",
          "email-session-host"
        ]
      } else {
        res = [
          ...res,
          "call-school-lead",
          "email-school-lead"
        ]
      }

      if (type === 'Past' && session.Status === 'Completed') {
        res.push(isRatedSession ? "edit-rate-session" : "rate-session");
      }

      if (type === 'School' && !session["Premium Registration Closed"] && !session["Multi-class Not Allowed"][0] && (!session["Supplies Required"] || session["Supplies Shipment Not Required"])) {
        let availableDate = new Date(session["Session Start Date/Time"]);
        const currentTime = new Date();
        availableDate.setDate(availableDate.getDate() - 1);
        if (availableDate > currentTime) res.push("add-my-class");
      }

      const sessionDate = new Date(session["Session Start Date/Time"]);
      const now = new Date();
      const diff = sessionDate - now;
      if (diff > session["PFCWM"] && session.Status === "Booked") {
        res.push("cancel-booking");
      } else if (session.Status === "Requested") {
        res.push("cancel-request");
      }

      return res;
      case "Session Host":
        res = [
          ...(type === 'Past' ? [] : [
            "copy-link",
            "request-support",
            /* "join-session", */
          ]),
          "call-teacher",
          "email-teacher",
          "call-provider",
          "email-provider",
          "open-detail",
          "open-rubric",
          "cancel-session",
          "show-welcome-map",
        ]
        if (
          session["# Schools"] === 1 &&
          session["Resource Tracking URL"] &&
          session["Resource Tracking URL"].length
        ) res.push("track-supplies")
  
        return res; 
      case "Team":
        res = [
          ...(type === 'Past' ? [] : [
            "copy-link",
            "request-support",
            /* "join-session", */
          ]),
          "call-teacher",
          "email-teacher",
          "call-provider",
          "email-provider",
          "reassign-session-lead",
          "open-detail",
          "open-rubric",
          "cancel-session",
          "edit-session",
          "show-welcome-map",
          /* "assign-to-funding-pool", */
          "duplicate-session",
          "open-airtable"
        ]
        if (session["CILC Request URL"]) {
          res.push("cilc-request")
        } else if (session["CILC Booking URL"]) {
          res.push("book-cilc")
        } else if (session["CILC Profile URL"]) {
          res.push("open-cilc-profile")
        }
        if (
          session["# Schools"] === 1 &&
          session["Resource Tracking URL"] &&
          session["Resource Tracking URL"].length
        ) res.push("track-supplies")

        return res;
    case "Provider":
      isRatedSession = session['Provider Rating'] ? true : false;
      res = [
        ...(type === 'Past' ? [isRatedSession ? "edit-rate-session" : "rate-session"] : [
          "copy-link",
          "request-support",
          "join-session",
          "show-welcome-map"
        ]),
        "internal-id",
        "assign-presenter"
      ]
      if (
        session["# Schools"] === 1 &&
        session["Resource Tracking URL"] &&
        session["Resource Tracking URL"].length
      ) res.push("track-supplies")
      if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) {
        res = [
          ...res,
          "call-session-host",
          "email-session-host"
        ]
      } else {
        res = [
          ...res,
          "call-school-lead",
          "email-school-lead"
        ]
      }
      return res;
    case "Presenter":
      res = [
        ...(type === 'Past' ? [] : [
          "copy-link",
          "join-session",
        ]),
        "dial-bridge-address"
      ];

      if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) {
        res = [
          ...res,
          "call-session-host",
          "email-session-host"
        ]
      } else {
        res = [
          ...res,
          "call-school-lead",
          "email-school-lead"
        ]
      }
      return res;
    default:
      return [];
  }
}

export const getActionMenusForExample = (session) => {
  if (!session) return [];

  let res;
  res = [
    "call-teacher",
    "email-teacher",
    "call-provider",
    "email-provider",
    "open-detail",
    "show-welcome-map",
    "duplicate-session"
  ]
  if (session["CILC Request URL"]) {
    res.push("cilc-request")
  } else if (session["CILC Booking URL"]) {
    res.push("book-cilc")
  } else if (session["CILC Profile URL"]) {
    res.push("open-cilc-profile")
  }
  if (
    session["# Schools"] === 1 &&
    session["Resource Tracking URL"] &&
    session["Resource Tracking URL"].length
  ) res.push("track-supplies")

  return res;
}

export const getActionMenusForSchoolLead = (session) => {
  if (!session) return [];

  return ["open-detail"];
}

export const getActionPendingMenus = (session, userType, presentersForProvider) => {
  if (!session) return [];

  let res;
  switch (userType) {
    case "Teacher":
      res = [
        "copy-link",
        "request-support",
        "open-detail",
        "edit-request",
        "confirm-actions",
      ]
      if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")
      if (session["Session Host(s)"]) {
        res = [
          ...res,
          "call-session-host",
          "email-session-host"
        ]
      } else {
        res = [
          ...res,
          "call-school-lead",
          "email-school-lead"
        ]
      }

      if (session.Status === "Requested") {
        res.push("cancel-request");
      } else if (session.Status === "Booked") {
        res.push("cancel-booking");
      }

      return res;
    case "Team":
      res = [
        "call-teacher",
        "email-teacher",
        "call-provider",
        "email-provider",
        "book-confirm",
        "status-inplanning",
        "status-indevelopment",
        "status-noresponse",
        "status-requested",
        "status-unfulfilled",
        "edit-session",
        "duplicate-session",
        /* "assign-to-funding-pool" */
      ]

      if (
        session["# Schools"] === 1 &&
        session["Resource Tracking URL"] &&
        session["Resource Tracking URL"].length
      ) {
        res.push("track-supplies")
      }

      if (
        session["# Schools"] === 1 &&
        session["Resource Tracking URL"] &&
        session["Resource Tracking URL"].length
      ) {
        res.push("track-supplies")
      }

      if (session["CILC Request URL"]) {
        res.push("cilc-request")
      } else if (session["CILC Booking URL"]) {
        res.push("book-cilc")
      } else if (session["CILC Profile URL"]) {
        res.push("open-cilc-profile")
      }
      if (session["CILC Profile URL"]) {
        res.push("add-cilc-request-id")
      }

      return res;
    case "Provider":
      res = [
        "internal-id",
        "assign-presenter",
        "confirm-actions"
      ]
      if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")
      if (session["Session Host(s)"]) {
        res = [
          ...res,
          "call-session-host",
          "email-session-host"
        ]
      } else {
        res = [
          ...res,
          "call-school-lead",
          "email-school-lead"
        ]
      }

      if (presentersForProvider && presentersForProvider.length) {
        res = [
          ...res,
          'assign-presenter'
        ]
      }

      return res;
    default:
      return [];
  }
}

export const getDisplayInfos = (session, userType) => {
  if (!session) return [];
  let res;

  switch (userType) {
    case "Session-Page":
      res = [
        'Provider Name',
        ...(session['# Schools'] > 1 ? [] : ['Resources Ship Date']),
        'Provider Internal ID',
        'Session Host',
        'Grade(s)',
        'Image/Photo',
        'open-detail',
        'Credits',
        'Optimized Image',
        'Menu Photo',
        'Primary Subject',
        'School Lead',
        'Presenter',
        'Favorite',
        'request-session',
      ]
      if (session["# Schools"] === 1) res.push("Resources Ship Date");

      return res;
    case "Teacher":
      res = [
        'Provider Name',
        ...(session['# Schools'] > 1 ? [] : ['Resources Ship Date']),
        'Provider Internal ID',
        'Session Host',
        'Grade(s)',
        'Image/Photo',
        'Optimized Image',
        'Menu Photo',
        'Credits',
        'open-detail',
        'join-session',
        'request-session',
        'Primary Subject',
        'School Lead',
        'Presenter',
        'Favorite',
      ]
      if (session["# Schools"] === 1) res.push("Resources Ship Date");

      return res;
    case "Team":
      res = [
        'Provider Name',
        "School Name",
        "Teacher Name",
        'Image/Photo',
        'Optimized Image',
        'open-detail',
        "Grade(s)",
        "Provider Internal ID",
        "book-session"
      ]
      if (session["# Schools"] === 1) res.push("Resources Ship Date");

      return res
    case "Provider":
      res = [
        "School Name",
        "School Location",
        "Teacher Name",
        'open-detail',
        "Grade(s)",
        "Presenters"
      ]
      if (session["Session Host(s)"]) res.push("Session Host"); else res.push("School Lead");
      return res;
    default:
      return [];
  }
}

export const getDisplayInfosUpcoming = (session, userType, type) => {
  if (!session) return [];
  let res;

  switch (userType) {
    case "Teacher":
      res = [
        'Provider Name',
        ...(session['# Schools'] > 1 ? [] : ['Resources Ship Date']),
        'Provider Internal ID',
        'Grade(s)',
        'Credits',
        'Image/Photo',
        'Session Host',
        'Optimized Image',
        'Menu Photo',
        ...(type === 'Upcoming' ? ['join-session'] : []),
        'Primary Subject',
        'Presenter',
        'Favorite',
        'Teacher School Name',
      ]
      if (session["# Schools"] === 1) res.push("Resources Ship Date");

      if (type !== 'School') {
        if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) res.push("Session Host");
        else res.push("School Lead");
      } else {
        res.push("Teacher Name")
      }

      return res;
    case "Team":
      res = [
        "Image/Photo",
        "Optimized Image",
        'Provider Name',
        "CILC Provider URL",
        "CILC Request URL",
        "School Name",
        "School Location",
        "Teacher School Name",
        ...(type === 'Upcoming' ? ['join-session'] : []),
        "Provider Internal ID"
      ]
      if (session["# Schools"] === 1) res.push("Resources Ship Date");

      return res
    case "Provider":
      res = [
        "Image/Photo",
        "Optimized Image",
        "School Name",
        "School Location",
        "Teacher School Name",
        "Grade(s)",
        'Image/Photo',
        'Optimized Image',
        'Menu Photo',
        ...(type === 'Upcoming' ? ['join-session'] : []),
        "Presenters"
      ]

      if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) res.push("Session Host");
      else res.push("School Lead");
      return res;
    case "Presenter":
      res = [
        "Image/Photo",
        "Optimized Image",
        "School Name",
        "School Location",
        "Teacher School Name",
        "Grade(s)",
        "Presenters"
      ]

      if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) res.push("Session Host");
      else res.push("School Lead");
      return res;
    default:
      return [];
  }
}

export const getDisplayInfosForExample = (session) => {
  if (!session) return [];
  let res;

  res = [
    "Image/Photo",
    "Optimized Image",
    'Provider Name',
    "Grade(s)",
    "CILC Provider URL",
    "CILC Request URL",
    "School Name",
    "School Location",
    "Teacher School Name",
    "Provider Internal ID"
  ]
  if (session["# Schools"] === 1) res.push("Resources Ship Date");

  return res
}

export const getDisplayInfosForSchoolLead = (session, type) => {
  if (!session) return [];
  let res;

  res = [
    'Provider Name',
    ...(session['# Schools'] > 1 ? [] : ['Resources Ship Date']),
    'Provider Internal ID',
    'Grade(s)',
    'Credits',
    'Image/Photo',
    'Optimized Image',
    'Menu Photo',
    ...(type === 'Upcoming' ? ['join-session'] : []),
    'Primary Subject',
    'Presenter',
    'Favorite',
  ]
  if (session["# Schools"] === 1) res.push("Resources Ship Date");

  if (type !== 'School') {
    if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) res.push("Session Host");
    else res.push("School Lead");
  } else {
    res.push("Teacher Name")
  }

  return res;
}

export const getDisplayInfosPending = (session, userType) => {
  if (!session) return [];
  let res;

  switch (userType) {
    case "Teacher":
      res = [
        'Provider Name',
        ...(session['# Schools'] > 1 ? [] : ['Resources Ship Date']),
        'Provider Internal ID',
        'Session Host',
        'Grade(s)',
        'Image/Photo',
        'Optimized Image',
        'Menu Photo',
        'Primary Subject',
        'School Lead',
        'Presenter',
        'Favorite',
      ]
      if (session["# Schools"] === 1) res.push("Resources Ship Date");
      if (session["Alternative Date/Time"]) {
        res.push("Alternative-Date")
      }

      return res;
    case "Team":
      res = [
        'Provider Name',
        "CILC Provider URL",
        "CILC Request URL",
        "School Name",
        "Teacher Name",
        "Teacher School Name",
        "Grade(s)",
        'Image/Photo',
        'Optimized Image',
        "Length (Minutes)",
        "Provider Internal ID"
      ]
      if (session["# Schools"] === 1) res.push("Resources Ship Date");
      if (session["Alternative Date/Time"]) {
        res.push("Alternative-Date")
      }

      return res
    case "Provider":
      res = [
        "Teacher School Name",
        "School Location",
        "Teacher Name",
        "Grade(s)",
        "Length (Minutes)",
        "Presenters",
        "Time Options"
      ]
      // if (session["Session Host(s)"]) res.push("Session Host"); else res.push("School Lead");
      return res;
    default:
      return [];
  }
}

export const getActionMenuForSearch = (userType, userInfo) => {
  if (userType === 'Teacher') {
    const res = [
      "copy-provider-session-link",
      "open-detail",
    ]
    if (userInfo["Cluster Leadership"]) res.push("send-to-teacher");

    return res;
  }

  if (userType === 'Team') {
    return [
      "copy-provider-session-link",
      "add-to-collection",
      "send-to-teacher",
      "open-detail",
    ]
  }

  return []
}

export const getDisplayInfosForSearch = (session, userType) => {
  if (userType === 'Teacher') {
    return [
      "request-session",
      "Primary Subject",
      "Primary Subject Action",
      "Image/Photo",
      "Optimized Image",
      "Grade(s)",
      "Favorite",
      "Credits",
      "Provider Name",
    ]
  }

  if (userType === 'Team') {
    return [
      "book-session",
      "Primary Subject",
      "Primary Subject Action",
      "Image/Photo",
      "Optimized Image",
      "Grade(s)",
      "Credits",
      "Provider Name",
    ]
  }

  return []
}

export const getActionPastMenus = (session, userType) => {
  const items = []
  if (userType === 'Teacher' || userType === 'Team') {
    items.push("open-detail")
    if (session["Status"] === 'Completed') items.push("rate-session")
  }

  if (
    userType === 'Team' &&
    !session?.Payments &&
    !session["CILC?"][0] &&
    session["Cost"] > 0 &&
    session["Status"] === 'Completed'
  ) {
    items.push("request-payment")

    if (session['Payment Type'] === 'Individual Invoices Per Session') {
      items.push("generate-invoice");
    }
  }

  if (userType === 'Team' && session["Status"] === 'Booked') {
    items.push("status-completed");
    items.push("status-cancelled")
  }

  if (userType === 'Team' && session["Status"] === 'Completed') {
    items.push("session-result");
  }

  if (userType === 'Team') {
    items.push("duplicate-session")
  }

  return items;
}

export const getDisplayInfosPast = (session, userType) => {
  if (!session) return [];

  let res;
  let isRatedSession;
  switch (userType) {
    case "Teacher":
      isRatedSession = session['Rating'] ? true : false;
      res = [
        'Provider Name',
        ...(session['# Schools'] > 1 ? [] : ['Resources Ship Date']),
        'Provider Internal ID',
        'Session Host',
        'Grade(s)',
        'Image/Photo',
        'Optimized Image',
        'Menu Photo',
        'Credits',
        'open-detail',
        'Primary Subject',
        'School Lead',
        'Rating'
      ]

      if (session.Status === 'Completed') {
        res.push(isRatedSession ? "edit-rate-session" : "rate-session");
      }
      return res;
    case "Team":
      isRatedSession = session['Team Rating'] ? true : false;
      res = [
        'Image/Photo',
        'Provider Name',
        "School Name",
        "Teacher Name",
        "Teacher School Name",
        'open-detail',
        "Grade(s)",
        'Rating'
      ]
      if (session["Status"] === 'Booked') res.push("status-cancelled")
      else if (session.Status === 'Completed') {
        res.push(isRatedSession ? "edit-rate-session" : "rate-session");
      }

      return res;
    case "Provider":
      isRatedSession = session['Provider Rating'] ? true : false;
      res = [
        "School Name",
        'Image/Photo',
        "School Location",
        "Teacher Name",
        'open-detail',
        "Grade(s)",
        "Presenters"
      ]
      if (session.Status === 'Completed') {
        res.push(isRatedSession ? "edit-rate-session" : "rate-session");
      }
      if (session["Session Host(s)"]) res.push("Session Host"); else res.push("School Lead");
      return res;
    default:
      return [];
  }
}

export const getDisplayInfosForAvailableSessions = (session) => {
  return [
    "Image/Photo",
    "Optimized Image",
    'Provider Name',
    "School Name",
    "Teacher School Name",
    "Provider Internal ID"
  ];
}

export const getActionsForAvailableSessions = (session) => {
  return [
    "call-teacher",
    "email-teacher",
    "call-provider",
    "email-provider",
    "reassign-session-lead",
    "open-detail",
    "host-session",
  ]
}