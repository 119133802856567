import '../styles.scss';
import React from 'react'
import moment from "moment";
import { Modal } from '@mui/material';
// import { getCategory } from './ResourceBrowse';
import { useTranslation } from 'react-i18next';
import { downloadAttachment } from '../../../utils/file';

export const ResourceCard = ({ item, type = 0 }) => {
  const { t } = useTranslation();
  // const tag = getCategory(item.Category);
  const className = ["carousel_card", "latest_card", "featured_card"]
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const video = item['Video URL'] || null;

  return (
    <>
      <div className={`resource_card ${className[type]}`}>
        <div className="image-wrap">
          <img src={item.Thumbnail[0].url} alt="" loading="lazy" />
          <span className="tag">{item.Type}</span>

        </div>
        <div className="card-text">
          <span className="date">{moment(item["Date Added"]).format('dddd, MMMM D')}</span>
          <h3>{item['Resource Name']}</h3>
          <ReadMore text={item.Description} maxLength={80} />

          <button type="button" className={`btn-link ${type === 2 ? 'secondary' : 'primary'} readmore`} onClick={handleOpen}>
            {t('read-more')}
          </button>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className="modal-card resource_card">
          <div className="image-wrap">
            {video !== null && video !== undefined ?
              <iframe
                src={video}
                // frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded Video"
              ></iframe>
              :
              <>
                <img src={item.Thumbnail[0].url} alt="" loading="lazy" />
                <span className="tag">{item.Type}</span>
              </>
            }
          </div>
          <div className="card-text">
            <span className="date">{moment(item["Date Added"]).format('dddd, MMMM D')}</span>
            <h3>{item['Resource Name']}</h3>
            <p>{item.Description}</p>

            <a href={item['Link URL']}>
              {item.Source}
            </a>

            {!!item["Attachment"] && (
              <span className='btn-download-resource' onClick={() => downloadAttachment(item["Attachment"][0].url, item["Attachment"][0].filename)}>
                Download Resource
              </span>
            )}
          </div>
          <button type="button" className="btn readless" onClick={handleClose}>
            x
          </button>
        </div>
      </Modal>
    </>

  )
}
const ReadMore = ({ text, maxLength }) => {

  if (text.length <= maxLength) {
    return <p>{text}</p>;
  }

  const displayedText = `${text.slice(0, maxLength)}...`;

  const lastSpaceIndex = displayedText.lastIndexOf(' ');
  const visibleText = displayedText.slice(0, lastSpaceIndex);

  return (
    <div>
      <p>
        {visibleText}
        ...
      </p>
    </div>
  );
};