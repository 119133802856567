import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { toast } from 'react-toastify';
import { Redirect } from "react-router";
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { setSession as saveSessionStorage } from '../../../utils/session';
import logoImg from '../../../assets/img/logo1.png';
import Loading from '../../../components/Loading';
import airtable from '../../../airtables';
import { getNiceFormatDateTimeString } from '../../../utils/time';
import SessionReject from '../../modals/RejectSession/Provider';
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../../../config';
import { setAppUserInfo, setAppUserType } from '../../../redux/actions';

const height = window.innerHeight;

const Screen = (props) => {
  const { appLocale } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(true);
  const [owner, setOwner] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [session, setSession] = useState(null);
  const [sessionId, setSessionId] = useState("");
  const [option, setOption] = useState(null);
  const [selectedTimeStr, setSelectedTimeStr] = useState("")
  const [saving, setSaving] = useState(false);
  const [rejectModal, showRejectModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      const { match: { params } } = props;
      const pId = params.providerId, sId = params.sessionId, opt = params?.option

      const provider = await airtable.providers.select(pId);
      await saveSessionStorage(CONNECTEDNORTH_SIGNINFO, provider);
      await saveSessionStorage(CONNECTEDNORTH_SIGNTYPE, "Provider");

      dispatch(setAppUserInfo(provider));
      dispatch(setAppUserType("Provider"));

      const sess = await airtable.sessions.select(sId);
      setSession(sess);
      setSessionId(sId);

      if (opt === 'reject') {
        showRejectModal(true);
      } else {
        switch (opt) {
          case "accept1":
          case "1":
            setOption("1")
            break;
          case "accept2":
          case "2":
            setOption("2");
            break;
          default:
            break;
        }
      }

      if (!sess || sess.Provider.indexOf(pId) < 0) {
        setOwner(false);
        setLoading(false);
      } else {
        setOwner(true);
        setLoading(false);
        setAccepted(sess.Status === 'Requested' ? false : true);
      }
    }

    init();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (option === "1") {
      setSelectedTimeStr(`${t("option")} 1: ${getNiceFormatDateTimeString(new Date(session['Session Start Date/Time']), appLocale)}`)
    } else if (option === "2") {
      setSelectedTimeStr(`${t("option")} 2: ${getNiceFormatDateTimeString(new Date(session['Alternative Date/Time']), appLocale)}`)
    } else {
      setSelectedTimeStr("");
    }
  }, [option]) // eslint-disable-line react-hooks/exhaustive-deps

  const onAccept = () => {
    setSaving(true);

    const date1 = option === "1" ? session['Session Start Date/Time'] : session['Alternative Date/Time'];
    const date2 = option === "2" ? session['Alternative Date/Time'] : session['Session Start Date/Time'];

    var params = {
      'Session Start Date/Time': new Date(date1),
      'Alternative Date/Time': new Date(date2),
      'Status': 'Confirmed for Booking'
    }

    airtable.sessions.update(sessionId, params).then(res => {
      setSaving(false);
      setAccepted(true);
      toast.success(t("update-session-success-msg"));
    }).catch(error => {
      setSaving(false)
      toast.error(error.toString())
    })
  }

  const postReject = () => {
    toast.success("Your revised dates/times have been submitted for the teacher's response!")
    setAccepted(true);
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="rate-content">
          <Loading size={24} />
        </div>
      )
    }

    if (!owner) {
      return (
        <>
          <div className="rate-content">
            <span className="rate-title" style={{ color: 'red' }}>{t("session-not-own")}</span>
          </div>
          <div className="rate-action">
            <Button
              className="ml-auto"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => window.location.href = "/cn/pending"}
            >{t("ok")}</Button>
          </div>
        </>
      )
    }

    if (!accepted) {
      return (
        <div align="center">
          <div className="rate-content">
            <span className="rate-title">{t("accept-request")}</span>
            <div style={{ height: 10 }} />
            <span>
              <Trans i18nKey="session-request-accept-msg" option={selectedTimeStr}>
                You are accepting date <strong>{{ option: selectedTimeStr }}</strong> and will receive a calendar invite with booking details.
              </Trans>
            </span>
          </div>
          <div className="rating" style={{ maxWidth: 320, marginTop: 20 }} align="left">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="accept-option"
                value={option}
                onChange={e => setOption(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={`${t("option")} 1: ${getNiceFormatDateTimeString(new Date(session['Session Start Date/Time']), appLocale)}`}
                />
                <FormControlLabel
                  value="2"
                  defaultChecked={option === 2}
                  control={<Radio />}
                  label={`${t("option")} 2: ${getNiceFormatDateTimeString(new Date(session['Alternative Date/Time']), appLocale)}`}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="rate-action">
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => window.location.href = "/cn/pending"}
            >{t("cancel")}</Button>
            <Button
              color="danger"
              type="button"
              onClick={() => showRejectModal(true)}
            >{t("reject")}</Button>
            <Button
              color="success"
              type="button"
              onClick={() => onAccept()}
            >{saving && <Loading size={14} />}{t("accept")}</Button>
          </div>
        </div>
      )
    }

    return null;
  }

  if (accepted) {
    return (
      <Redirect to="/cn/pending" />
    )
  }

  return (
    <>
      <main>
        <section className="section section-shaped section-lg" style={{ height: height, background: 'rgb(85, 197, 242, 0.7)' }}>
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-8">
            <Row className="justify-content-center align-items-center" style={{ height: '100%', paddingBottom: 70 }}>
              <Col lg="8">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="rate-logo">
                      <img src={logoImg} style={{ padding: '0 20px', maxWidth: 300, marginBottom: 10 }} alt="" />
                    </div>
                    {renderContent()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {
          (rejectModal && sessionId !== null) && (
            <SessionReject
              sessionId={sessionId}
              onToggle={() => {
                showRejectModal(false)
              }}
              onPost={postReject}
            />
          )
        }
      </main>
    </>
  )
}

export default Screen;