import React, { useState } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import ReactTimeAgo from 'react-time-ago';

const Question = ({ info, onFavorite, userType }) => {
  const { userInfo, appLocale } = useSelector(state => state.appInfo);
  const selfQuestion = userInfo.id === info["Added By"][0];
  const [liking, setLiking] = useState(false);

  const favouriteQuestion = async () => {
    if (selfQuestion || liking) return;

    setLiking(true);
    await onFavorite();
    setLiking(false);
  }

  const renderLikes = () => {
    if (liking) return <Loading size={11} />
    return ` ${(info["Favourites"] || []).length}`
  }

  return (
    <div className="question-container">
      <div className="question-item">
        <div className="q-content">
          <p>{info["Question"]}</p>
        </div>

        <div className="q-info">
          {userType === 'Team' && (
            <span className="q-poster">
              {info["Display Name"] && info["Display Name"][0]}{`, `}{info["Display School"][0]}
            </span>
          )}

          {userType === 'Teacher' ? (
            <Button
              outline
              size="sm"
              color="primary"
              className="btn-social"
              onClick={() => favouriteQuestion()}
              disabled={userType === 'Team' || selfQuestion} // Disable button for 'Team' userType or if it's the user's own question
            >
              ❤️&nbsp;<span>{renderLikes()}</span>
            </Button>
          ) : (
            <span className="q-favor">❤️&nbsp;<span>{renderLikes()}</span></span>
          )}

          <span className="q-date">
            <ReactTimeAgo
              date={new Date(info['Date Asked'])}
              locale={appLocale}
            />
          </span>
        </div>
      </div>
      <hr />
    </div>
  )
}

export default Question;
