import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useUpcoming } from './useUpcoming';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';
import RenderSchoolSessions from '../SessionList';

const Screen = () => {
  const { t } = useTranslation()
  const { userInfo, userType } = useSelector((state) => state.appInfo);
  const { data, refresh } = useUpcoming();

  const isDistrictLeader = (userInfo["District Leader?"] ?? "").toLowerCase() === "true";
  const [activeTab, setActiveTab] = useState(isDistrictLeader ? 'school' : 'upcoming');

  return (
    <div className="main-container upcoming">
      <h1>{userType === 'Provider' ? t('upcoming-past') : t('my-sessions')}</h1>
      <Nav tabs className="upcoming-tabs">
        {data.map((tabData, i) => (
          <NavItem key={i}>
            <NavLink
              className={classNames({ active: activeTab === tabData.name })}
              onClick={() => setActiveTab(tabData.name)}
            >
              {tabData.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {data.map((tabData, i) => (
          <TabPane tabId={tabData.name} key={i}>
            <RenderSchoolSessions
              loading={tabData.loading}
              sessions={tabData.sessions}
              type={tabData.type}
              noMessage={t("no-upcoming-sessions")}
              refreshPage={refresh}
            />
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default Screen;