import React, { useEffect, useState } from 'react';
import LoadingBar from '../../components/LoadingBar'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import airtable from '../../airtables';
import OverlayLoading from '../../components/OverlayLoading';
import SessionItem from '../../components/SessionItem';
import { Button } from 'reactstrap';
import { getWMDDateString } from '../../utils/time';
import { isTodayORTomorrowSession } from '../../utils/time';
import VimeoPlayer from '../modals/VimeoPlayer';
import { getProviderSessionIDFromSession } from '../../utils/session';

const Screen = () => {
  const { userInfo, appLocale, userType } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const [sessionMap, setSessionMap] = useState({});
  const [sessions, setSessions] = useState([]);
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [dateMap, setDateMap] = useState({});
  const [videoSession, setVideoSession] = useState();
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    window.document.title = "PD Week - Connected North"
    setLoading(true)
    airtable.sessions.getPDWeekSessions().then((res) => {
      setSessions(res);
      setLoading(false);
    }).catch(error => setLoading(false));
  }, [])

  useEffect(() => {
    if (!sessions || sessions.length === 0) {
      setSessionMap({});
      setKeys([]);
    } else {
      let dMap = {};
      const sMap = sessions.reduce((result, sess, id) => {
        const dateInfo = getWMDDateString(sess["Session Start Date/Time"], appLocale);
        const { label, date } = dateInfo;
        if (!dMap[label]) dMap[label] = dateInfo;
        if (label) {
          if (result[label]) {
            result[label].push({ session: sess, no: id, date });
            result[label].sort((a, b) => a.date.localeCompare(b.date))
          } else {
            result[label] = [{ session: sess, no: id, date }];
          }
        }
        return result;
      }, {});
      setDateMap(dMap);
      setSessionMap(sMap);
      setKeys(Object.keys(sMap).sort());
    }
  }, [sessions, appLocale])

  const cancelRegistration = async (session, no) => {
    if (window.confirm("Are you sure you wish to cancel your registration for this event?")) {
      setProcessing(true);
      const newSession = await airtable.sessions.update(session.id, {
        Teacher: session.Teacher.filter(t => t !== userInfo.id)
      });
      await airtable.events.deleteEvent(userInfo["Email"], session.Session);
      let nSessions = [...sessions]; nSessions[no] = newSession;
      setSessions(nSessions);
      setProcessing(false)
    }
  }

  const cancelRegistrationPending = async (session, no) => {
    if (window.confirm("Are you sure you wish to cancel your registration for this event?")) {
      setProcessing(true);
      await airtable.events.deleteEvent(userInfo["Email"], session.Session);
      const newSession = await airtable.sessions.select(session.id);
      let nSessions = [...sessions]; nSessions[no] = newSession;
      setSessions(nSessions);
      setProcessing(false);
    }
  }

  const requestRegister = async (session, no) => {
    setProcessing(true);
    await airtable.events.create({
      Name: userInfo["Teacher Name"],
      "E-mail Address": userInfo["Email"],
      School: [userInfo["School Name"][0]],
      Session: [session.id]
    })
    const newSession = await airtable.sessions.select(session.id);
    let nSessions = [...sessions]; nSessions[no] = newSession;
    setSessions(nSessions);
    setProcessing(false);
  }

  const canJoin = (session) => {
    if (userType === 'Teacher') {
      return session.Teacher && session.Teacher.indexOf(userInfo.id) >= 0 && isTodayORTomorrowSession(session["Session Start Date/Time"]) && session["WebEx/Zoom Link"];
    }

    return true;
  }

  const renderRegistrationStatus = (session, i) => {
    const sessionDate = new Date(session["Session Start Date/Time"]);
    let sessionPassed = false;
    if (sessionDate.getTime() < new Date().getTime()) sessionPassed = true;

    if (userType !== 'Teacher') return null;

    if (session.Teacher && session.Teacher.indexOf(userInfo.id) >= 0) {
      return (
        <>
          <Button className='btn-session' color="link" disabled={true} size="sm">Registered</Button>
          {!sessionPassed && <Button
            className='btn-session'
            color="danger"
            size="sm"
            onClick={(e) => cancelRegistration(session, i)}
          >Cancel Registration</Button>}
        </>
      )
    }

    if (session["Premium Registrations"] && session["Premium Registrations"].indexOf(userInfo.Email) >= 0) {
      return (
        <>
          <Button className='btn-session' color="link" disabled={true} size="sm">Registration Pending</Button>
          {!sessionPassed && <Button
            className='btn-session'
            color="danger"
            size="sm"
            onClick={(e) => cancelRegistrationPending(session, i)}
          >Cancel Registration</Button>}
        </>
      )
    }

    if (session["Capacity (Premium Sessions)"] && (session["# Schools"] >= session["Capacity (Premium Sessions)"])) {
      return (
        <Button
          className='btn-session'
          color="warning"
          size="sm"
          onClick={(e) => requestRegister(session, i)}
        >Session Full - Join Waitlist</Button>
      )
    }

    return (
      <Button
        className='btn-session'
        color="success"
        size="sm"
        onClick={(e) => requestRegister(session, i)}
      >Register</Button>
    );
  }

  return (
    <div className="main-container">
      <h1>{t("pd-week")}</h1>
      <div style={{ marginTop: 10 }}>
        {loading ? <LoadingBar /> : null}
        <div className="general-container pd-week">
          {
            loading ? null : (
              keys.map((k, i) => (
                <div key={i}>
                  <h4 className='monthly-group'><b>{dateMap[k].heading}</b></h4>
                  <div>{
                    sessionMap[k].map((sessInfo, j) => (
                      <div key={j}>
                        <SessionItem
                          session={sessInfo.session}
                          viewType="events"
                          pdweek
                        >
                          <div className="buttons">
                            {renderRegistrationStatus(sessInfo.session, sessInfo.no)}

                            {
                              sessInfo.session["Flyer"] ? (
                                <Button
                                  className='btn-session'
                                  color="primary"
                                  size="sm"
                                  onClick={(e) => window.open(sessInfo.session["Flyer"][0].url, "_blank")}
                                >View Flyer</Button>
                              ) : null
                            }

                            {
                              canJoin(sessInfo.session) ? (
                                <Button
                                  className='btn-session'
                                  color="success"
                                  size="sm"
                                  onClick={(e) => window.open(sessInfo.session["WebEx/Zoom Link"], "_blank")}
                                >Join</Button>
                              ) : null
                            }

                            {
                              sessInfo.session["Recording Embed Link"] ? (
                                <Button
                                  className='btn-session'
                                  color="default"
                                  size="sm"
                                  onClick={(e) => {
                                    setVideoSession(sessInfo.session)
                                    setShowVideo(true);
                                  }}
                                >View Recording</Button>
                              ) : null
                            }

                            {
                              sessInfo.session["Handouts"] ? (
                                <Button
                                  className='btn-session'
                                  color="info"
                                  size="sm"
                                  onClickCapture={() => window.open(`/cn/session/${getProviderSessionIDFromSession(sessInfo.session)}`)}
                                >View Handouts</Button>
                              ) : null
                            }
                          </div>
                        </SessionItem>
                      </div>
                    ))
                  }</div>
                </div>
              ))
            )
          }
        </div>
      </div>
      {
        (videoSession && showVideo) && (
          <VimeoPlayer
            title={videoSession['Session Title Text']}
            onToggle={() => setShowVideo(!showVideo)}
            vimeo={videoSession["Recording Embed Link"]}
            onPlay={async () => {
              const providerSessionId = getProviderSessionIDFromSession(videoSession);
              if (!userType === "Teacher") return;
              if (userInfo["Recordings Watched"] && userInfo["Recordings Watched"].indexOf(providerSessionId) >= 0) return;

              let recordings = [];
              if (!userInfo["Recordings Watched"]) recordings = [providerSessionId]
              else recordings = [...userInfo["Recordings Watched"], providerSessionId];

              await airtable.teachers.update(userInfo.id, { "Recordings Watched": recordings });
            }}
          />
        )
      }
      <OverlayLoading visible={processing} title="Registering..." />
    </div>
  )
}

export default Screen;