import React, { useEffect, useState } from "react";
import "./styles.scss"
import classNames from "classnames";
import { Button, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import airtable from '../../airtables';
import { setAppUserInfo } from "../../redux/actions";
import Select from 'react-select';

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri"];

const Screen = () => {
  const { userInfo, totalTimezones } = useSelector(state => state.appInfo);
  const [availability, setAvailability] = useState();
  const [changed, setChanged] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let st = null
    if (userInfo["Availability"]) st = JSON.parse(userInfo["Availability"]);

    const tmpArray = {};
    weekDays.forEach((wd, i) => {
      tmpArray[wd] = Array(11).fill(false);

      if (st && st[i] && st[i].length) {
        st[i].forEach(a => {
          tmpArray[wd][a - 8] = true;
        })
      }
    })

    setAvailability(tmpArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!totalTimezones || totalTimezones.length === 0) return;

    if (userInfo["Time Zone"]) {
      const tz = totalTimezones.find(tt => tt.id === userInfo["Time Zone"][0]);
      setSelectedTimezone({ label: tz["Connected North System"], value: tz.id });
    }
  }, [totalTimezones, userInfo])

  const toggleAvailability = (weekName, no) => {
    const weekAvailability = availability[weekName];
    weekAvailability[no] = !weekAvailability[no];
    setAvailability({ ...availability });
    setChanged(true);
  }

  const saveChange = async () => {
    if (!selectedTimezone) {
      alert("Please select your time zone.");
      return;
    }

    const result = [];
    weekDays.forEach((wd) => {
      const wdTimes = availability[wd]
        .map((da, i) => ({ availability: da, time: i + 8 }))
        .filter(a => a.availability)
        .map((d) => d.time);
      result.push(wdTimes || []);
    })
    const updatedUserInfo = await airtable.teams.update(userInfo.id, {
      "Availability": JSON.stringify(result),
      "Time Zone": [selectedTimezone.value]
    })
    dispatch(setAppUserInfo(updatedUserInfo));
    setChanged(false);
  }

  if (!availability) return null;

  return (
    <div className="main-container">
      <div className="page-head">
        <div className="page-title">
          <h1>Availability</h1>
          <p>Let us know which days of the week and hours in each day (in your time zone) you're available for hosting by clicking the cells below!</p>
        </div>
        <div className="head-actions">
          <Button
            color="primary"
            type="button"
            disabled={!changed}
            onClick={() => saveChange()}
          ><i className="fa fa-save" />&nbsp;Save</Button>
        </div>
      </div>
      <div className="timezone-select">
        <FormGroup>
          <label>What time zone do you live in?</label>
          <Select
            className="form-style"
            value={selectedTimezone}
            placeholder="Time Zone"
            onChange={(selectedOption) => {
              setSelectedTimezone(selectedOption)
              setChanged(true);
            }}
            options={totalTimezones.map(tt => ({ value: tt.id, label: tt["Connected North System"] }))}
          />
        </FormGroup>
      </div>
      <table className="availability">
        <thead>
          <tr>
            <th className="head-weekday"></th>
            <th className="head-time">8-9 AM</th>
            <th className="head-time">9-10 AM</th>
            <th className="head-time">10-11 AM</th>
            <th className="head-time">11-12 PM</th>
            <th className="head-time">12-1 PM</th>
            <th className="head-time">1-2 PM</th>
            <th className="head-time">2-3 PM</th>
            <th className="head-time">3-4 PM</th>
            <th className="head-time">4-5 PM</th>
            <th className="head-time">5-6 PM</th>
            <th className="head-time">6-7 PM</th>
          </tr>
        </thead>
        <tbody>
          {weekDays.map(wd => (
            <tr key={wd}>
              <th className="head-weekday">{wd}</th>
              {availability[wd].map((d, i) => (
                <td
                  key={i}
                  className={classNames({
                    "selected": d,
                    "available-cell": true
                  })}
                  onClick={() => toggleAvailability(wd, i)}
                />
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="12">
              <div className="foot-row">
                <div className="selected-ex" />
                <p>time blocks you're available</p>
                <div className="gap" />
                <div className="unselected-ex" />
                <p>time blocks you're not available</p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default Screen;
