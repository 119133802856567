import React, { Fragment, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { check2023BestPractices } from '../../utils/bp-utils';
import StepProgress from '../../components/StepProgress';
import Loading from '../../components/Loading';
import { setAppUserInfo } from '../../redux/actions';
import { toast } from 'react-toastify';
import airtable from '../../airtables';

const TraumaInformedApproaches = () => {
    const { userInfo, userType, isNewProvider, appLocale } = useSelector(state => state.appInfo);
    const [reflection, setReflection] = useState("");
    const [saving, setSaving] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const isRedirectRef = useRef(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Trauma Informed Approaches - Connected North"
    }, [])

    useEffect(() => {
        const redir = check2023BestPractices(userInfo, userType, isNewProvider, "reflection", "trauma-informed-approaches");
        if (isRedirectRef.current) window.location.href = `/cn${redir}`;
        else {
            setIsInProgress(redir ? false : true);
        }
    }, [userInfo, userType, isNewProvider])

    const updateBestPractices = async () => {
        if (userInfo["2023 Reflection Mandatory?"] && !reflection) return;

        setSaving(true);
        try {
            await airtable.providers.update(userInfo.id, {
                "2023 Reflection": reflection || "",
                "2023 Reflection Completed": true
            });
            const ui = await airtable.providers.select(userInfo.id);
            isRedirectRef.current = true;
            dispatch(setAppUserInfo(ui));
        } catch (error) {
            toast.error(error.toString());
        } finally {
            setSaving(false);
        }
    }

    return (
        <div className="main-container">
            {isInProgress && (
                <Fragment>
                    <StepProgress
                        steps={[
                            t("provider-step-progress.survey"),
                            t("provider-step-progress.reflection"),
                            t("provider-step-progress.code-of-conduct"),
                            ...(userInfo["Cost/Session"] ? [t("provider-step-progress.cancellation-policy")] : []),
                            t("provider-step-progress.confirm-session-offerings"),
                        ]}
                        activeStep={1}
                        completedSteps={[0]}
                    />
                    <div style={{ height: 20 }} />
                </Fragment>
            )}
            <h1>{isInProgress ? `${t('step2')}: ` : ''}{t("resources.trauma-informed.title")}</h1>
            <div className="main-content">
                <p>{t("resources.trauma-informed.p1")}</p>

                <p>{t("resources.trauma-informed.p2")}</p>

                <p><b>{t("resources.trauma-informed.p3")}</b></p>
                {
                    appLocale === 'fr' ? (
                        <a href="/resources/Trauma-fr.pdf" target='_blank'><img src="/resources/traumaInformedApproaches1-fr.png" className='traumaImg' alt="" /></a>
                    ) : (
                        <a href="/resources/Trauma.pdf" target='_blank'><img src="/resources/traumaInformedApproaches1.png" className='traumaImg' alt="" /></a>
                    )
                }

                <p className="mt20"><b>{t("resources.trauma-informed.ul1.title")}</b></p>
                <ul>
                    <li><p>{t("resources.trauma-informed.ul1.l1")}</p></li>
                    <li><p>{t("resources.trauma-informed.ul1.l2")}</p></li>
                    <li><p>{t("resources.trauma-informed.ul1.l3")}</p></li>
                    <li><p><Trans i18nKey="resources.trauma-informed.ul1.l4">Anxiety or overwhelm which manifests itself as abrupt statements<br />For example: “this activity is stupid” may mean “this is too complicated, I don’t understand, I feel stupid, and I don’t want to look stupid”</Trans></p></li>
                    <li><p>Ill-suited comments or questions due to low reasoning skills</p></li>
                </ul>

                <p>{t("resources.trauma-informed.p4")}</p>

                <p><b>{t("resources.trauma-informed.p5")}</b></p>
                {
                    appLocale === 'fr' ? (
                        <a href="/resources/traumaInformedApproaches2-fr.png" target='_blank'><img src="/resources/traumaInformedApproaches2-fr.png" className="trauma2Img" alt="" /></a>
                    ) : (
                        <a href="/resources/traumaInformedApproaches2.png" target='_blank'><img src="/resources/traumaInformedApproaches2.png" className="trauma2Img" alt="" /></a>
                    )
                }

                <p><b>{t("resources.trauma-informed.p5")}</b></p>
                <ol>
                    <li><span className="ol">{t("resources.trauma-informed.ol.l1.title")}</span>
                        <ul>
                            <li>{t("resources.trauma-informed.ol.l1.l1")}</li>
                            <li>{t("resources.trauma-informed.ol.l1.l2")}</li>
                        </ul>
                    </li>
                    <li><span className="ol">{t("resources.trauma-informed.ol.l2.title")}</span>
                        <ul>
                            <li>{t("resources.trauma-informed.ol.l2.l1")}</li>
                            <li>{t("resources.trauma-informed.ol.l2.l2")}</li>
                            <li>{t("resources.trauma-informed.ol.l2.l3")}</li>
                        </ul>
                    </li>
                    <li><span className="ol">{t("resources.trauma-informed.ol.l3.title")}</span>
                        <ul>
                            <li>{t("resources.trauma-informed.ol.l3.l1")}</li>
                            <li>{t("resources.trauma-informed.ol.l3.l2")}</li>
                            <li>{t("resources.trauma-informed.ol.l3.l3")}</li>
                        </ul>
                    </li>
                    <li><span className="ol">{t("resources.trauma-informed.ol.l4.title")}</span>
                        <ul>
                            <li>{t("resources.trauma-informed.ol.l4.l1")}</li>
                            <li>{t("resources.trauma-informed.ol.l4.l2")}</li>
                            <li>{t("resources.trauma-informed.ol.l4.l3")}</li>
                        </ul>
                        {
                            appLocale === "fr" ? (
                                <a href="/resources/traumaInformedApproaches3-fr.png" target='_blank'><img src="/resources/traumaInformedApproaches3-fr.png" className="traumaImg" alt="" /></a>
                            ) : (
                                <a href="/resources/traumaInformedApproaches3.png" target='_blank'><img src="/resources/traumaInformedApproaches3.png" className="traumaImg" alt="" /></a>
                            )
                        }
                    </li>
                    <li><span className="ol">{t("resources.trauma-informed.ol.l5.title")}</span></li>
                </ol>

                <p>{t("resources.trauma-informed.p6")}</p>

                <p>{t("resources.trauma-informed.p7")}</p>

                <p>{t("resources.trauma-informed.p8")}</p>

                <p>
                    <Trans i18nKey="resources.trauma-informed.p9">For more ideas about including Indigenous perspectives and engaging with students and their interests, see our  <a href="/cn/resources/creatively-customized-content" target='_blank'>customization resource</a> and our <a href="/cn/resources/facilitation-of-student-interactivity" target='_blank'>interactivity resource</a>.
                    </Trans>
                </p>

                <p className='underline'>{t("resources.trauma-informed.p10")}</p>

                {isInProgress && (
                    <Input
                        type="textarea"
                        rows={6}
                        disabled={saving}
                        onChange={e => setReflection(e.target.value)}
                        defaultValue={reflection}
                    />
                )}

                <p className='mt20'>
                    <Trans i18nKey="resources.trauma-informed.p11">Have any questions?  <a href="https://calendly.com/annemarie-tig/30min" target='_blank' rel="noreferrer">Book a meeting</a> with a team member to discuss.
                    </Trans>
                </p>

                <b>{t("resources.trauma-informed.additional")}</b><br />
                <a href="https://vimeo.com/takingitglobal/overcome" target='_blank' rel="noreferrer">- Overcome panel discussion</a>

                {isInProgress && (
                    <div align="center" style={{ marginTop: 20, marginBottom: 50 }}>
                        <Button
                            color="primary"
                            size="md"
                            type="button"
                            disabled={saving || (userInfo["2023 Reflection Mandatory?"] && !reflection)}
                            onClick={() => updateBestPractices()}
                        >{saving && <Loading size={14} />}&nbsp;{t("resources.trauma-informed.button")}</Button>
                    </div>
                )}
            </div>
        </div >
    )
}

export default TraumaInformedApproaches;
