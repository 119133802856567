import { useEffect, useState } from "react";
import airtable from '../../../airtables';

const useSchool = (school) => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    setLoading(true)
    refreshSchoolData();
  }, [school.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const refreshSchoolData = async () => {
    const sessList = await airtable.sessions.getAllSessionsInYearInSchool(school.id, school.School);
    setSessions(sessList);

    setLoading(false)
  }

  return {
    loading,
    sessions
  }
}

export default useSchool;