export const SET_APP_USERINFO = 'SET_APP_USERINFO';
export const SET_APP_USERTYPE = 'SET_APP_USERTYPE';
export const SET_APP_IS_SESSION_HOST = 'SET_APP_IS_SESSION_HOST';
export const SET_PASS_SESSION_PAGE_TYPE = 'SET_PASS_SESSION_PAGE_TYPE';
export const SET_APP_LOCALE = 'SET_APP_LOCALE';
export const SET_SUBJECT_MAP = 'SET_SUBJECT_MAP';
export const SET_TOTAL_SUBJECTS = 'SET_TOTAL_SUBJECTS';
export const SET_MY_FAVORITES = 'SET_MY_FAVORITES';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const SET_PRESENTER_UPCOMING = 'SET_PRESENTER_UPCOMING';
export const SET_PRESENTER_PAST = 'SET_PRESENTER_PAST';
export const SET_PRESENTER_LOADING = 'SET_PRESENTER_LOADING';

export const SET_PRESENTERS_FOR_PROVIDER = 'SET_PRESENTERS_FOR_PROVIDER';

export const SET_TOTAL_REGIONS = 'SET_TOTAL_REGIONS';
export const SET_REGIONS_MAP = 'SET_REGIONS_MAP';
export const SET_IS_NEW_PROVIDER = 'SET_IS_NEW_PROVIDER';

export const SET_COMMUNITIES = 'SET_COMMUNITIES';
export const SET_GROUP_TEACHERS = 'SET_GROUP_TEACHERS';
export const SET_TOTAL_TEAMS = 'SET_TOTAL_TEAMS';
export const SET_TAGS = 'SET_TAGS';

export const SET_TIMEZONES = 'SET_TIMEZONES'