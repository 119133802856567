import React, { useEffect, useState } from 'react';
import { Button, Input } from "reactstrap";

const Commitment = ({
  label,
  confirmLabel,
  btnLabel,
  finalLabel,
  defaultReflection,
  submitConfirm,
}) => {
  const [confirm, setConfirm] = useState(false);
  const [reflection, setReflection] = useState("");

  useEffect(() => {
    setReflection(defaultReflection)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="confirm-reflection">
      <div className="reflection">
        <span><strong>{label}</strong></span><br />
        <Input
          type="textarea"
          rows={6}
          onChange={e => setReflection(e.target.value)}
          value={reflection}
        />
      </div>
      <div className="confirm custom-control custom-checkbox mb-3">
        <input
          className="custom-control-input"
          id="confirm-check"
          type="checkbox"
          onChange={(e) => setConfirm(!confirm)}
        />
        <label className="custom-control-label" htmlFor="confirm-check">
          {confirmLabel}
        </label>
      </div>

      <Button
        className="ml-auto"
        color="primary"
        type="button"
        disabled={!confirm}
        onClick={() => submitConfirm(reflection)}
      >{btnLabel}</Button>

      {!!finalLabel && (
        <p style={{ marginTop: 10, fontSize: 14 }}>
          <em>{finalLabel}</em>
        </p>
      )}
    </div>
  )
}

export default Commitment;
