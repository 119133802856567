import { Breadcrumb as BreadcrumbReactStrap, BreadcrumbItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import s from "../styles.module.scss";

/**
 * @param {Object} props
 * @param {string} props.heading
 * */
export function Breadcrumb({ heading }) {
	const { t } = useTranslation();
	return (
		<div className="d-flex align-items-center" style={{ gap: "10px" }}>
			<a href="/cn/browser/curriculum">
				<Arrow />
			</a>
			<BreadcrumbReactStrap className={s.bread}>
				<BreadcrumbItem>
					<a href="/cn/browser/curriculum">
						{t("explore-by-curriculum")}
					</a>
				</BreadcrumbItem>
				<BreadcrumbItem active>{heading}</BreadcrumbItem>
			</BreadcrumbReactStrap>
		</div>
	);
}

function Arrow() {
	return (
		<svg
			width="30"
			height="20"
			viewBox="0 0 35 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M33 13.5C33.8284 13.5 34.5 12.8284 34.5 12C34.5 11.1716 33.8284 10.5 33 10.5V13.5ZM0.939339 10.9393C0.353554 11.5251 0.353554 12.4749 0.939339 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939339 10.9393ZM33 10.5L2 10.5V13.5L33 13.5V10.5Z"
				fill="#2B4F70"
			/>
		</svg>
	);
}
