import AirtableTable from "../AirtableTable";

export class CommunityTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Communities")

    this.fields = {
      "Name": "fld6OzZzRbsz01JYo",
      "# Providers": "fldp3g0wJbtnl0C0Y",
      "Type": "fldB74kR5OhULxs5y"
    }
  }

  getAllCommunities = () => {
    return this.list({
      filterByFormula: "{# Providers} > 0",
      sort: [{ field: 'Name', direction: 'asc' }]
    });
  }

  getTotalCommunities = () => {
    return this.list({
      sort: [{ field: 'Name', direction: 'asc' }]
    });
  }
}