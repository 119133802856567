import React, { Fragment, useEffect, useState } from 'react';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, Progress } from 'reactstrap';
import airtable from '../airtables';
import { setSession } from '../utils/session';
import { setAppUserInfo } from '../redux/actions';
import { CONNECTEDNORTH_SIGNINFO } from '../config';
import SessionDetail from '../pages/modals/Session';
import { Button } from "reactstrap";
import { toast } from 'react-toastify';
import ProviderSessionModal from './modals/ProviderSession';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import BP2024ProgressBar from './Best-Practices/2024/components/BP2024ProgressBar';
import { checkBestPractices } from './Best-Practices/2024/utils';

const sortlist = [
  { id: 0, label: 'Alphabetical (a-z)', icon: 'sort-alpha-down', sort: { field: 'Session Title', direction: 'asc' } },
  { id: 1, label: 'Alphabetical (z-a)', icon: 'sort-alpha-up', sort: { field: 'Session Title', direction: 'desc' } },
  { id: 2, label: 'Most Booked (9-0)', icon: 'sort-numeric-up', sort: { field: '# Booked', direction: 'desc' } },
  { id: 3, label: 'Least Booked (0-9)', icon: 'sort-numeric-down', sort: { field: '# Booked', direction: 'asc' } }
];
const TOTAL_BPC_NUMBER = 5;

// Make true in the summer -- December 2023 allow editing as a test
const sessionEditable = true;

const Screen = () => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [sortInfo, setSortInfo] = useState(sortlist[0]);
  const { userType, userInfo, isNewProvider } = useSelector(state => state.appInfo);
  const BPInfo = userInfo["Best Practice Commitments"];
  const [detailModal, openDetailModal] = useState(false);
  const [editModal, openEditModal] = useState(false);
  const [activeSession, setActiveSession] = useState(null);
  const [activeSessions, setActiveSessions] = useState([]);
  const [archivedSessions, setArchivedSessions] = useState([]);
  const [devSessions, setDevSessions] = useState([]);
  const [privateSessions, SetPrivateSessions] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const refreshPage = (ui, si) => {
    setLoading(true);
    airtable.providerSessions.getProviderSessions(ui.id, ui['Name'], si.sort).then(res => {
      setLoading(false);
      setSessions(res);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }

  const resort = async (si) => {
    setSortInfo(si);
  }

  useEffect(() => {
    window.document.title = "My Sessions - Connected North"
  }, [])

  useEffect(() => {
    if (userType === "Provider") {
      const redir = checkBestPractices(userInfo, userType, "completed");
      if (redir) window.location.href = `/cn${redir}`;
    }
  }, [userInfo, userType, isNewProvider])

  useEffect(() => {
    if (userInfo && userType) {
      refreshPage(userInfo, sortInfo)
    }
  }, [sortInfo, userInfo, userType])

  useEffect(() => {
    if (!sessions || sessions.length === 0) {
      setActiveSessions([])
      setArchivedSessions([]);
    } else {
      const aSessions = [], arSessions = [], dSessions = [], pSessions = [];
      sessions.forEach(sess => {
        if (sess.Status === 'Active (Open for booking)' || sess.Status === "Consider Another Option (See notes)") {
          aSessions.push(sess);
        } else if (sess.Status === 'Archived (No longer offered)' || sess.Status === "Currently unavailable (see provider notes)") {
          arSessions.push(sess);
        } else if (sess.Status === 'New' || sess.Status === 'In Development') {
          dSessions.push(sess);
        } else if (sess.Status === 'Private (See notes for booking, not in directory)') {
          pSessions.push(sess);
        } else {

        }
      });
      setActiveSessions(aSessions);
      setArchivedSessions(arSessions);
      setDevSessions(dSessions);
      SetPrivateSessions(pSessions);
    }
  }, [sessions])

  const onConfirmSessions = () => {
    if (confirm) var params = {};
    params["24-25 Provider Reviewed"] = true

    airtable.providers.update(userInfo.id, params).then(async res => {
      const ui = {
        ...res,
        id: userInfo.id
      }
      setSession(CONNECTEDNORTH_SIGNINFO, ui);
      dispatch(setAppUserInfo(ui))
      toast.success("Submitted!")
    })
  }

  const onEditSession = async (session) => {
    setActiveSession(session);
    setTimeout(() => {
      openEditModal(true);
    }, 100)
  }

  const onViewSession = (session) => {
    setActiveSession(session);
    openDetailModal(true);
  }

  const changeStatusofSession = (session, status) => {
    airtable.providerSessions.update(session.id, {
      'Status': status,
      'Provider Updates to Review': true
    }).then(res => {
      refreshPage(userInfo, sortInfo);
    })
  }

  const getSessionEditable = () => {
    if (userInfo["Allow Editing"]) return true;

    // return false;
    return sessionEditable;
  }

  const possibleDeliveryInfo = () => {
    if (userInfo["Total Delivered"]) return true;
    if (userInfo["Unique Schools"]) return true;
    if (userInfo["Upcoming"]) return true;
    return false;
  }

  const renderActionMenu = (session) => {
    switch (session['Status']) {
      case 'Active (Open for booking)':
      case "Consider Another Option (See notes)":
        return (
          <DropdownMenu>
            <DropdownItem href="#" onClick={e => onViewSession(session)}>{t("view-session")}</DropdownItem>
            <DropdownItem href="#" onClick={e => onEditSession(session)}>{t("edit-session")}</DropdownItem>
            <DropdownItem href="#" onClick={e => changeStatusofSession(session, 'Archived (No longer offered)')}>{t("archive")}</DropdownItem>
          </DropdownMenu>
        );
      case 'Archived (No longer offered)':
        return (
          <DropdownMenu>
            <DropdownItem href="#" onClick={e => onViewSession(session)}>{t("view-session")}</DropdownItem>
            <DropdownItem href="#" onClick={e => onEditSession(session)}>{t("edit-session")}</DropdownItem>
            {
              getSessionEditable() ? (
                <DropdownItem
                  href="#"
                  onClick={e => changeStatusofSession(session, 'Active (Open for booking)')}
                >{t("reactivate")}</DropdownItem>
              ) : null
            }
          </DropdownMenu>
        )
      case 'New':
      case 'In Development':
      case 'Private (See notes for booking, not in directory)':
        return (
          <DropdownMenu>
            <DropdownItem href="#" onClick={e => onViewSession(session)}>{t("view-session")}</DropdownItem>
            <DropdownItem href="#" onClick={e => onEditSession(session)}>{t("edit-session")}</DropdownItem>
          </DropdownMenu>
        )
      default:
        return null;
    }
  }

  const renderSessions = () => {
    if (activeSessions.length === 0 && archivedSessions.length === 0 && devSessions.length === 0) {
      return (
        <div
          className="nolist-placeholder"
          align="center"
        >{!loading && <span>{t("have-no-session-desc")}</span>}</div>
      )
    }

    const SessionItem = ({ session }) => (
      <div
        className="search-session"
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 20px', height: 80 }}
      >
        <div
          style={{
            flex: 1,
            minHeight: 80,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
          onClick={() => onEditSession(session)}
        >
          <div><span className="title">{session['Session Title']}</span></div>
          {session['Status'] === 'Active (Open for booking)' && (
            session['# Booked (23-24)'] ? (
              <span style={{ fontSize: 12 }}>{i18n.t("booked-time", { bookedTime: session['# Booked (23-24)'] })}</span>
            ) : (
              <span style={{ fontSize: 12 }}>{t("not-booked")}</span>
            )
          )}
        </div>

        <UncontrolledDropdown>
          <DropdownToggle color="secondary">
            <i className="fa fa-ellipsis-v" />
          </DropdownToggle>
          {renderActionMenu(session)}
        </UncontrolledDropdown>
      </div>
    )

    const email = "connectednorth@takingitglobal.org";
    return (
      <div>
        {activeSessions.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <span>{t("active-sessions")}</span><br />
            {Date.now() > new Date(2023, 8, 1) && (   // this will be shown since 2023-08-01
              <span style={{ fontSize: "small" }}>
                <Trans
                  i18nKey="mysession-desc"
                  email={email}
                  components={{
                    // eslint-disable-next-line
                    a: <a href="mailto:connectednorth@takingitglobal.org" />
                  }}
                />
              </span>
            )}
            {activeSessions.map((session, i) => (
              <SessionItem key={i} session={session} />
            ))}
          </div>
        )}

        {privateSessions.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <span>{t("private-sessions")}</span>
            <>
              {
                privateSessions.map((session, i) => (
                  <SessionItem key={i} session={session} />
                ))
              }
            </>
          </div>
        )}

        {devSessions.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <span>{t("proposed-sessions")}</span>
            <>
              {
                devSessions.map((session, i) => (
                  <SessionItem key={i} session={session} />
                ))
              }
            </>
          </div>
        )}

        {archivedSessions.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <span>{t("archived-sessions")}</span>
            <>
              {
                archivedSessions.map((session, i) => (
                  <SessionItem key={i} session={session} />
                ))
              }
            </>
          </div>
        )}
      </div>
    )
  }

  const renderProgress = () => {
    if (userType !== "Provider") return null;

    if (!userInfo["24-25 Provider Reviewed"]) {
      return (
        <Fragment>
          <BP2024ProgressBar current="confirm" />
          <div style={{ height: 20 }} />
        </Fragment>
      )
    }
    return null;
  }

  const renderReviewSection = () => {
    if (userType !== "Provider") return null;
    if (!sessions || sessions.length === 0) return null;
    if (userInfo["24-25 Provider Reviewed"]) return null;

    return (
      <div style={{ paddingTop: 20, borderTopWidth: 2, borderTopColor: 'gray', borderTopStyle: 'dotted', marginTop: 20 }}>
        <div className="custom-control custom-checkbox mb-3">
          <input
            className="custom-control-input"
            id="customCheck1"
            type="checkbox"
            defaultChecked={confirm}
            onChange={(e) => setConfirm(!confirm)}
          />
          <label className="custom-control-label" htmlFor="customCheck1">{t("mysession-desc1")}</label>
        </div>
        <Button
          className="ml-auto"
          color="primary"
          type="button"
          onClick={() => onConfirmSessions()}
        >{t("confirm-sessions")}</Button>
      </div>
    )
  }

  return (
    <div className="main-container">
      {renderProgress()}

      <h1>{t("my-sessions")}</h1>
      {loading && <Progress />}
      <div>
        <p>
          <Trans
            i18nKey="mysession-bestpractice-desc1"
            components={{
              // eslint-disable-next-line
              a: <a href="mailto:annemarie@takingitglobal.org" />
            }}
          />
        </p>
        <p>{t("mysession-bestpractice-desc2")}</p>
        <p>{t("mysession-bestpractice-desc3")}</p>
      </div>
      <div style={{ marginTop: 10 }}>
        {userInfo["Email"] === "mfurdyk+provider@gmail.com" && (!BPInfo || BPInfo.length !== TOTAL_BPC_NUMBER) && (
          <div className="notify notify-warn">
            <span>
              <Trans
                i18nKey="review-acknowledge-provider"
                components={{
                  // eslint-disable-next-line
                  a: <a href="/cn/best-practices/meaningful-land-acknowledgement" />
                }}
              />
            </span>
          </div>
        )}

        {possibleDeliveryInfo() && (
          <div className="notify notify-info" style={{ marginBottom: 20 }}>
            <span>
              <Trans
                i18nKey="provider-status-msg"
                values={{
                  totalDelivered: userInfo["Total Delivered"],
                  uniqueSchools: userInfo["Unique Schools"],
                  upcoming: userInfo["Upcoming"]
                }}
                components={{
                  // eslint-disable-next-line
                  1: <a href="/cn/upcoming/past" />,
                  // eslint-disable-next-line
                  3: <a href="/cn/myinfo" />,
                  // eslint-disable-next-line
                  5: <a href="/cn/upcoming" />
                }}
              />
            </span>
          </div>
        )}

        {!loading && (
          <Row>
            <Col xs="12" sm="3">
              {sessions.length > 0 && (
                <UncontrolledDropdown>
                  <DropdownToggle caret color="secondary">{sortInfo.label}</DropdownToggle>
                  <DropdownMenu>
                    {
                      sortlist.map((sort, i) => (
                        <DropdownItem href="#pablo" onClick={e => resort(sort)} key={i}>
                          {sort.label}
                        </DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Col>
            <Col xs="12" sm="9" align="right">
              <Button
                color="primary"
                size="md"
                type="button"
                onClick={() => onEditSession()}
              ><i className="fa fa-plus" />&nbsp;{t("add-session")}</Button>
            </Col>
          </Row>
        )}

        <div className="general-container">
          {renderSessions()}

          {renderReviewSection()}
        </div>
      </div>

      {(detailModal && activeSession !== null) && (
        <SessionDetail
          session={activeSession}
          userType={userType}
          onToggle={() => openDetailModal(!detailModal)}
        />
      )}

      {(editModal) && (
        <ProviderSessionModal
          session={activeSession}
          onToggle={() => openEditModal(!editModal)}
          disableField={!getSessionEditable()}
          onChange={() => refreshPage(userInfo, sortInfo)}
          userInfo={userInfo}
        />
      )}
    </div>
  )
}

export default Screen;
