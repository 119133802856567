import { Box, Button, Step, StepLabel, Stepper } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { FormGroup, Input, Label, Modal } from 'reactstrap';
import useHook from './useHook';
import Loading from '../../../components/Loading';
import Select from 'react-select';
import './styles.scss';

const SendToTeacher = ({
  data,
  onToggle,
  type
}) => {
  const {
    loading,
    matchedTeachers,
    otherTeachers,
    selectedMatchedTeachers,
    setSelectedMatchedTeachers,
    selectedOtherTeachers,
    setSelectedOtherTeachers,
    message,
    setMessage,
    ccEmail,
    setCcEmail,
    sendMessages,
    processing,

    regions,
    selectedRegions,
    changeCurrentRegions
  } = useHook(data, type);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) onToggle();
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleFinish = async () => {
    await sendMessages();
    onToggle();
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="modal-body min-body">
          <Loading size={40} />
        </div>
      )
    }

    return (
      <div className="modal-body">
        <Stepper activeStep={activeStep}>
          <Step key="teachers">
            <StepLabel>Select Teacher(s)</StepLabel>
          </Step>
          <Step key="fill">
            <StepLabel>Customize Message</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && (
          <div className='select-teacher'>
            <div className="filter-by-region">
              <FormGroup>
                <label>Select desired region</label>
                <Select
                  isMulti
                  className="form-style"
                  value={selectedRegions.map(r => ({
                    value: r.id,
                    label: `${r["Long Name"]} (${r["Name"]})`
                  }))}
                  onChange={options => {
                    const sIds = options.map(op => op.value);
                    const rs = regions.filter(rg => sIds.indexOf(rg.id) >= 0);
                    changeCurrentRegions(rs);
                  }}
                  options={regions.map(region => ({
                    value: region.id,
                    label: `${region["Long Name"]} (${region["Name"]})`
                  }))}
                  isDisabled={processing}
                />
              </FormGroup>
            </div>
            <div className='matched-teachers-selector'>
              <FormGroup>
                <label>{matchedTeachers.length !== 0 ? "Teachers Matching Subject(s) and Grade(s) Taught" : "No Teachers Matching Subject(s) and Grade(s)"}</label>

                {matchedTeachers.length !== 0 && (
                  <Select
                    isMulti
                    className="form-style"
                    value={selectedMatchedTeachers}
                    onChange={options => setSelectedMatchedTeachers(options)}
                    options={matchedTeachers.map(teacher => ({
                      value: teacher.id,
                      label: `${teacher["Teacher Name"]} (${teacher["School Name Text"]})`
                    }))}
                    isDisabled={processing}
                  />
                )}
              </FormGroup>

              {matchedTeachers.length !== 0 && (
                <Button
                  color="inherit"
                  sx={{ mr: 1 }}
                  onClick={() => setSelectedMatchedTeachers(matchedTeachers.map(t => ({
                    value: t.id,
                    label: `${t["Teacher Name"]} (${t["School Name Text"]})`
                  })))}
                  disabled={processing}
                >
                  Select all matched teachers
                </Button>
              )}
            </div>

            {otherTeachers.length !== 0 && (
              <FormGroup>
                <label>Other Teachers</label>

                {otherTeachers.length !== 0 && (
                  <Select
                    isMulti
                    className="form-style"
                    value={selectedOtherTeachers}
                    onChange={opts => setSelectedOtherTeachers(opts)}
                    options={otherTeachers.map(teacher => ({
                      value: teacher.id,
                      label: `${teacher["Teacher Name"]} (${teacher["School Name Text"]})`
                    }))}
                    isDisabled={processing}
                  />
                )}
              </FormGroup>
            )}
          </div>
        )}

        {activeStep === 1 && (
          <div className='st-customize-msg'>
            <FormGroup>
              <Input
                type="textarea"
                value={message}
                className="message"
                onChange={ele => setMessage(ele.target.value)}
                placeholder="Please include additional text here you want to display above the recommendation you are making to the Teacher(s), to explain why you are sending it to them and/or why you think it's a good fit for their class."
                disabled={processing}
              />
            </FormGroup>

            <FormGroup check>
              <Input
                type='checkbox'
                id="check1"
                onChange={(e) => setCcEmail(e.target.checked)}
                checked={ccEmail}
                disabled={processing}
              />
              <Label check for="check1" style={{ fontSize: 16 }}><strong>Copy (cc) me on e-mails sent</strong></Label>
            </FormGroup>
          </div>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 1 }}
            disabled={processing}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />

          {activeStep === 1 ? (
            <Button
              onClick={handleFinish}
              disabled={!message || processing}
            >
              {processing && (
                <Fragment>
                  <Loading size={20} />{' '}
                </Fragment>
              )}Send {selectedMatchedTeachers.length + selectedOtherTeachers.length} Messages
            </Button>
          ) : (
            <Button
              onClick={handleNext}
              disabled={(selectedMatchedTeachers.length + selectedOtherTeachers.length) === 0 || processing}
            >
              Next
            </Button>
          )}
        </Box>
      </div>
    )
  }

  return (
    <Modal
      isOpen
      className="modal-dialog-centered"
      toggle={() => {
        if (processing) return;
        onToggle()
      }}
    >{renderContent()}</Modal>
  )
}

export default SendToTeacher;
