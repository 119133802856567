import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import greatLogo from "../../../assets/img/great-e.png"
import Commitment from '../components/Commitment';
import pageImg1 from "../../../assets/img/engage-img1.png";
import pageImg2 from "../../../assets/img/engage-img2.png";
import pageImg3 from "../../../assets/img/engage-img3.png";
import { Input } from 'reactstrap';

const NavContent = ({
  defaultReflection,
  defaultEngageTimestamps,
  commitment,
  width
}) => {
  const [engageTimestamps, setEngageTimestamps] = useState("");
  const { t } = useTranslation();
  const [videoHeight, setVideoHeight] = useState(0);
  const history = useHistory();

  useEffect(() => {
    setVideoHeight(width / 1.69)
  }, [width])

  useEffect(() => {
    setEngageTimestamps(defaultEngageTimestamps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="nav-content-wrap">
      <div className="main-content">
        <h4>{t("session-host-bp.engage.title")}</h4>
        <img
          src={greatLogo}
          className="great-logo sh-img"
          alt=""
        />

        <img
          src={pageImg1}
          className="sh-img"
          alt=""
        />

        <p>{t("session-host-bp.engage.p1")}</p>
        <p>
          <Trans
            i18nKey="session-host-bp.engage.p2"
            components={{ b: <b /> }}
          />
        </p>
        <p>{t("session-host-bp.engage.p3")}</p>
        <p>{t("session-host-bp.engage.p4")}</p>

        <img
          src={pageImg2}
          className="sh-img"
          alt=""
        />

        <p>{t("session-host-bp.engage.p7")}</p>
        <ul className="session-host">
          <li>{t("session-host-bp.engage.p7-li1")}</li>
          <li>{t("session-host-bp.engage.p7-li2")}</li>
          <li>{t("session-host-bp.engage.p7-li3")}</li>
          <li>{t("session-host-bp.engage.p7-li4")}</li>
        </ul>

        <img
          src={pageImg3}
          className="sh-img"
          alt=""
        />

        <p>{t("session-host-bp.engage.p8")}</p>

        <div>
          {!!width && (
            <iframe
              src="https://player.vimeo.com/video/998950797?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Session Host Training - Engage Example"
              width={width}
              height={videoHeight}
            />
          )}
        </div>

        <p>
          <Trans
            i18nKey="session-host-bp.engage.p9"
            components={{ b: <b /> }}
          />
        </p>

        <div>
          <Input
            type="textarea"
            rows={6}
            placeholder="Example: 2:05 - I would have stopped the presenter to ask students a question"
            onChange={e => setEngageTimestamps(e.target.value)}
            value={engageTimestamps}
          />
        </div>

        <Commitment
          label={t("session-host-bp.engage.reflection-label")}
          confirmLabel={t("session-host-bp.engage.agree")}
          btnLabel={t("session-host-bp.engage.continue")}
          defaultReflection={defaultReflection}
          submitConfirm={async (reflection) => {
            await commitment(reflection, engageTimestamps);
            history.push("act");
          }}
        />
      </div>
    </div>
  )
}

export default NavContent;