import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';
import MyFavorites from './my';
import TeacherFavorites from './school';
import { useTranslation } from 'react-i18next';

const Screen = () => {
  const [activeTab, setActiveTab] = useState("my");
  const { userInfo } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const isSchoolOrDistrictLeader =
    (userInfo["School Leader?"] ?? "").toLowerCase() === "true" ||
    (userInfo["District Leader?"] ?? "").toLowerCase() === "true";

  return (
    <div className="main-container">
      <Row>
        <Col align='left' xs='12' className="text-nowrap"><h1>{t("my-favourites")}</h1></Col>
      </Row>
      {isSchoolOrDistrictLeader ? (
        <Fragment>
          <Nav tabs className="upcoming-tabs">
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "my" })}
                onClick={() => setActiveTab("my")}
              >My Favourites</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "school" })}
                onClick={() => setActiveTab("school")}
              >Teacher Favourites</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="my">
              <MyFavorites />
            </TabPane>
            <TabPane tabId="school">
              <TeacherFavorites />
            </TabPane>
          </TabContent>
        </Fragment>
      ) : (
        <div style={{ marginTop: 20, minHeight: 500 }}>
          <MyFavorites />
        </div>
      )
      }
    </div >
  )
}

export default Screen;
