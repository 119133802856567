import React, { useEffect, useState } from 'react';
import './styles.scss';
import logoImg from '../../assets/img/logo1.png';
import Loading from '../../components/Loading';
import airtable from '../../airtables';
import { CurrentGradeLevels } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import * as EmailValidator from 'email-validator';
import { setAppUserInfo, setAppUserType } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setSession } from "../../utils/session";
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../../config';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { analytics } from '../../firebase';
import { toast } from "react-toastify";

const Screen = (props) => {
  const { totalSubjects } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match: { params: { username, clusterId } } } = props;

  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(0);
  const [saving, setSaving] = useState(false);

  const [schools, setSchools] = useState([]);

  const [email, setEmail] = useState("");
  const [secondemail, setsecondEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [defaultCredits, setDefaultCredits] = useState(0);

  const resizeListener = () => {
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.document.title = "Register - Connected North"
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    setTimeout(async () => {
      resizeListener();
      const cluster = await airtable.clusters.select(clusterId);

      const ss = [];
      for (const sId of cluster["Schools"]) {
        const school = await airtable.schools.select(sId);
        ss.push(school);
      }
      ss.sort((a, b) => a["School"].localeCompare(b["School"]));
      setSchools(ss);

      setEmail(`${username}@${cluster.Domain}`);

      setDefaultCredits(cluster["Default New Teacher Credits"] || 15);
      setLoading(false);
    });

    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener("resize", resizeListener);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleSubjects = (subject) => {
    const pos = subjects.indexOf(subject.id);
    if (pos < 0) {
      const newSubs = [...subjects, subject.id];
      setSubjects(newSubs);
    } else {
      const newSubs = subjects.filter(item => item !== subject.id);
      setSubjects(newSubs);
    }
  }

  const toggleGrades = (grade) => {
    const pos = grades.indexOf(grade);
    if (pos < 0) {
      const newGrades = [...grades, grade];
      setGrades(newGrades);
    } else {
      const newGrades = grades.filter(g => g !== grade);
      setGrades(newGrades);
    }
  }

  const registerTeacher = async () => {
    if (!email || !EmailValidator.validate(email)) {
      alert("Your E-mail address is not valid. Please check your entry and try again!");
      return;
    }
    if (!name) {
      alert("Please input your full name.");
      return;
    }
    if (!schoolId) {
      alert("Please select your school. If your school isn't listed please contact connectednorth@takingitglobal.org");
      return;
    }
    /*
    if (!subjects || !subjects.length) {
        alert("Please specify at least one subject taught. If you are in school administration, you can select Professional Development.");
        return;
    }
    */
    if (!grades || !grades.length) {
      alert("Please specify at least one grade taught. If you are in school administration, please select all grades served by your school.");
      return;
    }

    setSaving(true);
    let userInfo = await airtable.teachers.getTeacherByEmail(email);
    if (userInfo) {
      toast.info(`A teacher with ${email} is already registered.`)
    } else {
      userInfo = await airtable.teachers.create({
        "Teacher Name": name,
        "Email": email,
        "Secondary Email": secondemail,
        "School Name": [schoolId],
        "Personal Phone": phone,
        "Subject(s) Taught": subjects,
        "Grade(s) Taught": grades,
        "Self Signup": true,
        "Platform Logins": 1,
        "Status": "Active",
        "E-mail Reminders": ["24 hours before"]
      });

      await airtable.credit.create({
        "Teacher": [userInfo.id],
        "Credits": defaultCredits,
        "Action": "Credits Issued by Program",
        "Display Note": "Introductory Credits - Teacher joined the Connected North Platform"
      })
    }

    const userType = "Teacher";

    await Promise.all([
      setUserId(analytics, email),
      setUserProperties(analytics, 'user_type', userType),
      logEvent(analytics, 'register', { email, userType }),
      logEvent(analytics, 'login', { email, userType })
    ]);

    await setSession(CONNECTEDNORTH_SIGNINFO, userInfo);
    await setSession(CONNECTEDNORTH_SIGNTYPE, userType);

    delete userInfo["24 Report Message"];
    dispatch(setAppUserInfo(userInfo));
    dispatch(setAppUserType(userType));
    setSaving(false);
    window.location.href = "/cn/dashboard";
  }

  const renderContent = () => {
    if (loading) return (
      <div className="registering-content">
        <Loading size={24} />
      </div>
    )

    return (
      <div className="registering-content">
        <FormGroup className="registering-item">
          <Label for="name">Full Name</Label>
          <Input
            id="name"
            className="form-style"
            placeholder={t("full-name")}
            defaultValue={name}
            onChange={e => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="registering-item">
          <Label for="email">E-mail Address</Label>
          <Input
            id="email"
            className="form-style"
            placeholder={t("email")}
            defaultValue={email}
            onChange={e => setEmail(e.target.value)}
            readOnly
          />
        </FormGroup>
        <FormGroup className="registering-item">
          <Label for="email">Secondary / Personal E-mail (optional)</Label>
          <Input
            id="secondemail"
            className="form-style"
            placeholder={t("email")}
            defaultValue={secondemail}
            onChange={e => setsecondEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="registering-item">
          <Label for="phone">Mobile Phone Number (optional)</Label>
          <Input
            id="phone"
            className="form-style"
            placeholder={t("phone-number")}
            defaultValue={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </FormGroup>

        <FormGroup className="registering-item">
          <Label for="school">Select your School:</Label>
          <Input
            type="select"
            id="school"
            onChange={e => setSchoolId(e.target.value)}
          >
            <option></option>
            {
              schools.map((s, i) => (
                <option value={s.id} key={i}>{s["School"]}</option>
              ))
            }
          </Input>
        </FormGroup>

        <FormGroup className="registering-item">
          <Label for="subjects">Subject(s) Taught</Label>
          <div className="flex-wrap-container">
            {
              totalSubjects
                .filter(sub => sub.Subject !== "Premium Session" && sub.Subject !== "Therapy (SLP/OT)" && sub.Subject !== "Holiday Themed")
                .map((sub, i) => (
                  <div
                    className={subjects.indexOf(sub.id) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                    key={i}
                    onClick={() => toggleSubjects(sub)}
                  ><span>{sub.Subject}</span></div>
                ))
            }
          </div>
        </FormGroup>

        <FormGroup className="registering-item">
          <Label for="grades">Grade(s) Taught</Label>
          <div className="flex-wrap-container">
            {
              CurrentGradeLevels.map((grade, i) => (
                <div
                  className={grades.indexOf(grade) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                  key={i}
                  onClick={() => toggleGrades(grade)}
                ><span>{grade}</span></div>
              ))
            }
          </div>
        </FormGroup>

        <div className="registering-item registering-actions">
          <Button
            type="button"
            onClick={() => window.location.href = "/login"}
          >{t("back-login")}</Button>
          <Button
            color="success"
            type="button"
            onClick={() => registerTeacher()}
            disabled={saving}
          >{saving ? (<Loading size={14} />) : (null)}{t("register-teacher")}</Button>
        </div>
      </div>
    );
  }

  return (
    <main>
      <section className="section section-shaped section-lg" style={{ background: 'rgb(85, 197, 242, 0.7)', height, paddingTop: 0, paddingBottom: 0 }}>
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container>
          <Row className="justify-content-center align-items-center" style={{ height: '100%', paddingBottom: 70, overflow: 'scroll' }}>
            <Col lg="9">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="rate-logo">
                    <img src={logoImg} style={{ padding: '0 20px', maxWidth: 300, marginBottom: 10 }} alt="" />
                    <span>{t("register-teacher-title")}</span>
                  </div>
                  {renderContent()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default Screen;
