import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

const SubjectsPieGraph = ({ sessions, title }) => {
  var subjects = [];
  
  sessions.forEach((s) => {
    s['Primary Subject Text'] &&
      s['Primary Subject Text'].forEach((g) => {
        var index = subjects.findIndex((e) => e.name === g)
        if (index !== -1) {
          subjects[index].value = subjects[index].value + 1
        } else {
          subjects = [...subjects, { name: g, value: 1 }]
        }
      })
  })
  const state = {
    labels: subjects.map((label) => label.name),
    datasets: [
      {
        label: '# of Sessions',
        data: subjects.map((label) => label.value),
        backgroundColor: [
          '#0D1120',
          '#1094CE',
          '#0E6BA8',
          '#9DE0F8',
          '#2B4F70',
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
      },
    ],
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          usePointStyle: true,
          padding: 24,
        },
      },
      title: {
        display: true,
        text: title,
        position: 'top',
        padding: {
          top: 0,
          bottom: 20,
        },
        fullSize: true,
        align: 'start',
        color: '#0D1120',
        font: {
          size: 25,
          weigth: 700,
        },
      },
    },
  }
  return (
    <Pie
      data={state}
      options={options}
      height="300px"
    />
  );
}
export default SubjectsPieGraph
