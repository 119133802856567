import { useEffect, useRef, useState } from 'react';
import { addSearchResult, checkContactByKeyword } from './utils';

const useContacts = (contactType, loadFunc, params, search) => {
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [pageNo, setPageNo] = useState(0);

  const [refresher, updateRefresher] = useState(0);
  const searchResultRef = useRef([]);
  const searchPageNoRef = useRef(-1);
  const searchingRef = useRef(false);

  useEffect(() => {
    updateRefresher(refresher + 1);
  }, [searchResultRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadContacts = () => {
    setLoading(true);

    searchResultRef.current = [];
    loadFunc(params, (ts) => {
      setPages(ts);
    }).then(() => setLoading(false));
  }

  useEffect(() => {
    loadContacts();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    searchResultRef.current = [];
    if (!search) {
      return;
    }

    searchPageNoRef.current = -1;
    findContacts([...pages], pages.length - 1);
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!search || !pages || !pages.length) {
      searchResultRef.current = [];
      return;
    }

    if (searchingRef.current) return;
    const sPages = pages.slice(searchPageNoRef.current + 1, pages.length);
    findContacts(sPages, pages.length - 1);
  }, [pages]) // eslint-disable-line react-hooks/exhaustive-deps

  const findContacts = (subPages, no) => {
    searchingRef.current = true;

    const tmpResult = [...searchResultRef.current];
    subPages.forEach(page => {
      page.items.forEach((contact) => {
        if (checkContactByKeyword(contactType, contact, search)) {
          addSearchResult(tmpResult, contact, params.pageSize);
        }
      })
      searchResultRef.current = [...tmpResult];
    })
    searchPageNoRef.current = no;
    searchingRef.current = false;
  }

  const getItems = () => {
    if (search) {
      // if (contactType === "Teacher") console.log("searchResult", searchResult.length)
      if (pageNo >= searchResultRef.current.length) return [];
      return searchResultRef.current[pageNo].items || [];
    } else {
      if (pageNo >= pages.length) return [];
      return pages[pageNo].items || [];
    }
  }

  const getLoadingStatus = () => {
    if (search && loading) return true;

    return loading && pageNo >= pages.length;
  }

  return {
    loading: getLoadingStatus(),
    pageCount: search ? searchResultRef.current.length : pages.length,
    contacts: getItems(),
    changePage: setPageNo,
    pageNo,
    refresh: loadContacts
  }
}

export default useContacts;