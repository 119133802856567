import React, { Fragment, useEffect, useState } from 'react';
import LoadingBar from '../../components/LoadingBar';
import { useTranslation } from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SessionInfo from '../../components/SessionInfo';
import BookingModal from '../../pages/modals/Booking';
import InternalIDModal from '../modals/InternalID';
import AssignPresenter from '../modals/AssignPresenter';
import AddCILCRequestIDModal from '../modals/AddCILCRequestID';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import airtable from '../../airtables';
import { toast } from 'react-toastify'
import { getActionPendingMenus, getDisplayInfosPending } from '../SessionInfoDisplay';
import { hideLoading, showLoading } from '../../utils/loading';
import { getProviderSessionIDFromSession } from '../../utils/session';
import { setRequestSessionModal } from '../../redux/modalActions';
import DuplicateSessionModal from '../modals/DuplicateSession';
import AssignFundingPoolModal from '../modals/AssignFundPool';
import CancelBRSessionModal from "../modals/CancelBookRequest";

const SessionList = ({
  loading,
  sessionInfo: {
    sessions: sessList,
    updateFunc
  },
  onRefresh,
  sessionConfirm
}) => {
  const dispatch = useDispatch();

  const { userType, presentersForProvider } = useSelector(state => state.appInfo);
  const { t } = useTranslation();

  const [sessions, setSessions] = useState([])

  // book & confirm info
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [activeBookingSession, setActiveBookingSession] = useState(null);

  // edit internal id
  const [showInternalIDModal, setShowInternalIDModal] = useState(false);
  const [activeSessionForInternalID, setActiveSessionForInternalID] = useState(null);

  const [showAssignPresenterModal, setAssignPresenterModal] = useState(false);
  const [activeSessionForAssignPresenter, setActiveSessionForAssignPresenter] = useState(null);

  const [showAddCILCReuqestIDModal, setAddCILCReuqestIDModal] = useState(false);
  const [activeSessionForAddCILCReuqestIDModal, setActiveSessionForAddCILCReuqestIDModal] = useState(null);

  //duplicate session
  const [activeDuplicateSession, setActiveDuplicateSession] = useState(null);
  const [showDuplicateSessionModal, setShowDuplicateSessionModal] = useState(false);

  // assign to funding pool
  const [activeAssignFundPoolSession, setActiveAssignFundPoolSession] = useState(null);
  const [showAssignFundPoolModal, setShowAssignFundPoolModal] = useState(false);

  // cancel booked or requested session
  const [activeCancelSession, setActiveCancelSession] = useState(null);
  const [cancelBookedOrRequestedSessionModal, showCancelBookedOrRequestedSessionModal] = useState(false);

  useEffect(() => {
    setSessions(sessList);
  }, [sessList])

  const openInternalIDModal = (session) => {
    setActiveSessionForInternalID(session);
    setShowInternalIDModal(true)
  }

  const openAddCILCReuqestIDModal = (session) => {
    setActiveSessionForAddCILCReuqestIDModal(session);
    setAddCILCReuqestIDModal(true);
  }

  const addCILCRequestID = async (id) => {
    try {
      const nSession = await airtable.sessions.update(activeSessionForAddCILCReuqestIDModal.id, {
        "CILC Request ID": id
      })
      const sessId = sessions.findIndex((session) => session.id === activeSessionForAddCILCReuqestIDModal.id);
      if (sessId >= 0) {
        const nPending = [...sessions];
        nPending[sessId] = nSession;
        updateFunc(nPending);
      }
    } catch (error) {
      toast.error(error.toString());
    }
  }

  const onChangeSession = (e, session, status) => {
    e.preventDefault();
    airtable.sessions.update(session.id, {
      'Status': status
    }).then(res => {
      onRefresh();
    }).catch(error => {
      toast.error(error.toString());
    })
  }

  const onBookConfirm = (e, session) => {
    e.preventDefault();

    setShowBookingModal(true);
    setActiveBookingSession(session);
  }

  const onBookedSession = () => {
    setShowBookingModal(() => false, () => onRefresh())
    setActiveBookingSession(null);
    onRefresh();
  }

  const editRequest = (session) => {
    dispatch(setRequestSessionModal({
      visible: true,
      providerSession: session,
      onSubmitted: () => {
        sendNotificationForChange(session.id, 'change');
        dispatch(setRequestSessionModal({}));
        onRefresh();
      },
      isEdit: true,
      prefillSession: session
    }))
  }

  const cancelRequestedOrBookedSession = (session) => {
    showCancelBookedOrRequestedSessionModal(true);
    setActiveCancelSession(session);
  }

  const assignPresenter = (session) => {
    setActiveSessionForAssignPresenter(session);
    setAssignPresenterModal(true)
  }

  const sendNotificationForChange = (sessId, action) => {
    Axios.get(`https://hooks.zapier.com/hooks/catch/89715/b6i5gt7?RequestID=${sessId}&action=${action}`)
  }

  const bookOnCILC = async (session) => {
    showLoading("Processing...");
    const res = await Axios.get("https://research.tigweb.org/connectednorth/cilc/?recID=" + session.id);
    const cilcReqId = res.data["CILC Request ID"];

    const sessionIndex = sessions.findIndex(s => s.id === session.id);
    const updatedSessions = [...sessions];
    updatedSessions[sessionIndex] = {
      ...session,
      'CILC Request ID': String(cilcReqId),
      'CILC Request URL': `https://www.cilc.org/ContentProvider/ViewAProgramRequest.aspx?Type=S&id=${cilcReqId}`
    };
    setSessions(updatedSessions);
    hideLoading();
  }

  const duplicateSession = (e, session) => {
    setActiveDuplicateSession(session);
    setShowDuplicateSessionModal(true);
  }

  const assignToFundingPool = (e, session) => {
    setActiveAssignFundPoolSession(session);
    setShowAssignFundPoolModal(true);
  }

  const postSessionReject = () => {
    if (userType === "Provider") {
      toast.success("Your revised dates/times have been submitted for the teacher's response!")
    } else if (userType === "Teacher") {
      toast.success("Your revised dates/times have been submitted for the provider's response!")
    }
    onRefresh();
  }

  if (loading) return <LoadingBar />;

  return (
    <Fragment>
      <div style={{ width: '100%', height: 20 }}></div>
      {sessions.length > 0 ? sessions.map((session, index) => (
        <SessionInfo
          key={index}
          session={session}
          displayInfos={getDisplayInfosPending(session, userType)}
          actionMenu={getActionPendingMenus(session, userType, presentersForProvider)}
          sessionDetailLink={getProviderSessionIDFromSession(session)}
          openInternalIDModal={openInternalIDModal}
          bookConfirm={onBookConfirm}
          editRequest={editRequest}
          cancelRequestedOrBookedSession={cancelRequestedOrBookedSession}
          changeStatus={onChangeSession}
          assignPresenter={assignPresenter}
          bookOnCILC={bookOnCILC}
          duplicateSession={duplicateSession}
          openAddCILCReuqestIDModal={openAddCILCReuqestIDModal}
          postSessionReject={postSessionReject}
          confirmBooking={(e, bookingTime, alternativeTime, selectedTimeStr) => sessionConfirm(e, bookingTime, alternativeTime, session.id, selectedTimeStr)}
          assignToFundingPool={assignToFundingPool}
        />
      )) : (
        <div className="nolist-placeholder" align="center">
          <span>{t(userType !== 'Teacher' ? "no-pending-sesssions-desc" : "no-pending-sesssions-desc-teacher")}</span>
        </div>
      )}

      {(showBookingModal && activeBookingSession) ? (
        <BookingModal
          showModal={showBookingModal}
          session={activeBookingSession}
          onDone={() => onBookedSession()}
          onToggle={() => setShowBookingModal(!showBookingModal)}
        />
      ) : null}

      {(activeSessionForInternalID && showInternalIDModal) ? (
        <InternalIDModal
          session={activeSessionForInternalID}
          onToggle={() => setShowInternalIDModal(!showInternalIDModal)}
          onSave={async (sessionId, internalId) => {
            setShowInternalIDModal(false)
            await airtable.sessions.update(sessionId, { "Provider Internal ID": internalId })
            const newPendings = [...sessions]
            for (const session of newPendings) {
              if (session.id === sessionId) {
                session["Provider Internal ID"] = internalId;
              }
            }
            updateFunc(newPendings)
          }}
        />
      ) : null}

      {(showAssignPresenterModal && activeSessionForAssignPresenter) ? (
        <AssignPresenter
          session={activeSessionForAssignPresenter}
          onToggle={() => setAssignPresenterModal(!showAssignPresenterModal)}
          onConfirm={async (sessionId, presenters) => {
            setAssignPresenterModal(false);
            const newSession = await airtable.sessions.update(sessionId, { 'Presenters': presenters });

            const sessionIdx = sessions.findIndex(sess => sess.id === sessionId);
            if (sessionIdx >= 0) {
              const newSessions = [...sessions];
              newSessions[sessionIdx] = newSession;
              updateFunc(newSessions)
            }
          }}
        />
      ) : null}

      {(showAddCILCReuqestIDModal && activeSessionForAddCILCReuqestIDModal) ? (
        <AddCILCRequestIDModal
          session={activeSessionForAddCILCReuqestIDModal}
          onToggle={() => setAddCILCReuqestIDModal(!showAddCILCReuqestIDModal)}
          onDone={(id) => addCILCRequestID(id)}
        />
      ) : null}

      {(showDuplicateSessionModal && !!activeDuplicateSession) && (
        <DuplicateSessionModal
          session={activeDuplicateSession}
          onToggle={() => {
            setActiveDuplicateSession(null);
            setShowDuplicateSessionModal(false)
          }}
        />
      )}

      {(showAssignFundPoolModal && !!activeAssignFundPoolSession) && (
        <AssignFundingPoolModal
          session={activeAssignFundPoolSession}
          onToggle={() => {
            setShowAssignFundPoolModal(false)
            setActiveAssignFundPoolSession(null);
          }}
        />
      )}

      {(cancelBookedOrRequestedSessionModal && !!activeCancelSession) && (
        <CancelBRSessionModal
          session={activeCancelSession}
          onToggle={() => {
            showCancelBookedOrRequestedSessionModal(false);
            setActiveCancelSession(null);
          }}
          toChangeEdit={(session) => {
            editRequest(session);
            showCancelBookedOrRequestedSessionModal(false);
            setActiveCancelSession(null);
          }}
          onDone={() => {
            showCancelBookedOrRequestedSessionModal(false);
            setActiveCancelSession(null);
            onRefresh();
          }}
        />
      )}
    </Fragment>
  )
}

export default SessionList;