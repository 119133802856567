import React, { Fragment, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { check2023BestPractices } from '../../utils/bp-utils';
import { setAppUserInfo } from '../../redux/actions';
import { toast } from 'react-toastify';
import airtable from '../../airtables';
import StepProgress from '../../components/StepProgress';
import Loading from '../../components/Loading';

const StudentInteractivity = () => {
    const { userInfo, userType, isNewProvider, appLocale } = useSelector(state => state.appInfo);
    const [reflection, setReflection] = useState("");
    const [saving, setSaving] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const isRedirectRef = useRef(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Student Interactivity - Connected North"
    }, [])

    useEffect(() => {
        const redir = check2023BestPractices(userInfo, userType, isNewProvider, "reflection", "facilitation-of-student-interactivity");
        if (isRedirectRef.current) window.location.href = `/cn${redir}`;
        else {
            setIsInProgress(redir ? false : true);
        }
    }, [userInfo, userType, isNewProvider])

    const updateBestPractices = async () => {
        if (userInfo["2023 Reflection Mandatory?"] && !reflection) return;

        setSaving(true);
        try {
            await airtable.providers.update(userInfo.id, {
                "2023 Reflection": reflection || "",
                "2023 Reflection Completed": true
            });
            const ui = await airtable.providers.select(userInfo.id);
            isRedirectRef.current = true;
            dispatch(setAppUserInfo(ui));
        } catch (error) {
            toast.error(error.toString());
        } finally {
            setSaving(false);
        }
    }

    return (
        <div className="main-container">
            {isInProgress && (
                <Fragment>
                    <StepProgress
                        steps={[
                            t("provider-step-progress.survey"),
                            t("provider-step-progress.reflection"),
                            t("provider-step-progress.code-of-conduct"),
                            ...(userInfo["Cost/Session"] ? [t("provider-step-progress.cancellation-policy")] : []),
                            t("provider-step-progress.confirm-session-offerings"),
                        ]}
                        activeStep={1}
                        completedSteps={[0]}
                    />
                    <div style={{ height: 20 }} />
                </Fragment>
            )}
            <h1>{isInProgress ? `${t('step2')}: ` : ''}{t("resources.student-interactivity.title")}</h1>
            <div className="main-content">
                <p>{t("resources.student-interactivity.p1")}</p>
                <p>{t("resources.student-interactivity.p2")}</p>
                {
                    appLocale === 'fr' ? (
                        <a href="/resources/Interactivity-fr.pdf" target='_blank'><img src="/resources/Interactivite-fr.png" className="studentImg" alt="Student Interactivity Tips - Click to download" /></a>
                    ) : (
                        <a href="/resources/Interactivity.pdf" target='_blank'><img src="/resources/Interactivite.png" className="studentImg" alt="Student Interactivity Tips - Click to download" /></a>
                    )
                }

                <p>{t("resources.student-interactivity.p3")}</p>

                {isInProgress && (
                    <Input
                        type="textarea"
                        placeholder={t("resources.student-interactivity.placeholder")}
                        rows={6}
                        disabled={saving}
                        onChange={e => setReflection(e.target.value)}
                        defaultValue={reflection}
                    />
                )}

                <b>{t("resources.student-interactivity.additional.title")}</b>
                <ul>
                    <li>
                        <a href="/cn/resources/trauma-informed-approaches" target='_blank' rel="noreferrer">{t("resources.student-interactivity.additional.trauma")}</a>
                    </li>
                    <li>
                        <a href="/cn/resources/creatively-customized-content" target='_blank' rel="noreferrer">{t("resources.student-interactivity.additional.customized")}</a>
                    </li>
                </ul>
                <p>
                    <Trans i18nKey="resources.student-interactivity.p4">
                        Still not sure what kind of engaging activities you can incorporate into your sessions? Have any questions? <a href="https://calendly.com/annemarie-tig/30min" target='_blank' rel="noreferrer">Book a meeting</a> with a team member to discuss.
                    </Trans>
                </p>

                {isInProgress && (
                    <div align="center" style={{ marginTop: 20, marginBottom: 50 }}>
                        <Button
                            color="primary"
                            size="md"
                            type="button"
                            disabled={saving || (userInfo["2023 Reflection Mandatory?"] && !reflection)}
                            onClick={() => updateBestPractices()}
                        >{saving && <Loading size={14} />}&nbsp;{t("resources.student-interactivity.button")}</Button>
                    </div>
                )}
            </div>
        </div >
    )
}

export default StudentInteractivity;
