import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const LineGraph = ({ sessions }) => {
  const { t } = useTranslation();
  const [months, setMonths] = useState(new Array(12).fill(0))
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const mns = new Array(12).fill(0);

    sessions.forEach((s) => {
      var date = s['Session Start Date/Time']
      if (typeof date === 'string') {
        date = new Date(date)
      }

      const no = (date.getMonth() + 6) % 12;
      mns[no] = mns[no] + 1;
    });

    setMonths(mns);
    setTotal(mns.reduce((res, m) => { return res + m }, 0));
  }, [sessions])

  const state = {
    labels: [
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun'
    ],
    datasets: [
      {
        fill: false,
        borderColor: '#1094CE',
        borderWidth: 2,
        data: months,
      },
    ],
  }

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t("this-school-year"),
        align: 'start',
        position: 'top',
        fullSize: 'true',
        padding: 24,
      },
    },
  }

  return (
    <>
      <h3 style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 700,
        fontSize: 25,
        marginBottom: -44,

      }}
      >
        {t("total-sessions")}
        <span
          style={{
            fontWeight: 700,
            fontSize: 70,
            lineHeight: 1,
            color: '#0E6BA8'
          }}
        >{total}</span>
      </h3>
      <Line data={state} options={options} />
    </>
  )
}
export default LineGraph
