import { Box } from "@mui/material";
import logo from "../../../assets/img/logo1.png";

export const OnboardPageOuter = ({ children }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "stretch",
				minHeight: "100vh",
				".content-wrap": {
					width: "80vw",
					maxWidth: "55rem",
					margin: "1rem 0",
					padding: "1rem 2.5rem",
					textAlign: "center",
					"h1, p, .btn": {
						margin: "1.25rem 0",
					},
				},
				h1: {
					fontSize: "2.5rem",
					fontWeight: "bold",
					color: "#0D1120",
					textAlign: "center",
					margin: "0 0 0.25em",
				},
				".btn": {
					margin: 0,
					"&:focus": {
						outline: "solid 0.2em #0e6ba8",
						outlineOffset: "0.15em",
					},
				},
			}}
		>
			<Box sx={{ padding: "1.5rem", flex: "0 0 auto" }}>
				<Box sx={{ maxWidth: "15rem" }}>
					<Box
						component="img"
						src={logo}
						alt="Connected North"
						sx={{ maxWidth: "100%", height: "auto" }}
					/>
				</Box>
			</Box>
			<Box
				component="main"
				sx={{
					padding: "1.5rem",
					flex: "1 1 auto",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{children}
			</Box>
		</Box>
	);
};
