import { useSelector } from "react-redux";
import { useFilter } from "./useFilter";
import { useEffect, useState } from "react";
import airtable from '../../../../airtables';
import { CurriculumGradeLevels } from "../../../../utils/constants";
import { useTeacherFocus } from "./useTeacherFocus";

export const useCurriculum = () => {
  const { totalSubjects } = useSelector(state => state.appInfo);
  const [fresh, setFresh] = useState(0);
  const refresh = () => setFresh(fresh + 1);
  const {
    handleSelectGrade,
    subjectFilter,
    handleSelectSubject,
    gradeFilter,
    handleClearFilter,
    handleApplyFilter,
    showAll,
    handleShowAll,
    initialLoaded
  } = useFilter(refresh);

  const {
    regions,
    grades,
    subjects
  } = useTeacherFocus();

  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [pageNo, setPageNo] = useState(0);

  useEffect(() => {
    if (fresh > 0) loadLinks();
  }, [fresh]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialLoaded) {
      refresh();
    }
  }, [initialLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadLinks = () => {
    const gradeLevel = [];
    for (let i = 0; i < gradeFilter.length; ++i) {
      if (gradeFilter[i]) {
        gradeLevel.push(CurriculumGradeLevels[i]);
      }
    }

    const subjectArea = [];
    for (let i = 0; i < subjectFilter.length; ++i) {
      if (subjectFilter[i]) {
        subjectArea.push(totalSubjects[i]);
      }
    }

    setPageNo(0);
    setLoading(true);

    airtable.curriculumLinks.getCurriculumLinks({ gradeLevel, subjectArea, showAll }, regions, (ts) => {
      setPages(ts);
    }).then(res => {
      setLoading(false)
    }).catch(error => { })
      .finally(() => setLoading(false))
  }

  return {
    handleSelectGrade,
    subjectFilter,
    handleSelectSubject,
    gradeFilter,
    handleClearFilter,
    handleApplyFilter,
    showAll,
    handleShowAll,

    loading: loading && pageNo >= pages.length,
    pageNo,
    pageCount: pages.length,
    links: pageNo >= pages.length ? [] : (pages[pageNo].items || []),
    changePage: setPageNo,

    regions,
    grades,
    subjects
  }
}