import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Airtable from '../../airtables';
import { CircularProgress } from "@mui/material";
import './styles.scss';
import CarouselProvider from "../../components/Carousel";
import { ResourceCard } from "./components/ResourceCard";
import { ResourceBrowse, getCategory } from "./components/ResourceBrowse";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 2,

  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,

  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,

  },
};

const Screen = () => {
  const { t } = useTranslation();


  const [loading, setLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [featured, setFeatured] = useState(null);
  const [latest, setLatest] = useState([]);

  useEffect(() => {
    window.document.title = "Resource Hub - Connected North";
    onRefresh();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRefresh = async () => {
    setLoading(true);
    const tResources = await Airtable.resource.listAll();
    const adjusted = groupObjectsByField(tResources, 'Category');
    const tFeatured = tResources.find(object => object.Featured);

    const tLatest = tResources.sort((a, b) => {
      const dateA = new Date(a["Date Added"]);
      const dateB = new Date(b["Date Added"]);
      return dateA - dateB;
    }).slice(0, 2);

    setLatest(tLatest)
    setResources(adjusted);
    setFeatured(tFeatured);


    setLoading(false);

  };

  return (
    <div className="main-container resource-hub">


      <section className="intro">

        <div className="intro_text">
          <h1>{t("resources-hub.title")}</h1>
          <ReadMore
            maxLength={300}
            text={t("resources-hub.description")}
          />
        </div>

      </section>
      {!loading ?
        <>
          <section>
            <div className="featured_latest">
              {featured &&
                <div>
                  <div>
                    <h2>{t("resources-hub.headers.featured")}</h2>
                  </div>
                  <ResourceCard item={featured} type={2} />

                </div>
              }
              {
                latest.length > 0 &&
                <div>
                  <div>
                    <h2>{t("resources-hub.headers.latest")}</h2>
                  </div>
                  <div className="flex-col">
                    {latest.map((item, i) => (
                      <Fragment key={i}>
                        <ResourceCard
                          key={i}
                          item={item}
                          type={1}
                        />
                      </Fragment>
                    ))}
                  </div>
                </div>
              }
            </div>
          </section>

          <ResourceBrowse resources={resources} />


          {Object.keys(resources).map((key, i) => {
            const category = getCategory(key);
            console.log(key,category);
            return (
              <section className="resource_carousel" id={category?.id} key={i}>
                <div className="resource-title">
                  {category !== null && <h2>{t(category.title)}</h2>}
                </div>
                <div style={{ overflow: 'hidden', padding: '60px 0 40px', margin: '-10px 0 0' }}>
                  <CarouselProvider responsive={responsive}>
                    {resources[key].map((item, j) => (
                      <Fragment key={j}>
                        <ResourceCard item={item} type={0} />
                      </Fragment>
                    ))}
                  </CarouselProvider>
                </div>
              </section>
            )
          })}

        </>

        :
        <div style={{ margin: "60px auto" }}>
          <CircularProgress />
        </div>
      }


    </div>
  )
};

export default Screen;


function groupObjectsByField(obj, field) {
  return obj.reduce((acc, cur) => {
    const fieldValue = cur[field];
    if (!acc[fieldValue]) {
      acc[fieldValue] = [];
    }
    acc[fieldValue].push(cur);
    return acc;
  }, {});
}

const ReadMore = ({ text, maxLength }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (text.length <= maxLength) {
    return <p>{text}</p>;
  }

  const displayedText = showMore ? text : `${text.slice(0, maxLength)}...`;

  const lastSpaceIndex = displayedText.lastIndexOf(' ');
  const visibleText = showMore ? displayedText : displayedText.slice(0, lastSpaceIndex);

  return (
    <div>
      <p>
        {visibleText}
        {!showMore ? (
          <>
            <span>...</span>
            <button onClick={toggleShowMore} style={{ marginLeft: '5px', border: 'none', background: 'none', color: 'blue', cursor: 'pointer' }}>
              Read More
            </button>
          </>
        ) :
          <>
            <span>{`  `}</span>
            <button onClick={toggleShowMore} style={{ marginLeft: '5px', border: 'none', background: 'none', color: 'blue', cursor: 'pointer' }}>
              Read Less
            </button>
          </>
        }
      </p>
    </div>
  );
};
