import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IntroductionContent from "./pages/Introduction";
import HostSessionContent from "./pages/HostSession";
import GreetContent from "./pages/Greet";
import ReviewContent from "./pages/Review";
import EngageContent from "./pages/Engage";
import ActContent from "./pages/Act";
import ThankContent from "./pages/Thank";
import airtable from "../../airtables";
import { useHistory } from 'react-router-dom';
import { setAppUserInfo } from "../../redux/actions";
import { hideLoading, showLoading } from "../../utils/loading";

const pages = [
  { slug: "introduction", label: "Introduction" },
  { slug: "how-to-host-a-great-session", label: "How to host a GREAT Session" },
  { slug: "greet", label: "Greet" },
  { slug: "review", label: "Review/Rubric" },
  { slug: "engage", label: "Engage" },
  { slug: "act", label: "Act" },
  { slug: "thank", label: "Thank" }
];

const Screen = (props) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [pageType, setPageType] = useState("");
  const containerRef = useRef();
  const { userInfo } = useSelector(state => state.appInfo);
  const page = props.match.params.page;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = "Best Practices - Connected North"
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    }
  }, [])

  useEffect(() => {
    setPageType(page)
  }, [page])

  const resizeListener = () => {
    setContainerWidth(containerRef.current.offsetWidth);
  }

  const updateCommitments = async (params) => {
    showLoading();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const uInfo = await airtable.teams.update(userInfo.id, params);
    dispatch(setAppUserInfo(uInfo));
    hideLoading();
  }

  const isCompletedPage = (pageSlug) => {
    switch (pageSlug) {
      case "introduction": return true;
      case "how-to-host-a-great-session":
        return !!userInfo["Host Training Started"];
      case "greet":
        return !!userInfo["ConfirmationGreet"];
      case "review":
        return !!userInfo["ConfirmationReview"];
      case "engage":
        return !!userInfo["ConfirmationEngage"];
      case "act":
        return !!userInfo["ConfirmationAct"];
      case "thank":
        return !!userInfo["ConfirmationThank"];
      default: return false;
    }
  }

  const confirmStarted = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    showLoading();
    const uInfo = await airtable.teams.update(userInfo.id, {
      "Host Training Started": true
    })
    dispatch(setAppUserInfo(uInfo));
    history.push("greet");
    hideLoading();
  }

  const NavItem = ({ nav, label, checked, active }) => {
    return (
      <Link to={nav}>
        <div className={active ? "bp-nav-item active" : "bp-nav-item"}>
          <div className="commitment-status">
            {checked ? <span style={{ color: 'green' }}>&#10004;</span> : <span style={{ color: 'red' }}>&#9744;</span>}
          </div>
          <span className="bp-nav-label">{label}</span>
        </div>
      </Link>
    )
  }

  const renderPageContent = () => {
    switch (pageType) {
      case "introduction":
        return <IntroductionContent
          width={containerWidth}
        />
      case "how-to-host-a-great-session":
        return <HostSessionContent
          confirmContent={confirmStarted}
          width={containerWidth}
        />
      case "greet":
        return <GreetContent
          width={containerWidth}
          defaultReflection={userInfo["ReflectionGreet"]}
          commitment={async (reflection) => {
            await updateCommitments({
              "ConfirmationGreet": true,
              "ReflectionGreet": reflection
            });
          }}
        />
      case "review":
        return <ReviewContent
          width={containerWidth}
          commitment={async (reflection) => {
            await updateCommitments({
              "ConfirmationReview": true,
              "ReflectionReview": reflection
            })
          }}
          defaultReflection={userInfo["ReflectionReview"]}
        />
      case "engage":
        return <EngageContent
          width={containerWidth}
          commitment={async (ref, ref1) => {
            await updateCommitments({
              "ConfirmationEngage": true,
              "EngageTimestamps": ref1,
              "ReflectionEngage": ref
            })
          }}
          defaultReflection={userInfo["ReflectionEngage"]}
          defaultEngageTimestamps={userInfo["EngageTimestamps"]}
        />
      case "act":
        return <ActContent
          width={containerWidth}
          commitment={async (reflection, scenarioSteps) => {
            await updateCommitments({
              "ConfirmationAct": true,
              "ActScenarioSteps": scenarioSteps,
              "ReflectionAct": reflection
            })
          }}
          defaultReflection={userInfo["ReflectionAct"]}
          defaultScenarioSteps={userInfo["ActScenarioSteps"]}
        />
      case "thank":
        return <ThankContent
          width={containerWidth}
          commitment={async (reflection) => {
            await updateCommitments({
              "ConfirmationThank": true,
              "ReflectionThank": reflection
            })
          }}
          defaultReflection={userInfo["ReflectionThank"]}
        />
      default: return null;
    }
  }

  return (
    <div className="main-container host-training">
      <h1>Host Training</h1>

      <div className="bp-container">
        <div className="bp-nav-bar">
          {pages.map((p, i) => (
            <NavItem
              key={i}
              nav={p.slug}
              label={p.label}
              checked={isCompletedPage(p.slug)}
              active={pageType === p.slug}
            />
          ))}
        </div>

        <div className="bp-content" ref={containerRef}>
          {renderPageContent()}
        </div>
      </div>
    </div>
  )
}

export default Screen;