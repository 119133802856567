import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export const ArrowLink = ({
	children,
	className = "btn btn-outline-primary",
	left = false,
	right = false,
	...props
}) => (
	<Link className={className} {...props}>
		<Box
			component="span"
			display="flex"
			alignContent="center"
			justifyContent="space-between"
		>
			{left && (
				<KeyboardArrowLeftIcon style={{ marginLeft: "-0.75rem" }} />
			)}
			{children}
			{right && (
				<KeyboardArrowRightIcon style={{ marginRight: "-0.75rem" }} />
			)}
		</Box>
	</Link>
);
