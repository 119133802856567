import Airtable from "../../airtables";
import { useState, useEffect } from "react";

const MAX_CREDIT = 30;

/**
 * @typedef {Object} FilterProps
 * @property {number} credit
 * @property {(e: number) => void} handleCreditSlider
 * @property {boolean} loading
 * @property {object[]} sessions
 * @property {object} links
 * @property {string[]} wellBeingLinks
 * @property {string[]} groups
 * @property {string} community
 * @property {boolean[]} wellBeingLinkFilter
 * @property {(i: number) => () => void} onWellBeingLinks
 * @property {boolean[]} groupFilter
 * @property {(i: number) => () => void} onGroups
 *
 * @param {string} cId
 */
export function useFilter(cId) {
  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [credit, setCredit] = useState(MAX_CREDIT);

  /** @type {(e: number) => void} */
  function handleCreditSlider(e) {
    setCredit(() => e);
  }

  // WELL BEING LINKS FILTER
  /** @type {[string[], React.Dispatch<React.SetStateAction<string[]>>]} */
  const [wellBeingLinks, setWellBeingLinks] = useState([]);
  /** @type {[boolean[], React.Dispatch<React.SetStateAction<boolean[]>>]} */
  const [wellBeingLinkFilter, setWellBeingLinkFilter] = useState([]);

  useEffect(() => {
    setWellBeingLinkFilter(() =>
      new Array(wellBeingLinks.length).fill(false),
    );
  }, [wellBeingLinks]);

  /**
   * @param {number} i
   * @returns {() => void}
   */
  function onWellBeingLinks(i) {
    return () => {
      const c = structuredClone(wellBeingLinkFilter);
      c[i] = !c[i];
      setWellBeingLinkFilter(() => c);
    };
  }

  // GROUPS
  /** @type {[string[], React.Dispatch<React.SetStateAction<string[]>>]} */
  const [groups, setGroups] = useState([]);
  /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
  const [groupFilter, setGroupFilter] = useState([]);

  useEffect(() => {
    setGroupFilter(() => new Array(groups.length).fill(false));
  }, [groups]);

  /**
   * @param {number} i
   * @returns {() => void}
   */
  function onGroups(i) {
    return () => {
      const c = structuredClone(groupFilter);
      c[i] = !c[i];
      setGroupFilter(() => c);
    };
  }

  // COMMUNITY
  /** @type {[string, React.Dispatch<React.SetStateAction<string>>]} */
  const [community, setCommunity] = useState("");
  /** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
  const [link, setLink] = useState([]);
  /** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
  const [data, setData] = useState([]);
  /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const l = await Airtable.curriculumLinks.select(cId);
        setLink(l);

        if (!l) {
          return;
        }

        const sessionIDs = l["Sessions"];
        const s = await Airtable.providerSessions.getActiveSessionsByIds(sessionIDs);

        if (!s.length) {
          return;
        }

        s.sort((a, b) =>
          a["Session Title"].localeCompare(b["Session Title"]),
        );
        setData(s);

        const providerID = s[0]["Provider"][0];
        const p = await getProviderCommunity(providerID);
        setCommunity(() => p);

        setWellBeingLinks(() => {
          return getFilterValues(s, "Well-being Link");
        });
        setGroups(() => getFilterValues(s, "Indigenous Group(s)"));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [cId]);

  // FILTERING DATA
  /** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
  const [sessions, setSessions] = useState([]);
  useEffect(() => {
    const g = collectFilterValues(groups, groupFilter);
    const w = collectFilterValues(wellBeingLinks, wellBeingLinkFilter);

    const filteredData = data.filter((s) => {
      let matchWellBeingLinks = false;
      let matchGroup = false;

      if (!w.length) {
        matchWellBeingLinks = true;
      } else {
        const wbl = s["Well-being Link"];
        if (wbl && w.length) {
          for (let i = 0; i < wbl.length; i++) {
            if (w.includes(wbl[i])) {
              matchWellBeingLinks = true;
              break;
            }
          }
        }
      }

      const indGroup = s["Indigenous Group(s)"];
      if (!indGroup || !g.length) {
        matchGroup = true;
      } else {
        for (let i = 0; i < indGroup.length; i++) {
          if (g.includes(indGroup[i])) {
            matchGroup = true;
            break;
          }
        }
      }

      const creditOK = s["Credits"] <= credit;
      return matchWellBeingLinks && matchGroup && creditOK;
    });

    setSessions(() => filteredData);
  }, [
    groupFilter,
    wellBeingLinkFilter,
    credit,
    groups,
    wellBeingLinks,
    data,
  ]);

  return {
    credit,
    handleCreditSlider,
    loading,
    link,
    sessions,
    wellBeingLinks,
    groups,
    community,
    groupFilter,
    onGroups,
    wellBeingLinkFilter,
    onWellBeingLinks,
  };
}

/**
 * @param {string[]} filterValues
 * @param {boolean[]} filter
 * @returns {string[]} values
 */
function collectFilterValues(filterValues, filter) {
  const p = [];
  for (let i = 0; i < filterValues.length; i++) {
    if (filter[i]) {
      p.push(filterValues[i]);
    }
  }
  return p;
}

/**
 * @typedef {object} ProviderProps
 * @property {string[]} communities
 * @param {string} providerID
 */
async function getProviderCommunity(providerID) {
  const filterByFormula = `RECORD_ID() = "${providerID}"`;
  const fields = ["City/Community"];
  const p = await Airtable.providers.list({ filterByFormula, fields });
  return p[0]["City/Community"];
}

/**
 * @param {object[]} data
 * @param {string} entry
 * @returns {string[]}
 */
function getFilterValues(data, entry) {
  /** @type {Set<string>} */
  const s = new Set();
  for (let i = 0; i < data.length; i++) {
    const values = data[i][entry];
    if (!values) {
      continue;
    }
    for (const v of values) {
      s.add(v);
    }
  }

  /** @type {string[]} */
  const v = [];
  for (const i of s.values()) {
    v.push(i);
  }

  return v;
}
