import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import airtable from "../../../airtables";
import { setSession } from "../../../utils/session";
import {
	CONNECTEDNORTH_SIGNINFO,
	CONNECTEDNORTH_SIGNTYPE,
} from "../../../config";
import { setAppUserInfo, setAppUserType } from "../../../redux/actions";
import { analytics } from "../../../firebase";
import { CREDIT_FIELDS, TEACHER_FIELDS } from "../constants/teacher-fields";
import { statusOptions } from "../constants/select-options";

const { NAME, EMAIL, SECONDARY_EMAIL, PHONE } = TEACHER_FIELDS;

export const useCreateTeacher = (isIncompleteTeacher) => {
	const { search: searchParamsString } = useLocation();
	const searchParams = new URLSearchParams(searchParamsString);
	const userEmail = searchParams.has("email")
		? decodeURIComponent(searchParams.get("email"))
		: "";
	const userCluster = searchParams.has("cluster")
		? searchParams.get("cluster")
		: "";

	const dispatch = useDispatch();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { userInfo } = useSelector((state) => state.appInfo);

	const [formValues, setFormValues] = useState({
		fullName: userInfo && userInfo[NAME] ? userInfo[NAME] : "",
		email: userInfo && userInfo[EMAIL] ? userInfo[EMAIL] : userEmail,
		secondaryEmail:
			userInfo && userInfo[SECONDARY_EMAIL]
				? userInfo[SECONDARY_EMAIL]
				: "",
		phoneNumber: userInfo && userInfo[PHONE] ? userInfo[PHONE] : "",
	});

	const registerTeacher = async (nextLink = "") => {
		const { email, fullName, secondaryEmail, phoneNumber } = formValues;

		if (isIncompleteTeacher) {
			history.push(nextLink);
			return;
		}

		setLoading(true);

		// Reference existing user emails to confirm new one is unique
		const existingUser = await airtable.teachers.getTeacherByEmail(email);
		if (existingUser) {
			toast.error(`A teacher with "${email}" is already registered.`, {
				position: "bottom-center",
			});
			setLoading(false);
			return;
		}

		// Reference existing school domains to confirm email matches
		const emailDomain = email.split("@")[1];
		const matchingCluster = await airtable.clusters.selectClusterByDomain(
			emailDomain,
		);
		if (!matchingCluster) {
			toast.error(
				`No schools have approved the email domain "${emailDomain}".`,
				{
					position: "bottom-center",
				},
			);
			setLoading(false);
			return;
		}

		const userInfo = await airtable.teachers.create({
			[NAME]: fullName,
			[EMAIL]: email,
			[SECONDARY_EMAIL]: secondaryEmail,
			[PHONE]: phoneNumber,
			[TEACHER_FIELDS.SELF_SIGNUP]: true,
			[TEACHER_FIELDS.STATUS]: statusOptions[0],
			[TEACHER_FIELDS.LOGINS]: 0,
		});

		await airtable.credit.create({
			[CREDIT_FIELDS.TEACHER]: [userInfo.id],
			[CREDIT_FIELDS.CREDITS]: matchingCluster["Default New Teacher Credits"] || 15,
			[CREDIT_FIELDS.ACTION]: "Credits Issued by Program",
			[CREDIT_FIELDS.NOTE]:
				"Introductory Credits - Teacher joined the Connected North Platform",
		});

		const userType = "Teacher";

		await Promise.all([
			setUserId(analytics, email),
			setUserProperties(analytics, "user_type", userType),
			logEvent(analytics, "register", { email, userType }),
			logEvent(analytics, "login", { email, userType }),
		]);

		setSession(CONNECTEDNORTH_SIGNINFO, userInfo);
		setSession(CONNECTEDNORTH_SIGNTYPE, userType);

		delete userInfo[TEACHER_FIELDS.REPORT_MSG];

		dispatch(setAppUserInfo(userInfo));
		dispatch(setAppUserType(userType));

		setLoading(false);

		history.push(`${nextLink}?cluster=${userCluster}`);
	};

	return {
		loading,
		registerTeacher,
		formValues,
		setFormValues,
	};
};
