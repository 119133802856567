import React, { useState } from 'react';
import {
  Modal,
  Button,
  FormGroup,
  Input
} from 'reactstrap';
import { cancellationReasons } from '../../utils/constants';
import Select from 'react-select';
import Loading from '../../components/Loading';

const CancelSessionModal = ({ onToggle, onDone }) => {
  const [updating, setUpdating] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  const confirm = async () => {
    if (!reason) return;

    try {
      setError("");
      setUpdating(true);
      await onDone(reason.value, feedback);
      onToggle();
    } catch (error) {
      setError("Cancellation was failed.");
    }

    setUpdating(false);
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Session Cancellation</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <FormGroup>
          <label>Cause of Cancellation/Reschedule</label>
          <Select
            className="form-style"
            placeholder=""
            onChange={(option) => setReason(option)}
            options={cancellationReasons.map((reason) => ({ value: reason, label: reason }))}
          />
        </FormGroup>

        <FormGroup>
          <label>Additional Notes (included in e-mail to Teacher and Provider)</label>
          <Input
            rows="3"
            type="textarea"
            className="form-style"
            defaultValue={feedback}
            onChange={e => setFeedback(e.target.value)}
          />
        </FormGroup>

        <div style={{ width: '100%', height: 20, color: 'red' }}>
          <span>{error}</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >Abort Cancellation</Button>
        <Button
          color="success"
          type="button"
          onClick={confirm}
          disabled={!reason}
        >{updating && (<Loading size={20} />)}Confirm Cancellation</Button>
      </div>
    </Modal>
  )
}

export default CancelSessionModal;
