import React from "react";
import { PointBreakdown } from "./PointBreakdown";
import { Stepper } from "../../../../components/Stepper";

/**
 * @typedef {import("../useCredits").Point} Point
 * @param {Point} props
 */
export function SessionCard({
	title,
	subtitle,
	link,
	progress,
	progressTitle,
	buttonTitle,
	pointBreakdown,
}) {
	return (
		<section className="bg-white">
			<div>
				<h2>{title}</h2>
				<p className="font-weight-normal">{subtitle}</p>
			</div>

			<PointBreakdown pointBreakdown={pointBreakdown} />

			<div className="mt-1">
				<h3>{progressTitle}</h3>
				<Steps
					progress={progress}
					style={{
						width: "108%",
						transform: "translateX(-4%)",
						margin: "25px 0",
					}}
				/>
				</div>
				<a
					href={link}
					className="btn btn-primary"
					style={{ width: "200px" }}
				>
					{buttonTitle}
				</a>
		</section>
	);
}

/**
 * @param {object} props
 * @param {number} props.progress
 * @param {object} props.style
 * @returns {React.JSX}
 */
function Steps({ progress, style }) {
	const scale = 5;
	/** @type {Array<number>} */
	const labels = [];
	for (let i = 0; i <= 10; ++i) {
		labels.push(i * scale);
	}

	const i = (progress - (progress % scale)) / scale;
	const step = i > labels.length ? labels.length - 1 : i;

	return <Stepper labels={labels} step={step} style={style} />;
}
