import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import './i18n';
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import fr from 'javascript-time-ago/locale/fr.json'
import { IntercomProvider } from 'react-use-intercom';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(fr);

const store = configureStore();
const ConnectedNorthRedux = () => (
  <Provider store={store}>
    <IntercomProvider
      appId='an4qqxki'
      apiBase='https://api-iam.intercom.io'
    >
      <App />
    </IntercomProvider>
  </Provider>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConnectedNorthRedux />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
