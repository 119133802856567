import React, { useEffect, useState } from 'react';
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux';
import airtable from '../../../airtables';
import { setGroupTeachers } from '../../../redux/actions';
import Select from 'react-select';
import Loading from '../../../components/Loading';
import { Button, FormGroup } from 'reactstrap';
import classNames from 'classnames';

const StepSelectTeachers = ({
  next,
  hidden,
  prefill = null
}) => {
  const {
    userInfo,
    groupTeachers: {
      loaded: teachersLoaded,
      teachers: teamTeachers
    }
  } = useSelector(state => state.appInfo)
  const [teachers, setTeachers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prefill && teamTeachers && teamTeachers.length) {
      const ts = teamTeachers.filter(t => prefill["Teacher"].indexOf(t.id) >= 0);
      setTeachers(ts.map((t => ({ value: t.id, label: `${t["Teacher Name"]} (${t["School Name Text"][0]})` }))))
    }
  }, [teamTeachers]) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    if (!teachersLoaded) {
      airtable.teachers.getTeachersForTeam(userInfo["Email"]).then(ts => {
        dispatch(setGroupTeachers({
          loaded: true,
          teachers: ts
        }));
      })
    }
  }

  const goNext = () => {
    const tIds = teachers.map(t => t.value);
    const ts = teamTeachers.filter(t => tIds.indexOf(t.id) >= 0);
    next(ts);
  }

  return (
    <div className={classNames({ 'step-hidden': hidden })}>
      <div className="step-content">
        <FormGroup>
          <label>Which teacher(s) do you want to book this session for?</label>
          {
            teachersLoaded ? (
              <Select
                isMulti
                className="form-style"
                value={teachers}
                onChange={(selectedOptions) => setTeachers(selectedOptions)}
                options={teamTeachers.map(t => ({
                  value: t.id,
                  label: `${t["Teacher Name"]} (${t["School Name Text"][0]})`
                }))}
              />
            ) : (
              <div align="center"><Loading size={20} /></div>
            )
          }
        </FormGroup>
      </div>
      <div className="step-actions">
        <div />
        <Button
          color="primary"
          onClick={() => goNext()}
          disabled={teachers.length === 0}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default StepSelectTeachers;