import React, { useEffect, useState } from 'react';
import './styles.scss'
import { Button, Modal } from 'reactstrap';
import Loading from '../../../components/Loading';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Gap from '../../../components/Gap';
import airtable from '../../../airtables';
import { getCoreCompetenciesString, getStrandTopicLabel } from '../../../utils/string';
import { compareGrades, sortGrades } from '../../../utils/session';
import axios from 'axios';

const CurriculumSuggestion = ({ onToggle, onSuggest, session }) => {
  const [region, setRegion] = useState();
  const [subjects, setSubjects] = useState([]);
  const [links, setLinks] = useState([])
  const [checked, setChecked] = useState([]);
  const [suggesting, setSuggesting] = useState(false);

  const { t } = useTranslation();
  const { totalSubjects, totalRegions, regionsMap, userInfo } = useSelector(state => state.appInfo);
  const [regions] = useState(totalRegions.filter(r => r["# Curriculum Links"] > 0));
  const [tSubjects] = useState(totalSubjects.filter(s => s["# Curriculum Links"] > 0));

  useEffect(() => {
    if (!region) return;

    refreshLinks();
  }, [region, subjects]) // eslint-disable-line react-hooks/exhaustive-deps

  const refreshLinks = () => {
    airtable.curriculumLinks.filter(region.name, subjects.map(s => s.label))
      .then(res => {
        if (!res) setLinks([]);
        else {
          const sLinks = res.filter(link => !session["Curriculum Links"].includes(link))
          sLinks.sort((a, b) => compareGrades(a["Grade(s)"], b["Grade(s)"]))
          setLinks(sLinks);
        }
      })
      .catch(error => {
        console.log("Error:", error)
      });
  }

  const submitSuggestion = async () => {
    setSuggesting(true);
    await airtable.providerSessions.update(session.id, {
      "Curriculum Links": [
        ...session["Curriculum Links"],
        ...checked
      ]
    });
    const checkedLinks = links.filter(link => checked.includes(link.id));
    await airtable.curriculumLinks.multipleUpdate(checkedLinks.map(link => ({
      id: link.id,
      param: {
        "Suggested By": [...(link["Suggested By"] || []), userInfo.id]
      }
    })));
    await axios.get(`https://hooks.zapier.com/hooks/catch/89715/34xyi4f?user=${userInfo.id}&session=${session.id}&links=${checked.join(",")}`)
    onSuggest();
  }

  const toggleCheck = (item) => {
    var updatedList = [...checked];
    if (updatedList.indexOf(item) < 0) {
      updatedList = [...checked, item];
    } else {
      updatedList = updatedList.filter(v => v !== item);
    }
    setChecked(updatedList);
  };

  const renderStrandTopicInfo = (link) => {
    var stlbl = getStrandTopicLabel(regionsMap, link["Region"] ? link["Region"][0] : "")
    return (
      <div>
        <span className="link-title"><b>{stlbl.strand}</b>: {link["Strand"]}</span><br />
        {
          link["Topic"] ? (
            <span className="link-title rich-text"><b>{stlbl.topic}</b>: {link["Topic"]}</span>
          ) : null
        }
        {
          (link["Core Competencies"] && link["Core Competencies"].length) && (
            <>
              <br />
              <span className="link-title"><b>Core Competency</b>: {getCoreCompetenciesString(link["Core Competencies"])}</span>
            </>
          )
        }
      </div>
    )
  }

  return (
    <Modal
      className="modal-dialog-centered curriculum-suggest"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-body">
        <div sx={{ width: '100%', maxWidth: 640 }}>
          <div className="curriculum-filter">
            <Select
              isDisabled={suggesting}
              placeholder="Select a Province or Territory:"
              onChange={(option) => setRegion(option)}
              options={regions.map(r => ({ value: r.id, label: r["Long Name"], name: r["Name"] }))}
            />
            <Gap height={10} />
            <Select
              isMulti
              isDisabled={suggesting}
              placeholder="Select one or more Subject(s):"
              onChange={(option) => setSubjects(option)}
              options={tSubjects.map(subject => ({ value: subject.id, label: subject["Subject"] }))}
            />
          </div>
          <div className="links">
            {links.map((link) => (
              <div className="suggest-link" key={link.id} onClick={() => toggleCheck(link.id)}>
                <div className="row">
                  <div className="selector">
                    <input
                      type="checkbox"
                      value={link.id}
                      checked={checked.includes(link.id)}
                      disabled={suggesting}
                      onChange={(event) => toggleCheck(event.target.value)}
                    />
                  </div>
                  <div className="full">
                    {renderStrandTopicInfo(link)}
                    <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                      <Gap width={10} />
                      <span>Grade(s): {sortGrades(link["Grade(s)"]).join(',')}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          type="button"
          disabled={suggesting || checked.length === 0}
          onClick={submitSuggestion}
        >
          {suggesting ? (<Loading size={14} />) : (null)}Suggest
        </Button>
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >{t("close")}</Button>
      </div>
    </Modal >
  )
}

export default CurriculumSuggestion;
