import { Stepper as FormStepper, Step } from "react-form-stepper";
import "./override.css";
import { nanoid } from "nanoid";

/**
 * @typedef {object} Props
 * @property {object?} style
 * @property {number} step
 * @property {Array<string | number>} labels
 *
 * @param {Props}
 */
export function Stepper({ style, step, labels }) {
	return (
		<FormStepper
			style={{
				padding: 0,
				...style,
			}}
			activeStep={step}
			styleConfig={{
				activeBgColor: "#0E6BA8",
				completedBgColor: "#0E6BA8",
				inactiveBgColor: "#E4EAEF",
				size: "14px",
				fontWeight: 700,
				labelFontSize: "16px",
			}}
			connectorStateColors={true}
			connectorStyleConfig={{
				activeColor: "#0e6ba8",
				completedColor: "#0e6ba8",
				size: "4px",
				disabledColor: "#e4eaef",
			}}
		>
			{labels.map((l) => (
				<Step
					key={nanoid(10)}
					label={String(l)}
					children={<></>}
					style={{ cursor: "auto" }}
				/>
			))}
		</FormStepper>
	);
}
