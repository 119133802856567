import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import Loading from '../../../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import airtable from '../../../airtables';
import { setAppUserInfo } from '../../../redux/actions';
import { isCompletedBestPractices } from '../../../utils/bp-utils';

const NavContent = () => {
  const [reflectionRecording, setReflectionRecording] = useState("");
  const [allowed, setAllowed] = useState("");
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();

  const saveRecording = async () => {
    setSaving(true);
    try {
      const newUserInfo = await airtable.providers.update(userInfo.id, {
        "Recording Allowed": allowed,
        "ReflectionRecording": reflectionRecording
      });

      if (isCompletedBestPractices(newUserInfo, userType)) {
        window.location.href = "/cn/mysessions";
      }

      dispatch(setAppUserInfo(newUserInfo));
    } catch (error) {
      toast.error("Error! " + error.toString());
    } finally {
      setSaving(false)
    }
  }

  return (
    <div style={{ width: '100%', marginTop: 10 }}>
      <div className="main-content">
        <p><strong>{t("best-practices-content.rp.title")}</strong></p>
      </div>

      <div className="main-content">
        <p>{t("best-practices-content.rp.para1")}</p>
        <p>{t("best-practices-content.rp.para2")}</p>
        <p>{t("best-practices-content.rp.para3")}</p>
        <p>{t("best-practices-content.rp.para4")}</p>
        <p>{t("best-practices-content.rp.para5")}</p>
      </div>

      <div className="confirm-reflection">
        <span><strong>{t("confirmation")}</strong></span><br />
        <FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="allowed"
                defaultChecked={allowed === "Yes"}
                onChange={(e) => setAllowed("Yes")}
              />{' '} {t("best-practices-content.rp.para6")}
            </Label>
          </FormGroup><br />
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="allowed"
                defaultChecked={allowed === "No"}
                onChange={(e) => setAllowed("No")}
              />{' '} {t("best-practices-content.rp.para7")}
            </Label>
          </FormGroup>
        </FormGroup>
        <p>{t("best-practices-content.rp.para8")}</p>
        <Input
          type="textarea"
          rows={6}
          onChange={e => setReflectionRecording(e.target.value)}
          defaultValue={reflectionRecording}
        />

        <div style={{ height: 20 }} />

        <Button
          className="ml-auto"
          color="primary"
          type="button"
          disabled={!allowed || saving}
          onClick={() => saveRecording()}
        >{saving ? (<Loading size={14} />) : (null)}{t("submit")}</Button>
      </div>

      <p style={{ marginTop: 10, fontSize: 14 }}><em>{t("bp-helprequired")}</em></p>
    </div>
  )
}

export default NavContent
