import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: "AKIAZFLASWORIZMGPC2O",
    secretAccessKey: "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",
    region: 'ca-central-1',
    signatureVersion: 'v4'
});
const s3 = new AWS.S3();

const uploadFile = async (file) => {
    try {
        const response = await s3.upload({
            ACL: 'public-read',
            Bucket: 'risingyouth',
            Key: file.name,
            Body: file
        }).promise();
        return response.Location;
    } catch (error) {
        throw new Error("Error uploading file:")
    }
}

export default uploadFile;