import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

const StepProgress = ({ steps, completedSteps, activeStep }) => {
    return (
        <Stepper activeStep={activeStep}>
            {
                steps.map((step, i) => (
                    <Step
                        completed={completedSteps.indexOf(i) >= 0}
                        key={i}
                    >
                        <StepLabel>{step}</StepLabel>
                    </Step>
                ))
            }
        </Stepper>
    )
}

export default StepProgress;