import { useEffect, useState } from "react";

/**
 * mapping number of credit to the url purchased link `S`
 * where `S` is the path param of `https://pay.connectednorth.org/b/${S}`
 *
 * @type {Record<number, string>}
 * */
export const CREDIT_OPTS = {
  1: "bIY3gd6nF5uH9yMeUU",
  2: "dR67wtdQ70anaCQcMN",
  3: "7sIeYVfYf6yLaCQ3ce",
  5: "8wMbMJcM38GTbGUbIL",
  10: "14keYVfYfcX9bGU004",
  25: "3cseYVfYf0anaCQcMR",
  50: "aEU2c9cM35uH12g4gm",
  // etc
};

export const COST_PER_CREDIT = 20;

/**
 * @typedef {object} Point
 * @property {string} title
 * @property {string} subtitle
 * @property {string} buttonTitle
 * @property {string} link
 * @property {number} progress
 * @property {string} progressTitle
 * @property {Array<PointInfo>} pointBreakdown
 *
 * @typedef {object} PointInfo
 * @property {number} points
 * @property {string} description
 *
 * @param {number} review
 * @param {number} rated
 * @param {number} unique
 * @param {string} teacherID
 *
 * @typedef {object} usePointConstantProps
 * @property {Array<Point>} points
 * @property {number} credits
 * @property {React.ChangeEventHandler<HTMLSelectElement>} handleCreditSelect
 * @property {string} purchaseLink
 * @returns {usePointConstantProps}
 */
export function useCredits(review, rated, unique, teacherID) {
  const points = pointContent(review, rated, unique);

  const [credits, setCredit] = useState(1);
  /** @type {React.ChangeEventHandler<HTMLSelectElement>} */
  function handleCreditSelect(e) {
    e.preventDefault();
    setCredit(() => e.target.value);
  }

  const [purchaseLink, setPurchaseLink] = useState("");
  useEffect(() => {
    setPurchaseLink(() => makeUrl(credits, teacherID));
  }, [credits, setPurchaseLink, teacherID]);

  return { points, credits, handleCreditSelect, purchaseLink };
}

/**
 * @param {number} reviewed
 * @param {number} rated
 * @param {number} unique
 * @returns {Point}
 */
function pointContent(reviewed, rated, unique) {
  return [
    {
      title: "Review your Sessions",
      subtitle:
        "Help us understand how Sessions have met classroom needs, and share feedback on ways providers could adjust delivery to support more student engagement. Selected reviews may be anonymously featured on Session profile pages!",
      buttonTitle: "Review Sessions Now",
      link: "/cn/upcoming",
      progress: reviewed,
      progressTitle: "My Reviewed Sessions",
      pointBreakdown: [
        { points: 1, description: "5 Reviewed Sessions" },
        { points: 3, description: "10 Reviewed Sessions" },
        { points: 5, description: "25 Reviewed Sessions" },
        { points: 10, description: "50 Reviewed Sessions" },
      ],
    },
    {
      title: "Rate your Sessions",
      subtitle:
        "After each completed Session, you'll receive an e-mail to provide a quick 1-5 star rating providing insight on how the Session delivery supported classroom learning.",
      buttonTitle: "Rate Sessions Now",
      link: "/cn/upcoming",
      progress: rated,
      progressTitle: "My Rated Sessions",
      pointBreakdown: [
        { points: 1, description: "10 Rated Sessions" },
        { points: 3, description: "25 Rated Sessions" },
        { points: 5, description: "50 Rated Sessions" },
      ],
    },
    {
      title: "Book Different Content Providers",
      subtitle:
        "Explore the diversity of amazing Content Providers in the Connected North catalogue! We want to encourage and reward you to book a variety of presenters to connect with your students.",
      buttonTitle: "Explore Sessions",
      link: "/cn/search",
      progress: unique,
      progressTitle: "Unique Content Providers Booked",
      pointBreakdown: [
        {
          points: 2,
          description: "10 Unique Content Providers Booked",
        },
        {
          points: 5,
          description: "25 Unique Content Providers Booked",
        },
        {
          points: 10,
          description: "50 Unique Content Providers Booked",
        },
        {
          points: 15,
          description: "75 Unique Content Providers Booked",
        },
      ],
    },
  ];
}

/**
 * @param {number} credit
 * @param {string} teacherID
 * @returns {string}
 */
function makeUrl(credit, teacherID) {
  const q = new URLSearchParams({
    client_reference_id: teacherID,
  });

  let url = "https://pay.connectednorth.org/b/";
  url += CREDIT_OPTS[credit];
  url += "?";
  url += q.toString();
  return url;
}
