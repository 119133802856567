import { Card } from "../../../components/Card";
import { useTranslation } from 'react-i18next'

/**
 * @param {Object} props
 * @param {import("../useProviderDetails").CreateToLearn[]} props.courses
 */
export function CreateToLearn({ courses }) {
	const { t } = useTranslation()

	return (
		<div className="create-courses">
			{courses.map(
				({
					length,
					difficulty,
					id,
					url,
					title,
					videoCount,
					thumbnail,
					description,
					creator,
				}) => (
					<Card className='bg-white col-md-6' key={id}>
						<a href={url} target="_blank" rel="noreferrer">
							<img
							alt={title}
							src={thumbnail}
							className="full-image-wrap mb-2"
							/>
						</a>
						<h3 style={{fontWeight: '700', color: '#2b4F70'}}>{title}</h3>
						<p>
							{videoCount} Videos | {length} Minute(s)
						</p>
						{difficulty && ( <p className="large" style={{fontWeight: '700'}}>{creator}</p> )}
						<p>{description ?? "No description provided"}</p>
						<a className="btn btn-primary" href={url} target="_blank" rel="noreferrer">
							{t('view-course')}
						</a>
					</Card>
				),
			)}
		</div>
	);
}
