import Axios from "axios";

export const sendVerifySms = (phone) => {
    return new Promise((resolve, reject) => {
        const url = "https://onsendverifysms-raj5gg67oa-uc.a.run.app";
        Axios.post(url, {
            phonenumber: '+1 ' + phone
        }).then(res => {
            if (res.data.suceess) {
                resolve(res.data.data)
            } else {
                reject(res.data.error)
            }
        }).catch(error => {
            reject(error)
        })
    })
}

export const verifySmsCode = (phone, code) => {
    return new Promise((resolve, reject) => {
        const url = "https://onverifysmscode-raj5gg67oa-uc.a.run.app";
        Axios.post(url, {
            phonenumber: '+1 ' + phone,
            code
        }).then(res => {
            if (res.data.suceess) {
                resolve(res.data.data)
            } else {
                reject(res.data.error)
            }
        }).catch(error => {
            reject(error)
        })
    })
}

export const checkFreeBusy = (email, start, end) => {
    return new Promise((resolve, reject) => {
        const url = `https://research.tigweb.org/connectednorth/calendar-invite/service-account.html?email=${email}&start=${start}&end=${end}`;
        Axios.get(url).then(res => {
            resolve(res.data.status)
        }).catch(error => {
            reject(error)
        })
    })
}

export const sendToTeacher = (
    emails,
    type, // Collection/Session/Event
    teamName,
    teamEmail,
    teacherNames,
    targetTitle,
    targetDescription,
    targetLink,
    customMessage,
    ccEmail,
    attachments,
    niceDate,
    userType
) => {
    return new Promise((resolve, reject) => {
        const url = "https://sendtoteacher-raj5gg67oa-uc.a.run.app";

        Axios.post(url, {
            emails,
            type, // Collection/Session/Event
            teamName,
            teamEmail,
            teacherNames,
            targetTitle,
            targetDescription,
            targetLink,
            customMessage,
            ccEmail: ccEmail ? teamEmail : '',
            attachments: attachments || "",
            niceDate: niceDate || "",
            userType
        }).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject(error)
        })
    })
}