import React, { useEffect, useState } from 'react';
import Loading from '../../../../components/Loading';
import { toast } from 'react-toastify';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import airtable from '../../../../airtables';
import { setAppUserInfo } from '../../../../redux/actions';
import './styles.scss';
import { checkBestPractices } from '../utils';
import BP2024ProgressBar from '../components/BP2024ProgressBar';
import { Trans, useTranslation } from 'react-i18next';

const BPLanguageRevitalization = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [saving, setSaving] = useState(false);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = "Introduction - Best Practices - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "language-revitalization");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const updateBestPractices = async () => {
    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "24-25 Language Reflection Completed": true,
        "LanguageReflection": content
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="main-container best-practices">
      <span className="page-topic"></span>
      <div style={{ height: 20 }} />
      <BP2024ProgressBar current="lang-rev" />
      <div style={{ height: 20 }} />
      <h1>{t("bestPractices.lang-revitalization.title")}</h1>
      <div className="main-content">
        <h3>{t("bestPractices.lang-revitalization.sub-title")}</h3>
        <p>{t("bestPractices.lang-revitalization.p1")}</p>
        <p>{t("bestPractices.lang-revitalization.p2")}</p>
        <p>{t("bestPractices.lang-revitalization.p3")}</p>
        <p>{t("bestPractices.lang-revitalization.p4")}</p>
        <p>
          <Trans i18nKey="bestPractices.lang-revitalization.p5" components={{ b: <b /> }} />
        </p>
        <p>
          <Trans i18nKey="bestPractices.lang-revitalization.p6" components={{ b: <b /> }} />
        </p>
        <p>{t("bestPractices.lang-revitalization.p7")}</p>

        <span style={{ textDecoration: "underline" }}>{t("bestPractices.lang-revitalization.ul-title")}</span>
        <ul>
          <li>{t("bestPractices.lang-revitalization.li1")}</li>
          <li>{t("bestPractices.lang-revitalization.li2")}</li>
          <li>{t("bestPractices.lang-revitalization.li3")}</li>
          <li>{t("bestPractices.lang-revitalization.li4")}</li>
          <li>{t("bestPractices.lang-revitalization.li5")}</li>
        </ul>

        <div style={{ marginTop: 30 }}>
          <p><b>{t("bestPractices.lang-revitalization.input-desc")}</b></p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setContent(e.target.value)}
            value={content}
          />
        </div>

        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={!content}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;{t("bestPractices.lang-revitalization.btn-next")}</Button>
        </div>
      </div>
    </div>
  )
}

export default BPLanguageRevitalization;
