import { useState } from "react";
import { useHistory } from "react-router-dom";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import { useUpdateUserInfo } from "./use-update-user-info";

export const useOptionalTerms = () => {
	const history = useHistory();
	const { loading, updateUserInfo, confirmUserInfoUpdated } =
		useUpdateUserInfo();
	const [formValues, setFormValues] = useState({
		optionalTerms: false,
	});

	const updateOptionalTerms = async (
		nextLink = "",
		isNewOrIncompleteTeacher,
	) => {
		const { optionalTerms } = formValues;

		// Temporary bypass until we have the field name
		if (!TEACHER_FIELDS.OPTIONAL_TERMS) {
			if (isNewOrIncompleteTeacher) {
				history.push(nextLink);
			} else {
				confirmUserInfoUpdated();
			}
			return;
		}

		const updateBody = {
			[TEACHER_FIELDS.OPTIONAL_TERMS]: optionalTerms,
		};

		updateUserInfo(updateBody, nextLink, () => {
			if (!isNewOrIncompleteTeacher) {
				confirmUserInfoUpdated();
			}
		});
	};

	return { loading, formValues, setFormValues, updateOptionalTerms };
};
