import React, { useEffect } from 'react';
import TeamPendingScreen from './team';
import PendingScreen from './main';
import { useSelector } from 'react-redux';

const Screen = () => {
    const { userType } = useSelector(state => state.appInfo);

    useEffect(() => {
        window.document.title = "My Requests - Connected North"
    }, [])

    if (userType === 'Team') return <TeamPendingScreen />
    return <PendingScreen />
}

export default Screen;
