import React, { Fragment, useState } from 'react';
import useHook from './useHook';
import Select from 'react-select';
import { Button, FormGroup, Input, Label, Modal } from 'reactstrap';
import Loading from '../../../components/Loading';
import styles from "./styles.module.scss";
import { SELECTBOX_STYLE } from '../../../utils/constants';
import { toast } from 'react-toastify';

const TransferCreditModal = ({
  teacher,
  onToggle,
  onPost
}) => {
  const {
    loading,
    completing,
    teachers,
    complete,
    from, setFrom,
    target, setTarget,
    credits, setCredits,
    notes, setNotes,
    getErrors,
    canComplete
  } = useHook(teacher);
  const [errors, setErrors] = useState("")

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ padding: '20px 0' }} align="center">
          <Loading size={20} />
        </div>
      )
    }

    return (
      <Fragment>
        <FormGroup>
          <Label for="transfer-from">Transfer From</Label>
          <Select
            id="transfer-from"
            className="form-style"
            placeholder=""
            value={from ? { value: from.id, label: from["Teacher Name"] } : null}
            onChange={selectedOption => {
              const t = teachers.find(item => item.id === selectedOption.value)
              setFrom(t);
            }}
            styles={SELECTBOX_STYLE}
            options={teachers.map((teacher) => ({ value: teacher.id, label: teacher["Teacher Name"] }))}
          />
        </FormGroup>

        <FormGroup>
          <Label for="transfer-to">Transfer To</Label>
          <Select
            id="transfer-to"
            className="form-style"
            placeholder=""
            styles={SELECTBOX_STYLE}
            value={target ? { value: target.id, label: target["Teacher Name"] } : null}
            onChange={selectedOption => {
              const t = teachers.find(item => item.id === selectedOption.value)
              setTarget(t);
            }}
            options={teachers.map((teacher) => ({ value: teacher.id, label: teacher["Teacher Name"] }))}
          />
        </FormGroup>

        <div className={styles.transferRow}>
          <Label for="transfer-credits">Credits</Label>
          <div className="full">
            <Input
              value={credits}
              id="transfer-credits"
              type="number"
              onChange={e => setCredits(parseInt(e.target.value))}
            />
          </div>
        </div>

        <FormGroup>
          <Label for="transfer-notes">Display Note (visible to teachers)</Label>
          <Input
            value={notes}
            id="transfer-notes"
            type="textarea"
            onChange={e => setNotes(e.target.value)}
          />
        </FormGroup>
      </Fragment>
    )
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Transfer Credits</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {renderContent()}
        <div className={styles.errors}>
          <span>{errors}</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >Cancel</Button>
        <Button
          color="success"
          type="button"
          onClick={async () => {
            const ers = getErrors();
            if (ers) {
              setErrors(ers);
            } else {
              await complete();
              toast.success("The requested transfer has been completed.");
              onPost && onPost();
              onToggle();
            }
          }}
          disabled={!canComplete()}
        >{completing && (<Loading size={20} />)}Complete</Button>
      </div>
    </Modal>
  )
}

export default TransferCreditModal;
