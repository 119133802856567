import AirtableTable from "../AirtableTable";

export class EventTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Events")
  }

  selectEvent = (email, sessionName) => {

    return this.selectOneByCondition({
      filterByFormula: `AND({E-mail Address} = "${email}", FIND('${sessionName.replaceAll('"', '""')}', {Session}))`
    })
  }

  deleteEvent = (email, sessionName) => {
    return new Promise(async (resolve, reject) => {
      this.selectEvent(email, sessionName).then(event => {
        if (!event) {
          resolve({ deleted: true, id: "" });
        } else {
          this.delete(event.id).then(res => resolve(res))
            .catch(error => reject(error))
        }
      }).catch(error => reject(error))
    })
  }
}