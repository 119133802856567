import React, { useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';

const VideoPlayer = ({ videoData, width, onPlay, onEnd }) => {
  // const [loading, setLoading] = useState(true);
  const [videoType, setVideoType] = useState();
  const [videoUrl, setVideoUrl] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (videoData && videoData.search("vimeo") >= 0) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(videoData, "text/html");
      const iframes = xmlDoc.documentElement.getElementsByTagName('iframe');
      if (iframes && iframes.length) {
        setVideoUrl(iframes[0].src);
      } else {
        setError("Parsing failed.")
      }

      setVideoType("vimeo");
    }

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!videoData) return null;

  if (error) {
    return (
      <div>{error}</div>
    )
  }

  if (videoType === 'vimeo') {
    return (
      <Vimeo
        video={videoUrl}
        width={width}
        // onProgress={() => setLoading(false)}
        onPlay={onPlay}
        onEnd={onEnd}
      />
    )
  }

  return null
}

export default VideoPlayer;