import React, { useEffect, useState } from 'react';
import airtable from '../../airtables';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import "./styles.scss";
import SessionList from './SessionList';
import { getNiceFormatDateTimeString } from '../../utils/time';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify'
import ProviderSessionReject from '../modals/RejectSession/Provider';
import TeacherSessionReject from '../modals/RejectSession/Teacher';

const Screen = () => {
  const { t, i18n } = useTranslation();
  const { userInfo, userType, appLocale } = useSelector(state => state.appInfo);

  const [pendingSessions, setPendingSessions] = useState([]);
  const [pLoading, setPLoading] = useState(false);

  const [rejectModal, setRejectModal] = useState(false);
  const [rejectSessionId, setRejectSessionId] = useState(null);

  useEffect(() => {
    if (userType === 'Provider' || userType === 'Teacher') manageSession();

    window.document.title = "My Requests - Connected North"
    refreshPage();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const manageSession = async () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const sessionId = params.get('sessionId');
    const manageType = params.get('type');

    if (!manageType || !sessionId) return;

    const session = await airtable.sessions.select(sessionId);
    window.history.replaceState(null, "", `/cn/pending`);

    switch (manageType) {
      case "accpet":
      case "accept1":
        sessionConfirm(
          null,
          session["Session Start Date/Time"],
          session["Alternative Date/Time"],
          sessionId,
          `Option 1: ${getNiceFormatDateTimeString(
            new Date(session["Session Start Date/Time"]),
            appLocale,
          )}`
        )
        return;
      case "accept2":
        sessionConfirm(
          null,
          session["Alternative Date/Time"],
          session["Session Start Date/Time"],
          sessionId,
          `Option 2: ${getNiceFormatDateTimeString(
            new Date(session["Alternative Date/Time"]),
            appLocale,
          )}`
        )
        return;
      case "reject":
        setRejectSessionId(sessionId);
        setRejectModal(true);
        return;
      default:
        break;
    }
  }

  const sessionConfirm = (e, date1, date2, sessionId, selectedTimeStr) => {
    confirmAlert({
      title: t("accept-request"),
      message: i18n.t("accept-request-desc", { selectedTimeStr }),
      buttons: [
        {
          label: t("accept"),
          onClick: () => {
            e && e.preventDefault();
            airtable.sessions.update(sessionId, {
              'Session Start Date/Time': new Date(date1),
              'Alternative Date/Time': new Date(date2),
              'Status': 'Confirmed for Booking'
            }).then(() => {
              refreshPage();
            }).catch(error => {
              toast.error(error.toString());
            })
          }
        },
        {
          label: t("cancel"),
          onClick: () => { }
        }
      ]
    });
  }

  const refreshPage = async () => {
    setPLoading(true);
    try {
      if (userType === 'Teacher') {
        const sessions = await airtable.sessions.getPendingSessionsForTeacher(userInfo.id, userInfo['Teacher Name'])
        setPendingSessions(sessions);
      } else if (userType === 'Provider') {
        const sessions = await airtable.sessions.getPendingSessionsForProvider(userInfo.id, userInfo['Name']);
        setPendingSessions(sessions);
      }
    } catch (error) { }
    setPLoading(false);
  }

  return (
    <div className="main-container">
      <h1>{t("pending_session_requests")}</h1>
      <div style={{ marginTop: 10 }}>
        <SessionList
          loading={pLoading}
          sessionInfo={{ sessions: pendingSessions, updateFunc: setPendingSessions }}
          onRefresh={refreshPage}
          sessionConfirm={sessionConfirm}
        />
      </div>

      {(userType === "Provider") && rejectModal && (
        <ProviderSessionReject
          sessionId={rejectSessionId}
          onToggle={() => setRejectModal(false)}
          onPost={() => {
            toast.success("Your revised dates/times have been submitted for the teacher's response!")
            refreshPage();
          }}
        />
      )}

      {userType === 'Teacher' && rejectModal && (
        <TeacherSessionReject
          sessionId={rejectSessionId}
          onToggle={() => setRejectModal(false)}
          onPost={() => {
            refreshPage();
          }}
        />
      )}
    </div>
  )
}

export default Screen;
