import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { FormGroup, Input, Label } from "reactstrap";
import { OnboardPageInner } from "../components/OnboardPageInner";
import { StepProgress } from "../components/StepProgress";
import { useSetReminders } from "../hooks/use-set-reminders";
import { getNextBackLinks } from "../utils/get-next-back-links";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.setReminders`;
const formId = "set-reminders-form";

export const SetReminders = ({
	isNewTeacher,
	isIncompleteTeacher,
	stepRoutes = [],
	currentRouteName,
}) => {
	const { t } = useTranslation();
	const { formValues, setFormValues, loading, updateReminders } =
		useSetReminders();

	const showNewTeacherFlow = isNewTeacher || isIncompleteTeacher;

	const { currentStep, backLink, nextLink } = getNextBackLinks(
		stepRoutes,
		currentRouteName,
	);

	const onChangeInput = (e) => {
		const { name, checked } = e.target;
		setFormValues({
			...formValues,
			[name]: checked,
		});
	};

	const onSubmitForm = (e) => {
		e.preventDefault();
		updateReminders(nextLink);
	};

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	});

	return (
		<OnboardPageInner
			formId={formId}
			onSubmit={onSubmitForm}
			heading={
				<>
					<Typography variant="h1">
						{t(`${pageKey}.mainHeading`)}
					</Typography>
					<Typography variant="body1">
						{t(`${pageKey}.introText`)}
					</Typography>
				</>
			}
			actions={
				<StepProgress
					formId={formId}
					backLink={backLink}
					nextLabel={
						showNewTeacherFlow
							? `${tKey}.nextButton`
							: `${tKey}.confirmButton`
					}
					currentStep={currentStep}
					totalSteps={stepRoutes.length}
					disabled={loading}
				/>
			}
		>
			<Box className="form-field-wrap" mt="0.5rem">
				<FormGroup>
					<Input
						id="oneDay"
						type="checkbox"
						name="oneDay"
						checked={formValues.oneDay}
						onChange={onChangeInput}
						disabled={loading}
					/>
					<Label htmlFor="oneDay">
						{t(`${pageKey}.oneDayOptionLabel`)}
					</Label>
				</FormGroup>
				<FormGroup>
					<Input
						id="oneHour"
						type="checkbox"
						name="oneHour"
						checked={formValues.oneHour}
						onChange={onChangeInput}
						disabled={loading}
					/>
					<Label htmlFor="oneHour">
						{t(`${pageKey}.oneHourOptionLabel`)}
					</Label>
				</FormGroup>
			</Box>
		</OnboardPageInner>
	);
};
