import { CONNECTEDNORTH_REGIONS } from "../../config";
import { getSession, setSession } from "../../utils/session";
import AirtableTable from "../AirtableTable";

export class RegionTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Regions");

    this.fields = {
      "# Curriculum Links": "fldFODvY8YAeHcaCD",
      "Name": "fldGcfofMV2OTkbyz",
      "Long Name": "fldUmArxSBwtnP18D"
    }
  }

  getAllRegions = async () => {
    const regions = getSession(CONNECTEDNORTH_REGIONS);
    if (regions) return regions;

    const rs = await this.list({
      sort: [{ field: 'Name', direction: 'asc' }]
    });

    setSession(CONNECTEDNORTH_REGIONS, rs);
    return rs;
  }

  getRegionByName = (name) => {
    return this.list({
      filterByFormula: `OR({Name} = "${name}", {Long Name} = "${name}")`
    })
  }
}