import React, { useEffect } from "react";
import { nanoid } from "nanoid";
import { SessionCard } from "./components/SessionCard";
import { useCredits, CREDIT_OPTS, COST_PER_CREDIT } from "./useCredits";
import { Card } from "../../../components/Card";
import { useTranslation } from "react-i18next";
import { useRequestInvoice } from "./useRequestInvoice";
import { RequestInvoice } from "./components/RequestInvoice";
import { useSelector } from "react-redux";

export function EarnMore() {
	const { t } = useTranslation();
	const { userInfo } = useSelector((state) => state.appInfo);
	useEffect(() => {
		window.document.title = "Get more Credits - Connected North";
		window.document
			.querySelector("main")
			?.style.setProperty("background-color", "#f3f9ff");
	}, []);

	/** @type {number} */
	const review = userInfo["Reviewed Sessions TY"];
	/** @type {number} */
	const rated = userInfo["Rated Sessions TY"];
	/** @type {number} */
	const unique = userInfo["Unique Providers TY"];
	/** @type {string} */
	const teacherID = userInfo.id;
	const { points, handleCreditSelect, credits, purchaseLink } = useCredits(
		review,
		rated,
		unique,
		teacherID,
	);

	/** @type {string} */
	const name = userInfo["Teacher Name"];
	/** @type {string} */
	const email = userInfo["Email"];
	/** @type {string} */
	const school = userInfo["School Name Text"][0];
	const requestInvoice = useRequestInvoice(credits, name, email, school);

	/** @type {number} */
	const balance = userInfo["Credit Balance"];
	return (
		<>
			<div className="main-container">
				<div className="row justify-content-between align-items-center mb-xs-4">
					<div className="col mb-3 my-md-0">
						<h1 style={{ width: "100%" }} className=''>
							{t("credit-page.earn-more.h1")}
						</h1>
						<h6 className='w-md-75'>
							{t("credit-page.earn-more.h6")}
						</h6>
					</div>

					<Card
						className="d-flex flex-column my-lg-4 bg-white px-lg-5 py-lg-5 earn-more-card"
					>

{/* BUY MORE CREDITS */}
<section className="bg-white">
							<div>
								<h2>
									{t(
										"credit-page.earn-more.buy-more.heading",
									)}
								</h2>
								<p className="font-weight-normal">
									{t("credit-page.earn-more.buy-more.text-1")}
								</p>
								<p className="font-weight-normal">
									{t("credit-page.earn-more.buy-more.text-2")}
								</p>
							</div>

							<h3
								className="h5 font-weight-bold my-4"
								style={{ color: "#2b4f70" }}
							>
								{t("credit-page.earn-more.current-balance")}:{" "}
								{balance}
							</h3>

							{/* credit selection */}
							<div>
								<label
									htmlFor="credit-opts"
									className="font-weight-normal"
								>
									{t("credit-page.earn-more.form.label")}
								</label>
								<div
									className="mt-2 px-4 py-2"
									style={{
										backgroundColor: "#F3F9FF",
										width: "max-content",
										borderRadius: "100px",
									}}
								>
									<select
										id="credit-opts"
										name="credit-opts"
										className="border-0 bg-transparent"
										onChange={handleCreditSelect}
										value={credits}
									>
										{Object.entries(CREDIT_OPTS).map(
											([i]) => (
												<option
													key={nanoid(10)}
													value={i}
												>
													{i}{" "}
													{t(
														"credit-page.earn-more.form.credits",
													)}
												</option>
											),
										)}
									</select>
								</div>
							</div>

							<h4 className="h6 font-weight-bold my-4">
								{t("credit-page.earn-more.total-cost")}: $
								{credits * COST_PER_CREDIT}
							</h4>

							<div
								className="d-flex align-items-center"
								style={{ gap: "10px" }}
							>
								<a
									href={purchaseLink}
									className="btn btn-primary"
								>
									{t("credit-page.earn-more.credit-card")}
								</a>

								<button
									className="btn btn-outline-primary"
									onClick={requestInvoice.toggleModal}
								>
									{t("credit-page.earn-more.request-invoice")}
								</button>
							</div>
						</section>
						
						{points.map((props, i) => (
							<SessionCard key={i} {...props} />
						))}

					</Card>
				</div>
			</div>

			<RequestInvoice {...requestInvoice} credits={credits} />

			{requestInvoice.purchaseOK && (
				<div
					className="bg-success d-flex flex-column justify-content-start align-items-start px-3 py-2"
					role="alert"
					style={{
						position: "fixed",
						bottom: 20,
						left: 20,
						zIndex: 1000,
						borderRadius: 10,
					}}
				>
					<h6 className="h5 m-0 p-0">
						{t("credit-page.earn-more.request-invoice-ok.heading")}
					</h6>
					<p className="m-0 p-0">
						{t("credit-page.earn-more.request-invoice-ok.text")}
					</p>
				</div>
			)}
		</>
	);
}
