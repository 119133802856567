import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useSchools } from './hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingBar from '../../components/LoadingBar';
import Select from 'react-select';
import School from './School';
import TransferCreditModal from '../modals/TransferCredit';
import SchoolCharts from './SchoolCharts';

const Screen = () => {
    const { t } = useTranslation();
    const { userType, userInfo } = useSelector(state => state.appInfo);
    const { loading: schoolLoading, schools, refresh } = useSchools();
    const [school, setSchool] = useState(null);
    const [showTransferCreditModal, setShowTransferCreditModal] = useState(false);

    useEffect(() => {
        if (
            userType === 'Teacher' &&
            (!userInfo["School Leader?"] && !userInfo["District Leader?"])
        ) {
            window.location.replace("/cn/dashboard");
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let isMulti = false;
        if (userType === 'Team' || (schools && schools.length > 1)) isMulti = true;

        const schoolTitle = isMulti ? t("principal.heading-multi") : t("principal.heading");

        window.document.title = `${schoolTitle} - Connected North`;

        if (schools && schools.length) setSchool(schools[0]);
    }, [schools]) // eslint-disable-line react-hooks/exhaustive-deps

    const getTitle = () => {
        if (schoolLoading) return t("principal.heading");
        if (schools && schools.length > 1) return t("principal.heading-multi");
        return t("principal.heading");
    }

    return (
        <div className="main-container">
            <div className="schools-header">
                <h1>{getTitle()}</h1>
                {userType === 'Teacher' && !userInfo["District Leader?"] && (
                    <a
                        className="btn btn-primary"
                        href="/cn/credits/earn-more"
                    >{t("principal.buy-credits")}</a>
                )}
                {userType === 'Team' && (
                    <button
                        className="btn btn-primary"
                        onClick={() => setShowTransferCreditModal(true)}
                    >Credit Transfer</button>
                )}
            </div>
            {schoolLoading && <LoadingBar />}

            {(!!schools && schools.length > 1) && (
                <div className="school-selector">
                    <Select
                        className="form-style"
                        value={school ? { value: school.id, label: school.School } : null}
                        placeholder="Select a School"
                        onChange={(selectedOption) => {
                            const sc = schools.find(s => s.id === selectedOption.value);
                            sc && setSchool(sc);
                        }}
                        options={schools.map(sc => ({ value: sc.id, label: sc.School }))}
                    />
                </div>
            )}

            {!!school && (
                <School
                    school={{ id: school.id, name: school.School }}
                    isSchoolCredit={(userType === 'Teacher' || userType === 'Team') && schools.length > 1}
                />
            )}

            {!!school && (userType === 'Team' || userInfo["District Leader?"]) && (<SchoolCharts school={school} />)}

            {(showTransferCreditModal) && (
                <TransferCreditModal
                    onToggle={() => setShowTransferCreditModal(false)}
                    onPost={() => refresh()}
                />
            )}
        </div>
    )
}

export default Screen;