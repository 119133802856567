import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { check2023BestPractices } from '../../utils/bp-utils';
import { toast } from 'react-toastify';
import airtable from '../../airtables';
import { setAppUserInfo } from '../../redux/actions';
import Loading from '../../components/Loading';
import StepProgress from '../../components/StepProgress';

const BPReflection = () => {
  const { userInfo, userType, isNewProvider } = useSelector(state => state.appInfo);
  const [reflection, setReflection] = useState("");
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = "Reflection - Best Practices - Connected North"
  }, [])

  useEffect(() => {
    const redir = check2023BestPractices(userInfo, userType, isNewProvider, "reflection");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType, isNewProvider])

  const updateBestPractices = async () => {
    if (userInfo["2023 Reflection Mandatory?"] && !reflection) return;

    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "2023 Reflection": reflection || "",
        "2023 Reflection Completed": true
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  const gotoBP = (bpName) => {
    window.open(`/cn/best-practices/${bpName}`, "_target");
  }

  return (
    <div className="main-container">
      <StepProgress
        steps={[
          t("provider-step-progress.survey"),
          t("provider-step-progress.reflection"),
          t("provider-step-progress.code-of-conduct"),
          ...(userInfo["Cost/Session"] ? [t("provider-step-progress.cancellation-policy")] : []),
          t("provider-step-progress.confirm-session-offerings"),
        ]}
        activeStep={1}
        completedSteps={[0]}
      />
      <div style={{ height: 20 }} />
      <h1>{t("bestPractices.reflection.title")}</h1>
      <div className="main-content">
        <p>{t("bestPractices.reflection.p1.lead")}</p>
        <ul>
          <li><span className="bp-link" onClick={() => gotoBP("meaningful-land-acknowledgement")}>{t("bestPractices.reflection.p1.li1-t")}</span>: {t("bestPractices.reflection.p1.li1")}</li>
          <li><span className="bp-link" onClick={() => gotoBP("facilitation-of-student-interactivity")}>{t("bestPractices.reflection.p1.li2-t")}</span>: {t("bestPractices.reflection.p1.li2")}</li>
          <li><span className="bp-link" onClick={() => gotoBP("creatively-customized-content")}>{t("bestPractices.reflection.p1.li3-t")}</span>: {t("bestPractices.reflection.p1.li3")}</li>
          <li><span className="bp-link" onClick={() => gotoBP("indigenous-histories-contributions")}>{t("bestPractices.reflection.p1.li4-t")}</span>: {t("bestPractices.reflection.p1.li4")}</li>
          <li><span className="bp-link" onClick={() => gotoBP("trauma-informed-approaches")}>{t("bestPractices.reflection.p1.li5-t")}</span>: {t("bestPractices.reflection.p1.li5")}</li>
        </ul>

        <p>{t("bestPractices.reflection.p2")}</p>

        <div>
          <strong>{t("bestPractices.reflection.p1.li1-t")}:&nbsp;</strong>
          <em>{userInfo["ReflectionMLA"] || "N/A"}</em>
        </div>

        <div>
          <strong>{t("bestPractices.reflection.p1.li2-t")}:&nbsp;</strong>
          <em>{userInfo["ReflectionFSI"] || "N/A"}</em>
        </div>

        <div>
          <strong>{t("bestPractices.reflection.p1.li3-t")}:&nbsp;</strong>
          <em>{userInfo["ReflectionCCC"] || "N/A"}</em>
        </div>

        <div>
          <strong>{t("bestPractices.reflection.p1.li4-t")}:&nbsp;</strong>
          <em>{userInfo["ReflectionIHC"] || "N/A"}</em>
        </div>

        <div>
          <strong>{t("bestPractices.reflection.p1.li5-t")}:&nbsp;</strong>
          <em>{userInfo["ReflectionTIA"] || "N/A"}</em>
        </div>

        <div style={{ marginTop: 30 }}>
          {
            userInfo["2023 Reflection Mandatory?"] ? (
              <p>{t("bestPractices.reflection.p3")}</p>
            ) : (
              <p>{t("bestPractices.reflection.p4")}</p>
            )
          }
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflection(e.target.value)}
            defaultValue={reflection}
          />
        </div>

        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={saving || (userInfo["2023 Reflection Mandatory?"] && !reflection)}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;Continue to Next Step</Button>
        </div>
      </div>
    </div>
  )
}

export default BPReflection;
