import React, { useEffect, useState } from 'react';
import StepProgress from '../../../../components/StepProgress';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const BP2024ProgressBar = ({ current }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector(state => state.appInfo);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const ss = [];
    if (userInfo["Delivered 23-24"] > 1) {
      ss.push({ snake: "survey", label: t("provider-step-progress.survey") })
    }
    if (userInfo["Renewal Actions 24-25"]) {
      ss.push({ snake: "reflection", label: t("provider-step-progress.reflection") })
    }

    ss.push({ snake: "lang-rev", label: "Language Revitalization" })
    ss.push({ snake: "role", label: "Role Expectations" })

    if (userInfo["Cost/Session"] && !userInfo["2023 Cancellation Policy Reviewed"]) {
      ss.push({ snake: "cancel-policy", label: t("provider-step-progress.cancellation-policy") })
    }
    ss.push({ snake: "confirm", label: t("provider-step-progress.confirm-session-offerings") })
    setSteps([...ss]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (steps.length === 0) return null;

  const activeStep = steps.findIndex(step => step.snake === current);
  let completed = [];
  if (activeStep > 0) {
    completed = Array(activeStep).fill().map((_, i) => i);
  }

  return (
    <StepProgress
      steps={steps.map(step => step.label)}
      activeStep={activeStep}
      completedSteps={completed}
    />
  )
}

export default BP2024ProgressBar