import React, { Fragment, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import "./styles.scss"
import Thumbnail from './Thumbnail';
import { Button } from 'reactstrap';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '12px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#9de0f8'
};

const acceptStyle = {
    borderColor: '#9de0f8'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const ImageDropzone = ({ files: imgFiles, onDrop, onRemove }) => {
    const {
        getRootProps,
        getInputProps,
        open,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: { 'image/*': [] },
        noClick: true
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="dropzone-container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {imgFiles.length > 0 ? (
                    <Fragment>
                        <div className="horizontal-wrap">
                            {imgFiles.map((image, i) => (
                                <Thumbnail
                                    key={i}
                                    file={image.file}
                                    onRemove={() => onRemove(i)}
                                />
                            ))}
                        </div>
                        <div className="small-drag">
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => open()}
                            >Add Photo</Button>
                        </div>
                    </Fragment>
                ) : (
                    <div className="full-drag">
                        <span>Drag 'n' drop some files here, or click to select files</span>
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => open()}
                        >Attach Image</Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ImageDropzone;