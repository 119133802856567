import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import classNames from 'classnames';

const TextEllipsis = ({ text, lines, fullText }) => {
    const containerRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(true);

    useEffect(() => {
        if (containerRef.current) {
            setIsOverflowing(containerRef.current.scrollHeight > containerRef.current.clientHeight);
        }
    }, [text])

    useEffect(() => {
        setIsOverflowing(!fullText)
    }, [fullText])

    return (
        <div
            style={{'--parameter-line-clamp': lines}}
            className={classNames({
                "multiline-ellipsis": true,
                "overflow": isOverflowing
            })}
            ref={containerRef}
        >
            {text}
        </div>
    );
};

export default TextEllipsis;