import AirtableTable from "../AirtableTable";

export class QuestionTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Questions");

    this.fields = {
      "ID": "fldIG0hCJTVAF3otJ",
      "Question": "fldk2o65h9MA8XuZM",
      "Session": "fld4AhzWt8dA66EEW",
      "Added By": "fldJcBxppArIc8SPu",
      "Favourites": "fldQ2iajMxOqJ2fiA",
      "# of Favourites": "fldu8oUAXh54fLxqz",
      "Date Asked": "fldvquXgezcZbZ61Y",
      "Display Name": "fldNJXuZ417yZzaSu",
      "Display School": "flduceHjeZ9mHyqBh"
    }
  }

  getQuestionsOfSession = (session) => {
    return this.list({
      filterByFormula: `FIND("${session}", {Session})`,
      sort: [
        { field: "# of Favourites", direction: "desc" },
        { field: "Date Asked", direction: "desc" },
      ]
    });
  }
}