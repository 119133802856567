import cx from "classnames";
import { nanoid } from "nanoid";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { INQUIRY_OPTS } from "../useHelp";

/**
 * @param {import("../useHelp").UseHelpModal} props
 */
export function HelpModal({
	openModal,
	toggleModal,
	handleSubmit,
	handleInquiry,
	inquiry,
	message,
	handleMessage,
	error,
	loading,
}) {
	return (
		<>
			<Modal isOpen={openModal} toggle={toggleModal} centered={true}>
				<ModalHeader
					close={
						<button
							onClick={toggleModal}
							className="mt-2 border-0"
							style={{ backgroundColor: "transparent" }}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="19"
								height="19"
								viewBox="0 0 19 19"
								fill="none"
							>
								<path
									d="M14.4398 1.18161L9.46796 6.15345L4.49612 1.18161C3.88603 0.571523 2.89649 0.571523 2.28641 1.18161L1.18155 2.28646C0.571466 2.89655 0.571466 3.88609 1.18155 4.49617L6.1534 9.46802L1.18155 14.4399C0.571466 15.0499 0.571466 16.0395 1.18155 16.6496L2.28641 17.7544C2.89649 18.3645 3.88603 18.3645 4.49612 17.7544L9.46796 12.7826L14.4398 17.7544C15.0499 18.3645 16.0394 18.3645 16.6495 17.7544L17.7544 16.6496C18.3645 16.0395 18.3645 15.0499 17.7544 14.4399L12.7825 9.46802L17.7544 4.49617C18.3645 3.88609 18.3645 2.89655 17.7544 2.28646L16.6495 1.18161C16.0394 0.571523 15.0499 0.571523 14.4398 1.18161Z"
									fill="#2B4F70"
								/>
							</svg>
						</button>
					}
				>
					Need Help?
				</ModalHeader>
				<ModalBody className="p-5">
					<form
						onSubmit={handleSubmit}
						className="d-flex flex-column"
						style={{ gap: 30 }}
					>
						<FormField
							style={{
								width: "max-content",
							}}
						>
							<FormLabel htmlFor="inquiries">
								Reason for Inquiry
							</FormLabel>
							<div
								className="border-0 d-flex flex-row align-items-center justify-content-between"
								style={{
									gap: 20,
									backgroundColor: "#F3F9FF",
									borderRadius: 100,
									overflow: "hidden",
									paddingRight: 20,
								}}
							>
								<select
									required
									className="px-4 py-2"
									id="inquiries"
									onChange={handleInquiry}
									value={inquiry}
									style={{
										backgroundColor: "inherit",
										border: "none",
										appearance: "none",
										cursor: "pointer",
										zIndex: 999,
									}}
								>
									{INQUIRY_OPTS.map((m) => (
										<option value={m} key={nanoid(10)}>
											{m}
										</option>
									))}
								</select>

								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="13"
									height="9"
									viewBox="0 0 13 9"
									fill="none"
								>
									<path
										d="M5.81322 8.22701L0.286496 2.96079C-0.0954986 2.59681 -0.0954986 2.00823 0.286496 1.64811L1.20491 0.772991C1.5869 0.409003 2.2046 0.409003 2.58253 0.772991L6.5 4.50581L10.4175 0.772991C10.7995 0.409003 11.4172 0.409003 11.7951 0.772991L12.7135 1.64811C13.0955 2.0121 13.0955 2.60068 12.7135 2.96079L7.18678 8.22701C6.81291 8.591 6.19522 8.591 5.81322 8.22701Z"
										fill="#0D1120"
									/>
								</svg>
							</div>
						</FormField>

						<FormField>
							<FormLabel htmlFor="message">Message</FormLabel>
							<textarea
								required
								className="px-2"
								style={{
									backgroundColor: "#f3f9ff",
									borderRadius: 10,
									height: 91,
									border: "1px solid #9DE0F8",
								}}
								id="message"
								type="text"
								value={message}
								onChange={handleMessage}
							/>
						</FormField>

						<button
							className="btn btn-primary mx-auto"
							style={{ width: 190 }}
							type="submit"
							disabled={loading}
						>
							{loading ? "Sending..." : "Submit Message"}
						</button>
					</form>
					{error && (
						<p className="text-red p-0 mt-4 text-center">{error}</p>
					)}
				</ModalBody>
			</Modal>
		</>
	);
}

/**
 * @typedef {React.HTMLAttributes<HTMLDivElement>} FormFieldProps
 * @param {FormFieldProps} props
 */
function FormField(props) {
	const { children, className, ...rest } = props;
	return (
		<div className={cx(["d-flex flex-column", className])} {...rest}>
			{children}
		</div>
	);
}

/**
 * @param {React.HTMLAttributes<HTMLLabelElement>} props
 */
function FormLabel(props) {
	const { children, className, ...rest } = props;
	return (
		<label className={cx(["font-weight-normal", className])} {...rest}>
			{children}*
		</label>
	);
}
