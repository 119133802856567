import React, { useState } from 'react';

export const DropdownRadio = ({
  header,
  values,
  handleChange,
  filters
}) => {
  const [open, setOpen] = useState(false);
  function toggle() {
    setOpen((p) => !p);
  }

  return (
    <div
      style={{ position: "relative" }}
      className="w-50"
      onMouseLeave={() => setOpen(() => false)}
    >
      <button
        onClick={toggle}
        style={{
          borderRadius: open ? "30px 30px 0 0" : "30px",
          border: "1px solid #1094CE",
          padding: "16px 20px",
          color: "#0D1120",
          gap: "20px",
          background: "white",
        }}
        className="d-flex justify-content-between w-100 btn"
      >
        <span
          className="font-weight-bold"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {header}
        </span>
        <span
          style={{
            transform: open ? "rotate(180deg)" : "none",
            transition: "all 100ms ease-in-out",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
          >
            <path
              d="M5.81322 8.22701L0.286496 2.96079C-0.0954986 2.59681 -0.0954986 2.00823 0.286496 1.64811L1.20491 0.772991C1.5869 0.409003 2.2046 0.409003 2.58253 0.772991L6.5 4.50581L10.4175 0.772991C10.7995 0.409003 11.4172 0.409003 11.7951 0.772991L12.7135 1.64811C13.0955 2.0121 13.0955 2.60068 12.7135 2.96079L7.18678 8.22701C6.81291 8.591 6.19522 8.591 5.81322 8.22701Z"
              fill="#0D1120"
            />
          </svg>
        </span>
      </button>
      <ul
        style={{
          listStyle: "none",
          position: "absolute",
          top: "99%",
          left: 0,
          right: 0,
          transition: "all 100ms ease-in-out",
          background: "white",
          padding: "16px 20px",
          border: "1px solid #1094CE",
          borderTop: "none",
          borderRadius: "0px 0px 10px 10px",
          zIndex: 9999,
          opacity: open ? 100 : 0,
          clipPath: open
            ? "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"
            : "polygon(0 0, 100% 0, 100% 0, 0 0)",
          maxHeight: "500px",
          overflowY: "scroll",
        }}
      >
        {values.map((v, i) => (
          <li
            key={`${v}-${i}`}
            className="d-flex justify-content-start align-items-start"
          >
            <input
              type="checkbox"
              id={`${v}-${i}`}
              checked={filters[i] ? true : false}
              value={i}
              onChange={handleChange}
              style={{ marginRight: "10px", marginTop: "2px" }}
            />
            <label htmlFor={`${v}-${i}`}>{v}</label>
          </li>
        ))}
      </ul>
    </div>
  );
}