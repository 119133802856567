import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const CancellationReschedulePolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="">
      <h1>Cancellation Policy</h1>
      <div className="main-content">
        <p>{t("bestPractices.cr-policy.p1")}</p>
        <p>{t("bestPractices.cr-policy.p2.extra")}</p>
        <p>{t("bestPractices.cr-policy.p2.lead")}</p>

        <b>A. {t("bestPractices.cr-policy.p2.a.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.a.li1")}</p></li>
        </ul>

        <b>B. {t("bestPractices.cr-policy.p2.b.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.b.li1")}</p></li>
          <li><p>{t("bestPractices.cr-policy.p2.b.li2")}</p></li>
        </ul>

        <b>C. {t("bestPractices.cr-policy.p2.c.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.c.li1")}</p></li>
        </ul>

        <b>D. {t("bestPractices.cr-policy.p2.d.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.d.li1")}</p></li>
        </ul>

        <b>{t("bestPractices.cr-policy.p3-title")}</b>
        <p className="no-margin">
          <Trans
            i18nKey={"bestPractices.cr-policy.p3"}
            // eslint-disable-next-line
            components={{ a: <a href="https://calendly.com/annemarie-tig/renewal" target="_blank" rel="noreferrer" /> }}
          />
        </p>
      </div>
    </div>
  )
}

export default CancellationReschedulePolicy;
