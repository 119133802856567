import React from "react";
import { Modal } from "reactstrap";

const ScheduleInterviewModal = ({ onToggle }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={onToggle}
    >
      <div className="modal-body">
        <h3>Schedule Interview</h3>
        <p>Thanks for completing your Session Host training modules! As a final step, please use the calendar below to schedule an interview to meet our team and discuss next steps.</p>
        <div
          className="calendly-inline-widget interview-schedule"
          data-url="https://calendly.com/lily-tig/interview"
        />
      </div>
    </Modal>
  )
}

export default ScheduleInterviewModal;