import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import airtable from "../../../airtables";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import { useUpdateUserInfo } from "./use-update-user-info";

export const useSelectSchool = (isNewTeacher, isIncompleteTeacher) => {
	const history = useHistory();
	const { t } = useTranslation();
	const { loading, setLoading, userInfo, updateUserInfo } =
		useUpdateUserInfo();

	const { search: searchParamsString } = useLocation();
	const searchParams = new URLSearchParams(searchParamsString);
	const paramCluster = searchParams.has("cluster")
		? searchParams.get("cluster")
		: undefined;

	const [currentSchoolName, setCurrentSchoolName] = useState("");
	const [allSchools, setAllSchools] = useState([]);

	const [formValues, setFormValues] = useState({
		isCurrent: isNewTeacher || isIncompleteTeacher ? "no" : "yes",
		school: userInfo[TEACHER_FIELDS.SCHOOL]
			? userInfo[TEACHER_FIELDS.SCHOOL][0]
			: "",
	});

	const updateSchool = async (nextLink = "") => {
		const { school } = formValues;

		if (
			school !== "" &&
			userInfo[TEACHER_FIELDS.SCHOOL] &&
			userInfo[TEACHER_FIELDS.SCHOOL].includes(school)
		) {
			history.push(nextLink);
			return;
		}

		// Get the current and previous school info
		const prevSchool = userInfo[TEACHER_FIELDS.SCHOOL] || [];
		const prevSchoolsList = userInfo[TEACHER_FIELDS.PREVSCHOOL] || [];

		// Add the current school to the previous schools list if it's not already included
		const updatedPrevSchoolsList = [...new Set([...prevSchoolsList, ...prevSchool])];

		const updateBody = {
			[TEACHER_FIELDS.SCHOOL]: [school],
			[TEACHER_FIELDS.PREVSCHOOL]: updatedPrevSchoolsList,
		};

		updateUserInfo(updateBody, nextLink);
	};

	useEffect(() => {
		const fetchClusterSchools = async () => {
			setLoading(true);
			try {
				let clusterSchools = [];
				const clusterIds = paramCluster
					? [paramCluster]
					: userInfo?.Cluster || [];

				const ignoredClusterId = "recP65M3bxZOZuF4r";
				const schoolSet = new Set();

				for (const clusterId of clusterIds) {
					if (clusterId !== ignoredClusterId) {
						const cluster = await airtable.clusters.select(clusterId);
						if (cluster["Schools"]) {
							cluster["Schools"].forEach((id, idx) => {
								const schoolName = cluster["School List"][idx] || "";
								if (!schoolSet.has(id) && !/Floor|Mobile/i.test(schoolName)) {
									schoolSet.add(id);
									clusterSchools.push({
										label: schoolName,
										id,
									});
								}
							});
						}
					}
				}

				if (clusterSchools.length === 0) {
					const schools = await airtable.schools.getAllSchools();
					schools.forEach(({ School, id }) => {
						if (!schoolSet.has(id) && !/Floor|Mobile/i.test(School)) {
							schoolSet.add(id);
							clusterSchools.push({
								label: School,
								id,
							});
						}
					});
				}

				// Sort schools alphabetically by label (school name)
				clusterSchools.sort((a, b) => a.label.localeCompare(b.label));

				setAllSchools(clusterSchools);

				if (clusterSchools.length === 0) {
					toast.error(t("teacherOnboard.selectSchool.noSchoolsError"), {
						position: "bottom-center",
					});
				}
			} catch (error) {
				console.log(error.toString());
				toast.error(t("teacherOnboard.selectSchool.noSchoolsError"), {
					position: "bottom-center",
				});
			}
			setLoading(false);
		};

		if (allSchools.length === 0 && !loading) {
			fetchClusterSchools();
		}
	}, [allSchools, loading, setLoading, paramCluster, userInfo, t]);

	useEffect(() => {
		if (
			allSchools.length > 0 &&
			userInfo[TEACHER_FIELDS.SCHOOL] &&
			currentSchoolName === ""
		) {
			const currentSchool = allSchools.find(({ id }) =>
				userInfo[TEACHER_FIELDS.SCHOOL].includes(id)
			);
			if (currentSchool && currentSchool.label)
				setCurrentSchoolName(currentSchool.label);
		}
	}, [allSchools, userInfo, currentSchoolName]);

	return {
		allSchools,
		formValues,
		setFormValues,
		loading,
		updateSchool,
		currentSchoolName,
	};
};