import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { OnboardPageInner } from "../components/OnboardPageInner";
import { useCreateTeacher } from "../hooks/use-create-teacher";
import { APP_LOGIN, ONBOARD_TEACHER_ROUTES } from "../routing";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.createAccount`;
const formId = "create-account-form";

export const CreateAccount = ({ isIncompleteTeacher }) => {
	const { t } = useTranslation();
	const { formValues, setFormValues, loading, registerTeacher } =
		useCreateTeacher(isIncompleteTeacher);

	const nextLink = ONBOARD_TEACHER_ROUTES.DETAILS.path;

	const onChangeInput = (e) => {
		const { name, value } = e.target;
		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const onSubmitForm = (e) => {
		e.preventDefault();
		registerTeacher(nextLink);
	};

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	});

	return (
		<OnboardPageInner
			formId={formId}
			onSubmit={onSubmitForm}
			heading={
				<>
					<Typography variant="h1">
						{t(`${pageKey}.mainHeading`)}
					</Typography>
					<Typography variant="body1">
						{t(`${pageKey}.introText`)}
					</Typography>
				</>
			}
			actions={
				<>
					<Button
						color="primary"
						type="submit"
						form={formId}
						disabled={loading}
					>
						{t(
							isIncompleteTeacher
								? `${tKey}.continueCreateAccountButton`
								: `${tKey}.createAccountButton`,
						)}
					</Button>
					<Typography mt="1rem">
						{t(`${pageKey}.signInPromptText`) + " "}
						<Typography
							component={Link}
							to={APP_LOGIN}
							sx={{
								color: "inherit",
								fontWeight: "bold",
								textDecoration: "underline",
							}}
						>
							{t(`${pageKey}.signInPromptLink`)}
						</Typography>
					</Typography>
				</>
			}
		>
			<>
				<FormGroup>
					<Label for="fullName">{`${t(`${tKey}.fullNameLabel`)} (${t(
						`${tKey}.requiredField`,
					)})`}</Label>
					<Input
						id="fullName"
						name="fullName"
						type="text"
						value={formValues.fullName}
						onChange={onChangeInput}
						disabled={loading}
						required
					/>
				</FormGroup>
				<FormGroup>
					<Label for="email">{`${t(`${tKey}.emailLabel`)} (${t(
						`${tKey}.requiredField`,
					)})`}</Label>
					<Input
						id="email"
						name="email"
						type="email"
						value={formValues.email}
						onChange={onChangeInput}
						disabled={loading}
						required
					/>
				</FormGroup>
				<FormGroup>
					<Label for="secondaryEmail">
						{t(`${tKey}.secondaryEmailLabel`)}
					</Label>
					<Input
						id="secondaryEmail"
						name="secondaryEmail"
						type="email"
						value={formValues.secondaryEmail}
						onChange={onChangeInput}
						disabled={loading}
					/>
				</FormGroup>
				<FormGroup>
					<Label for="phoneNumber">
						{t(`${tKey}.phoneNumberLabel`)}
					</Label>
					<Input
						id="phoneNumber"
						name="phoneNumber"
						type="tel"
						value={formValues.phoneNumber}
						onChange={onChangeInput}
						disabled={loading}
					/>
				</FormGroup>
			</>
		</OnboardPageInner>
	);
};
