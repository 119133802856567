import React, { useState } from 'react';
import { Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../components/LoadingBar'
import Gap from '../../components/Gap';
import SessionInfo from '../../components/SessionInfo';
import RateSessionModal from '../../pages/modals/Rate';
import { setPresenterPast } from '../../redux/actions';

const Screen = () => {
  const { presenterPast, presenterLoading } = useSelector(state => state.appInfo);

  const [activeSession, setActiveSession] = useState(null);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const postRating = () => {
    setShowRatingModal(false)
    const mSessions = [...presenterPast];
    mSessions.forEach((session, i) => {
      if (session.id === activeSession.id) {
        mSessions[i]["Provider Rating"] = 5;
      }
    })

    dispatch(setPresenterPast(mSessions));
  }

  return (
    <div className="main-container">
      <Row>
        <Col>
          <h1>{t("view-past-sessions")}</h1>
        </Col>
        <Col align="right">
          <Link to="/cn/upcoming">
            <h1 className="title-link">{t('upcoming_sessions')} <i className='fa fa-arrow-right' /></h1>
          </Link>
        </Col>
      </Row>
      <div style={{ marginTop: 10 }}>
        <Gap height={20} />
        {presenterLoading ? <LoadingBar /> : null}
        <div style={{ minHeight: 500 }}>
          {
            (presenterPast && presenterPast.length > 0) ? presenterPast.map((session, i) => (
              <SessionInfo
                key={i}
                session={session}
                displayInfos={session["Session Host(s)"] ? [
                  "Teacher School Name",
                  "Session Host",
                  "Teacher Name",
                  "Image/Photo"
                ] : [
                  "Teacher School Name",
                  "School Name",
                  "School Lead",
                  "Teacher Name",
                  "Image/Photo"
                ]}
                actionMenu={["provider-feedback"]}
                openProviderFeedbackModal={(e, session) => {
                  setActiveSession(session);
                  setShowRatingModal(true);
                }}
              />
            )) : (
              <div className="nolist-placeholder" align="center">
                <span>{t("no-past-sessions")}</span>
              </div>
            )
          }
        </div>
      </div>

      {(showRatingModal && activeSession) ? (
        <RateSessionModal
          session={activeSession}
          onDone={() => postRating()}
          onToggle={() => setShowRatingModal(!showRatingModal)}
        />
      ) : null}
    </div>
  )
}

export default Screen;