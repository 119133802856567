import React from 'react'
import { useSelector } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { useTranslation } from 'react-i18next'
import { Pie } from 'react-chartjs-2'
import { getClusterLeadName } from '../../utils/string';

ChartJS.register(ArcElement, Tooltip, Legend, Title)

const WellbeingPieGraph = ({ isDistrictLeader, showPrincipalView, sessions }) => {
  const { t } = useTranslation()
  const wellBeing = new Array(7).fill(0)
  const { userInfo } = useSelector(
    (state) => state.appInfo,
  );
  sessions.forEach((s) => {
    s['Well-being Link'] &&
      s['Well-being Link'].forEach((g) => {
        switch (g) {
          case 'Relationship with Students/Peers':
            wellBeing[0] = wellBeing[0] + 1
            break
          case 'Culture, Language & Identity':
            wellBeing[1] = wellBeing[1] + 1
            break
          case 'Relationship with Staff & Authority':
            wellBeing[2] = wellBeing[2] + 1
            break
          case 'Food & Nutrition':
            wellBeing[3] = wellBeing[3] + 1
            break
          case 'Hope For the Future':
            wellBeing[4] = wellBeing[4] + 1
            break
          case 'Arts & Recreation':
            wellBeing[5] = wellBeing[5] + 1
            break
          case 'Parent & Family Involvement':
            wellBeing[6] = wellBeing[6] + 1
            break
          default:
            break
        }
      })
  })

  const state = {
    labels: [
      t('Relationship with Students/Peers'),
      t('Culture, Language & Identity'),
      t('Relationship with Staff & Authority'),
      t('Food & Nutrition'),
      t('Hope For the Future'),
      t('Arts & Recreation'),
      t('Parent & Family Involvement'),
    ],
    datasets: [
      {
        label: '# of Sessions',
        data: wellBeing,
        backgroundColor: [
          '#D74353',
          '#E28139',
          '#CD83B1',
          '#D4A796',
          '#747E84',
          '#FAD50E',
          '#7D69A8',
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
      },
    ],
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          usePointStyle: true,
          padding: 24,
        },
      },
      title: {
        display: true,
        text: isDistrictLeader
          ? t("dashboard-page.district-sessions-by-wellbeing", { displayname: getClusterLeadName(userInfo) })
          : (showPrincipalView
            ? t("dashboard-page.school-sessions-by-wellbeing")
            : t("dashboard-page.sessions-by-wellbeing")),
        position: 'top',
        padding: {
          top: 0,
          bottom: 20,
        },
        fullSize: true,
        align: 'start',
        color: '#0D1120',
        font: {
          size: 25,
          weigth: 700,
        },
      },
    },
  }
  return (
    <>
      <Pie
        data={state}
        options={options}
        height="400px"
      />
    </>
  );
}
export default WellbeingPieGraph
