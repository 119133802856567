import AirtableTable from "../AirtableTable";

export class OtherSurveyTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Other Surveys")

    this.fields = {
      "Provider": "fld5zOZMJ0iOt3rUM",
      "OverallExperience": "flddQiEySc7H9mwxs",
      "LevelSupport": "fldVy6DQBXic4d6WS",
      "Highlight": "fldo2NTtEKFEO5maO",
      "Effective": "fld8kFmkUY9LrW1M3"
    }
  }
}