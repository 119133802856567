import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import useHook from './useHook';
import Loading from '../../components/Loading';
import SessionInfo from '../../components/SessionInfo';
import { getActionsForAvailableSessions, getDisplayInfosForAvailableSessions } from '../SessionInfoDisplay';
import { getProviderSessionIDFromSession } from '../../utils/session';

const Screen = () => {
  const {
    error,
    loading,
    sessions,
    hostSession
  } = useHook();

  const hostSessionCallback = (activeSession) => {
    hostSession(activeSession);
  }

  const renderContent = () => {
    if (error) {
      return (
        <div className="nolist-placeholder list-error" align="center">
          <span>{error}</span>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="upcoming-loader">
          <Loading size={30} />
        </div>
      )
    }

    if (!sessions || sessions.length === 0) {
      return (
        <div className="nolist-placeholder" align="center">
          <span>No availabile sessions</span>
        </div>
      );
    }

    return (
      <Fragment>
        {sessions.map((session, i) => (
          <SessionInfo
            key={i}
            displayInfos={getDisplayInfosForAvailableSessions(session)}
            actionMenu={getActionsForAvailableSessions(session)}
            session={session}
            sessionDetailLink={getProviderSessionIDFromSession(session)}
            hostSessionCallback={() => hostSessionCallback()}
          />
        ))}
      </Fragment>
    )
  }

  return (
    <div className="main-container">
      <div className="header-content">
        <h1>Available Sessions</h1>
        <div className='header-actions'>
          <Button
            outline
            color="primary"
            size='sm'
            onClick={() => window.location.href = "/cn/session-host/availability"}
          ><i className="fa fa-edit" /> Availability</Button>
        </div>
      </div>
      <div className="session-list">
        {renderContent()}
      </div>
    </div>
  )
}

export default Screen;