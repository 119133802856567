import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import './styles.scss';
import { Button, ListSubheader, Stack } from '@mui/material';

const AssignPresenter = ({ onToggle, onConfirm, session }) => {
  const { presentersForProvider } = useSelector(state => state.appInfo);
  const [presenters, setPresenters] = useState(session["Presenters"] || [])

  const togglePresenter = (presenterId) => {
    const idx = presenters.indexOf(presenterId);
    if (idx >= 0) {
      const ps = [...presenters]
      ps.splice(idx, 1);
      setPresenters(ps);
    } else {
      setPresenters([...presenters, presenterId]);
    }
  }

  return (
    <Modal
      className="modal-dialog-centered assign-presenter-modal"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-body">
        <Box sx={{ width: '100%', maxWidth: 480, bgcolor: 'background.paper' }}>
          <nav aria-label="main mailbox folders">
            <List
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Please select one or more Presenters for this session:
                </ListSubheader>
              }
            >
              {
                presentersForProvider.map((presenter, i) => {
                  const isSelected = presenters.indexOf(presenter.id) >= 0
                  return (
                    <ListItem
                      key={i}
                      disablePadding
                      onClick={() => togglePresenter(presenter.id)}
                    >
                      <ListItemButton selected={isSelected}>
                        <ListItemIcon className='checked'>
                          {isSelected ? <TaskAltIcon color='primary' /> : <RadioButtonUncheckedIcon color='primary' />}
                        </ListItemIcon>
                        <ListItemText
                          primary={presenter.Name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                className="presenter-info"
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <span>{presenter.Email}</span>
                                <span>{presenter.Phone}</span>
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                })
              }
            </List>
          </nav>

          <Stack direction="row" marginInline={5} marginBottom={2} justifyContent='space-between'>
            <Button variant="text" onClick={onToggle}>Cancel</Button>
            <Button variant="contained" onClick={() => onConfirm(session.id, presenters)}>Confirm</Button>
          </Stack>
        </Box>
      </div>
    </Modal>
  )
}

export default AssignPresenter;
