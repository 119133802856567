import React, { useState } from 'react';
import './styles.scss';
import { Button, Input } from 'reactstrap';
import useQuestions from './useQuestions';
import Loading from '../../components/Loading';
import LoadingBar from '../../components/LoadingBar'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Question from './Question';

const Screen = (props) => {
  const { match: { params } } = props;
  const sessionId = params.sessionId;
  const { userType } = useSelector(state => state.appInfo)
  const {
    loading,
    error,
    session,
    questions,
    updateQuestions,
    insertQuestion,
    likeQuestion,
    refresh
  } = useQuestions(sessionId);

  const [submitting, setSubmitting] = useState(false);
  const [question, setQuestion] = useState("");

  const submitQuestion = async () => {
    setSubmitting(true);

    insertQuestion(question, sessionId).then(res => {
      setSubmitting(false);
      setQuestion("");
      toast.success("Submitted!")
      refresh();
    }).catch(error => {
      setSubmitting(false);
      toast.error("Failed")
    })
  }

  const onFavorite = async (question, i) => {
    const q = await likeQuestion(question.id);
    const qs = [...questions];
    qs[i] = q;
    updateQuestions(qs);
  }

  if (userType !== 'Teacher' && userType !== 'Team') return null;

  if (error) return (
    <div>{error}</div>
  )

  const renderSessionInfo = () => {
    if (!session) return null;

    const pSessionId = session["Provider Session Record ID"][0]

    return (
      <a href={`/cn/session/${pSessionId}`} target="_blank" rel="noreferrer">
        <span>{session["Session Title Text"]}</span>
      </a>
    )
  }

  const renderQuestionForm = () => {
    if (userType === 'Team') return null;

    return (
      <div className="question-form">
        <Input
          type="textarea"
          rows={4}
          disabled={submitting}
          onChange={e => setQuestion(e.target.value)}
          value={question}
        />

        <div className='actions'>
          <Button
            color="primary"
            type="button"
            onClick={() => submitQuestion()}
            disabled={submitting || !question}
          >{submitting && <Loading size={14} />}Submit a Question</Button>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    if (userType === 'Teacher') return "Ask a question";
    return "Questions"
  }

  return (
    <div className="main-container">
      <h1>{renderTitle()}</h1>
      {renderSessionInfo()}
      <div className="m-container">
        {renderQuestionForm()}

        <div className='questions'>
          {userType === 'Teacher' && (
            <h4>Questions for this session</h4>
          )}
          {loading && <LoadingBar />}
          <div className="question-list">
            {questions.map((q, i) => (
              <Question
                key={i}
                info={q}
                userType={userType} // Pass userType as prop to the Question component
                onFavorite={() => onFavorite(q, i)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen;
