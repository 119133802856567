import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Loading from '../../../../components/Loading';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import airtable from '../../../../airtables';
import { setAppUserInfo } from '../../../../redux/actions';
import './styles.scss';
import { checkBestPractices } from '../utils';
import BP2024ProgressBar from '../components/BP2024ProgressBar';

const BPIntroduction = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [saving, setSaving] = useState(false);
  const [isResourceRenewal] = useState((userInfo["Renewal Actions"] || []).some(item => item.includes("resource")));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = "Introduction - Best Practices - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "introduction");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const updateBestPractices = async () => {
    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, { "24-25 Renewal Started": true });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  let items;
  if (userInfo["Delivered 23-24"] > 1) {
    items = [
      t("bestPractices.introduction.p2-new.li1"),
      t("bestPractices.introduction.p2-new.li2"),
      t("bestPractices.introduction.p2-new.li3"),
      t("bestPractices.introduction.p2-new.li4"),
      t("bestPractices.introduction.p2-new.li6")
    ]

    if (!userInfo["2023 Cancellation Policy Reviewed"]) {
      items.splice(4, 0, t("bestPractices.introduction.p2-new.li5"));
    }
  } else {
    items = [
      t("bestPractices.introduction.p2-new.li2"),
      t("bestPractices.introduction.p2-new.li3"),
      t("bestPractices.introduction.p2-new.li4"),
      t("bestPractices.introduction.p2-new.li6")
    ]

    if (!userInfo["2023 Cancellation Policy Reviewed"]) {
      items.splice(3, 0, t("bestPractices.introduction.p2-new.li5"));
    }
  }

  return (
    <div className="main-container best-practices">
      <span className="page-topic"></span>
      <div style={{ height: 20 }} />
      <BP2024ProgressBar current="introduction" />

      <div style={{ height: 20 }} />
      <h1>Introduction</h1>
      <div className="main-content">
        <p>{t("bestPractices.introduction.p1")}</p>
        <p>{t("bestPractices.introduction.p2.lead")}</p>
        <ol type="1">
          {items.map((item, i) => (
            <li key={i}>
              <p>{item}</p>
            </li>
          ))}
        </ol>
        <p>{t("bestPractices.introduction.p3-new", { mins: isResourceRenewal ? 30 : 20 })}</p>

        <p>
          <Trans
            i18nKey="bestPractices.introduction.p4"
            components={{
              // eslint-disable-next-line
              1: <a href="mailto:annemarie@takingitglobal.org" />,
              // eslint-disable-next-line
              3: <a href="https://calendly.com/annemarie-tig/renewal" target="_blank" rel="noreferrer" />
            }}
          />
        </p>
        <p>{t("bestPractices.introduction.p5")}</p>
        <p>{t("bestPractices.introduction.p6")}</p>
        <p>{t("bestPractices.introduction.p7")}</p>
        <p>({t("bestPractices.introduction.p8")})</p>
        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;{`Continue`}</Button>
        </div>
      </div>
    </div>
  )
}

export default BPIntroduction;
