import React from 'react';
import { Button, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setRequestSessionModal } from '../../redux/modalActions';

const SessionComponent = ({ session, userType, onToggle }) => {
    const { subjectMap, appLocale } = useSelector(state => state.appInfo);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const renderGrade = (grade, i) => {
        if (grade.startsWith('Premium Session')) return null;

        return (
            <div key={i} className="tag-item grade-tag">
                <span>{grade}</span>
            </div>
        )
    }

    const renderSubject = (sub, i) => {
        if (!subjectMap[sub]) return null;

        const subjectName = appLocale === 'fr' ? subjectMap[sub]["Subject (FR)"] : subjectMap[sub]["Subject"];
        if (!subjectName) return null;

        return (
            <div key={i} className="tag-item subject-tag">
                <span>{subjectName}</span>
            </div>
        )
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={true}
                toggle={onToggle}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="modal-title-default" style={{ flex: 1 }}>{session['Session Title']}</h5>
                    <div style={{ paddingLeft: 20 }}>
                        <button
                            aria-label="Link"
                            className="modal-action"
                            type="button"
                            onClick={() => {
                                const tempInput = document.createElement('input');
                                tempInput.value = `https://sessions.connectednorth.org/cn/session/${session.id}`;
                                document.body.appendChild(tempInput);
                                tempInput.select();
                                document.execCommand('copy');
                                document.body.removeChild(tempInput);
                                toast.success("Copied!");
                            }}
                        ><span><i className="fa fa-link" /></span></button>

                        <button
                            aria-label="Close"
                            className="modal-action"
                            data-dismiss="modal"
                            type="button"
                            onClick={onToggle}
                        ><span><i className="fa fa-times" /></span></button>
                    </div>
                </div>
                <div className="modal-body">

                    {
                        session ? (
                            <div className="session-info">
                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                    {
                                        session['Optimized Image'] && session['Optimized Image'].length ? (
                                            <div className="session-image">
                                                <img src={session['Optimized Image'][0].url} alt="" />
                                            </div>
                                        ) : session['Image/Photo'] && session['Image/Photo'].length ? (
                                            <div className="session-image">
                                                <img src={session['Image/Photo'][0].url} alt="" />
                                            </div>
                                        ) : null
                                    }
                                    <div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {
                                            session['Cost'] && userType !== 'Teacher' ? (
                                                <div className="session-info-item">
                                                    <span className="info-title">{t("cost")}: </span>
                                                    <span className="info-content">${session['Cost']}</span>
                                                </div>
                                            ) : null
                                        }

                                        <div className="session-info-item">
                                            <span className="info-title">{t("language")}: </span>
                                            <span className="info-content">{session['Language']}</span>
                                        </div>

                                        {
                                            session['# Booked'] > 0 ? (
                                                <div className="session-info-item">
                                                    <span className="info-title">{t("session-booked")}: </span>
                                                    <span className="info-content">{session['# Booked']}</span>
                                                </div>
                                            ) : null
                                        }

                                        <div className="session-info-item">
                                            <span className="info-title">{t("subjects")}: </span>
                                            <div className="info-content">
                                                {
                                                    (session.Subject && Array.isArray(session.Subject)) ? session.Subject.map((sub, i) =>
                                                        renderSubject(sub, i)
                                                    ) : (null)
                                                }
                                            </div>
                                        </div>

                                        <div className="session-info-item">
                                            <span className="info-title">{t("grades")}: </span>
                                            <div className="info-content">
                                                {
                                                    (session['Grade(s)'] && Array.isArray(session['Grade(s)'])) ? session['Grade(s)'].map((grade, i) =>
                                                        renderGrade(grade, i)
                                                    ) : (null)
                                                }
                                            </div>
                                        </div>

                                        {
                                            session['Length (Minutes)'] ? (
                                                <div className="session-info-item">
                                                    <span className="info-title">{t("length")}: </span>
                                                    <span className="info-content">{session['Length (Minutes)']} min</span>
                                                </div>
                                            ) : (null)
                                        }
                                    </div>
                                </div>

                                {
                                    (session['Resource Sheets'] && Array.isArray(session['Resource Sheets'])) ? (
                                        <div className="session-info-item">
                                            <span className="info-title">{t("resource-sheets")}: </span>
                                            <div className="info-content">
                                                {
                                                    session['Resource Sheets'].map((resource, i) => (
                                                        <a href={resource.url} target="_blank" key={i} rel="noopener noreferrer">
                                                            <div style={{ backgroundColor: 'lightgray', borderRadius: 5, margin: 10 }}>
                                                                <img src={resource?.thumbnails?.large?.url} style={{ width: 50, height: 50, borderRadius: 5 }} alt="" />
                                                            </div>
                                                        </a>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ) : (null)
                                }

                                <div className="session-info-item">
                                    <span className="info-title">{t("description")}: </span>
                                    <div className="info-content">
                                        <span className="info-content">{session['Description']}</span>
                                    </div>
                                </div>

                                {
                                    session["Materials Info"] ? (
                                        <div className="session-info-item">
                                            <span className="info-title">{t("materials-required")}: </span>
                                            <div className="info-content">
                                                <span className="info-content">{session['Materials Info']}</span>
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div >
                        ) : null
                    }
                </div>
                <div className="modal-footer">
                    {(userType === 'Teacher') && (
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => dispatch(setRequestSessionModal({
                                visible: true,
                                providerSession: session,
                                onSubmitted: () => dispatch(setRequestSessionModal({}))
                            }))}
                        >{t("request")}</Button>
                    )}
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onToggle}
                    >{t("close")}</Button>
                </div>
            </Modal>
        </>
    )
}

export default SessionComponent;