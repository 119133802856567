import React, { useEffect, useState } from 'react';
import {
    Modal,
    Button,
    FormGroup,
    Input
} from 'reactstrap';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import airtable from '../../airtables';
import { logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { analytics } from '../../firebase';
import ImageDropzone from '../../components/ImageDropzone';
import uploadFile from '../../libs/aws/uploadfile';

const RateSessionModal = ({ session, onToggle, onDone }) => {
    const [saving, setSaving] = useState(false);
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [students, setStudents] = useState('0');
    const [photos, setPhotos] = useState([]);

    const rates = [1, 2, 3, 4, 5];
    const { t } = useTranslation();
    const { userType } = useSelector(state => state.appInfo);

    useEffect(() => {
        switch (userType) {
            case 'Team':
                setRating(session['Team Rating'] || 0)
                setFeedback(session['Team Feedback'] || '');
                break;
            case 'Teacher':
                setRating(session['Rating'] || 0);
                setFeedback(session['Feedback'] || '');
                break;
            case 'Provider':
                setRating(session['Provider Rating'] || 0);
                setFeedback(session['Provider Feedback'] || '');
                break;
            case 'Presenter':
                setRating(session['Provider Rating'] || 0);
                setFeedback(session['Provider Feedback'] || '');
                break;
            default:
                setRating(0);
                setFeedback('');
                break;
        }

        if (session['Students']) setStudents(session['Students'].toString())
    }, [session, userType])

    const onRate = async () => {
        if (rating === 0) {
            toast.warning("Please rate this session.");
            return;
        }

        try {
            var sts = 0;
            if (!students || parseInt(students) <= 0) sts = 0;
            else sts = parseInt(students);

            setSaving(true);

            var params = {}
            if (userType === 'Team') {
                params['Team Rating'] = rating;
                params['Team Feedback'] = feedback;
                params['Students'] = sts;
            } else if (userType === 'Teacher') {
                params['Rating'] = rating;
                params['Feedback'] = feedback;
                params['Students'] = sts;
            } else if (userType === 'Provider' || userType === 'Presenter') {
                params['Provider Rating'] = rating;
                params['Provider Feedback'] = feedback;
            }

            logEvent(analytics, 'rate_session', {
                userType: userType,
                session: session.id
            })
            const updatedSession = await airtable.sessions.update(session['id'], params);

            if (photos && photos.length) {
                const highlightPhotos = [];
                for (var i = 0; i < photos.length; i++) {
                    const file = photos[i].file;
                    const uRes = await uploadFile(file)
                    highlightPhotos.push({url: uRes});
                }

                await airtable.sessions.update(updatedSession['id'], {
                    "Classroom Photos": [
                        ...(updatedSession["Classroom Photos"] || []),
                        ...highlightPhotos
                    ]
                });
            }

            setSaving(true)
            onDone();
            toast.success("Rated successfully!");
        } catch (error) {
            setSaving(false)
            toast.error(error.toString())
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={true}
            toggle={() => onToggle()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">{t("rate_past_sessions")}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <div>
                    <FormGroup>
                        <label>How do you rate this session?</label>
                        <div style={{ padding: '20px 10px', display: 'flex' }}>
                            {
                                rates.map((item, index) => (
                                    <div style={{ padding: '0 5px', flex: 1 }} key={index}>
                                        <div
                                            onClick={() => setRating(item)}
                                            style={{
                                                height: 32,
                                                background: (rating === item) ? "#FF5D5D" : "#EAEAEB",
                                                borderRadius: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                color: (rating === item) ? "white" : "#1A1824"
                                            }}
                                        >
                                            <span>{item}</span>&nbsp;<i className="fa fa-star" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </FormGroup>

                    {
                        (userType === 'Teacher' || userType === 'Team') && (
                            <FormGroup>
                                <label>How many students participated?</label>
                                <Input
                                    type="number"
                                    className="form-style"
                                    value={students}
                                    onChange={e => setStudents(e.target.value)}
                                />
                            </FormGroup>
                        )
                    }

                    <FormGroup>
                        <label>Is there anything else you'd like us to know?</label>
                        <Input
                            rows="2"
                            type="textarea"
                            className="form-style"
                            value={feedback}
                            onChange={e => setFeedback(e.target.value)}
                        />
                    </FormGroup>

                    {(userType === 'Teacher' || userType === 'Team') && (
                        <FormGroup>
                            <label>Share student work with us</label>
                            <ImageDropzone
                                files={photos}
                                onDrop={(acceptedFiles) => {
                                    setPhotos([
                                        ...photos,
                                        ...acceptedFiles.map(file => ({
                                            file
                                        }))
                                    ])
                                }}
                                onRemove={(no) => {
                                    if (window.confirm("Are you sure you want to delete this file?")) {
                                        const nImgFiles = [...photos];
                                        nImgFiles.splice(no, 1);
                                        setPhotos(nImgFiles);
                                    }
                                }}
                            />
                            <label style={{ color: 'gray' }}>We'd love to see what students created or how they participated in this session. If you're able to upload photos for us that are cleared for sharing (or don't show student faces), you'll receive 1 additional booking Credit!</label>
                        </FormGroup>
                    )}
                </div>
            </div>
            <div className="modal-footer">
                <Button
                    className="ml-auto"
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >CANCEL</Button>
                <Button
                    color="success"
                    type="button"
                    onClick={() => onRate()}
                >{saving ? (<Loading size={14} />) : (null)}SUBMIT RATING</Button>
            </div>
        </Modal>
    )
}

export default RateSessionModal
