import React from 'react';
import sbiImg from '../../../assets/img/sbi.png';
import { Trans, useTranslation } from 'react-i18next';

const FacilitatingSessions = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("policies.facilitating.header")}</h1>
      <div className="main-content">
        <p>{t("policies.facilitating.description")}</p>

        <div className="contents contens-item">
          <div className="content">
            <h5 className="fac-subtitle">{t("policies.facilitating.g1.content1.header")}</h5>

            <span><b>{t("policies.facilitating.g1.content1.sub1.header")}</b></span>
            <ul>
              <li><b>{t("policies.facilitating.g1.content1.sub1.li1.header")}</b>
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content1.sub1.li1.li1")}</li>
                  <li>{t("policies.facilitating.g1.content1.sub1.li1.li2")}</li>
                </ul>
              </li>
              <li><b>{t("policies.facilitating.g1.content1.sub1.li2.header")}</b>
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content1.sub1.li2.li1.content")}
                    <ul className="mb0">
                      <li>{t("policies.facilitating.g1.content1.sub1.li2.li1.li1")}</li>
                    </ul>
                  </li>
                  <li>{t("policies.facilitating.g1.content1.sub1.li2.li2.content")}
                    <ul className="mb0">
                      <li>{t("policies.facilitating.g1.content1.sub1.li2.li2.li1")}</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

            <span><b>{t("policies.facilitating.g1.content1.sub2.header")}</b></span>
            <ul>
              <li><b>{t("policies.facilitating.g1.content1.sub2.li1.header")}</b>
                <ul className="mb0">
                  <li>
                    <Trans
                      i18nKey={"policies.facilitating.g1.content1.sub2.li1.li1"}
                      // eslint-disable-next-line
                      components={{ a: <a href="https://sessions.connectednorth.org/cn/best-practices/introduction" target="_blank" rel="noopener noreferrer" /> }}
                    />
                  </li>
                </ul>
              </li>
              <li><b>{t("policies.facilitating.g1.content1.sub2.li2.header")}</b>
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content1.sub2.li2.li1")}</li>
                  <li>{t("policies.facilitating.g1.content1.sub2.li2.li2")}</li>
                  <li>{t("policies.facilitating.g1.content1.sub2.li2.li3")}</li>
                  <li>{t("policies.facilitating.g1.content1.sub2.li2.li4.content")}
                    <ul className="mb0">
                      <li>{t("policies.facilitating.g1.content1.sub2.li2.li4.li1")}</li>
                      <li>{t("policies.facilitating.g1.content1.sub2.li2.li4.li2")}</li>
                      <li>{t("policies.facilitating.g1.content1.sub2.li2.li4.li3")}</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="content">
            <h5 className="fac-subtitle">{t("policies.facilitating.g1.content2.header")}</h5>

            <span><b>{t("policies.facilitating.g1.content2.sub1.header")}</b></span>
            <ul>
              <li>
                <Trans
                  i18nKey={"policies.facilitating.g1.content2.sub1.li1.content"}
                  components={{ b: <b /> }}
                />
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content2.sub1.li1.li1")}</li>
                  <li>{t("policies.facilitating.g1.content2.sub1.li1.li2")}</li>
                  <li>
                    <Trans
                      i18nKey={"policies.facilitating.g1.content2.sub1.li1.li3"}
                      // eslint-disable-next-line
                      components={{ a: <a href="https://sessions.connectednorth.org/cn/upcoming" target="_blank" rel="noopener noreferrer" /> }}
                    />
                  </li>
                  <li>{t("policies.facilitating.g1.content2.sub1.li1.li4")}</li>
                </ul>
              </li>
              <li>
                <Trans
                  i18nKey={"policies.facilitating.g1.content2.sub1.li2.content"}
                  components={{ b: <b /> }}
                />
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content2.sub1.li2.li1")}</li>
                  <li>{t("policies.facilitating.g1.content2.sub1.li2.li2")}</li>
                </ul>
              </li>
            </ul>

            <span><b>{t("policies.facilitating.g1.content2.sub2.header")}</b></span>
            <ul>
              <li>
                <Trans
                  i18nKey={"policies.facilitating.g1.content2.sub2.li1.content"}
                  components={{ b: <b /> }}
                />
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content2.sub2.li1.li1")}</li>
                  <li>{t("policies.facilitating.g1.content2.sub2.li1.li2")}</li>
                </ul>
              </li>
              <li>
                <Trans
                  i18nKey={"policies.facilitating.g1.content2.sub2.li2"}
                  components={{ b: <b /> }}
                />
              </li>
            </ul>
          </div>

          <div className="content">
            <h5 className="fac-subtitle">{t("policies.facilitating.g1.content3.header")}</h5>

            <span><b>{t("policies.facilitating.g1.content3.sub1.header")}</b></span>
            <ul>
              <li><b>{t("policies.facilitating.g1.content3.sub1.li1.header")}</b>
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content3.sub1.li1.li1")}</li>
                  <li>{t("policies.facilitating.g1.content3.sub1.li1.li2")}</li>
                  <li>{t("policies.facilitating.g1.content3.sub1.li1.li3.content")}
                    <ul className="mb0">
                      <li>{t("policies.facilitating.g1.content3.sub1.li1.li3.li1")}</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>{t("policies.facilitating.g1.content3.sub1.li2")}</li>
            </ul>

            <span><b>{t("policies.facilitating.g1.content3.sub2.header")}</b></span>
            <ul>
              <li><b>{t("policies.facilitating.g1.content3.sub2.li1.header")}</b>
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content3.sub2.li1.li1.content")}
                    <ul className="mb0">
                      <li>{t("policies.facilitating.g1.content3.sub2.li1.li1.li1")}</li>
                      <li>{t("policies.facilitating.g1.content3.sub2.li1.li1.li2")}</li>
                      <li>{t("policies.facilitating.g1.content3.sub2.li1.li1.li3")}</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li><b>{t("policies.facilitating.g1.content3.sub2.li2.header")}</b>
                <ul className="mb0">
                  <li>
                    <Trans
                      i18nKey={t("policies.facilitating.g1.content3.sub2.li2.li1")}
                      // eslint-disable-next-line
                      components={{ a: <a href="https://docs.google.com/document/d/1s6LO2MxTV1kzkajt28tJCUBIGNY1YKlSiVveiDPLoUE/edit" target="_blank" rel="noopener noreferrer" /> }}
                    />
                  </li>
                  <li>{t("policies.facilitating.g1.content3.sub2.li2.li2")}</li>
                  <li>{t("policies.facilitating.g1.content3.sub2.li2.li3")}</li>
                  <li>{t("policies.facilitating.g1.content3.sub2.li2.li4")}</li>
                </ul>
              </li>
              <li><b>{t("policies.facilitating.g1.content3.sub2.li3.header")}</b>
                <ul className="mb0">
                  <li>{t("policies.facilitating.g1.content3.sub2.li3.li1")}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="contents contens-item">
          <h4>{t("policies.facilitating.g2.header")}</h4>
          <p>
            <Trans
              i18nKey={t("policies.facilitating.g2.description1")}
              components={{ b: <b /> }}
            />
          </p>
          <p>{t("policies.facilitating.g2.description2")}</p>

          <div className="content">
            <h5 className="fac-subtitle">{t("policies.facilitating.g2.content1.header")}</h5>

            <span><b>{t("policies.facilitating.g2.content1.sub1.header")}</b></span>
            <ul>
              <li>{t("policies.facilitating.g2.content1.sub1.li1")}</li>
              <li>{t("policies.facilitating.g2.content1.sub1.li2")}</li>
              <li>{t("policies.facilitating.g2.content1.sub1.li3")}</li>
            </ul>

            <span><b>{t("policies.facilitating.g2.content1.sub2.header")}</b></span>
            <ul>
              <li>
                <Trans
                  i18nKey={t("policies.facilitating.g2.content1.sub2.li1")}
                  components={{ b: <b /> }}
                />
              </li>
              <li>{t("policies.facilitating.g2.content1.sub2.li2.content")}
                <ul className="mb0">
                  <li>
                    <Trans
                      i18nKey={t("policies.facilitating.g2.content1.sub2.li2.li1")}
                      components={{ b: <b /> }}
                    />
                  </li>
                  <li>{t("policies.facilitating.g2.content1.sub2.li2.li2")}</li>
                  <li>
                    <Trans
                      i18nKey={t("policies.facilitating.g2.content1.sub2.li2.li3")}
                      components={{ b: <b /> }}
                    />
                  </li>
                </ul>
              </li>
              <li>{t("policies.facilitating.g2.content1.sub2.li3")}</li>
            </ul>

            <p><i>{t("policies.facilitating.g2.content1.final")}</i></p>
          </div>

          <div className="content">
            <h5 className="fac-subtitle">{t("policies.facilitating.g2.content2.header")}</h5>
            <ul>
              <li>{t("policies.facilitating.g2.content2.li1")}</li>
              <li>{t("policies.facilitating.g2.content2.li2")}</li>
              <li>{t("policies.facilitating.g2.content2.li3")}</li>
              <li>{t("policies.facilitating.g2.content2.li4.content")}
                <ul className="mb0">
                  <li>{t("policies.facilitating.g2.content2.li4.li1")}</li>
                  <li>{t("policies.facilitating.g2.content2.li4.li2")}</li>
                  <li>{t("policies.facilitating.g2.content2.li4.li3")}</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="content">
            <h5 className="fac-subtitle">{t("policies.facilitating.g2.content3.header")}</h5>
            <ul>
              <li>{t("policies.facilitating.g2.content3.li1")}</li>
              <li>{t("policies.facilitating.g2.content3.li2")}</li>
              <li>{t("policies.facilitating.g2.content3.li3")}</li>
              <li>{t("policies.facilitating.g2.content3.li4")}</li>
            </ul>
          </div>

          <div className="content">
            <h5 className="fac-subtitle">{t("policies.facilitating.g2.content4.header")}</h5>
            <ul>
              <li>{t("policies.facilitating.g2.content4.li1.content")}
                <ul className="mb0">
                  <li>{t("policies.facilitating.g2.content4.li1.li1")}</li>
                </ul>
              </li>
              <li>
                <Trans
                  i18nKey={t("policies.facilitating.g2.content4.li2")}
                  components={{ b: <b /> }}
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="contents contens-item">
          <h4>{t("policies.facilitating.g3.header")}</h4>

          <p>{t("policies.facilitating.g3.p1")}</p>

          <p className="flate">{t("policies.facilitating.g3.p2")}</p>
          <ul>
            <li><i>{t("policies.facilitating.g3.p2-li1")}</i></li>
            <li><i>{t("policies.facilitating.g3.p2-li2")}</i></li>
            <li><i>{t("policies.facilitating.g3.p2-li3")}</i></li>
          </ul>

          <p>{t("policies.facilitating.g3.p3")}</p>

          <p>{t("policies.facilitating.g3.p4")}</p>

          <p>{t("policies.facilitating.g3.p5")}</p>

          <p>
            
            <Trans
              i18nKey={t("policies.facilitating.g3.p6")}
              components={{
                // eslint-disable-next-line
                a: <a href="https://www.ccl.org/articles/leading-effectively-articles/closing-the-gap-between-intent-vs-impact-sbii/" target="_blank" rel="noopener noreferrer" />,
                // eslint-disable-next-line
                aa: <a href="https://www.canada.ca/en/public-health/services/mental-health-services/mental-health-get-help.html" target="_blank" rel="noopener noreferrer" />
              }}
            />
          </p>

          {/* image */}
          <img src={sbiImg} alt="" />

          <p>{t("policies.facilitating.g3.p7")}</p>

          <p className="flate">{t("policies.facilitating.g3.p8")}</p>
          <ol>
            <li>{t("policies.facilitating.g3.p8-li1")}</li>
            <li>{t("policies.facilitating.g3.p8-li2")}</li>
            <li>{t("policies.facilitating.g3.p8-li3")}</li>
            <li>{t("policies.facilitating.g3.p8-li4")}</li>
            <li>{t("policies.facilitating.g3.p8-li5")}</li>
          </ol>

          <p>{t("policies.facilitating.g3.p9")}</p>
        </div>
      </div>
    </div>
  )
}

export default FacilitatingSessions;
