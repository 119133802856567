import { useEffect, useState } from "react"
import airtable from '../../airtables';
import { useSelector } from "react-redux";

export function useTeamPendingData() {
    const { userInfo, userType } = useSelector(state => state.appInfo);
    const isAdmin = (userType === 'Team' && userInfo["Administrator"]) ? true : false;

    const [requestedSessions, setRequestedSessions] = useState([]);
    const [planningSessions, setplanningSessions] = useState([]);
    const [devSessions, setDevSessions] = useState([]);
    const [wishSessions, setWishSessions] = useState([]);
    const [adminSessions, setAdminSessions] = useState([]);
    const [rLoading, setRLoading] = useState(false);
    const [ipLoading, setIpLoading] = useState(false);
    const [dLoading, setDLoading] = useState(false);
    const [aLoading, setALoading] = useState(false);
    const [wLoading, setWLoading] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState("")
    const [filterDate, setFilterDate] = useState(null);
    const [filterCILC, setFilterCILC] = useState(false);

    const [fresh, setfresh] = useState(0);

    useEffect(() => {
        loadRequestedSessions();
        loadPlanningSessions();
        loadDevelopmentSessions();
        loadWishListSessions();
        if (isAdmin) loadAdminSessions();
    }, [fresh]) // eslint-disable-line react-hooks/exhaustive-deps

    const refresh = () => setfresh(fresh + 1);

    const loadRequestedSessions = async () => {
        setRLoading(true);
        try {
            const sessions = await airtable.sessions.getPendingSessionsForTeam(
                userInfo.id,
                userInfo['Name'],
                'Requested',
                {
                    filterKeyword,
                    filterDate,
                    filterCILC
                }
            );
            setRequestedSessions(sessions);
        } catch (error) { }
        setRLoading(false);
    }

    const loadPlanningSessions = async () => {
        setIpLoading(true);
        try {
            const sessions = await airtable.sessions.getPendingSessionsForTeam(
                userInfo.id,
                userInfo['Name'],
                'In Planning',
                {
                    filterKeyword,
                    filterDate,
                    filterCILC
                }
            );
            setplanningSessions(sessions);
        } catch (error) { }
        setIpLoading(false);
    }

    const loadDevelopmentSessions = async () => {
        setDLoading(true);
        try {
            const sessions = await airtable.sessions.getPendingSessionsForTeam(
                userInfo.id,
                userInfo['Name'],
                'Pending Provider Response',
                {
                    filterKeyword,
                    filterDate,
                    filterCILC
                }
            );
            setDevSessions(sessions);
        } catch (error) { }
        setDLoading(false);
    }

    const loadWishListSessions = async () => {
        setWLoading(true);
        try {
            const sessions = await airtable.sessions.getPendingSessionsForTeam(
                userInfo.id,
                userInfo['Name'],
                'Wish List',
                {
                    filterKeyword,
                    filterDate,
                    filterCILC
                }
            );
            setWishSessions(sessions);
        } catch (error) { }
        setWLoading(false);
    }

    const loadAdminSessions = async () => {
        setALoading(true);
        try {
            const sessions = await airtable.sessions.getPendingSessionsForAdmin(
                {
                    filterKeyword,
                    filterDate,
                    filterCILC
                }
            );
            setAdminSessions(sessions);
        } catch (error) { }
        setALoading(false);
    }

    return {
        requestedSessions,
        setRequestedSessions,
        planningSessions,
        setplanningSessions,
        devSessions,
        setDevSessions,
        wishSessions,
        setWishSessions,
        adminSessions,
        setAdminSessions,
        rLoading,
        ipLoading,
        dLoading,
        aLoading,
        wLoading,
        filterKeyword,
        setFilterKeyword,
        filterDate,
        setFilterDate,
        filterCILC,
        setFilterCILC,
        refresh
    }
}