import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { FormGroup, Input, Label } from "reactstrap";
import { OnboardPageInner } from "../components/OnboardPageInner";
import { StepProgress } from "../components/StepProgress";
import { useCodeOfConduct } from "../hooks/use-code-of-conduct";
import { getNextBackLinks } from "../utils/get-next-back-links";
import { APP_ROOT, ONBOARD_TEACHER_OPTIONAL_TERMS_ENABLED, ONBOARD_TEACHER_ROUTES } from "../routing";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.codeOfConduct`;
const formId = "code-of-conduct-form";

export const CodeOfConduct = ({
	isNewTeacher,
	isIncompleteTeacher,
	stepRoutes = [],
	currentRouteName,
}) => {
	const { t } = useTranslation();
	const { formValues, setFormValues, loading, updateCodeOfConduct } =
		useCodeOfConduct();

	const showNewTeacherFlow = isNewTeacher || isIncompleteTeacher;

	const { currentStep, backLink, nextLink: curLink } = getNextBackLinks(
		stepRoutes,
		currentRouteName,
	);

	const lastLink = showNewTeacherFlow
		? ONBOARD_TEACHER_ROUTES.WELCOME.path
		: APP_ROOT;

	const nextLink = ONBOARD_TEACHER_OPTIONAL_TERMS_ENABLED ?  curLink : lastLink ;
	const onChangeInput = (e) => {
		const { name, checked } = e.target;

		setFormValues({
			...formValues,
			[name]: checked,
		});
	};

	const onSubmitForm = (e) => {
		e.preventDefault();
		updateCodeOfConduct(nextLink, showNewTeacherFlow);
	};

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	});

	return (
		<OnboardPageInner
			formId={formId}
			onSubmit={onSubmitForm}
			heading={
				<Typography variant="h1">
					{t(`${pageKey}.mainHeading`)}
				</Typography>
			}
			actions={
				<StepProgress
					formId={formId}
					backLink={backLink}
					currentStep={currentStep}
					totalSteps={stepRoutes.length}
					nextLabel={`${tKey}.confirmButton`}
					disabled={loading}
				/>
			}
		>
			<Box
				sx={{
					width: "calc(100% + 3rem)",
					transform: "translateX(-1.5rem)",
					marginBottom: "2rem",
					li: {
						margin: "0.25rem 0 0.5rem",
						ol: {
							listStyleType: "lower-alpha",
						},
					},
				}}
			>
				<Typography variant="body1" mb="1rem">
					{t(`${pageKey}.introText`)}
				</Typography>
				<Box component="ol" pl="1.5rem">
					<Typography variant="body1" component="li">
						<Typography
							variant="body1"
							component="strong"
							fontWeight="bold"
						>
							{t(`${pageKey}.respectKindness`)}
						</Typography>
						<Box component="ol" pl="1.5rem">
							<Typography variant="body1" component="li">
								{t(`${pageKey}.respectKindnessA`)}
							</Typography>
							<Typography variant="body1" component="li">
								{t(`${pageKey}.respectKindnessB`)}
							</Typography>
							<Typography variant="body1" component="li">
								{t(`${pageKey}.respectKindnessC`)}
							</Typography>
						</Box>
					</Typography>
					<Typography variant="body1" component="li">
						<Typography
							variant="body1"
							component="strong"
							fontWeight="bold"
						>
							{t(`${pageKey}.preparationPunctuality`)}
						</Typography>
						<Box component="ol" pl="1.5rem">
							<Typography variant="body1" component="li">
								{t(`${pageKey}.preparationPunctualityA`)}
							</Typography>
							<Typography variant="body1" component="li">
								{t(`${pageKey}.preparationPunctualityB`)}
							</Typography>
						</Box>
					</Typography>
					<Typography variant="body1" component="li">
						<Typography
							variant="body1"
							component="strong"
							fontWeight="bold"
						>
							{t(`${pageKey}.participationSupervision`)}
						</Typography>
						<Box component="ol" pl="1.5rem">
							<Typography variant="body1" component="li">
								{t(`${pageKey}.participationSupervisionA`)}
							</Typography>
							<Typography variant="body1" component="li">
								{t(`${pageKey}.participationSupervisionB`)}
							</Typography>
							<Typography variant="body1" component="li">
								{t(`${pageKey}.participationSupervisionC`)}
							</Typography>
							<Typography variant="body1" component="li">
								{t(`${pageKey}.participationSupervisionD`)}
							</Typography>
						</Box>
					</Typography>
					<Typography variant="body1" component="li">
						<Typography
							variant="body1"
							component="strong"
							fontWeight="bold"
						>
							{t(`${pageKey}.followupFeedback`)}
						</Typography>
						<Box component="ol" pl="1.5rem">
							<Typography variant="body1" component="li">
								{t(`${pageKey}.followupFeedbackA`)}
							</Typography>
							<Typography variant="body1" component="li">
								{t(`${pageKey}.followupFeedbackB`)}
							</Typography>
						</Box>
					</Typography>
				</Box>
			</Box>
			<Box className="form-field-wrap" mt="0.5rem">
				<FormGroup>
					<Input
						id="codeOfConduct"
						type="checkbox"
						name="codeOfConduct"
						checked={formValues.codeOfConduct}
						onChange={onChangeInput}
						disabled={loading}
						required
					/>
					<Label htmlFor="codeOfConduct">
						{t(`${pageKey}.confirmLabel`)}
					</Label>
				</FormGroup>
			</Box>
		</OnboardPageInner>
	);
};
