import { Button } from "reactstrap";
import { Box } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export const ArrowButton = ({
	children,
	color = "primary",
	left = false,
	right = false,
	...props
}) => (
	<Button color={color} {...props}>
		<Box
			component="span"
			display="flex"
			alignContent="center"
			justifyContent="space-between"
		>
			{left && (
				<KeyboardArrowLeftIcon style={{ marginLeft: "-0.75rem" }} />
			)}
			{children}
			{right && (
				<KeyboardArrowRightIcon style={{ marginRight: "-0.75rem" }} />
			)}
		</Box>
	</Button>
);
