import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import airtable from '../../../airtables';

export const useSchools = () => {
    const { userType, userInfo } = useSelector(state => state.appInfo);
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        initialize();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const initialize = async () => {
        let sIds = null;
        if (userType === 'Team') {
            if (!userInfo.Administrator) {
                sIds = userInfo["Schools Managed"];
            }
        } else if (userType === 'Teacher') {
            if (userInfo["Cluster Leadership"]) {
                const cluster = await airtable.clusters.select(userInfo["Cluster Leadership"][0]);
                sIds = cluster["Schools"];
            } else {
                sIds = userInfo["School Name"];
            }

            if (!sIds || !sIds.length) {
                setSchools([]);
                setLoading(false)
                return ;
            }
        }

        airtable.schools.getAllSchools(sIds).then(ss => {
            setSchools(ss);
            setLoading(false)
        });
    }

    return {
        loading,
        schools,
        refresh: initialize
    }
}
