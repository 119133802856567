import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true
});

export const getChatGPTResponse = async (query, updateResponse, onDone) => {
  if (!query) return;

  const stream = await openai.chat.completions.create({
    model: 'gpt-4',
    messages: [{ role: 'user', content: query }],
    stream: true,
  });

  let content = "";
  for await (const part of stream) {
    if (part.choices[0]?.delta?.content) {
      content += part.choices[0]?.delta?.content;
      updateResponse(content);
    }
  }

  onDone(content);
}