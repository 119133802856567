import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MapWithAKmlLayer from '../components/MapWithAKmlLayer';

const Screen = () => {
    const { userInfo } = useSelector(state => state.appInfo);
    const { t } = useTranslation();

    useEffect(() => {
        window.document.title = "My stats - Connected North"
    }, [])

    return (
        <div className="main-container">
            <h1>{t("my-stats")} ({userInfo["Unique Providers"]} {t("providers_explored")})</h1>
            <MapWithAKmlLayer recId={userInfo.id} type="teacher_providers" />
            <h2>Your class virtually travelled {new Intl.NumberFormat().format(Math.round(userInfo["Distance (23-24)"]))} kilometers this school year!</h2>
        </div>
    )
}

export default Screen;
