import React, { useEffect, useState } from 'react';
import StepProgress from '../../components/StepProgress';
import { Trans, useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import airtable from '../../airtables';
import { setAppUserInfo } from '../../redux/actions';
import './styles.scss';
import { check2023BestPractices } from '../../utils/bp-utils';

const BPIntroduction = () => {
  const { userInfo, userType, isNewProvider } = useSelector(state => state.appInfo);
  const [saving, setSaving] = useState(false);
  const [isResourceRenewal] = useState((userInfo["Renewal Actions"] || []).some(item => item.includes("resource")));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = "Introduction - Best Practices - Connected North"
  }, [])

  useEffect(() => {
    const redir = check2023BestPractices(userInfo, userType, isNewProvider, "introduction");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType, isNewProvider])

  const updateBestPractices = async () => {
    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, { "23-24 Renewal Started": true });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  const getMunuCount = () => {
    let count = 5;
    if (!isResourceRenewal) count--;
    if (!userInfo["Cost/Session"]) count--;
    return count;
  }

  const steps = [];
  if (isNewProvider && !userInfo["Waive Best Practices"]) {
    steps.push(t("best-practices"));
  } else if (!isNewProvider) {
    steps.push(t("provider-step-progress.survey"));
    steps.push(t("provider-step-progress.reflection"));
  } else {
    steps.push("Introduction");
  }
  steps.push(t("provider-step-progress.code-of-conduct"));
  if (userInfo["Cost/Session"]) {
    steps.push(t("provider-step-progress.cancellation-policy"));
  }
  steps.push(t("provider-step-progress.confirm-session-offerings"));

  return (
    <div className="main-container">
      <span className="page-topic"></span>
      <div style={{ height: 20 }} />
      <StepProgress
        steps={steps}
        activeStep={-1}
        completedSteps={[]}
      />
      <div style={{ height: 20 }} />
      <h1>Introduction</h1>
      <div className="main-content">
        {
          isNewProvider ? (
            <>
              <p>{t("bestPractices.introduction.p1newprovider")}</p>
              <p>{userInfo["Waive Best Practices"] ? t("bestPractices.introduction.p2newproviderwaived") : t("bestPractices.introduction.p2newprovider")}</p>
              {!userInfo["Waive Best Practices"] && <p>{t("bestPractices.introduction.p3newprovider")}</p>}
              <p>
                <Trans i18nKey="bestPractices.introduction.p4">
                  If you have any questions, comments, or concerns about this process, or would like to request support, please contact the Content Provider Engagement Coordinator at <a href="mailto:annemarie@takingitglobal.org">annemarie@takingitglobal.org</a> or book a meeting directly at: <a href="https://calendly.com/annemarie-tig/renewal" target="_blank" rel="noreferrer">calendly.com/annemarie-tig/renewal</a>
                </Trans>
              </p>
              <p>{t("bestPractices.introduction.p5newprovider")}</p>
            </>
          ) : (
            <>
              <p>{t("bestPractices.introduction.p1")}</p>
              <p>{t("bestPractices.introduction.p2.lead")}</p>
              <ol type="1">
                <li><p>{t("bestPractices.introduction.p2.li1")}</p></li>
                {!isResourceRenewal && <li><p>{t("bestPractices.introduction.p2.li2")}</p></li>}
                {isResourceRenewal && <li><p>{t("bestPractices.introduction.p2.li3")}</p></li>}
                <li><p>{t("bestPractices.introduction.p2.li4")}</p></li>
                {userInfo["Cost/Session"] ? <li><p>{t("bestPractices.introduction.p2.li5")}</p></li> : null}
                <li><p>{t("bestPractices.introduction.p2.li6")}</p></li>
              </ol>
              <p>{t("bestPractices.introduction.p3", { count: getMunuCount(), mins: isResourceRenewal ? 30 : 20 })}</p>
              <p>
                <Trans i18nKey="bestPractices.introduction.p4">
                  If you have any questions, comments, or concerns about this process, or would like to request support, please contact the Content Provider Engagement Coordinator at <a href="mailto:annemarie@takingitglobal.org">annemarie@takingitglobal.org</a> or book a meeting directly at: <a href="https://calendly.com/annemarie-tig/renewal" target="_blank" rel="noreferrer">calendly.com/annemarie-tig/renewal</a>
                </Trans>
              </p>
              <p>{t("bestPractices.introduction.p5")}</p>
            </>
          )
        }
        <p>{t("bestPractices.introduction.p6")}</p>
        <p>{t("bestPractices.introduction.p7")}</p>
        <p>({t("bestPractices.introduction.p8")})</p>
        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;Continue</Button>
        </div>
      </div>
    </div>
  )
}

export default BPIntroduction;
