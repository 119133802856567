import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";

import BookingsIcon from "../../assets/img/icons/menu/calendar.svg";
import BarChartIcon from "../../assets/img/icons/menu/bar-chart.svg";
import SchoolIcon from "../../assets/img/icons/menu/school.svg";
import PastIcon from "../../assets/img/icons/menu/past.svg";
import SearchIcon from "../../assets/img/icons/menu/search.svg";
import RequestIcon from "../../assets/img/icons/menu/lightbulb_hourglass.svg";
import CurriculumIcon from "../../assets/img/icons/menu/search_curriculum.svg";
import CollectionsIcon from "../../assets/img/icons/menu/collections.svg";
import FavouritesIcon from "../../assets/img/icons/menu/favourites.svg";
import EventsIcon from "../../assets/img/icons/menu/users.svg";
import ContactIcon from "../../assets/img/icons/menu/contacts.svg";
import InfoIcon from "../../assets/img/icons/menu/user.svg";
import SessionsIcon from "../../assets/img/icons/menu/lightbulb_calendar.svg";
import PoliciesIcon from "../../assets/img/icons/menu/papers.svg";
import PracticesIcon from "../../assets/img/icons/menu/award.svg";
import ResourcesIcon from "../../assets/img/icons/menu/resources.svg";
import TourIcon from "../../assets/img/icons/menu/platform_tour.svg";

export const useSidebar = () => {
  const { userType, userInfo, isSessionHost } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const [navs, setNavs] = useState([]);
  const { startTour, show } = useIntercom();

  useEffect(() => {
    setNavs(getNavigations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, userType])

  const getNavigations = () => {
    switch (userType) {
      case "Teacher":
        return getTeacherNavs();
      case "Team":
        return getTeamNavs();
      case "Provider":
        return getProviderNavs();
      case "Presenter":
        return getPresenterNavs();
      default:
        return [];
    }
  }

  const getTeacherNavs = () => {
    const result = [
      { to: "/dashboard", label: t("dashboard"), iconImg: BarChartIcon },
      { to: "/upcoming", label: t("my-sessions"), iconImg: BookingsIcon },
    ];

    if (userInfo["School Leader?"] || userInfo["District Leader?"]) {
      let title = ""
      if (
        (
          Array.isArray(userInfo["Schools Managed"]) &&
          userInfo["Schools Managed"].length > 0
        ) || (
          Array.isArray(userInfo["School Name"]) &&
          userInfo["School Name"].length > 1
        ) || userInfo["District Leader?"]
      ) {
        title = t("principal.heading-multi");
      } else {
        title = t("principal.heading");
      }
      result.push({ to: "/principal", label: title, iconImg: SchoolIcon })
    }

    if (userInfo["Limit Booking to Collection"] && userInfo["Limit Booking to Collection"].length) {
      result.push({
        to: "/collection/" + userInfo["Limit Booking to Collection"][0],
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: PastIcon
      })
    } else {
      result.push({
        to: "/search",
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: SearchIcon
      })
    }

    result.push({ to: "/pending", label: t("requests"), tooltip: t("pending_session_requests"), iconImg: RequestIcon });
    if (userInfo["Preferred Language"] !== 'French') {
      result.push({ to: "/browser/curriculum", label: t("explore-by-curriculum"), iconImg: CurriculumIcon });
      result.push({ to: "/collection", label: t("explore-by-collection"), iconImg: CollectionsIcon });
    }
    result.push({ to: "/favorites", label: t("menu-favourites"), tooltip: t("my-favourites"), iconImg: FavouritesIcon });
    result.push({ to: "/events", label: t("events"), iconImg: EventsIcon });

    if (userInfo["Email"] === "ron.cameron@gapps.rrdsb.com") {
      result.push({ to: "/mystats", label: t("menu-stats"), tooltip: t("my-stats"), icon: "fa fa-map" });
    }

    result.push({
      to: "/mysettings",
      label: "My Settings",
      svg: "setting",
    })

    result.push({
      label: "Platform Tour",
      iconImg: TourIcon,
      onClick: () => startTour(458667)
    })

    result.push({
      label: "Help and FAQs",
      svg: "faqs",
      onClick: () => show()
    })

    return result;
  }

  const getTeamNavs = () => {
    if (isSessionHost && !userInfo["Host Training Approved"]) {
      return [{
        to: "/session-host/best-practices/introduction",
        label: "Host Training",
        iconImg: FavouritesIcon
      }];
    }

    const result = [
      { to: "/upcoming", label: t("upcoming"), tooltip: t("upcoming_sessions"), iconImg: BookingsIcon },
      { to: "/rate", label: t("manage-past"), tooltip: t("manage-past-sessions"), iconImg: PastIcon },
    ];
    if (!isSessionHost) {
      result.push({
        to: "/search",
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: SearchIcon
      })
    }

    if (userInfo["Schools Managed"] && userInfo["Schools Managed"].length > 0) {
      const lblId = (
        (Array.isArray(userInfo["Schools Managed"]) &&
          userInfo["Schools Managed"].length > 0
        ) || (
          Array.isArray(userInfo["School Name"]) &&
          userInfo["School Name"].length > 1
        ) || userInfo["District Leader?"]
      ) ? "principal.heading-multi" : "principal.heading";

      result.push({ to: "/principal", label: t(lblId), iconImg: SchoolIcon })
    }

    if (isSessionHost) {
      result.push({
        to: "/session-host/best-practices/introduction",
        label: "Host Training",
        iconImg: FavouritesIcon
      });
      result.push({
        to: "/available-sessions",
        label: "Available Sessions",
        iconImg: EventsIcon
      })
    }

    result.push({
      to: "/pending",
      label: t("requests"),
      tooltip: t("pending_session_requests"),
      iconImg: RequestIcon
    });

    if (!isSessionHost) {
      result.push({
        to: "/browser/curriculum",
        label: t("explore-by-curriculum"),
        iconImg: CurriculumIcon
      });
      result.push({
        to: "/collection",
        label: t("explore-by-collection"),
        iconImg: CollectionsIcon
      });
      result.push({
        to: "/contact",
        label: t("contacts"),
        iconImg: ContactIcon
      });
      result.push({
        to: "/events",
        label: t("events"),
        iconImg: EventsIcon
      });
    }
    return result;
  }

  const getProviderNavs = () => {
    const result = [
      { to: "/upcoming", label: t("my-bookings"), tooltip: t("upcoming_sessions"), iconImg: BookingsIcon },
      { to: "/pending", label: t("requests"), tooltip: t("pending_session_requests"), iconImg: RequestIcon },
      { to: "/mysessions", label: t("my-sessions"), tooltip: t("search-my-sessions"), iconImg: SessionsIcon },
      { to: "/policies", label: "Policies", iconImg: PoliciesIcon },
      { to: "/best-practices/introduction", label: t("best-practices"), iconImg: PracticesIcon },
      { to: "/resources", label: t("resources-title"), iconImg: ResourcesIcon },
      { to: "/upcoming?product_tour_id=466709", label: t("platform-tour"), iconImg: TourIcon },
      { to: "/myinfo", label: t("my-info"), tooltip: t("view-edit-contact-information"), iconImg: InfoIcon },
    ]

    return result;
  }

  const getPresenterNavs = () => {
    const result = [
      { to: "/upcoming", label: t("my-bookings"), tooltip: t("upcoming_sessions"), iconImg: BookingsIcon },
      { to: "/best-practices/introduction", label: t("best-practices"), iconImg: PracticesIcon },
      { to: "/myinfo", label: t("my-info"), tooltip: t("view-edit-contact-information"), iconImg: InfoIcon },
      { to: "/resources", label: t("resources-title"), iconImg: ResourcesIcon },
    ]

    return result;
  }

  return {
    navs
  }
}