import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { check2023BestPractices } from '../../utils/bp-utils';
import { toast } from 'react-toastify';
import airtable from '../../airtables';
import { setAppUserInfo } from '../../redux/actions';
import Loading from '../../components/Loading';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import StepProgress from '../../components/StepProgress';
import { Trans, useTranslation } from 'react-i18next';

const CancellationReschedulePolicy = () => {
  const { userInfo, userType, isNewProvider } = useSelector(state => state.appInfo);
  const [saving, setSaving] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [waived, setWaived] = useState(false);
  const [feedback, setFeedback] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = "Cancellation Policy - Policies - Connected North"
  }, [])

  useEffect(() => {
    const redir = check2023BestPractices(userInfo, userType, isNewProvider, "cancellationPolicy");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType, isNewProvider])

  const updateBestPractices = async () => {
    if (!reviewed) return;

    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "2023 Cancellation Exemption Request": (feedback || ""),
        "2023 Cancellation Policy Reviewed": true,
        "2023 Cancellation Fee Waived": waived
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setSaving(false);
    }
  }

  const steps = [];
  if (isNewProvider && !userInfo["Waive Best Practices"]) {
    steps.push(t("best-practices"));
  } else if (!isNewProvider) {
    steps.push(t("provider-step-progress.survey"));
    steps.push(t("provider-step-progress.reflection"));
  } else {
    steps.push("Introduction");
  }
  steps.push(t("provider-step-progress.code-of-conduct"));
  if (userInfo["Cost/Session"]) {
    steps.push(t("provider-step-progress.cancellation-policy"));
  }
  steps.push(t("provider-step-progress.confirm-session-offerings"));

  return (
    <div className="main-container">
      <StepProgress
        steps={steps}
        activeStep={isNewProvider ? 2 : 3}
        completedSteps={isNewProvider ? [0, 1] : [0, 1, 2]}
      />
      <div style={{ height: 20 }} />
      <h1>{t("bestPractices.cr-policy.title")}</h1>
      <div className="main-content">
        <p>{t("bestPractices.cr-policy.p1")}</p>
        <p>{t("bestPractices.cr-policy.p2.lead")}</p>

        <b>A. {t("bestPractices.cr-policy.p2.a.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.a.li1")}</p></li>
        </ul>

        <b>B. {t("bestPractices.cr-policy.p2.b.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.b.li1")}</p></li>
          <li><p>{t("bestPractices.cr-policy.p2.b.li2")}</p></li>
        </ul>

        <b>C. {t("bestPractices.cr-policy.p2.c.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.c.li1")}</p></li>
        </ul>

        <b>D. {t("bestPractices.cr-policy.p2.d.title")}</b>
        <ul>
          <li><p>{t("bestPractices.cr-policy.p2.d.li1")}</p></li>
        </ul>

        <FormGroup check>
          <Input type='checkbox' id="check1" onChange={(e) => setReviewed(e.target.checked)} />
          <Label check for="check1" style={{ fontSize: 16 }}><strong>{t("bestPractices.cr-policy.check.first")}</strong></Label>
        </FormGroup>

        <br />
        <FormGroup check>
          <Input type='checkbox' id="check2" onChange={(e) => setWaived(e.target.checked)} />
          <Label check for="check2" style={{ fontSize: 16 }}><strong>{t("bestPractices.cr-policy.check.second")}</strong></Label>
        </FormGroup>

        <br />
        <b>{t("bestPractices.cr-policy.p3-title")}</b>
        <p className="no-margin">
          <Trans i18nKey={"bestPractices.cr-policy.p3"}>
            We know that some providers may have prepared perishable supplies or materials, or incur financial hardship due to not being able to complete other paid work or tasks due to a cancellation beyond our control. You may <a href="https://calendly.com/annemarie-tig/renewal" target="_blank" rel="noreferrer">schedule a meeting to discuss</a>, or please share any feedback or special circumstances that you would ask be considered:
          </Trans>
        </p>
        <Input
          type="textarea"
          rows={4}
          disabled={saving}
          onChange={e => setFeedback(e.target.value)}
          defaultValue={feedback}
        />

        <div align="center" style={{ marginTop: 20, marginBottom: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={saving || !reviewed}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;Submit and Continue</Button>
        </div>
      </div>
    </div>
  )
}

export default CancellationReschedulePolicy;
