import React, { useEffect, useState } from "react";
import {
	Button,
	FormGroup,
	Input,
	InputGroupText,
	InputGroup,
} from "reactstrap";
import * as EmailValidator from "email-validator";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendVerifySms, verifySmsCode } from "../../libs/apis";
import {
	setAppIsSessionHost,
	setAppUserInfo,
	setAppUserType,
} from "../../redux/actions";
import { useTranslation } from "react-i18next";
import { analytics } from "../../firebase";
import airtable from "../../airtables";
import { setSession, getSession } from "../../utils/session";
import {
	CONNECTEDNORTH_SIGNINFO,
	CONNECTEDNORTH_SIGNTYPE,
	CONNECTEDNORTH_VERIFIED_PHONENUMBER,
} from "../../config";
import Loading from "../../components/Loading.js";
import { useQuery } from "../../useQuery.js";
import { Typography } from "@mui/material";
import { ONBOARD_TEACHER_ROOT } from "../OnboardTeacher/routing.js";
import { TEACHER_FIELDS } from "../OnboardTeacher/constants/teacher-fields.js";
import { getIsTeacherOnboardEnabled } from "../OnboardTeacher/utils/get-is-teacher-onboard-enabled.js";

import "./styles.scss";

const isTeacherOnboardEnabled = getIsTeacherOnboardEnabled();

const Login = () => {
	const [height, setHeight] = useState(0);
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [signing, setSigning] = useState(false);
	const dispatch = useDispatch();
	let query = useQuery();
	const url = query.get("url");
	const { t } = useTranslation();
	const history = useHistory();

	const [appLoading, setAppLoading] = useState(true);

	const [showNewTeacherPrompt, setShowNewTeacherPrompt] = useState(false);

	const resizeListener = () => {
		setHeight(window.innerHeight);
		setAppLoading(false);
	};

	useEffect(() => {
		window.document.title = "Log in - Connected North";
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;

		setTimeout(() => resizeListener());
		window.addEventListener("resize", resizeListener);
		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	const logSignin = async (user, userType) => {
		await Promise.all([
			setUserId(analytics, user),
			setUserProperties(analytics, "user_type", userType),
			logEvent(analytics, "login", { email: user, userType }),
		]);
	};

	const onLogin = async () => {
		setShowNewTeacherPrompt(false);
		setError("");

		if (email === "") {
			setError(t("type-email-address"));
			return;
		}

		if (!EmailValidator.validate(email)) {
			setError(t("invalid-email"));
			return;
		}

		setSigning(true);
		try {
			const tt = await airtable.teachers.getTeacherByEmail(email);
			if (tt) {
				const teacher = await airtable.teachers.select(tt.id);
				delete teacher["24 Report Message"];

				let params = {};
				// Check if hostname is sessions.connectednorth.org
				if (
					window.location.hostname === "sessions.connectednorth.org"
				) {
					teacher["Platform Logins"] = teacher["Platform Logins"]
						? teacher["Platform Logins"] + 1
						: 1;
					params["Platform Logins"] = teacher["Platform Logins"];
					if (!teacher["Status"]) params.Status = "Active";

					// Update Airtable only when on the specified hostname
					airtable.teachers.update(teacher.id, params);
				}

				await logSignin(teacher["Email"], "Teacher");
				await setSession(CONNECTEDNORTH_SIGNINFO, teacher);
				await setSession(CONNECTEDNORTH_SIGNTYPE, "Teacher");

				dispatch(setAppUserInfo(teacher));
				dispatch(setAppUserType("Teacher"));

				setSigning(false);

				// If teacher hasn't updated settings, redirect them to the onboard flow
				if (
					isTeacherOnboardEnabled &&
					!teacher[TEACHER_FIELDS.COMPLETED]
				) {
					history.push(ONBOARD_TEACHER_ROOT);
					return;
				}

				window.location.href = url ? url : "/cn";
				return;
			}

			const te = await airtable.teams.getTeamByEmail(email);
			if (te) {
				const team = await airtable.teams.select(te.id);
				const verifiedPhonenumber = await getSession(
					CONNECTEDNORTH_VERIFIED_PHONENUMBER,
				);
				if (
					verifiedPhonenumber &&
					verifiedPhonenumber === team["Phone"]
				) {
					await logSignin(team["Email"], "Team");
					await setSession(CONNECTEDNORTH_SIGNINFO, team);
					await setSession(CONNECTEDNORTH_SIGNTYPE, "Team");

					dispatch(setAppUserInfo(team));
					dispatch(setAppUserType("Team"));
					dispatch(
						setAppIsSessionHost(team["Status"] === "Session Host"),
					);

					setSigning(false);
					window.location.href = url ? url : "/cn";
				} else {
					await sendVerifySms(team["Phone"]);
					var smsCode = window.prompt(t("enter-sms-for-identity"));
					try {
						const res = await verifySmsCode(team["Phone"], smsCode);
						if (res.valid) {
							team["Platform Logins"] = team["Platform Logins"]
								? team["Platform Logins"] + 1
								: 1;
							airtable.teams.update(team.id, {
								"Platform Logins": team["Platform Logins"],
							});
							await logSignin(team["Email"], "Team");
							await setSession(
								CONNECTEDNORTH_VERIFIED_PHONENUMBER,
								team["Phone"],
							);
							await setSession(CONNECTEDNORTH_SIGNINFO, team);
							await setSession(CONNECTEDNORTH_SIGNTYPE, "Team");

							dispatch(setAppUserInfo(team));
							dispatch(setAppUserType("Team"));
							dispatch(
								setAppIsSessionHost(
									team["Status"] === "Session Host",
								),
							);

							setSigning(false);
							window.location.href = url ? url : "/cn";
						} else {
							setError(t("invalid-code"));
							setSigning(false);
						}
					} catch (error) {
						setError("Sms verification was failed.");
						setSigning(false);
					}
				}
				return;
			}

			const prov = await airtable.providers.getProviderByEmail(email);
			if (prov) {
				let provider = await airtable.providers.select(prov.id);
				if (
					window.location.hostname === "sessions.connectednorth.org"
				) {
					provider["Platform Logins"] = provider["Platform Logins"]
						? provider["Platform Logins"] + 1
						: 1;
					airtable.providers.update(provider.id, {
						"Platform Logins": provider["Platform Logins"],
					});
				}
				provider = await airtable.providers.select(provider.id);
				await logSignin(provider["Email"], "Provider");
				await setSession(CONNECTEDNORTH_SIGNINFO, provider);
				await setSession(CONNECTEDNORTH_SIGNTYPE, "Provider");

				dispatch(setAppUserInfo(provider));
				dispatch(setAppUserType("Provider"));

				setSigning(false);
				window.location.href = url ? url : "/cn";
				return;
			}

			let pres = await airtable.presenters.getPresenterByEmail(email);
			if (pres) {
				let presenter = await airtable.presenters.select(pres.id);
				if (window.location.hostname === "sessions.connectednorth.org") {
					presenter["Platform Logins"] = presenter["Platform Logins"]
						? presenter["Platform Logins"] + 1
						: 1;
					airtable.presenters.update(presenter.id, {
						"Platform Logins": presenter["Platform Logins"],
					});
				}
				presenter = await airtable.presenters.select(presenter.id);

				await logSignin(presenter["Email"], "Presenter");
				await setSession(CONNECTEDNORTH_SIGNINFO, presenter);
				await setSession(CONNECTEDNORTH_SIGNTYPE, "Presenter");

				dispatch(setAppUserInfo(presenter));
				dispatch(setAppUserType("Presenter"));

				setSigning(false);
				window.location.href = url ? url : "/cn";
			}

			let dms = email.split("@");
			let provider = await airtable.providers.getProviderByDomain(dms[1]);
			if (provider) {
				setSigning(false);
				window.location.href = `/register-presenter/${dms[0]}/${provider.id}`;
				return;
			}

			const cluster = await airtable.clusters.selectClusterByDomain(
				dms[1],
			);
			if (cluster) {
				setSigning(false);
				if (isTeacherOnboardEnabled) {
					history.push(
						`${ONBOARD_TEACHER_ROOT}?email=${encodeURIComponent(
							email,
						)}&cluster=${cluster.id}`,
					);
					return;
				} else {
					window.location.href = `/register-teacher/${dms[0]}/${cluster.id}`;
					return;
				}
			}

			setError(t("no-exist-user-with-email"));
			setSigning(false);

			// If we want to enable the new teacher prompt, this should be set to true here.
			setShowNewTeacherPrompt(false);
		} catch (error) {
			setError("Unknown Issue");
			setSigning(false);
		}
	};

	if (appLoading)
		return (
			<div
				style={{
					position: "fixed",
					inset: "0 0 0 0",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Loading size={64} />
			</div>
		);

	return (
		<main className="login-screen">
			<section className="continer" style={{ height: height }}>
				<div className="login-container">
					<div className="email-input">
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupText>
									<i className="ni ni-email-83" />
								</InputGroupText>
								<Input
									bsSize="lg"
									placeholder={t("email")}
									type="email"
									defaultValue={email}
									onChange={(e) => setEmail(e.target.value)}
									onKeyDown={(event) => {
										if (event.keyCode === 13) onLogin();
									}}
								/>
							</InputGroup>
						</FormGroup>
					</div>
					<div className="error">
						{error ? <span>{error}</span> : null}
					</div>

					{isTeacherOnboardEnabled && showNewTeacherPrompt ? (
						<Typography
							variant="body1"
							textAlign="center"
							mt="0.5rem"
							mb="1rem"
						>
							<Typography
								variant="body1"
								component="span"
								display="block"
							>
								{t("teacherOnboard.loginPromptText")}
							</Typography>
							<Typography
								component={Link}
								to={`${ONBOARD_TEACHER_ROOT}?email=${email}`}
								fontWeight="bold"
							>
								{t("teacherOnboard.loginPromptLink")}
							</Typography>
						</Typography>
					) : null}

					<div className="action">
						<Button
							color="primary"
							type="button"
							onClick={() => onLogin()}
							disabled={signing}
							size="lg"
						>
							{signing ? <Loading size={14} /> : null}
							{t("sign_in")}
						</Button>
					</div>
				</div>
			</section>
		</main>
	);
};

export default Login;
