import React from "react";
import Slider from "rc-slider";

const steps = [0, 5, 10, 15, 20, 30, 60];

const StepSlider = ({ onChange, value }) => {
	const maxIndex = steps.length - 1;

	// Find the closest index for the given value
	const findClosestIndex = (val) => {
		return steps.reduce((prev, curr, index) => 
			Math.abs(curr - val) < Math.abs(steps[prev] - val) ? index : prev
		, 0);
	};

	// Map the index back to the actual value
	const handleChange = (index) => {
		onChange(steps[index]);
	};

	return (
		<Slider
			min={0}
			max={maxIndex}
			step={1}
			dots={true}
			onChange={handleChange}
			marks={sliderMarksLabels(steps)}
			value={findClosestIndex(value)}
			trackStyle={{
				backgroundColor: "#0e6ba8",
			}}
			handleStyle={{
				borderColor: "#0e6ba8",
				backgroundColor: "#0e6ba8",
			}}
			activeDotStyle={{
				backgroundColor: "#0e6ba8",
				border: "#0e6ba8",
			}}
		/>
	);
};

const sliderMarksLabels = (steps) => {
	const dict = {};
	steps.forEach((step, index) => {
		dict[index] = <div style={{ color: "black" }}>{step}</div>;
	});
	return dict;
};

export default StepSlider;
