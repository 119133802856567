import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../components/LoadingBar'
import airtable from '../../airtables';
import { setMyFavorites } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import SessionInfo from '../../components/SessionInfo';

const MyFavorites = () => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const { subjectMap, userInfo } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getSubjectName = (subjects) => {
    if (Object.keys(subjectMap).length === 0) return "";

    if (!subjects || subjects.length === 0) return "No subject";

    return subjects.reduce((result, subId, index) => {
      if (index !== 0) result += ", ";
      result += subjectMap[subId]['Subject'];
      return result;
    }, "")
  }

  useEffect(() => {
    refresh();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = async () => {
    window.document.title = "Favourites - Connected North";
    setLoading(true);
    const teacherInfo = await airtable.teachers.select(userInfo.id);
    const favorites = teacherInfo['Favourites'] ? teacherInfo['Favourites'] : [];

    dispatch(setMyFavorites(favorites));
    const sesslist = (await airtable.providerSessions.listByIds(
      favorites,
      [{ field: "Session Title", direction: "asc" }]
    )).map(session => ({
      ...session,
      SubjectText: getSubjectName(session['Subject'])
    }));

    sesslist.sort((a, b) => a["SubjectText"].localeCompare(b["SubjectText"]))
    setSessions([...sesslist]);
    setLoading(false);
  }

  return (
    <div style={{ marginTop: 20, minHeight: 500 }}>
      {loading && <LoadingBar />}
      <div>
        {
          (sessions && sessions.length) ? sessions.map((session, i) => (
            <div key={i} className="">
              <SessionInfo
                displayInfos={[
                  "Provider Name",
                  "request-session",
                  "Primary Subject",
                  "Primary Subject Action",
                  "Image/Photo",
                  "Optimized Image",
                  "Grade(s)",
                  "Favorite"
                ]}
                actionMenu={["copy-provider-session-link"]}
                session={session}
                sessionDetailLink={session.id}
              />
            </div>
          )) : (
            <div className="nolist-placeholder" align="center">
              <span>{t("no-favorites")}</span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default MyFavorites;