import React, { Fragment, useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import {
    Button,
    Modal,
    FormGroup,
    InputGroupText,
    InputGroup,
    Input,
    Form
} from 'reactstrap'
import { toast } from 'react-toastify';
import ReactDatetime from 'react-datetime';
import airtable from '../../airtables';
import { getDateTimeString } from '../../utils/time';
import Select from 'react-select';
// import Axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const sessionTypes = [
    'Indigenous Role Model/Content Provider',
    'Virtual Experts & Field Trips',
    'Classroom Exchange',
    'Teacher PD',
    'Co-Planning with Teachers & Experts',
    'Technical Support',
    'Other',
    'Text',
    'Premium Session'
];
const connectionTypes = [
    "Our Zoom Only",
    "Our Zoom & TMS/CMM",
    "WebEx Only",
    "TMS/CMM Only",
    "Provider Zoom Only",
    "Provider Zoom & TMS/CMM"
]
const zoomAccounts = [
    "tinisha@takingitglobal.org",
    "gjoahaven@takingitglobal.org",
    "windigo@takingitglobal.org",
    "waukomaun@takingitglobal.org",
    "vvs@takingitglobal.org",
    "tania@takingitglobal.org",
    "stelias@takingitglobal.org",
    "smps@takingitglobal.org",
    "serei@takingitglobal.org",
    "serei+zoom2@takingitglobal.org",
    "rlmps@takingitglobal.org",
    "rldhs@takingitglobal.org",
    "rebecca@takingitglobal.org",
    "msolomon@takingitglobal.org",
    "migwin@takingitglobal.org",
    "michelle@takingitglobal.org",
    "michelle+zoom2@takingitglobal.org",
    "mesc@takingitglobal.org",
    "mali@takingitglobal.org",
    "nanook@takingitglobal.org",
    "nestor@takingitglobal.org",
    "magdalena@takingitglobal.org",
    "lbs@takingitglobal.org",
    "kyle@takingitglobal.org",
    "kpdsb4@takingitglobal.org",
    "kpdsb3@takingitglobal.org",
    "kpdsb2@takingitglobal.org",
    "kpdsb1@takingitglobal.org",
    "kimk@takingitglobal.org",
    "kimd@takingitglobal.org",
    "kerc@takingitglobal.org",
    "kayla@takingitglobal.org",
    "katie@takingitglobal.org",
    "jenniferm@takingitglobal.org",
    "jayson@takingitglobal.org",
    "fireside@takingitglobal.org",
    "efps@takingitglobal.org",
    "dhs@takingitglobal.org",
    "cps@takingitglobal.org",
    "connectednorth@takingitglobal.org",
    "bsherry@takingitglobal.org",
    "bbss@takingitglobal.org",
    "annemarie@takingitglobal.org",
    "mfurdyk@takingitglobal.org",
    "jason@takingitglobal.org",
    "grandrapids@takingitglobal.org",
    "serena@takingitglobal.org",
    "alana@takingitglobal.org",
    "lily@takingitglobal.org",
    "sara@takingitglobal.org",
    "ips@takingitglobal.org",
    "arviligruaq@takingitglobal.org",
    "sanikiluaq@takingitglobal.org",
    "quluaq@takingitglobal.org",
    "nnoc@takingitglobal.org",
    "aqsarniit@takingitglobal.org",
    "jwwalker@takingitglobal.org",
    "igloolik@takingitglobal.org",
    "mrsrob@takingitglobal.org",
    "swestover@takingitglobal.org",
    "pamkin@takingitglobal.org",
    "dvg@takingitglobal.org"
].sort();
const sendMeetingRequestTypes = ['All', 'Content Provider', 'Only Me', 'Teacher', 'Provider and Me'];

const Booking = ({ session, showModal, onDone, onToggle }) => {
    const { totalSubjects } = useSelector(state => state.appInfo);
    const providerId = session['Session Title'][0];
    const sessionId = session.id;
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState(false);
    const [startDate, setStartDate] = useState(new Date(session['Session Start Date/Time']));
    const [sessionType, setSessionType] = useState('');
    const [sendMeetingInvite, setSendMeetingInvite] = useState('All');
    const [subjectCurriculum, setSubjectCurriculum] = useState([]);
    const [connectionType, setConnectionType] = useState("");
    const [sessionHost, setSessionHost] = useState();
    const [hosts, setHosts] = useState([]);
    const [zoomAccount, setZoomAccount] = useState(session["Zoom Account"]);
    const [bridgeAddr, setBridgeAddr] = useState(session['Bridge Address / SIP URI']);
    const [webex, setWebex] = useState(session["WebEx/Zoom Link"])
    const [errors, setErrors] = useState({});

    const { t } = useTranslation();
    const alternativeDate = session['Alternative Date/Time'] ? new Date(session['Alternative Date/Time']) : 'none'

    useEffect(() => {
        setConnectionType(session["Connection Type"] || "Our Zoom Only");
        if (session["Indigenous?"]) {
            setSessionType("Indigenous Role Model/Content Provider");
        }

        airtable.teams.getAllTeams().then(ts => setHosts(ts));
    }, [session])

    useEffect(() => {
        (async () => {
            var subs = [];

            if (providerId) {
                const provider = await airtable.providers.select(providerId);
                if (provider) {
                    provider['Subject'].forEach(subjectId => {
                        for (var i = 0; i < totalSubjects.length; i++) {
                            if (subjectId === totalSubjects[i].id) {
                                subs.push({ value: subjectId, label: totalSubjects[i].Subject })
                            }
                        }
                    });
                    setSubjectCurriculum(subs);
                    setLoading(false)
                }
            }
        })();
    }, [providerId, totalSubjects]);

    const onBook = () => {
        let errList = {}
        if (!startDate) errList.startDate = "Session Date & Time is required.";
        else if (!(startDate instanceof Date)) errList.startDate = "Invalid Session Date & Time";

        if (Object.keys(errList).length) {
            setErrors(errList);
            return;
        }

        setBooking(true);
        setErrors({})
        var param = {
            'Session Start Date/Time': startDate,
            'Connection Type': connectionType,
            'Type of Session': sessionType,
            'Send Meeting Invite to:': sendMeetingInvite,
            'Zoom Account': zoomAccount,
            'Subject/Curriculum': subjectCurriculum.map(item => (item.value)),
            'Status': 'Booked',
        }

        if (sessionHost && sessionHost.value) {
            param["Session Host(s)"] = [sessionHost.value];
        }

        if (connectionType === 'Our Zoom Only' || connectionType === 'Our Zoom & TMS/CMM') {
            param["Zoom Account"] = zoomAccount;
        } else {
            param["Bridge Address / SIP URI"] = bridgeAddr || "";
            param["WebEx/Zoom Link"] = webex || "";
        }

        airtable.sessions.update(sessionId, param).then(async res => {
            // await Axios.post(
            //     "https://hooks.zapier.com/hooks/catch/89715/omzbz3f/",
            //     { record_id: sessionId },
            //     { headers: { 'Content-Type': 'text/plain' } }
            // )
            setBooking(false);
            toast.success("Booked successfully!")
            onDone();
        }).catch(error => {
            setBooking(false);
            toast.error(error.toString())
        })
    }

    const renderZoomInfo = () => {
        if (!connectionType || connectionType) return null; // Temporarily disable the Zoom Account dropdown since all meetings will default to CN Zoom this year

        if (connectionType === 'Our Zoom Only' || connectionType === 'Our Zoom & TMS/CMM') {
            return (
                <FormGroup>
                    <label>Zoom Account</label>

                    <Select
                        className="form-style"
                        value={{ value: zoomAccount, label: zoomAccount }}
                        placeholder="Zoom Account"
                        onChange={(selectedOption) => setZoomAccount(selectedOption.value)}
                        options={zoomAccounts.map(type => ({ value: type, label: type }))}
                    />
                </FormGroup>
            )
        }

        return (
            <Fragment>
                <FormGroup>
                    <label>Bridge Address / SIP URI</label>
                    <Input
                        className="form-style"
                        defaultValue={bridgeAddr}
                        placeholder="Bridge Address / SIP URI"
                        onChange={e => setBridgeAddr(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <label>WebEx/Zoom Link</label>
                    <Input
                        className="form-style"
                        defaultValue={webex}
                        placeholder="WebEx/Zoom Link"
                        onChange={e => setWebex(e.target.value)}
                    />
                </FormGroup>
            </Fragment>
        )
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => onToggle()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">{t("book-confirm")}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    loading ? (
                        <div style={{ padding: '20px 0' }} align="center">
                            <Loading size={20} />
                        </div>
                    ) : (
                        <Form>
                            <p>Please ensure you have confirmed the date and time below with the provider before booking!</p>
                            <FormGroup>
                                <label>Session Date & Time</label>
                                <div className={classNames({ "is-invalid": errors.startDate ? true : false })}>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupText>
                                            <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                        <ReactDatetime
                                            initialValue={startDate}
                                            inputProps={{
                                                placeholder: "Session Date & Time",
                                                style: {
                                                    color: 'black',
                                                    fontSize: 14
                                                }
                                            }}
                                            timeConstraints={{
                                                minutes: { step: 5 }
                                            }}
                                            timeFormat={true}
                                            onChange={(e) => {
                                                if (typeof e === 'string') {
                                                    setStartDate(e);
                                                } else {
                                                    setStartDate(e.toDate())
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="invalid-feedback">{errors.startDate}</div>
                                {alternativeDate !== 'none' && (
                                    <label>Alternate Time Requested: {getDateTimeString(alternativeDate)}</label>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <label>Connection Type</label>

                                <Select
                                    className="form-style"
                                    value={{ value: connectionType, label: connectionType }}
                                    placeholder="Type of Session"
                                    onChange={(selectedOption) => setConnectionType(selectedOption.value)}
                                    options={connectionTypes.map(type => ({ value: type, label: type }))}
                                />
                            </FormGroup>

                            {renderZoomInfo()}


                            <FormGroup>
                                <label>Type of Session</label>
                                <Select
                                    className="form-style"
                                    value={{ value: sessionType, label: sessionType }}
                                    placeholder="Type of Session"
                                    onChange={(selectedOption) => setSessionType(selectedOption.value)}
                                    options={sessionTypes.map(type => ({ value: type, label: type }))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Session Host</label>
                                <Select
                                    className="form-style"
                                    value={{ value: sessionHost?.value, label: sessionHost?.label }}
                                    placeholder="Type of Session"
                                    onChange={(selectedOption) => setSessionHost(selectedOption)}
                                    options={hosts.map(h => ({ value: h.id, label: h.Name }))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Subject/Curriculum</label>
                                <Select
                                    className="form-style"
                                    isMulti={true}
                                    value={subjectCurriculum}
                                    placeholder="Subject/Curriculum"
                                    onChange={(selectedOptions) => setSubjectCurriculum(selectedOptions)}
                                    options={totalSubjects.map(subject => ({ value: subject.id, label: subject.Subject }))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Send Meeting Invite to:</label>
                                <Select
                                    className="form-style"
                                    value={{ value: sendMeetingInvite, label: sendMeetingInvite }}
                                    onChange={selectedOption => setSendMeetingInvite(selectedOption.value)}
                                    options={sendMeetingRequestTypes.map(type => ({ value: type, label: type }))}
                                />
                            </FormGroup>
                        </Form>
                    )
                }
            </div >
            <div className="modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => onBook()}
                >
                    {booking ? (<Loading size={14} />) : (null)}{t("book-confirm")}
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >{t("close")}</Button>
            </div>
        </Modal >
    )
}

export default Booking;
