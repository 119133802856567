import React from 'react';
import './styles.scss';
import Dialog from '@mui/material/Dialog';
import QRCode from 'react-qr-code';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { toast } from "react-toastify";
import { DialogContent, DialogTitle } from '@mui/material';

const StudentQRSurveyModal = ({ session, onToggle }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open
      onClose={onToggle}
      className='qr-modal'
    >
      <DialogTitle align="center" style={{ fontSize: '2rem' }}>What did you think about this Connected North Session?</DialogTitle>
      <DialogContent dividers={true}>
        <div className='container'>
          <div className="qr-container">
            <QRCode
              size={256}
              style={{ height: 500, maxWidth: "100%", width: "100%" }}
              value={session["Survey Link"]}
              viewBox={`0 0 256 256`}
            />
          </div>
          <CopyToClipboard
            value={session["Survey Link"]}
            copied={() => toast.info("Copied to Clipboard!")}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default StudentQRSurveyModal;
