import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { FormGroup, Input, Label } from "reactstrap";
import { OnboardPageInner } from "../components/OnboardPageInner";
import { StepProgress } from "../components/StepProgress";
import { useSelectSchool } from "../hooks/use-select-school";
import { getNextBackLinks } from "../utils/get-next-back-links";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.selectSchool`;
const formId = "select-school-form";

export const SelectSchool = ({
	isNewTeacher,
	isIncompleteTeacher,
	stepRoutes = [],
	currentRouteName,
}) => {
	const { t } = useTranslation();
	const {
		allSchools,
		formValues,
		setFormValues,
		loading,
		updateSchool,
		currentSchoolName,
	} = useSelectSchool(isNewTeacher, isIncompleteTeacher);

	const showNewTeacherFlow = isNewTeacher || isIncompleteTeacher;

	const headingText = showNewTeacherFlow
		? t(`${pageKey}.newMainHeading`)
		: t(`${pageKey}.updateMainHeading`);

	const schoolLabel = showNewTeacherFlow
		? t(`${pageKey}.newSchoolLabel`)
		: t(`${pageKey}.updateSchoolLabel`);

	const { currentStep, nextLink, backLink } = getNextBackLinks(
		stepRoutes,
		currentRouteName,
	);

	const onChangeInput = (e) => {
		const { name, value } = e.target;

		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const onSubmitForm = (e) => {
		e.preventDefault();
		updateSchool(nextLink);
	};

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	});

	return (
		<OnboardPageInner
			formId={formId}
			onSubmit={onSubmitForm}
			heading={<Typography variant="h1">{headingText}</Typography>}
			actions={
				<StepProgress
					formId={formId}
					backLink={backLink}
					nextLabel={
						showNewTeacherFlow
							? `${tKey}.nextButton`
							: `${tKey}.confirmButton`
					}
					currentStep={currentStep}
					totalSteps={stepRoutes.length}
					disabled={loading}
				/>
			}
		>
			{!showNewTeacherFlow && (
				<Box className="form-field-wrap">
					<FormGroup>
						<Input
							id="isCurrent-yes"
							type="radio"
							name="isCurrent"
							value="yes"
							defaultChecked={formValues.isCurrent}
							onChange={onChangeInput}
							disabled={loading}
						/>
						<Label htmlFor="isCurrent-yes">
							{t(`${pageKey}.yesToSavedSchoolOption`, {
								school: currentSchoolName,
							})}
						</Label>
					</FormGroup>
				</Box>
			)}
			<Box className="form-field-wrap">
				{!showNewTeacherFlow && (
					<FormGroup>
						<Input
							id="isCurrent-no"
							type="radio"
							name="isCurrent"
							value="no"
							defaultChecked={!formValues.isCurrent}
							onChange={onChangeInput}
							disabled={loading}
						/>
						<Label htmlFor="isCurrent-no">
							{t(`${pageKey}.noToSavedSchoolOption`)}
						</Label>
					</FormGroup>
				)}
				<FormGroup>
					<Label
						for="school"
						disabled={formValues.isCurrent === "yes"}
					>
						{schoolLabel}:
					</Label>
					<Select
						id="school"
						name="school"
						className="form-control"
						value={formValues.school}
						onChange={onChangeInput}
						disabled={loading || formValues.isCurrent === "yes"}
						required
					>
						{allSchools.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormGroup>
			</Box>
		</OnboardPageInner>
	);
};
