import { BestPracticeLen } from "../../../utils/constants";

export const isCompletedBestPractices = (userInfo, userType) => {
  if (userType !== "Provider") return false;

  const BPInfo = userInfo["Best Practice Commitments"];
  if (BPInfo && (
    BPInfo.indexOf("Special Exemption") >= 0 ||
    BPInfo.indexOf("Exemption for Indigenous Elders") >= 0 ||
    BPInfo.length === BestPracticeLen
  )) {
    if (!userInfo["Recording Allowed"]) return false;
    return true;
  } else {
    return false;
  }
}

export const isFirstVisitAfterBPCompleted = (userInfo, userType) => {
  if (!isCompletedBestPractices(userInfo, userType)) return false;

  if (userInfo["All BP Modules Completed"]) return false;
  return true;
}

export const statusBestPractices = (userInfo, userType) => {
  if (userType !== "Provider") return false;

  if (userInfo["24-25 Provider Reviewed"]) return "completed";

  if (!isCompletedBestPractices(userInfo, userType)) return "bestpractice";

  if (!userInfo["24-25 Renewal Started"]) return "introduction";

  if (!userInfo["2024 Survey"] && userInfo["Delivered 23-24"] > 1) {
    return "survey";
  }
  if (userInfo["Renewal Actions 24-25"] && !userInfo["2024 Reflection Completed"]) return "reflection";

  if (!userInfo["24-25 Language Reflection Completed"]) return "language-revitalization";

  if (!userInfo["2024 Role Expectations Reviewed"]) return "roleExpectations";
  if (!userInfo["2023 Cancellation Policy Reviewed"]) {
    if (userInfo["Cost/Session"]) return "cancellationPolicy";
  }
  return "completed";
}

export const checkBestPractices = (userInfo, userType, target, reflectionStatus) => {
  const status = statusBestPractices(userInfo, userType);
  if (status !== 'reflection' && status === target) return "";

  switch (status) {
    case "completed": return "/mysessions";
    case "bestpractice": return "/best-practices/introduction";
    case "reflection":
      const renewalActions = userInfo["Renewal Actions"] || [];
      if (renewalActions.indexOf("Automatic renewal (interactivity resource)") >= 0) {
        if (reflectionStatus === "facilitation-of-student-interactivity") return "";
        return "/resources/facilitation-of-student-interactivity";
      } else if (renewalActions.indexOf("Automatic renewal (customization resource)") >= 0) {
        if (reflectionStatus === "creatively-customized-content") return "";
        return "/resources/creatively-customized-content";
      } else if (renewalActions.indexOf("Automatic renewal (trauma-informed resource)") >= 0) {
        if (reflectionStatus === "trauma-informed-approaches") return "";
        return "/resources/trauma-informed-approaches";
      } else if (status === target) return "";

      if (userInfo["All BP Modules Completed"]) return "/best-practice/2024/reflection";
      return "/best-practices/introduction"
    case "language-revitalization": return "/best-practice/2024/language-revitalization";
    case "roleExpectations": return "/best-practice/2024/role-expectations";
    case "cancellationPolicy": return "/best-practice/2024/cancellation-reschedule-policy";
    case "introduction": return "/best-practice/2024/introduction";
    case "survey":
    default:
      return "/best-practice/2024/survey";
  }
}
