import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { capitalize } from '../../../utils/string';
import styles from './style.module.scss';
import airtable from '../../../airtables';
import Loading from '../../../components/Loading';
import SessionList from './SessionList';
import { useTranslation } from 'react-i18next';

const TeacherSessionsInSchoolModal = ({
  teacherInfo,
  type = "upcoming",
  onToggle
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    refreshSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, teacherInfo])

  const refreshSessions = async () => {
    setLoading(true);
    if (type === "upcoming") {
      const sessList = await airtable.sessions.getSessions(
        'Teacher',
        teacherInfo.name,
        teacherInfo.id,
        'Booked'
      );
      setSessions(sessList);
    } else if (type === "completed") {
      const sessList = await airtable.sessions.getPastSessions({
        type: 'Teacher',
        name: teacherInfo.name,
        status: 'Completed'
      });
      setSessions(sessList);
    }
    setLoading(false);
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
      size='xl'
      scrollable
      centered
    >
      <ModalHeader
        close={(
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => onToggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        )}
        toggle={onToggle}
      >
        {teacherInfo.name}'s {capitalize(type)} sessions
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div className={styles.sessionsLoading}>
            <Loading size={48} />
          </div>
        ) : (
          <SessionList
            sessions={sessions}
            type={type === "completed" ? "Past" : type}
            noMessage={t("no-upcoming-sessions")}
            refreshPage={refreshSessions}
            isSchoolLeader
          />
        )}
      </ModalBody>
    </Modal>
  )
}

export default TeacherSessionsInSchoolModal;