import { useState } from "react";
import { Card } from "../../../../components/Card";
import "./stepper.css";
import { Collapse } from "reactstrap";
import { Stepper } from "../../../../components/Stepper";
import { TokenBlue } from "../../assets/token-blue";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * @param {object} prop
 * @param {number} prop.credit
 */
export function CreditStepper({ credit }) {
	const { t } = useTranslation();

	const labels = [0, 10, 20, 30, 40, 50, 60];
	const activeStep = (credit - (credit % 10)) / 10;
	const tokenMap = [
		// [1, "10 Rated Sessions"],
		[1, translated(t, 10, "rated")],
		[3, translated(t, 25, "rated")],
		[5, translated(t, 50, "rated")],
		[1, translated(t, 5, "reviewed")],
		[3, translated(t, 10, "reviewed")],
		[5, translated(t, 25, "reviewed")],
		[10, translated(t, 50, "reviewed")],
		[2, translated(t, 10, "unique")],
		[5, translated(t, 25, "unique")],
		[10, translated(t, 50, "unique")],
		[15, translated(t, 75, "unique")],
	];

	const { open, toggle } = useExpand();

	return (
		<Card
			className="bg-white"
		>
			<div className="d-flex justify-content-between align-items-center pb-3">
				<h2 className="h5 font-weight-bold">
					{t("credit-page.dash.achievement.heading")}
				</h2>
				<DropDown handleClick={toggle} open={open} />
			</div>

			<Collapse isOpen={open}>
				<section className="bg-white">
					<p className="font-weight-normal">
						{t("credit-page.dash.achievement.how")}
					</p>

					<ul className="p-0 mb-5">
						{tokenMap.map(([k, v], i) => (
							<li
								key={`tokenMap${i}`}
								style={{ listStyle: "none" }}
								className="d-flex align-items-center my-2"
							>
								<div className="d-flex align-items-center">
									<p
										className="font-weight-bold mr-2"
										style={{ width: "2ch" }}
									>
										{k}
									</p>
									<TokenBlue />
								</div>

								<p className="font-weight-normal ml-4">{v}</p>
							</li>
						))}
					</ul>
				</section>
			</Collapse>

			<Stepper
				style={{
					width: "110%",
					transform: "translateX(-5%)",
				}}
				step={activeStep}
				labels={labels}
			/>

			<Collapse isOpen={open}>
				<Link
					className="mt-5 btn btn-primary"
					style={{ width: "max-content" }}
					to="/cn/credits/earn-more"
				>
					Learn More
				</Link>
			</Collapse>
		</Card>
	);
}

/**
 * @typedef {object} useExpandProps
 * @property {boolean} open
 * @property {() => void} toggle
 *
 * @returns {useExpandProps}
 */
function useExpand() {
	const [open, setOpen] = useState(false);
	return {
		open,
		toggle: () => setOpen((o) => !o),
	};
}

/**
 * @param {object} props
 * @param {() => void} props.handleClick
 * @param {boolean} props.open
 */
function DropDown({ handleClick, open }) {
	return (
		<button onClick={handleClick} className="btn p-0 mb-2">
			<svg
				style={{
					transformOrigin: "center",
					transform: open ? "rotate(0deg)" : "rotate(180deg)",
					transition: "150ms",
				}}
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="11"
				viewBox="0 0 16 11"
				fill="none"
			>
				<path
					d="M8.63525 0.790674L15.2759 7.22222C15.7349 7.66676 15.7349 8.38558 15.2759 8.82538L14.1724 9.89415C13.7134 10.3387 12.9712 10.3387 12.5171 9.89415L7.81006 5.33532L3.10303 9.89415C2.64404 10.3387 1.90186 10.3387 1.44775 9.89415L0.344239 8.82538C-0.114745 8.38085 -0.114745 7.66203 0.344239 7.22222L6.98486 0.790674C7.43408 0.34614 8.17627 0.34614 8.63525 0.790674Z"
					fill="#0D1120"
				/>
			</svg>
		</button>
	);
}

function translated(t, sess, entry) {
	let s = String(sess);
	s += " ";
	s += t(`credit-page.dash.achievement.token-map.${entry}`);
	return s;
}
