import React, { Fragment } from 'react';
import { Button, Input, Modal } from 'reactstrap';
import useHook from './useHook';
import './styles.scss';
import Loading from '../../../components/Loading';
import SessionBookTimePicker from './SessionBookTimePicker';
import { toast } from 'react-toastify';

const DuplicateSessionModal = ({
    session,
    onToggle
}) => {
    const {
        loading,
        provider,
        providerSession,
        times,
        changeTimes,
        startDates,
        internalErrors,
        updateStartDates,
        updateValidations,
        duplicateSession,
        isValid,
        processing
    } = useHook(session);

    const onFinish = () => {
        toast.success(`Duplicated the session ${times} times successfully!`);
        onToggle();
    }

    return (
        <Modal
            className="modal-dialog-centered duplicate-session"
            isOpen
            toggle={() => {
                if (!processing) onToggle()
            }}
        >
            <div className="modal-header">
                <h5 className="modal-title">Duplicate Session</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                        if (!processing) onToggle()
                    }}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <p>Duplicating this session will keep all information the same, with the exception of the date/time. Duplicated sessions will be created with Booked status and automatically processed.</p>

                {loading ? (
                    <div className="modal-loader">
                        <Loading size={24} />
                    </div>
                ) : (
                    <Fragment>
                        <div className="duplicate-times">
                            <span>Duplicate this session</span>
                            <Input
                                value={times}
                                onChange={e => changeTimes(parseInt(e.target.value))}
                                size="sm"
                                type="number"
                                min="1"
                                max="10"
                                disabled={processing}
                            />
                            <span>time(s)</span>
                        </div>

                        <hr />

                        <div className="date-time-list">
                            {startDates.map((dt, i) => (
                                <SessionBookTimePicker
                                    key={i}
                                    startTime={dt}
                                    updateStartDate={(sd) => updateStartDates(sd, i)}
                                    provider={provider}
                                    providerSession={providerSession}
                                    nth={i + 1}
                                    updateValidation={(v) => updateValidations(v, i)}
                                    error={internalErrors[i]}
                                    disabled={processing}
                                />
                            ))}
                        </div>
                    </Fragment>
                )}
            </div>

            <div className="modal-footer">
                <Button
                    className="ml-auto"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                        if (!processing) onToggle()
                    }}
                >Cancel</Button>
                <Button
                    color="success"
                    type="button"
                    onClick={() => duplicateSession(onFinish)}
                    disabled={loading || !isValid || processing}
                >{processing && <Loading size={14} />}{` `}Duplicate Session</Button>
            </div>
        </Modal>
    )
}

export default DuplicateSessionModal;