export const TEACHER_FIELDS = {
	COMPLETED: "24-25 Onboarding Complete",
	REPORT_MSG: "24 Report Message",
	SCHOOL: "School Name",
	PREVSCHOOL: "Previous Schools",
	NAME: "Teacher Name",
	EMAIL: "Email",
	SECONDARY_EMAIL: "Secondary Email",
	PHONE: "Personal Phone",
	SELF_SIGNUP: "Self Signup",
	LOGINS: "Platform Logins",
	STATUS: "Status",
	INDIGENOUS_GROUPS: "Indigenous Group(s)",
	LANGUAGE: "Preferred Language",
	PRONOUNS: "Pronouns",
	GRADES: "Grade(s) Taught",
	SUBJECTS: "Subject(s) Taught",
	REMINDERS: "E-mail Reminders",
	SESSION_MENU: "2024 Session Menu Requested",
	CODE_OF_CONDUCT: "Code of Conduct Reviewed",
	OPTIONAL_TERMS: null, // Field name TBD
};

export const CREDIT_FIELDS = {
	TEACHER: "Teacher",
	CREDITS: "Credits",
	ACTION: "Action",
	NOTE: "Display Note",
};
