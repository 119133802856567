import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import greatLogo from "../../../assets/img/great-a.png"
import hostMenu from "../../../assets/img/hostmenu.jpg"
import Commitment from '../components/Commitment';
import { Input } from 'reactstrap';

const NavContent = ({
  defaultReflection,
  defaultScenarioSteps,
  commitment,
  width
}) => {
  const [scenarioSteps, setScenarioSteps] = useState(defaultScenarioSteps)
  const { t } = useTranslation();
  const history = useHistory();
  const [videoHeight, setVideoHeight] = useState(0);

  useEffect(() => {
    setVideoHeight(width / 1.69)
  }, [width])
  
  return (
    <div className="nav-content-wrap">
      <div className="main-content">
        <h4>{t("session-host-bp.act.title")}</h4>
        <img
          src={greatLogo}
          className="great-logo sh-img"
          alt=""
        />

        <span>{t("session-host-bp.act.p1")}</span>
        <ul className="session-host">
          <li>{t("session-host-bp.act.p1-li1.content")}
            <ul className="session-host">
              <li>
                <Trans
                  i18nKey={"session-host-bp.act.p1-li1.li1"}
                  components={{ b: <b /> }}
                />
              </li>
              <li>{t("session-host-bp.act.p1-li1.li2")}</li>
            </ul>
          </li>
        </ul>

        <span>{t("session-host-bp.act.p2")}</span>
        <ul className="session-host">
          <li>{t("session-host-bp.act.p2-li1")}</li>
          <li>{t("session-host-bp.act.p2-li2")}</li>
          <li>{t("session-host-bp.act.p2-li3")}</li>
          <li>{t("session-host-bp.act.p2-li4")}</li>
        </ul>

        <p>
          <Trans
            i18nKey="session-host-bp.act.p3"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ a: <a href="https://docs.google.com/document/d/1gZJbjEGqXDuQQiAXLzy288rsxcvx-pgPnj-HKxRJ1WE/edit" target="_blank" rel="noreferrer" /> }}
          />
        </p>

        <span>
          <Trans
              i18nKey="session-host-bp.act.s4-li1"
              components={{ b: <b /> }}
          />
        </span>
        <div>
          {!!width && (
            <iframe
              src="https://player.vimeo.com/video/997914810?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Session Host Training - Act - Audio Video Quality Support"
              width={width}
              height={videoHeight}
            />
          )}
        </div>
        <span>
          <Trans
              i18nKey="session-host-bp.act.s4-li2"
              components={{ b: <b /> }}
          />
        </span>
        <div>
          {!!width && (
            <iframe
              src="https://player.vimeo.com/video/997914761?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Session Host Training - Act - Getting Connected"
              width={width}
              height={videoHeight}
            />
          )}
        </div>

        <div className="image-text-container">
          <img
            src={hostMenu}
            className="host-menu"
            alt=""
          />
          <div className="text-content">
            <span>{t("session-host-bp.act.s5")}</span><br />
            <ul className="session-host ul-minus">
              <li>{t("session-host-bp.act.s5-li1")}</li>
              <li>{t("session-host-bp.act.s5-li6")}</li>
              <li>{t("session-host-bp.act.s5-li2")}</li>
              <li>{t("session-host-bp.act.s5-li3")}</li>
              <li>{t("session-host-bp.act.s5-li4")}</li>
              <li>{t("session-host-bp.act.s5-li5")}</li>
              <li>{t("session-host-bp.act.s5-li7")}</li>
              <li>{t("session-host-bp.act.s5-li8")}</li>
            </ul>
          </div>
        </div>

        <div>
          <span><b>{t("session-host-bp.act.scenario1.title")}</b></span>
          <p>{t("session-host-bp.act.scenario1.text")}</p>
          <ol>
            <li>{t("session-host-bp.act.scenario1.li2")}</li>
            <li>{t("session-host-bp.act.scenario1.li1")}</li>
            <li>{t("session-host-bp.act.scenario1.li4")}</li>
            <li>{t("session-host-bp.act.scenario1.li3")}</li>
          </ol>
        </div>

        <div>
          <span><b>{t("session-host-bp.act.scenario2.title")}</b></span>
          <p>{t("session-host-bp.act.scenario2.text")}</p>
          <ol>
            <li>{t("session-host-bp.act.scenario2.li3")}</li>
            <li>{t("session-host-bp.act.scenario2.li2")}</li>
            <li>{t("session-host-bp.act.scenario2.li1")}</li>
          </ol>
        </div>

        <div className="reflection">
          <span>
            <strong>{t("session-host-bp.act.scenario-steps-label")}</strong>
          </span>
          <br />
          <Input
            type="textarea"
            rows={6}
            onChange={e => setScenarioSteps(e.target.value)}
            value={scenarioSteps}
          />
        </div>

        <Commitment
          label={t("session-host-bp.act.reflection-label")}
          confirmLabel={t("session-host-bp.act.agree")}
          btnLabel={t("session-host-bp.act.continue")}
          defaultReflection={defaultReflection}
          submitConfirm={async (reflection) => {
            await commitment(reflection, scenarioSteps);
            history.push("thank");
          }}
        />
      </div>
    </div>
  )
}

export default NavContent;
