/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {number?} props.size
 * @param {boolean} props.active
 * @param {() => void} props.handleClick
 * @returns {React.JSX}
 */
export function PagButton({ children, active, handleClick, size, disabled }) {
	return (
		<button
			className="d-flex justify-content-center align-items-center btn p-0"
			style={{
				backgroundColor: active ? "#2B4F70" : "#9DE0F8",
				color: active ? "white" : "#0D1120",
				width: `${size ?? 35}px`,
				height: `${size ?? 35}px`,
				borderRadius: "10px",
				opacity: disabled ? "20%" : "100%",
				margin: "0 2px",
			}}
			aria-disabled={active}
			onClick={handleClick}
			disabled={disabled}
		>
			{children}
		</button>
	);
}
