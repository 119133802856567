import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row, Progress } from 'reactstrap';
import airtable from '../../airtables';
import { setAppUserInfo } from '../../redux/actions';
import { setSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO } from '../../config';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const Screen = () => {
    const { userInfo } = useSelector(state => state.appInfo);
    const [updating, setUpdating] = useState(false);
    const [communitiesMap, setCommunitiesMap] = useState({});
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pronouns, setPronouns] = useState("")
    const [indigenousCanada, setIndigenousCanada] = useState();
    const [indigenousStatus, setIndigenousStatus] = useState([]);
    const [indigenousAnotherNation, setIndigenousAnotherNation] = useState(false);
    const [indigenousNation, setIndigenousNation] = useState("");
    const [indigenousCommunities, setIndigenousCommunities] = useState([]);
    const [language, setLanguage] = useState(userInfo["Preferred Language"] || 'English')

    const [communities, setCommunities] = useState([])
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        airtable.communities.getTotalCommunities().then(res => setCommunities(res))
    }, [])

    useEffect(() => {
        if (!communities || communities.length === 0) return;

        const rMap = communities.reduce((result, item, i) => {
            result[item.id] = item;
            return result;
        }, {});
        setCommunitiesMap(rMap);
    }, [communities])

    useEffect(() => {
        setName(userInfo['Name']);
        setEmail(userInfo['Email']);
        setPhoneNumber(userInfo['Phone']);
        setPronouns(userInfo["Pronouns"]);
        setIndigenousCanada(userInfo["Indigenous to Canada?"] ? true : false);
        setIndigenousStatus(userInfo["Indigenous Status"] ? userInfo["Indigenous Status"] : []);
        if (userInfo["Indigenous Nation"]) setIndigenousAnotherNation(true)
        setIndigenousNation(userInfo["Indigenous Nation"] || "");
        setIndigenousCommunities(userInfo["Indigenous Communities"] || []);
        setLanguage(userInfo["Preferred Language"] || 'English')
    }, [userInfo])

    const cancelUpdate = () => {
        setName(userInfo['Name']);
        setEmail(userInfo['Email']);
        setPhoneNumber(userInfo['Phone']);
        setPronouns(userInfo["Pronouns"]);
        setIndigenousCanada(userInfo["Indigenous to Canada?"] ? true : false);
        setIndigenousStatus(userInfo["Indigenous Status"] ? userInfo["Indigenous Status"] : []);
        if (userInfo["Indigenous Nation"]) setIndigenousAnotherNation(true)
        setIndigenousNation(userInfo["Indigenous Nation"] || "");
        setIndigenousCommunities(userInfo["Indigenous Communities"] || []);
        setLanguage(userInfo["Preferred Language"] || 'English')
        setEdit(false)
    }

    const updateInfo = async () => {
        setUpdating(true);
        try {
            let params = {
                "Name": name,
                "Email": email.trim(),
                "Phone": phoneNumber,
                "Pronouns": pronouns,
                "Indigenous to Canada?": indigenousCanada,
                "Indigenous Nation": indigenousAnotherNation ? indigenousNation : "",
                "Preferred Language": language
            };
            
            if (indigenousCanada) {
                params["Indigenous Status"] = indigenousStatus;
                params["Indigenous Communities"] = indigenousCommunities;
            }
            const result = await airtable.presenters.update(userInfo.id, params);
            await setSession(CONNECTEDNORTH_SIGNINFO, result);
            dispatch(setAppUserInfo(result));
        } catch (error) { }
        setUpdating(false);
        setEdit(false);
    }

    return (
        <div className="main-container">
            <Row>
                <Col>
                    <h1>{t("my-info")}</h1>
                </Col>
                <Col align="right">
                    {
                        edit ? (
                            <>
                                <Button color="link" size="sm" onClick={() => cancelUpdate()}>{t("cancel")}</Button>
                                <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => updateInfo()}
                                    disabled={(!name || !email)}
                                >{t("save")}</Button>
                            </>
                        ) : (
                            <Button color="link" size="sm" onClick={() => setEdit(true)}>{t("edit")}</Button>
                        )
                    }
                </Col>
            </Row>
            {updating && <Progress />}
            <div style={{ marginTop: 10 }} align="center">
                <div style={{ maxWidth: 600, minHeight: 500, padding: '0 20px', paddingTop: 40 }} align="left">
                    <FormGroup row>
                        <Label for="name" sm={4}>{t("contact-name")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Contact Name"
                                        defaultValue={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                ) : (
                                    <span>{name}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="email" sm={4}>Preferred Language</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="select"
                                        className='pronouns form-control'
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                    >
                                        <option value="English">English</option>
                                        <option value="French">French</option>
                                    </Input>
                                ) : (
                                    <span>{language}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="name" sm={4}>{t("pronouns")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="pronouns"
                                        id="pronouns"
                                        placeholder={t("pronouns")}
                                        defaultValue={pronouns}
                                        onChange={e => setPronouns(e.target.value)}
                                    />
                                ) : (
                                    <span>{pronouns}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="email" sm={4}>{t("email")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        defaultValue={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                ) : (
                                    <span>{email}</span>
                                )
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="phone" sm={4}>{t("phone-number")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="Phone Number"
                                        defaultValue={phoneNumber}
                                        onChange={e => setPhoneNumber(e.target.value)}
                                    />
                                ) : (
                                    <span>{phoneNumber}</span>
                                )
                            }
                        </Col>
                    </FormGroup>
                    {
                        edit ? (
                            <Fragment>
                                <FormGroup row>
                                    <Col className="v-center checkbox-col">
                                        <Input
                                            type="checkbox"
                                            name="indigenousCanada"
                                            id="indigenousCanada"
                                            checked={indigenousCanada}
                                            className='ml0'
                                            onChange={e => setIndigenousCanada(e.target.checked)}
                                        />
                                    </Col>
                                    <Label for="indigenousCanada" sm={10}>Do you identify as Indigenous to Canada?</Label>
                                </FormGroup>
                                {
                                    indigenousCanada && (
                                        <Fragment>
                                            <FormGroup row>
                                                <Label for="phone" sm={4}>Are you First Nations, Métis and/or Inuit?</Label>
                                                <Col sm={8} className="v-center">
                                                    <Select
                                                        className="form-style full"
                                                        isMulti
                                                        value={indigenousStatus.map(v => ({ value: v, label: v }))}
                                                        onChange={(selections) => setIndigenousStatus(selections.map(v => v.value))}
                                                        options={["First Nations", "Inuit", "Métis"].map(type => ({ value: type, label: type }))}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label for="phone" sm={4}>Are you a member of a Nation or Community?</Label>
                                                <Col sm={8} className="v-center">
                                                    <Select
                                                        className="form-style full"
                                                        isMulti
                                                        value={indigenousCommunities.map(v => ({ value: v, label: communitiesMap[v].Name }))}
                                                        onChange={(selections) => setIndigenousCommunities(selections.map(v => v.value))}
                                                        options={communities.map(community => ({ value: community.id, label: community.Name }))}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Fragment>
                                    )
                                }
                            </Fragment>
                        ) : (
                            <Fragment>
                                <FormGroup row>
                                    <Label sm={4}>Indigenous to Canada?</Label>
                                    <Label sm={4}>{indigenousCanada ? "Yes" : "No"}</Label>
                                </FormGroup>
                                {
                                    indigenousCanada && (
                                        <FormGroup row>
                                            <Label sm={4}>Indigenous Status</Label>
                                            <Label sm={4}>{indigenousStatus.join(", ")}</Label>
                                        </FormGroup>
                                    )
                                }
                            </Fragment>
                        )
                    }

                    {
                        edit ? (
                            <Fragment>
                                <FormGroup row>
                                    <Col className="v-center checkbox-col">
                                        <Input
                                            type="checkbox"
                                            name="indigenousAnotherNation"
                                            id="indigenousAnotherNation"
                                            checked={indigenousAnotherNation}
                                            className='ml0'
                                            onChange={e => setIndigenousAnotherNation(e.target.checked)}
                                        />
                                    </Col>
                                    <Label for="indigenousAnotherNation" sm={10}>Do you identify as Indigenous to another country?</Label>
                                </FormGroup>
                                {
                                    indigenousAnotherNation && (
                                        <FormGroup>
                                            <Label for="indigenousNation">Which Nation or community are you a part of?</Label>
                                            <Col style={{ padding: 0 }}>
                                                <Input
                                                    type="textarea"
                                                    name="indigenousNation"
                                                    id="indigenousNation"
                                                    defaultValue={indigenousNation}
                                                    onChange={e => setIndigenousNation(e.target.value)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    )
                                }
                            </Fragment>
                        ) : (
                            indigenousAnotherNation && (
                                <FormGroup row>
                                    <Label sm={4}>Indigenous Nation</Label>
                                    <Label sm={4}>{indigenousNation}</Label>
                                </FormGroup>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Screen;