import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import greatLogo from "../../../assets/img/great-r.png"
import Commitment from '../components/Commitment';
import airtable from "../../../airtables";
import { DEFAULT_SESSION_FOR_REVIEW_SECTION } from '../../../utils/constants';
import SessionInfo from '../../../components/SessionInfo';
import pageImg1 from "../../../assets/img/review-img1.png";
import pageImg2 from "../../../assets/img/review-img2.png";
import { getActionMenusForExample, getDisplayInfosForExample } from '../../SessionInfoDisplay';
import { getProviderSessionIDFromSession } from '../../../utils/session';

const NavContent = ({
  defaultReflection,
  commitment,
  width
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [videoHeight, setVideoHeight] = useState(0);
  const [session, setSession] = useState(null);
  const { userInfo } = useSelector(state => state.appInfo);

  useEffect(() => {
    airtable.sessions.select(DEFAULT_SESSION_FOR_REVIEW_SECTION).then(res => {
      setSession(res);
    })
  }, [])

  useEffect(() => {
    setVideoHeight(width / 1.69)
  }, [width])

  return (
    <div className="nav-content-wrap">
      <div className="main-content">
        <h4>{t("session-host-bp.review.title")}</h4>
        <img
          src={greatLogo}
          className="great-logo sh-img"
          alt=""
        />

        <p>
          <Trans
            i18nKey="session-host-bp.review.p1"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a
                href="https://docs.google.com/document/d/1ElnEuYC8wXh-1SITQ9vcjNeIsiuJZpIscLRkkOQFWDA/edit"
                target="_blank"
                rel="noreferrer"
              />
            }}
          />
        </p>
        <p>
          <Trans
            i18nKey="session-host-bp.review.p2"
            components={{ u: <u />, b: <b /> }}
          />
        </p>

        <center><p>{t("session-host-bp.review.p3")}</p></center>

        <img
          src={pageImg1}
          className="sh-img"
          alt={t("session-host-bp.review.p4")}
        />
        <center><p>{t("session-host-bp.review.p4")}</p></center>

        <center><p>{t("session-host-bp.review.p5")}</p></center>
        <img
          src={pageImg2}
          className="sh-img"
          alt={t("session-host-bp.review.p5")}
        />

        <p>{t("session-host-bp.review.p6")}</p>
        <ol>
          <li>{t("session-host-bp.review.oli1")}</li>
          <li>{t("session-host-bp.review.oli2")}</li>
          <li>{t("session-host-bp.review.oli3")}</li>
          <li>{t("session-host-bp.review.oli4")}</li>
          <li>{t("session-host-bp.review.oli5")}</li>
          <li>{t("session-host-bp.review.oli6")}</li>
        </ol>

        <p>{t("session-host-bp.review.p7")}</p>
        <p>
          <Trans
            i18nKey="session-host-bp.review.p8"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a
                href="https://www.connectednorth.org/en/wellbeing"
                target="_blank"
                rel="noreferrer"
              />
            }}
          />
        </p>
        <p>
          <Trans
            i18nKey="session-host-bp.review.p9"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a
                href="https://takingitglobal.uberflip.com/i/1355551-connected-north-a-journey-of-transformation-well-being/0"
                target="_blank"
                rel="noreferrer"
              />
            }}
          />
        </p>

        <p>{t("session-host-bp.review.p10")}</p>
        <ul className="session-host">
          <li>{t("session-host-bp.review.p10-li1")}</li>
          <li>{t("session-host-bp.review.p10-li2")}</li>
          <li>{t("session-host-bp.review.p10-li3")}</li>
          <li>{t("session-host-bp.review.p10-li4")}</li>
          <li>{t("session-host-bp.review.p10-li5")}</li>
        </ul>

        <p>
          <Trans
            i18nKey="session-host-bp.review.p11"
            components={{ u: <u />, b: <b /> }}
          />
        </p>
        <ul className="session-host">
          <li>{t("session-host-bp.review.p11-li1")}</li>
          <li>
            {t("session-host-bp.review.p11-li2.content")}
            <ul className="session-host">
              <li>{t("session-host-bp.review.p11-li2.li1")}</li>
            </ul>
          </li>
        </ul>

        <p>
          <Trans
            i18nKey="session-host-bp.review.p11b"
            components={{ b: <b /> }}
          />
        </p>
        <div className="example-session">
          {!!session && <SessionInfo
            displayInfos={getDisplayInfosForExample(session)}
            actionMenu={getActionMenusForExample(session)}
            session={session}
            dateType="full"
            hideActionContacts="true"
            rubriclink={`https://airtable.com/appP1kThwW9zVEpHr/shr9szlNdUJSVGDEY?prefill_Which+session+are+you+hosting%2Freviewing%3F=recqiRA1e0oD5f6ax&prefill_Who+is+the+person+completing+this+Rubric%3F=${userInfo.id}`}
            sessionDetailLink={getProviderSessionIDFromSession(session)}
          />}
        </div>
        <p>
          <Trans
            i18nKey="session-host-bp.review.p12"
            components={{ b: <b /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="session-host-bp.review.p13"
            components={{ b: <b /> }}
          />
        </p>
        <p>{t("session-host-bp.review.p14")}</p>

        <div>
          {!!width && (
            <iframe
              src="https://player.vimeo.com/video/989718754?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Session Host Training - Review Example"
              width={width}
              height={videoHeight}
            />
          )}
        </div>

        <Commitment
          label={t("session-host-bp.review.reflection-label")}
          confirmLabel={t("session-host-bp.review.agree")}
          btnLabel={t("session-host-bp.review.continue")}
          defaultReflection={defaultReflection}
          submitConfirm={async (reflection) => {
            await commitment(reflection);
            history.push("engage");
          }}
        />
      </div>
    </div>
  )
}

export default NavContent;
