import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Input,
    Nav,
    NavItem,
    NavLink as TabNavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import DatePicker from '../../components/DatePicker';
import "./styles.scss";
import SessionList from './SessionList';
import { useTeamPendingData } from './useTeamPendingData';
import { Checkbox, FormControlLabel } from '@mui/material';

const Screen = () => {
    const { t } = useTranslation();
    const { userInfo, userType } = useSelector(state => state.appInfo);
    const isAdmin = (userType === 'Team' && userInfo["Administrator"]) ? true : false;

    const {
        requestedSessions,
        setRequestedSessions,
        planningSessions,
        setplanningSessions,
        devSessions,
        setDevSessions,
        wishSessions,
        setWishSessions,
        adminSessions,
        setAdminSessions,
        rLoading,
        ipLoading,
        dLoading,
        aLoading,
        wLoading,
        filterKeyword,
        setFilterKeyword,
        filterDate,
        setFilterDate,
        filterCILC,
        setFilterCILC,
        refresh
    } = useTeamPendingData();
    const [activeTab, setActiveTab] = useState(userType === 'Team' ? 'requested' : '');

    useEffect(() => {
        window.document.title = "My Requests - Connected North"
    }, [])

    return (
        <div className="main-container">
            <h1>{t("pending_session_requests")}</h1>
            <div style={{ marginTop: 10 }}>
                <div className='filter-conditions'>
                    <Row>
                        <Col lg={5} md={12} sm={12} className='filter-col'>
                            <Input
                                type="text"
                                className="form-style"
                                placeholder={t("search-by-name")}
                                value={filterKeyword}
                                onChange={e => setFilterKeyword(e.target.value)}
                            />
                        </Col>
                        <Col lg={3} md={8} sm={12} className='filter-col'>
                            <DatePicker
                                value={filterDate}
                                inputProps={{
                                    placeholder: "Session Date",
                                }}
                                timeFormat={false}
                                onChange={(e) => {
                                    if (typeof e !== 'string') setFilterDate(e.toDate());
                                }}
                                clearDate={() => setFilterDate(null)}
                            />
                        </Col>
                        <Col lg={2} md={4} sm={12} className='filter-col'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterCILC ? true : false}
                                        onChange={e => setFilterCILC(e.target.checked)}
                                    />
                                }
                                label="CILC"
                            />
                        </Col>
                        <Col lg={2} md={12} sm={12} className='filter-col'>
                            <Button
                                color="primary"
                                size='sm'
                                onClick={() => refresh()}
                            >
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Nav tabs className="pending-tabs">
                    <NavItem>
                        <TabNavLink
                            onClick={() => setActiveTab('requested')}
                            className={classNames({ active: activeTab === 'requested' })}
                        >
                            Requested {rLoading ? '' : `(${requestedSessions.length})`}
                        </TabNavLink>
                    </NavItem>
                    <NavItem>
                        <TabNavLink
                            onClick={() => setActiveTab('planning')}
                            className={classNames({ active: activeTab === 'planning' })}
                        >
                            In Planning {ipLoading ? '' : `(${planningSessions.length})`}
                        </TabNavLink>
                    </NavItem>
                    <NavItem>
                        <TabNavLink
                            onClick={() => setActiveTab('development')}
                            className={classNames({ active: activeTab === 'development' })}
                        >
                            Pending Provider Response {dLoading ? '' : `(${devSessions.length})`}
                        </TabNavLink>
                    </NavItem>
                    <NavItem>
                        <TabNavLink
                            onClick={() => setActiveTab('wishlist')}
                            className={classNames({ active: activeTab === 'wishlist' })}
                        >
                            Wish List {wLoading ? '' : `(${wishSessions.length})`}
                        </TabNavLink>
                    </NavItem>
                    {isAdmin && (
                        <NavItem>
                            <TabNavLink
                                onClick={() => setActiveTab('admin')}
                                className={classNames({ active: activeTab === 'admin' })}
                            >
                                All pending {aLoading ? '' : `(${adminSessions.length})`}
                            </TabNavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="requested">
                        <SessionList
                            loading={rLoading}
                            sessionInfo={{ sessions: requestedSessions, updateFunc: setRequestedSessions }}
                            onRefresh={refresh}
                        />
                    </TabPane>
                    <TabPane tabId="planning">
                        <SessionList
                            loading={ipLoading}
                            sessionInfo={{ sessions: planningSessions, updateFunc: setplanningSessions }}
                            onRefresh={refresh}
                        />
                    </TabPane>
                    <TabPane tabId="development">
                        <SessionList
                            loading={dLoading}
                            sessionInfo={{ sessions: devSessions, updateFunc: setDevSessions }}
                            onRefresh={refresh}
                        />
                    </TabPane>
                    <TabPane tabId="wishlist">
                        <SessionList
                            loading={wLoading}
                            sessionInfo={{ sessions: wishSessions, updateFunc: setWishSessions }}
                            onRefresh={refresh}
                        />
                    </TabPane>
                    {isAdmin && (
                        <TabPane tabId="admin">
                            <SessionList
                                loading={aLoading}
                                sessionInfo={{ sessions: adminSessions, updateFunc: setAdminSessions }}
                                onRefresh={refresh}
                            />
                        </TabPane>
                    )}
                </TabContent>
            </div>
        </div>
    )
}

export default Screen;
