import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PresenterUpcomingScreen from './presenter';
import TeamUpcomingScreen from './team';
import UpcomingScreen from './other';
import './styles.scss'

const Screen = () => {
    const { userType } = useSelector(state => state.appInfo);

    useEffect(() => {
        window.document.title = "My Sessions - Connected North"
    }, [])

    if (userType === 'Presenter') return <PresenterUpcomingScreen />
    if (userType === 'Team') return <TeamUpcomingScreen />
    return <UpcomingScreen />
}

export default Screen;
