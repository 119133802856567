import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { distinctArray } from "../../../../utils/array";

export const useTeacherFocus = () => {
  const { userInfo, userType, totalSubjects } = useSelector(state => state.appInfo)
  const [regions, setRegions] = useState("");
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    if (!userType || !userInfo) return;

    if (userType === 'Team') setRegions(distinctArray(userInfo["School P/Ts"]));
    else if (userType === 'Teacher') {
      const rs = userInfo["School P/T Text"].split(",");
      setRegions(distinctArray(rs.map(r => r.trim())));

      if (totalSubjects.length) {
        const subIds = userInfo["Subject(s) Taught"] || [];
        const subs = totalSubjects.filter(s => subIds.indexOf(s.id) >= 0);
        setSubjects(subs.map(s => s.Subject))
      }

      const gradesRaw = userInfo["Grade(s) Taught"] ?? [];
      const filteredGrades = gradesRaw.filter((grade) => grade !== "Administrator");
      const orderedGrades = ["JK", "K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
      const gs = filteredGrades.sort((a, b) => orderedGrades.indexOf(a) - orderedGrades.indexOf(b));
      setGrades(gs);
    }
  }, [userInfo, userType, totalSubjects])

  return {
    regions,
    grades,
    subjects
  }
}