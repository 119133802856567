import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CurriculumGradeLevels } from "../../../../utils/constants";

export function useFilter(applyFilterCallback) {
	const { userInfo, totalSubjects: subjects } = useSelector(state => state.appInfo)

	const [gradeFilter, setGradeFilter] = useState([]);
	const [subjectFilter, setSubjectFilter] = useState([]);
	const [showAll, setShowAll] = useState(false);
	const [initialLoaded, setInitialLoaded] = useState(false);

	useEffect(() => {
		if (!subjects || !subjects.length) return;

		const grades = userInfo["Grade(s) Taught"] ?? [];
		setGradeFilter(() =>
			CurriculumGradeLevels.map((g) => grades.includes(g)),
		);
		
		const focusSubjectIDs = userInfo["Subject(s) Taught"] ?? [];
		setSubjectFilter(() =>
			subjects.map((s) => focusSubjectIDs.includes(s.id)),
		);

		setInitialLoaded(true);
	}, [subjects]); // eslint-disable-line react-hooks/exhaustive-deps

	function handleSelectGrade(e) {
		const i = parseInt(e.target.value);
		const g = [...gradeFilter];
		g[i] = !g[i];
		setGradeFilter(() => g);
	}

	function handleSelectSubject(e) {
		const i = parseInt(e.target.value);
		const f = [...subjectFilter];
		f[i] = !f[i];
		setSubjectFilter(() => f);
	}

	function handleClearFilter() {
		setSubjectFilter(new Array(subjects.length).fill(false));
		setGradeFilter(new Array(CurriculumGradeLevels.length).fill(false));
	}

	function handleShowAll(e) {
		setShowAll(() => e.target.value === "true");
	}

	function handleApplyFilter() {
		applyFilterCallback();
	}

	return {
		gradeFilter,
		handleSelectGrade,
		subjectFilter,
		handleSelectSubject,
		handleClearFilter,
		handleApplyFilter,
		showAll,
		handleShowAll,
		initialLoaded
	};
}
