import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import greatLogo from "../../../assets/img/great-t.png"
import Commitment from '../components/Commitment';
import ScheduleInterviewModal from '../../modals/ScheduleInterview';
import { DEFAULT_SESSION_FOR_GREET_SECTION } from '../../../utils/constants';
import airtable from "../../../airtables";
import SessionInfo from '../../../components/SessionInfo';
import { getActionMenusForExample, getDisplayInfosForExample } from '../../SessionInfoDisplay';
import { getProviderSessionIDFromSession } from '../../../utils/session';

const NavContent = ({
  defaultReflection,
  commitment,
  width
}) => {
  const { userInfo } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const encodedName = encodeURIComponent(userInfo["Name"]);
  const [videoHeight, setVideoHeight] = useState(0);
  const [session, setSession] = useState(null);
  const [interviewModal, showInterviewModal] = useState(false);

  useEffect(() => {
    airtable.sessions.select(DEFAULT_SESSION_FOR_GREET_SECTION).then(res => {
      setSession(res);
    })
  }, [])

  useEffect(() => {
    setVideoHeight(width / 1.69)
  }, [width])

  useEffect(() => {
    if (interviewModal) {
      loadCalendlyScript();
    }

    return () => {
      // Clean up the script when the component unmounts
      const script = document.querySelector('script[src*="widget.js"]');
      if (script) {
        script.remove();
      }
    };
  }, [interviewModal]);

  const loadCalendlyScript = () => {
    // Check if the script is already loaded
    if (document.querySelector('script[src*="widget.js"]')) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  };

  const doCompleteProcess = () => {
    if (!userInfo["ConfirmationGreet"]) return false;
    if (!userInfo["ConfirmationReview"]) return false;
    if (!userInfo["ConfirmationEngage"]) return false;
    if (!userInfo["ConfirmationAct"]) return false;
    if (!userInfo["ConfirmationThank"]) return false;
    showInterviewModal(true);
  }

  return (
    <div className="nav-content-wrap">
      <div className="main-content">
        <h4>{t("session-host-bp.thank.title")}</h4>
        <img
          src={greatLogo}
          className="great-logo sh-img"
          alt=""
        />

        <span>{t("session-host-bp.thank.p1")}</span>

        <ul className="session-host mb0">
          <li>{t("session-host-bp.thank.p1-li1")}</li>
          <li>{t("session-host-bp.thank.p1-li2")}</li>
        </ul>

        <p>{t("session-host-bp.thank.p3")}</p>

        <div>
          {!!width && (
            <iframe
              src="https://player.vimeo.com/video/997932483?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Session Host Training - Thank Example"
              width={width}
              height={videoHeight}
            />
          )}
        </div>
        
        <p>{t("session-host-bp.thank.p4")}</p>
        
        <div className="example-session">
          {!!session && <SessionInfo
            displayInfos={getDisplayInfosForExample(session)}
            actionMenu={getActionMenusForExample(session)}
            session={session}
            dateType="full"
            hideActionContacts="true"
            sessionDetailLink={getProviderSessionIDFromSession(session)}
          />}
        </div>
        <span>{t("session-host-bp.thank.s5")}</span>
        <ul className="session-host">
          <li>{t("session-host-bp.thank.s5-li1")}</li>
          <li>{t("session-host-bp.thank.s5-li2")}</li>
          <li>{t("session-host-bp.thank.s5-li3")}</li>
        </ul>

        <iframe
          src={`https://www.videoask.com/fb5xpwprw#contact_name=${encodedName}&recordid=${userInfo.id}`}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          title="www"
          width="100%"
          height="600px"
        />

        <Commitment
          label={t("session-host-bp.thank.reflection-label")}
          confirmLabel={t("session-host-bp.thank.agree")}
          btnLabel={t("session-host-bp.thank.continue")}
          defaultReflection={defaultReflection}
          submitConfirm={async (reflection) => {
            await commitment(reflection);
            doCompleteProcess();
          }}
        />
      </div>

      {interviewModal && (
        <ScheduleInterviewModal
          onToggle={() => showInterviewModal(!interviewModal)}
        />
      )}
    </div>
  )
}

export default NavContent;