import React, { Fragment, useEffect, useState } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { Nav, NavItem, TabContent, NavLink as TabNavLink, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames'
import SessionListComponent from './SessionListComponent';

const Screen = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [activeTab, setActiveTab] = useState();
  const [types, setTypes] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let ts;
    switch (userType) {
      case "Team":
        ts = [
          {
            name: "booked",
            params: { type: "School Lead", name: userInfo["Name"], status: "Booked", isAdmin: userInfo['Administrator'] }
          },
          {
            name: "unrated",
            params: { type: "School Lead", name: userInfo["Name"], status: "Completed", pastType: "unrated", isAdmin: userInfo['Administrator'] }
          },
          {
            name: "unpaid",
            params: { type: "School Lead", name: userInfo["Name"], status: "Completed", pastType: "unpaid", isAdmin: userInfo['Administrator'] }
          },
          {
            name: "completed",
            params: { type: "School Lead", name: userInfo["Name"], status: "Completed", pastType: "rated", isAdmin: userInfo['Administrator'] }
          }
        ];
        break;
      case "Teacher":
        ts = [
          {
            name: "rate-past",
            params: { type: "Teacher", name: userInfo["Teacher Name"], status: "Completed", pastType: "unrated" }
          },
          {
            name: "all-past",
            params: { type: "Teacher", name: userInfo["Teacher Name"], status: "Completed" }
          }
        ]
        break;
      case "Provider":
        ts = [{
          name: "rate-past",
          params: { type: "Provider", name: userInfo["Name"], status: "Completed" }
        }]
        break;
      default:
        ts = [];
        break;
    }
    if (ts.length) {
      setTypes(ts)
      setActiveTab(ts[0].name);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderContent = () => {
    if (!types || types.length === 0) return null;
    if (types.length === 1) {
      return renderOnePage(types[0]);
    }

    return (
      <Fragment>
        <Nav tabs className="tabs">
          {types.map((type, i) => (
            <NavItem key={i}>
              <TabNavLink
                className={classnames({ active: activeTab === type.name })}
                onClick={() => setActiveTab(type.name)}
              >
                {t(type.name)}
              </TabNavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {types.map((type, i) => (
            <TabPane tabId={type.name} key={i}>
              {renderOnePage(type)}
            </TabPane>
          ))}
        </TabContent>
      </Fragment>
    )
  }

  const renderOnePage = (type) => {
    return (
      <SessionListComponent
        type={type.name}
        params={type.params}
      />
    );
  }

  const renderTitle = () => {
    if (userType === 'Team') {
      return (
        <h1>{t("manage-past-sessions")}</h1>
      )
    }

    return (
      <h1>{t("rate-past-sessions")}</h1>
    )
  }

  return (
    <div className="main-container">
      {renderTitle()}
      <div style={{ marginTop: 10 }}>
        {renderContent()}
      </div>
    </div>
  )
}

export default Screen;