import AirtableTable from "../AirtableTable";

export class C2LTable extends AirtableTable {
  constructor(instance) {
    super(instance, "C2L");

    this.fields = {
      "Series Name": "fldSGgRkq7D251jcy",
      "Creator": "flduVRfxsbXPL4WP9",
      "Thumbnail": "fldoUDG2zgCFVJ2tP",
      "Description": "fldcgmGHycasomuGe",
      "Web URL": "flduF9DPeUnUkWyib",
      "Videos": "fldLHrCBOez2ioriQ",
      "Total Length": "fldoUp5O3qth2x3eS",
      "Difficulty": "fldaudjqkziId5LMy"
    }
  }

  getC2lsByIds = (ids) => {
    if (!ids || ids.length === 0) return [];

    const filter = ids.map((id) => `RECORD_ID() = "${id}"`);
    return this.list({
      filterByFormula: `OR(${filter})`,
    })
  }
}
