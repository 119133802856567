import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { ArrowButton } from "../components/ArrowButton";
import { useUpdateUserInfo } from "../hooks/use-update-user-info";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.welcomeTeacher`;

export const WelcomeTeacher = () => {
	const { t } = useTranslation();
	const { confirmUserInfoUpdated, loading } = useUpdateUserInfo();

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	});

	return (
		<Box className="content-wrap">
			<iframe src="https://player.vimeo.com/video/996424270?h=1bd303a0c9&autoplay=1&title=0&byline=0&portrait=0" title="Welcome Video" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
			<Typography variant="h1">{t(`${pageKey}.mainHeading`)}</Typography>
			<Typography variant="body1">{t(`${pageKey}.introText`)}</Typography>
			<ArrowButton
				onClick={confirmUserInfoUpdated}
				disabled={loading}
				right
			>
				{t(`${pageKey}.nextButton`)}
			</ArrowButton>
		</Box>
	);
};
