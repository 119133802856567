import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { ArrowButton } from "./ArrowButton";
import { ArrowLink } from "./ArrowLink";

export const StepProgress = ({
	formId = "",
	backLink = "",
	currentStep = 0,
	totalSteps = 0,
	disabled = false,
	backLabel = "teacherOnboard.backButton",
	nextLabel = "teacherOnboard.nextButton",
}) => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				gap: "5rem",
			}}
		>
			<ArrowLink to={backLink} left>
				{t(backLabel)}
			</ArrowLink>
			<Typography variant="body1">{`${currentStep}/${totalSteps}`}</Typography>
			<ArrowButton type="submit" form={formId} disabled={disabled} right>
				{t(nextLabel)}
			</ArrowButton>
		</Box>
	);
};
