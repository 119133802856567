import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from '../../components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import { setAppUserInfo } from '../../redux/actions';
import { setSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO } from '../../config';
import { useTranslation } from 'react-i18next';
import MapWithAKmlLayer from '../../components/MapWithAKmlLayer';
import OverlayLoading from '../../components/OverlayLoading';
import PresenterFormModal from '../modals/PresenterForm';
import { setPresentersForProvider } from '../../redux/actions';
import airtable from '../../airtables';
import uploadFile from '../../libs/aws/uploadfile';
import { toast } from 'react-toastify';
import Select from 'react-select';
import './styles.scss';
import { DAY_LIMITATIONS, SELECTBOX_STYLE } from '../../utils/constants';

const Screen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { userInfo, presentersForProvider } = useSelector(state => state.appInfo);
    const fileInputRef = useRef();

    const [updating, setUpdating] = useState(false);
    const [name, setName] = useState("");
    const [pronouns, setPronouns] = useState("")
    const [email, setEmail] = useState("");
    const [bio, setBio] = useState("");
    const [logo, setLogo] = useState("");
    const [logoFile, setLogoFile] = useState();
    const [instructions, setInstructions] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("");
    const [timezone, setTimezone] = useState("");
    const [community, setCommunity] = useState("");
    const [dayLimitation, setDayLimitation] = useState([]);
    const [earliestStartTime, setEarliestStartTime] = useState("");
    const [recordingAllowed, setRecordingAllowed] = useState("");
    const [receiveRequestNotifications, setReceiveRequestNotifications] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [processingTitle, setProcessingTitle] = useState("");
    const [activePresenter, setActivePresenter] = useState(null);
    const [activePresenterNo, setActivePresenterNo] = useState();
    const [showPresenterModal, setShowPresenterModal] = useState(false);
    const [language, setLanguage] = useState(userInfo["Preferred Language"] || 'English')
    const [communities, setCommunities] = useState([])

    useEffect(() => {
        airtable.communities.getTotalCommunities().then(res => setCommunities(res))
    }, [])

    useEffect(() => {
        setName(userInfo['Contact Name']);
        setPronouns(userInfo["Pronouns"]);
        setEmail(userInfo['Email']);
        setPhoneNumber(userInfo['Phone']);
        setTimezone(userInfo["Timezone"]);
        setDayLimitation(getSelectRecords(userInfo["Day Limitations"]))
        setEarliestStartTime(userInfo["Earliest Start Time"] || "8:30");
        setBio(userInfo["Introduction/Bio"]);
        setRecordingAllowed(userInfo["Recording Allowed"]);
        setReceiveRequestNotifications(userInfo["Autobook"]);
        setInstructions(userInfo["Special Instructions"] ? userInfo["Special Instructions"] : '');
        setCommunity(userInfo["Indigenous Communities"] ? userInfo["Indigenous Communities"][0] : "");
        if (userInfo["Logo/Photo"] && userInfo["Logo/Photo"].length) {
            if (userInfo["Logo/Photo"][0].thumbnails) {
                setLogo(userInfo["Logo/Photo"][0].thumbnails.large.url)
            } else {
                setLogo(userInfo["Logo/Photo"][0].url)
            }
        }
        setLanguage(userInfo["Preferred Language"] || 'English');
    }, [userInfo])

    const getSelectRecords = (val) => {
        if (!val) return [];
        return val.map(item => ({
            value: item,
            label: item
        }))
    }

    const getValuesFromSelectRecords = (records) => {
        const vs = records.map(record => record.value)
        if (vs.length === 0) return null;
        return vs;
    }

    const updateInfo = async () => {
        setUpdating(true);
        try {
            let params = {
                "Contact Name": name,
                "Email": email.trim(),
                "Phone": phoneNumber,
                "Timezone": timezone,
                "Indigenous Communities": community ? [community] : [],
                "Day Limitations": getValuesFromSelectRecords(dayLimitation),
                "Earliest Start Time": earliestStartTime,
                "Introduction/Bio": bio,
                "Recording Allowed": recordingAllowed,
                "Pronouns": pronouns,
                "Special Instructions": instructions,
                "Autobook": receiveRequestNotifications,
                "Preferred Language": language,
            }

            if (logoFile) {
                const uRes = await uploadFile(logoFile);
                params["Logo/Photo"] = [{ url: uRes }];
                setLogoFile(null);
            }

            const info = await airtable.providers.update(userInfo.id, params);

            await setSession(CONNECTEDNORTH_SIGNINFO, info);
            dispatch(setAppUserInfo(info));
            toast.success("Saved successfully!")
        } catch (error) { }
        setUpdating(false);
    }

    const archivePresenter = async (presenter, no) => {
        setProcessingTitle("Archiving...");
        setProcessing(true);
        await airtable.presenters.update(presenter.id, {
            Archived: true
        });
        let newPs = [...presentersForProvider];
        newPs[no].Archived = true;
        dispatch(setPresentersForProvider(newPs))
        setProcessingTitle("");
        setProcessing(false);
    }

    const unarchivePresenter = async (presenter, no) => {
        setProcessingTitle("Unarchiving...");
        setProcessing(true);
        await airtable.presenters.update(presenter.id, {
            Archived: false
        });
        let newPs = [...presentersForProvider];
        newPs[no].Archived = false;
        dispatch(setPresentersForProvider(newPs))
        setProcessingTitle("");
        setProcessing(false);
    }

    const editPresenter = async (presenter, no) => {
        setActivePresenter(presenter);
        setActivePresenterNo(no);
        setShowPresenterModal(true)
    }

    const addPresenter = () => {
        setActivePresenter(null);
        setActivePresenterNo(-1);
        setShowPresenterModal(true);
    }

    const updatePresenterFromModal = async (email, name, phone) => {
        setShowPresenterModal(false);

        setProcessing(true);
        if (activePresenter) {
            setProcessingTitle("Updating...");
            const presenterInfo = await airtable.presenters.update(activePresenter.id, {
                "Name": name,
                "Email": email,
                "Phone": phone,
            });

            let newPs = [...presentersForProvider];
            newPs[activePresenterNo] = presenterInfo;
            dispatch(setPresentersForProvider(newPs));
        } else {
            setProcessingTitle("Adding...");
            const params = {
                Email: email,
                Name: name,
                Phone: phone,
                Provider: [userInfo.id]
            };
            const pInfo = await airtable.presenters.create(params);
            let newPs = [...presentersForProvider, pInfo];
            dispatch(setPresentersForProvider(newPs));
        }
        setProcessingTitle("");
        setProcessing(false);

        setActivePresenter(null);
        setActivePresenterNo(-1);
    }

    const deletePresenter = async (presenter, i) => {
        if (window.confirm("Are you sure you want to delete this presenter?")) {
            setProcessingTitle("Deleting...");
            setProcessing(true);
            await airtable.presenters.delete(presenter.id);

            let newPs = [...presentersForProvider];
            newPs.splice(i, 1);
            dispatch(setPresentersForProvider(newPs));

            setProcessingTitle("");
            setProcessing(false);
        }
    }

    return (
        <div className="main-container">
            {
                userInfo["Unique Schools"] >= 3 && (
                    <div style={{ marginBottom: 20 }}>
                        <h1>{userInfo["Unique Schools"]} {t("schools-reached")}</h1>
                        <MapWithAKmlLayer recId={userInfo.id} type="provider_schools" />
                    </div>
                )
            }

            <h1>{t("my-info")}</h1>

            {updating && <LoadingBar />}
            <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}>
                <div style={{ padding: 20 }}>
                    <div>
                        {
                            logo ? (
                                <img src={logo} style={{ maxWidth: 400, height: 'auto' }} alt="" />
                            ) : <div className="no-img" />
                        }

                        <input
                            type="file"
                            accept="application/image"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={e => {
                                setLogoFile(e.target.files[0]);
                                setLogo(URL.createObjectURL(e.target.files[0]));
                            }}
                        />

                        <div style={{ paddingTop: 10 }}>
                            <Button size="sm" onClick={() => fileInputRef.current.click()} type="button" color="secondary" >Select Image</Button>
                        </div>
                    </div>
                </div>
                <div style={{ maxWidth: 500, minHeight: 500, padding: 20 }} align="left">
                    <FormGroup row>
                        <Label for="name" sm={4}>{t("contact-name")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Contact Name"
                                defaultValue={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="name" sm={4}>{t("pronouns")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="text"
                                name="pronouns"
                                id="pronouns"
                                placeholder={t("pronouns")}
                                defaultValue={pronouns}
                                onChange={e => setPronouns(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="email" sm={4}>{t("email")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email"
                                defaultValue={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="email" sm={4}>Preferred Language</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="select"
                                className='pronouns form-control'
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >
                                <option value="English">English</option>
                                <option value="French">French</option>
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="phone" sm={4}>{t("phone-number")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="Phone Number"
                                defaultValue={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="timezone" sm={4}>{t("timezone")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="select"
                                name="timezone"
                                id="timezone"
                                className="form-control"
                                value={timezone}
                                onChange={e => setTimezone(e.target.value)}
                            >
                                <option></option>
                                <option>Canada/Eastern</option>
                                <option>Canada/Central</option>
                                <option>Canada/Mountain</option>
                                <option>Canada/Saskatchewan</option>
                                <option>Canada/Pacific</option>
                                <option>Canada/Yukon</option>
                                <option>US/Alaska</option>
                                <option>Other</option>
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="community" sm={4}>{t("community")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="select"
                                name="community"
                                id="community"
                                className="form-control"
                                value={community}
                                onChange={e => setCommunity(e.target.value)}
                            >
                                <option></option>
                                {communities.map((com, i) => <option value={com.id} key={i}>{com.Name}</option>)}
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="dayLimitation" sm={4}>Daily Availability</Label>
                        <Col sm={8}>
                            <Select
                                isMulti
                                className="form-style"
                                value={dayLimitation}
                                onChange={(selectedOptions) => setDayLimitation(selectedOptions)}
                                options={DAY_LIMITATIONS.map(limitation => ({
                                    value: limitation,
                                    label: limitation
                                }))}
                                styles={SELECTBOX_STYLE}
                            />
                        </Col>
                    </FormGroup>
                    <span style={{ fontSize: '0.85rem' }}>If you're only available for sessions on certain days of the week, select those days to limit when requests can be made. If you are available on any day of the week, you do not need to make a selection.</span>
                    <FormGroup row style={{ marginTop: '1rem' }}>
                        <Label for="earliestStartTime" sm={4}>Earliest Start Time</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="select"
                                name="earliestStartTime"
                                id="earliestStartTime"
                                className="form-control"
                                value={earliestStartTime}
                                onChange={e => setEarliestStartTime(e.target.value)}
                            >
                                <option>7:00</option>
                                <option>7:30</option>
                                <option>8:00</option>
                                <option>8:30</option>
                                <option>9:00</option>
                                <option>9:30</option>
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="recordingAllowed" sm={4}>{t("recording-allowed")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="select"
                                name="recordingAllowed"
                                id="recordingAllowed"
                                className="form-control"
                                value={recordingAllowed}
                                onChange={e => setRecordingAllowed(e.target.value)}
                            >
                                <option></option>
                                <option>Yes</option>
                                <option>Ask each time</option>
                                <option>No</option>
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="introductionBio" sm={4}>{t("introduction-bio")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="textarea"
                                name="introductionBio"
                                id="introductionBio"
                                style={{ height: 150 }}
                                value={bio}
                                onChange={e => setBio(e.target.value)}
                            ></Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row style={{ marginBottom: 0 }}>
                        <Label for="receiveNotifications" data-intercom-target="Request Notifications" sm={4}>{t("receive-request-notifications")}</Label>
                        <Col sm={8} className="v-center">
                            <input
                                id="receiveNotifications"
                                type="checkbox"
                                checked={receiveRequestNotifications}
                                onChange={(e) => setReceiveRequestNotifications(e.target.checked)}
                            />
                        </Col>
                    </FormGroup>

                    <span style={{ fontSize: '0.85rem' }}>{t("myinfo-receive-request-notifications-desc")}</span>

                    <FormGroup row style={{ marginTop: '1rem' }}>
                        <Label for="instructions" sm={4}>{t("availability-booking-instructions")}</Label>
                        <Col sm={8} className="v-center">
                            <Input
                                type="textarea"
                                name="instructions"
                                id="instructions"
                                style={{ height: 150 }}
                                value={instructions}
                                placeholder="Please provide any general notes on availability, for example, only available Tuesday and Thursday, or special booking instructions, such as a booking web form or process"
                                onChange={e => setInstructions(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="timezone" data-intercom-target="Google Calendar" sm={4}>{t("google-calendar")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                userInfo['Calendar Token'] ? (
                                    <span>✓ {t("connected")} (<a href={`https://research.tigweb.org/connectednorth/provider-availability/disconnect.html?provider=${userInfo.id}`}>{t("disconnect")}</a>)</span>
                                ) : (
                                    <a href={`https://research.tigweb.org/connectednorth/provider-availability/calendarauth.html?provider=${userInfo.id}`}><span>{t("connect-google-calendar")}</span></a>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <span style={{ fontSize: '0.85rem' }}>{t("myinfo-google-calendar-desc")}</span>

                    <div className="ms-action" data-intercom-target="Save">
                        <Button
                            color="primary"
                            size="sm"
                            onClick={() => updateInfo()}
                            disabled={(!name || !email)}
                        >{t("save-change")}</Button>
                    </div>
                </div>
            </div>

            {
                userInfo["Individual/Org/Post-Sec."] !== 'Individual' && (
                    <div style={{ marginTop: 20 }}>
                        <Row>
                            <Col>
                                <h1>Presenters</h1>
                            </Col>
                            <Col align="right">
                                <Button color="primary" size="sm" onClick={() => addPresenter()}><i className="fa fa-plus" />&nbsp;Add Presenter</Button>
                            </Col>
                        </Row>
                        <div>
                            {
                                presentersForProvider.map((presenter, i) => (
                                    <div className="presenter-container" key={i}>
                                        <div style={{ flex: 1, display: 'flex' }}>
                                            <div style={{ flex: 1 }}><span>{presenter.Name}{
                                                presenter.Archived ? (<span>(Archived)</span>) : null
                                            }</span></div>
                                            <div style={{ flex: 1 }}><span>{presenter.Email}</span></div>
                                            <div style={{ flex: 1 }}><span>{presenter.Phone}</span></div>
                                        </div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="secondary">
                                                <i className="fa fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => editPresenter(presenter, i)}>Edit</DropdownItem>
                                                {
                                                    !presenter.Archived ? (
                                                        <DropdownItem onClick={() => archivePresenter(presenter, i)}>Archive</DropdownItem>
                                                    ) : (
                                                        <DropdownItem onClick={() => unarchivePresenter(presenter, i)}>Unarchive</DropdownItem>
                                                    )
                                                }
                                                {
                                                    (!presenter["Sessions Delivered"]) ? (
                                                        <DropdownItem onClick={() => deletePresenter(presenter, i)}>Delete</DropdownItem>
                                                    ) : null
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                ))
                            }
                        </div>
                        <OverlayLoading visible={processing} title={processingTitle} />
                        {
                            showPresenterModal ? (
                                <PresenterFormModal
                                    presenter={activePresenter}
                                    onToggle={() => {
                                        setShowPresenterModal(false);
                                        setActivePresenter(null);
                                        setActivePresenterNo(-1);
                                    }}
                                    onSave={updatePresenterFromModal}
                                />
                            ) : null
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Screen;
