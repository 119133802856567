
import AirtableTable from "../AirtableTable";

export class FundTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Funds");

    this.fields = {
      "Funder": "fldeil9bO44S8K3sY",
      "Xero Tracking Category": "fldznPFyLO1wKJo1d",
      "End Date": "fld13plzQ5uxcJ5Gu",
      "Focus": "fldCAWC0uM32jBvOX",
      "# of Schools": "fldxXcioWp99HAxVl",
      "# of Teachers": "flds69B4uSae0ArEM",
      "# of Sessions": "fldGuz8mgEGKvMLb1",
      "Budget": "fldVg6e9kIKXNsvw3",
      "Spent": "fldKHrOFGaZMlIvlq",
      "Remaining": "fldhR2CiTkG9hBzkf",
      "Billed Sessions": "fldHNGBN552FLIs6h",
      "Jayson's Billed Sessions": "flduHy4g00HjzIn9s",
      "Jayson's Spending": "fld99JKD72rZU9I7r",
      "Jayson's Budget": "fldLOOfneMQJNGwpa",
      "Jayson's Budget Remaining": "flddAsy4C5vdBHZse",
      "Brenda's Budget": "fldPBbk9w8J15u6mW",
      "Brenda's Spending": "fldY54IGNstnGipw6",
      "Brenda's Budget Remaining": "fldwFRCaBwlHuaf5e",
      "Brenda's Billed Sessions": "fldaKLIbAhmZscmXG",
      "Sara's Budget": "fld3exmiNp8dksuG3",
      "Sara's Spending": "fldbch6AncY9BxKaW",
      "Sara's Budget Remaining": "fldwaXpDao7oBa81R",
      "Sara's Billed Sessions": "fldyq0Gx3VgRmY6zo",
      "Jennifer's Budget": "fld66LjokuATaCTf8",
      "Jennifer's Spending": "fld4eBvK7ZKJcZB33",
      "Jennifer's Budget Remaining": "fldK4tC1bmBpsAieu",
      "Jennifer's Billed Sessions": "fldFRip3I71l6Oxwt",
      "Serei's Budget": "fldhVjdFIv83ZPpq5",
      "Serei's Spending": "fldeW7XGnrz1z1EV4",
      "Serei's Budget Remaining": "fldneLK8VISU546Ob",
      "Serei's Billed Sessions": "fld7CexugAemGDXSr",
      "Mali's Budget": "fldmKI7mrY784LaD3",
      "Mali's Spending": "fld2IkFZwISPpNqj8",
      "Mali's Budget Remaining": "fldSdK8onAZ2fRa3l",
      "Mali's Billed Sessions": "fldJsAxSdkW5j91FJ",
      "Katie's Budget": "fldrD9HQutYa0YXg5",
      "Katie's Spending": "fld4b981lPj8Ka0GN",
      "Katie's Budget Remaining": "fldyclyVSSW7kQ2mh",
      "Katie's Billed Sessions": "fldae6DuRuDAAvzMT",
      "Kim's Budget": "fldrWolvrJ3dfVlcR",
      "Kim's Spending": "fldSjhOUqppMPEKwG",
      "Kim's Budget Remaining": "fldPatWx09M0kme9H",
      "Kim's Billed Sessions": "fldobB639v3s971SC",
      "Migwin's Budget": "fldHE7NOFg5SqRec2",
      "Migwin's Spending": "fldbOlIR6sqJHXPyz",
      "Migwin's Budget Remaining": "fldXbiqlOtoyGZSGw",
      "Migwin's Billed Sessions": "fldqbgbivoGTnapmA",
      "Anne-Marie's Budget": "fldY7KnUzvuy4bkPn",
      "Anne-Marie's Spending": "fld9sSHKRKv8seXma",
      "Anne-Marie's Budget Remaining": "fld9hnHvLBB2J6cGB",
      "Anne-Marie's Billed Sessions": "fldV2BxFGcgPRt4wN",
      "Waukomaun's Budget": "fldjtyojxw0vy01zK",
      "Waukomaun's Spending": "fld7JsZTX6QGfpfU4",
      "Waukomaun's Budget Remaining": "fldotyuCdneFVXmiP",
      "Waukomaun's Billed Sessions": "fld4doYDAVO8W0Wij",
      "Team-specific": "fldVnbRMaEi5waISB",
      "Cluster-specific": "fldMyEd4WIZgzU4il",
      "# of Cluster Schools": "flddQqVhXwsTxD2fG",
      "Closed": "flddZdEl56oe0isyV",
      "NU Supported": "fld9XmtXbAwOFF1qq"
    }


  }

  getFunders = async (team, session) => {
    const conditions = [
      "{Closed} = FALSE()",
      "{End Date} >= NOW()",
      `OR({Team-specific} = BLANK(), FIND("${team["Name"]}", {Team-specific}))`,
    ]

    const candidates = await this.list({
      filterByFormula: `AND(${conditions.join(",")})`
    });

    return candidates.filter(candidate => {
      if (candidate["Cluster-specific"]) {
        if (session["School Cluster"]) return false;

        for (let i = 0; i < candidate["Cluster-specific"].length; i++) {
          if (session["School Cluster"].indexOf(candidate["Cluster-specific"]) >= 0) return true;
        }
        return false;
      } else {
        return true;
      }
    })
  }
}
