import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import logoImg from '../../assets/img/logo1.png';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import airtable from '../../airtables';
import * as EmailValidator from 'email-validator';
import { setAppUserInfo, setAppUserType } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { setSession } from "../../utils/session";
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../../config';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import './styles.scss';
import { analytics } from '../../firebase';

const Screen = (props) => {
    const { t } = useTranslation();
    const { match: { params: { username, providerId } } } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [height, setHeight] = useState(0);
    const [provider, setProvider] = useState(null);
    const [saving, setSaving] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const resizeListener = () => {
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.document.title = "Register - Connected North"
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        setTimeout(async () => {
            resizeListener();
            const p = await airtable.providers.select(providerId)
            setProvider(p);
            setEmail(`${username}@${p.Domain}`)
            setLoading(false)
        });

        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const registerPresenter = async () => {
        if (!email || !EmailValidator.validate(email)) {
            alert("Email field should be non-empty and valid.");
            return;
        }
        if (!name) {
            alert("You should input your full name.");
            return;
        }
        if (!phone) {
            alert("You should input your phone number.");
            return;
        }

        setSaving(true);
        const userInfo = await airtable.presenters.createPresenter(provider.id, email, name, phone);
        const userType = "Presenter";

        await Promise.all([
            setUserId(analytics, email),
            setUserProperties(analytics, 'user_type', userType),
            logEvent(analytics, 'register', { email, userType }),
            logEvent(analytics, 'login', { email, userType })
        ]);

        await setSession(CONNECTEDNORTH_SIGNINFO, userInfo);
        await setSession(CONNECTEDNORTH_SIGNTYPE, userType);

        dispatch(setAppUserInfo(userInfo));
        dispatch(setAppUserType(userType));
        setSaving(false);
        window.location.href = "/cn/upcoming";
    }

    const renderContent = () => {
        if (loading) return (
            <div className="registering-content">
                <Loading size={24} />
            </div>
        )

        return (
            <div className="registering-content">
                <FormGroup className="registering-item">
                    <Label for="email">Email</Label>
                    <Input
                        id="email"
                        className="form-style"
                        placeholder={t("email")}
                        defaultValue={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </FormGroup>
                <FormGroup className="registering-item">
                    <Label for="name">Name</Label>
                    <Input
                        id="name"
                        className="form-style"
                        placeholder={t("full-name")}
                        defaultValue={name}
                        onChange={e => setName(e.target.value)}
                    />
                </FormGroup>
                <FormGroup className="registering-item">
                    <Label for="phone">Phone Number</Label>
                    <Input
                        id="phone"
                        className="form-style"
                        placeholder={t("phone-number")}
                        defaultValue={phone}
                        onChange={e => setPhone(e.target.value)}
                    />
                </FormGroup>

                <div className="registering-item registering-actions">
                    <Button
                        type="button"
                        onClick={() => window.location.href = "/login"}
                    >{t("back-login")}</Button>
                    <Button
                        color="success"
                        type="button"
                        onClick={() => registerPresenter()}
                        disabled={saving}
                    >{saving ? (<Loading size={14} />) : (null)}{t("register-presenter")}</Button>
                </div>
            </div>
        )
    }

    return (
        <main>
            <section className="section section-shaped section-lg" style={{ background: 'rgb(85, 197, 242, 0.7)', height }}>
                <div className="shape shape-style-1 bg-gradient-default">
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                </div>
                <Container className="pt-lg-8">
                    <Row className="justify-content-center align-items-center" style={{ height: '100%', paddingBottom: 70 }}>
                        <Col lg="8">
                            <Card className="bg-secondary shadow border-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="rate-logo">
                                        <img src={logoImg} style={{ padding: '0 20px', maxWidth: 300, marginBottom: 10 }} alt="" />
                                        <span>{t("register-presenter-title")}</span>
                                    </div>
                                    {renderContent()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    )
}

export default Screen;