import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Input, Label, Modal } from 'reactstrap';

const InternalIDModal = ({ session, onSave, onToggle }) => {
    const { t } = useTranslation();
    const [internalId, setInternalId] = useState("");

    useEffect(() => {
        if (session) {
            setInternalId(session["Provider Internal ID"]);
        }
    }, [session])

    const save = () => {
        onSave(session.id, internalId);
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={true}
            toggle={onToggle}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">{session["Provider Internal ID"] ? t("edit-internal-id") : t("add-internal-id")}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={onToggle}
                ><span aria-hidden={true}>×</span></button>
            </div>
            <div className="modal-body">
                <div className="">
                    <FormGroup className="registering-item">
                        <Label for="name">Internal ID</Label>
                        <Input
                            id="name"
                            className="form-style"
                            defaultValue={internalId}
                            onChange={e => setInternalId(e.target.value)}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => save()}
                >Save</Button>
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={onToggle}
                >Close</Button>
            </div>
        </Modal>
    )
}

export default InternalIDModal;