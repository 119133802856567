import React, { useEffect, useRef, useState } from 'react';
import Commitment from '../components/Commitment';
import Vimeo from '@u-wave/react-vimeo';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from 'firebase/analytics'
import { updateVideoHistory } from '../videotrack';
import { setAppUserInfo } from '../../../redux/actions';
import { Trans, useTranslation } from 'react-i18next';
import { analytics } from '../../../firebase';

const url1 = "https://www.whose.land/en/";
const url2 = "https://takingitglobal.uberflip.com/i/1389665-whose-land-lesson-plan-professional-learning/0";
const url3 = "https://www.cbc.ca/news/indigenous/land-acknowledgments-what-s-wrong-with-them-1.6217931"

const NavContent = ({ commitment }) => {
  const ref = useRef(null);
  const [videoWidth, setVideoWidth] = useState(0);
  const [watchStarted, setWatchStarted] = useState(false);
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setVideoWidth(ref.current.offsetWidth);
  }, [])

  const trackVideo = async (type) => {
    logEvent(analytics, `video-${type}`, {
      viewer: userInfo['Name'],
      video: `bp-land-christine-mlot`
    })
  }
  const trackVideoRT = async (type) => {
    logEvent(analytics, `video-${type}`, {
      viewer: userInfo['Name'],
      video: `bp-land-rebecca-thomas`
    })
  }

  const onPlay = async () => {
    if (!watchStarted) {
      setWatchStarted(true);
      trackVideo('play');
      const nUserInfo = await updateVideoHistory(userType, userInfo, "Christine M'Lot", "starting");
      dispatch(setAppUserInfo(nUserInfo));
    }
  }
  const onEnd = async () => {
    trackVideo('full-watch');
    const nUserInfo = await updateVideoHistory(userType, userInfo, "Christine M'Lot", "finished");
    dispatch(setAppUserInfo(nUserInfo));
  }
  const onPlayRT = async () => {
    if (!watchStarted) {
      setWatchStarted(true);
      trackVideoRT('play');
      const nUserInfo = await updateVideoHistory(userType, userInfo, "Rebecca Thomas", "starting");
      dispatch(setAppUserInfo(nUserInfo));
    }
  }
  const onEndRT = async () => {
    trackVideoRT('full-watch');
    const nUserInfo = await updateVideoHistory(userType, userInfo, "Rebecca Thomas", "finished");
    dispatch(setAppUserInfo(nUserInfo));
  }

  return (
    <div style={{ width: '100%', marginTop: 10 }} ref={ref}>
      <div className="main-content">
        <p><strong>{t("best-practices-content.mla.title")}</strong></p>
      </div>
      <div className="embeded-conatiner">
        {
          videoWidth !== 0 && (
            <Vimeo
              video="705237871"
              width={videoWidth}
              onPlay={() => onPlay()}
              onEnd={() => onEnd()}
            />
          )
        }
      </div>

      <div className="main-content">
        <p><em>{t("best-practices-content.mla.indigenous")}</em></p>

        <p>{t("best-practices-content.mla.para1")}</p>

        <p>{t("best-practices-content.mla.para2")}</p>

        <p>{t("best-practices-content.mla.para3")}</p>

        <strong>{t("best-practices-content.mla.para4.lead")}</strong>
        <ul className="ul1 bp-menu">
          <li>{t("best-practices-content.mla.para4.li1")}</li>
          <li>{t("best-practices-content.mla.para4.li2")}</li>
          <li>{t("best-practices-content.mla.para4.li3")}</li>
          <li>{t("best-practices-content.mla.para4.li4")}</li>
        </ul>

        <p>{t("best-practices-content.mla.para6")}</p>
        <div className="embeded-conatiner">
          {
            videoWidth !== 0 && (
              <Vimeo
                video="744612374"
                width={videoWidth}
                onPlay={() => onPlayRT()}
                onEnd={() => onEndRT()}
              />
            )
          }
        </div>
        <br /><strong>{t("best-practices-content.mla.para5.lead")}</strong>
        <ul className="ul2 bp-menu">
          <li>
            <Trans
              i18nKey="best-practices-content.mla.para5.li1"
              values={{ url: url1 }}
            >Whose Land: <a href={url1} target="_blank" rel="noopener noreferrer">{{ url1 }}</a></Trans>
          </li>
          <li>
            <Trans
              i18nKey="best-practices-content.mla.para5.li2"
              values={{ url: url2 }}
            >Professional development workshop: <br /><a href={url2} target="_blank" rel="noopener noreferrer">{{ url2 }}</a>?</Trans>
          </li>
          <li>
            <Trans
              i18nKey="best-practices-content.mla.para5.li3"
              values={{ url: url3 }}
            >Various Indigenous perspectives on crafting a land acknowledgement:<br /><a href={url3} target="_blank" rel="noopener noreferrer">{{ url3 }}</a></Trans>
          </li>
        </ul>
      </div>

      <Commitment submitConfirm={commitment} type="meaningful-land-acknowledgement" />
    </div>
  )
}

export default NavContent
