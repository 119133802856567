import React from 'react';
import { useSelector } from 'react-redux';
import ProviderPastSessionScreen from './provider';
import PresenterPastSessionScreen from './presenter';

const Screen = () => {
  const { userType } = useSelector(state => state.appInfo);

  if (userType === 'Provider') return <ProviderPastSessionScreen />
  if (userType === 'Presenter') return <PresenterPastSessionScreen />
  return null
}

export default Screen;