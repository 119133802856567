import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Input, Label } from "reactstrap";
import { Box, Typography } from "@mui/material";
import { OnboardPageInner } from "../components/OnboardPageInner";
import { StepProgress } from "../components/StepProgress";
import { useOptionalTerms } from "../hooks/use-optional-terms";
import { APP_ROOT, ONBOARD_TEACHER_ROUTES } from "../routing";
import { getNextBackLinks } from "../utils/get-next-back-links";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.optionalTerms`;
const formId = "optional-terms-form";

export const OptionalTerms = ({
	isNewTeacher,
	isIncompleteTeacher,
	stepRoutes = [],
	currentRouteName,
}) => {
	const { t } = useTranslation();
	const { formValues, setFormValues, loading, updateOptionalTerms } =
		useOptionalTerms();

	const showNewTeacherFlow = isNewTeacher || isIncompleteTeacher;

	const { currentStep, backLink } = getNextBackLinks(
		stepRoutes,
		currentRouteName,
	);

	const nextLink = showNewTeacherFlow
		? ONBOARD_TEACHER_ROUTES.WELCOME.path
		: APP_ROOT;

	const onChangeInput = (e) => {
		const { name, checked } = e.target;

		setFormValues({
			...formValues,
			[name]: checked,
		});
	};

	const onSubmitForm = (e) => {
		e.preventDefault();
		updateOptionalTerms(nextLink, showNewTeacherFlow);
	};

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	});

	return (
		<OnboardPageInner
			formId={formId}
			onSubmit={onSubmitForm}
			heading={
				<Typography variant="h1">
					{t(`${pageKey}.mainHeading`)}
				</Typography>
			}
			actions={
				<StepProgress
					formId={formId}
					backLink={backLink}
					currentStep={currentStep}
					totalSteps={stepRoutes.length}
					nextLabel={`${tKey}.confirmButton`}
					disabled={loading}
				/>
			}
		>
			<Box
				sx={{
					width: "calc(100% + 3rem)",
					transform: "translateX(-1.5rem)",
					marginBottom: "2rem",
				}}
			>
				<Typography variant="body1" mb="1rem">
					{t(`${pageKey}.introText`)}
				</Typography>
			</Box>
			<Box className="form-field-wrap" mt="0.5rem">
				<FormGroup>
					<Input
						id="optionalTerms"
						type="checkbox"
						name="optionalTerms"
						checked={formValues.optionalTerms}
						onChange={onChangeInput}
						disabled={loading}
						required
					/>
					<Label htmlFor="optionalTerms">
						{t(`${pageKey}.confirmLabel`)}
					</Label>
				</FormGroup>
			</Box>
		</OnboardPageInner>
	);
};
