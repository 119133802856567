import Rated10 from "./rated/10.png";
import Rated25 from "./rated/25.png";
import Rated50 from "./rated/50.png";
// eslint-disable-next-line
import Rated75 from "./rated/75.png";
// eslint-disable-next-line
import Rated100 from "./rated/100.png";
// eslint-disable-next-line
import Rated150 from "./rated/150.png";

import Review5 from "./reviewed/5.png";
import Review10 from "./reviewed/10.png";
import Review25 from "./reviewed/25.png";
import Review50 from "./reviewed/50.png";
// eslint-disable-next-line
import Review75 from "./reviewed/75.png";
// eslint-disable-next-line
import Review100 from "./reviewed/100.png";

import Unique10 from "./unique-providers/10.svg";
import Unique25 from "./unique-providers/25.svg";
import Unique50 from "./unique-providers/50.svg";
import Unique75 from "./unique-providers/75.svg";
// eslint-disable-next-line
import Unique100 from "./unique-providers/100.svg";

import Settings from "./settings.svg";

/**
 * @typedef {Object} Achievement
 * @property {number} target
 * @property {number} credits
 * @property {string} badge
 */

/** @type {Achievement[]} */
export const ratings = [
	{
		target: 10,
		credits: 1,
		badge: Rated10,
	},
	{
		target: 25,
		credits: 3,
		badge: Rated25,
	},
	{
		target: 50,
		credits: 5,
		badge: Rated50,
	},
	/*
	{
		target: 75,
		credits: 5,
		badge: Rated75,
	},
	{
		target: 100,
		credits: 5,
		badge: Rated100,
	},
	{
		target: 150,
		credits: 5,
		badge: Rated150,
	},
*/
];

/** @type {Achievement[]} */
export const reviews = [
	{
		target: 5,
		credits: 1,
		badge: Review5,
	},
	{
		target: 10,
		credits: 3,
		badge: Review10,
	},
	{
		target: 25,
		credits: 5,
		badge: Review25,
	},
	{
		target: 50,
		credits: 10,
		badge: Review50,
	},
	/*
	{
		target: 75,
		credits: 10,
		badge: Review75,
	},
	{
		target: 100,
		credits: 10,
		badge: Review100,
	},
  */
];

/** @type {Achievement[]} */
export const uniqueProviders = [
	{
		target: 10,
		credits: 2,
		badge: Unique10,
	},
	{
		target: 25,
		credits: 5,
		badge: Unique25,
	},
	{
		target: 50,
		credits: 10,
		badge: Unique50,
	},
	{
		target: 75,
		credits: 15,
		badge: Unique75,
	},
	/*
	{
		target: 100,
		credits: 20,
		badge: Unique100,
	},
 */
];

/** @type {Achievement} */
export const settingsUpdated = {
	badge: Settings,
	credits: 5,
	target: 1,
};
