import AirtableTable from "../AirtableTable";

export class ActivityTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Activities");

    this.fields = {
      "Session": "SessionfldShDvnfkDrpvgX4",
      "Activity Type": "fld4JAvXgfo1MII9z",
      "Activity": "fldTHtEMpEDjEgw9u",
      "Framework": "fldzMMbRbjhVMPeO4",
      "Reading Level": "fldFUWELjBXXeOs2p",
      "Optional Context": "flduN4q3Kio39Ibxd",
      "Teacher": "fldswhJrdWmkEuAYT",
      "Team Member": "fldpXAVqJv1jGdLV0"
    }
  }
}