import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingBar from '../../components/LoadingBar'
import airtable from '../../airtables';
import { useTranslation } from 'react-i18next';
import SessionInfo from '../../components/SessionInfo';
import { distinctArray } from '../../utils/array';

const SchoolFavorites = () => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const { subjectMap, userInfo } = useSelector(state => state.appInfo);
  const { t } = useTranslation();

  const getSubjectName = (subjects) => {
    if (Object.keys(subjectMap).length === 0) return "";

    if (!subjects || subjects.length === 0) return "No subject";

    return subjects.reduce((result, subId, index) => {
      if (index !== 0) result += ", ";
      result += subjectMap[subId]['Subject'];
      return result;
    }, "")
  }

  useEffect(() => {
    refresh();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = async () => {
    window.document.title = "Favourites - Connected North";
    setLoading(true);

    let schools = [];
    if ((userInfo["School Leader?"] ?? "").toLowerCase() === "true") {
      schools = await airtable.schools.listByIds(userInfo["School Name"]);
    } else if ((userInfo["District Leader?"] ?? "").toLowerCase() === "true") {
      if (userInfo["Cluster Leadership"]) {
        const cluster = await airtable.clusters.select(userInfo["Cluster Leadership"][0]);
        schools = await airtable.schools.listByIds(cluster["Schools"]);
      } else {
        schools = await airtable.schools.listByIds(userInfo["School Name"]);
      }
    }

    const tmpTeacherIds = schools.reduce((tIds, school) => {
      return [
        ...tIds,
        ...(school["Teacher List"] ?? [])
      ]
    }, []);
    const teacherIds = distinctArray(tmpTeacherIds);
    const teachers = await airtable.teachers.listByIds(teacherIds);

    const tmpFavorites = teachers.reduce((fIds, teacher) => {
      return [
        ...fIds,
        ...(teacher['Favourites'] ?? [])
      ]
    }, [])

    const favorites = distinctArray(tmpFavorites); 
    const sesslist = (await airtable.providerSessions.listByIds(
      favorites,
      [{ field: "Session Title", direction: "asc" }]
    )).map(session => ({
      ...session,
      SubjectText: getSubjectName(session['Subject'])
    }));

    const teachersMap = teachers.reduce((result, teacher) => {
      return {
        ...result,
        [teacher.id]: teacher
      }
    }, {});

    const sessionTeacherMap = teachers.reduce((result, teacher) => {
      if (teacher["Favourites"]) {
        teacher["Favourites"].forEach(f => {
          if (result[f]) result[f].push(teacher.id);
          else result[f] = [teacher.id]
        })
      }
      return result;
    }, {})

    for (let i = 0; i < sesslist.length; i++) {
      sesslist[i]["Favorited Teachers"] = sessionTeacherMap[sesslist[i].id].map(tId => teachersMap[tId]);
    }
    sesslist.sort((a, b) => b["Favorited Teachers"].length - a["Favorited Teachers"].length)
    setSessions([...sesslist]);
    setLoading(false);
  }

  return (
    <div style={{ marginTop: 20, minHeight: 500 }}>
      {loading && <LoadingBar />}
      <div>
        {
          (sessions && sessions.length) ? sessions.map((session, i) => (
            <div key={i} className="">
              <SessionInfo
                displayInfos={[
                  "Provider Name",
                  "Primary Subject",
                  "Primary Subject Action",
                  "Image/Photo",
                  "Optimized Image",
                  "Grade(s)",
                  "Favorite",
                  "favorited-teachers",
                ]}
                actionMenu={[
                  "copy-provider-session-link",
                  "open-detail"
                ]}
                session={session}
                sessionDetailLink={session.id}
              />
            </div>
          )) : (
            <div className="nolist-placeholder" align="center">
              <span>{t("no-favorites")}</span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default SchoolFavorites;