import React from "react";
import {
	Row,
	Col,
} from "reactstrap";
import LoadingBar from "../../components/LoadingBar";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../components/VideoPlayer";
import { useProviderDetails } from "./useProviderDetails";
import { Sessions, CreateToLearn } from "./components";
import FallbackLogo from "../../assets/img/login-back.jpg";
import { getValidUrl } from "../../utils/url";
import CarouselProvider from "../../components/Carousel";
import { nanoid } from "nanoid";

const responsiveSessions = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 2000 },
		items: 1,
	},
	desktop: {
		breakpoint: { max: 2000, min: 1024 },
		items: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const Screen = ({ match: { params } }) => {
	const { t } = useTranslation();
	const { loading, provider, sessions, sample, courses, reviews } =
		useProviderDetails(params?.providerId);

	let FSCthumbnailURL = undefined;
	if (provider && provider["firesideChatIllustration"]) {
		const iFile = provider["firesideChatIllustration"];
		if (iFile && iFile.length) {
			FSCthumbnailURL = iFile[0]["url"];
		}
	}
	
	return (
		<div className="main-container provider-detail">
			{loading ? (
				<LoadingBar />
			) : !provider ? (
				<>
					<div>{t('provider-not-found')}</div>
				</>
			) : (
				<>
					<Row className='mb-5'>
                        <Col md="5">
						<img
							src={provider.logo ?? FallbackLogo}
							alt={provider.name}
							style={{
								maxWidth: "100%",
								height: "auto",
								borderRadius: 16,
							}}
						/>
                        </Col>
						<Col md="7">
							<h1>{provider.name}</h1>

							{provider.groups.length ? (
								<span className="h4" style={{fontWeight: '700'}}>{provider.groups.join("|")}</span>
							) : (
								null
							)}
							<p className='large' style={{whiteSpace: 'pre-line'}}>{provider.bio ?? "No description provided."}</p>
						</Col>
					</Row>

					<section className="mb-5">
						<Sessions sessions={sessions} />
					</section>

					{provider.embedSample ? (
		            <>
                    {provider.sampleID && sample ? (
					<Row className='mb-5'>
					    <Col lg="7">
						<div className='video-col embed-responsive embed-responsive-16by9' style={{width: '100%', borderRadius: '16px', overflow: 'hidden'}}>
							<VideoPlayer
								videoData={provider.embedSample}
								onPlay={() => { }}
							/>
						</div>
					    </Col>
					    <Col lg='5'>
							<h2 className="mt-3" style={{fontWeight: '700'}}>{t("video-session-preview")}</h2>
							<p>{sample.description}</p>
							<p>
								{t('from-session')}:&nbsp; 
								<a
								rel="noopener noreferrer"
								href={`/cn/session/${sample.id}`}
								style={{fontWeight: '700', textDecoration: 'underline', color: '#0D1120'}}
								>
								{sample.title}
								</a>
							</p>
					    </Col>
					</Row>
					) : (
					<Row className='mb-5'>
					    <Col lg="12">
						<h2 style={{fontWeight: '700'}}>{t("video-session-preview")}</h2>
						<div className='video-col embed-responsive embed-responsive-16by9' style={{width: '100%', borderRadius: '16px', overflow: 'hidden'}}>
							<VideoPlayer
								videoData={provider.embedSample}
								onPlay={() => { }}
							/>
						</div>
					    </Col>
					</Row>
					)}
					</>
					) : (
						null
					)}

					{reviews && reviews.length ? (
					<div className="mb-5">
						<CarouselProvider
						title={t("session-feedback")}
						responsive={responsiveSessions}
						infinite={false} 
						>
						{reviews.map((review, i) => (
							<div key={nanoid(5)} className="session-detail-carousel-item feedback">
								<div
									className="review font-weight-bold"
									key={i}
								>
									<span>{review.review}</span>
								</div>
								<span>
									- {review.teachergrades}{" "}
									Teacher from{" "}
									{review.teacherlocation}{" "}
									for{" "}
									<a
										rel="noopener noreferrer"
										href={`/cn/session/${review.sessionID}`}
										style={{fontWeight: '700', textDecoration: 'underline', color: '#0D1120'}}
									>
										{review.sessiontitle}
									</a>
								</span>
							</div>
						))}
						</CarouselProvider>
					</div>
					) : null}

					{provider["firesideChatURL"] && FSCthumbnailURL ? (
						<>
							<section className='pb-5'>
								<div>
									<h2 style={{fontWeight: '700'}}>{t('view-fireside',{provider: provider["name"]})}</h2>
									<div className="session-item align-items-center justify-content-center">
										<div className="full-image-wrap p-4 col-md-6">
											{FSCthumbnailURL ? (
												FSCthumbnailURL.length && (
													<a 	
														target="_blank"
														rel="noreferrer"
														href={getValidUrl(String(provider.firesideChatURL))}
													>
														<img
															alt={`Illustration for Fireside Chat with ${provider.name}`}
															src={FSCthumbnailURL}
															className="session-card-media"
														/>
													</a>
												)
											) : null }
										</div>
										<div className="session-card-content col-md-6">
											<div className="item-info p-2">
												<div className="sup-title">{provider.firesideChatCategory} </div>
												<h3 style={{fontWeight: '700'}}>{provider.firesideChatSubtitle}</h3>
												<p>{provider.firesideChatQuote ?? "No description provided."}</p>
												<a 	
													className="btn btn-primary"
													target="_blank"
													rel="noreferrer"
													href={getValidUrl(String(provider.firesideChatURL))}
												>
													{t('view-chat')}
												</a>
											</div>
										</div>
									</div>
								</div>
							</section>
						</>
					) : (
						<></>
					)}

					{courses && courses.length ? (
						<>
							<section className='mb-5 pb-5'>
                                <h2 style={{fontWeight: '700'}}>{t('view-create-courses')}</h2>
								<CreateToLearn courses={courses} />
							</section>
						</>
					) : (
						<></>
					)}
				</>
			)}
		</div>
	);
};

export default Screen;
