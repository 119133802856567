import React, { useEffect, useRef, useState } from 'react';
import Commitment from '../components/Commitment';
import example3 from '../../../assets/img/example3.png'
import Vimeo from '@u-wave/react-vimeo';
import genderneutral from '../../../assets/img/genderneutralterms.jpeg'
import { logEvent } from 'firebase/analytics'
import { updateVideoHistory } from '../videotrack';
import { setAppUserInfo } from '../../../redux/actions';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { analytics } from '../../../firebase';

const url1 = "https://docs.google.com/presentation/d/1sadT4jOksWix-9GFBG5WegJR1BFT4hZi6aLv7dJsVM8/edit#slide=id.gc6f90357f_0_0";

const NavContent = ({ commitment }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { appLocale } = useSelector(state => state.appInfo);
  const [videoWidth, setVideoWidth] = useState(0);
  const [watchStarted, setWatchStarted] = useState(false);
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    setVideoWidth(ref.current.offsetWidth);
  }, [])

  const trackVideo = async (type) => {
    logEvent(analytics, `video-${type}`, {
      viewer: userInfo['Name'],
      video: `bp-customized-resurgence`
    })
  }

  const onPlay = async () => {
    if (!watchStarted) {
      setWatchStarted(true);
      trackVideo('play');
      const nUserInfo = await updateVideoHistory(userType, userInfo, "Resurgence", "starting");
      dispatch(setAppUserInfo(nUserInfo));
    }
  }

  const onEnd = async () => {
    trackVideo('full-watch');
    const nUserInfo = await updateVideoHistory(userType, userInfo, "Resurgence", "finished");
    dispatch(setAppUserInfo(nUserInfo));
  }

  return (
    <div style={{ width: '100%', marginTop: 10 }} ref={ref}>
      <div className="main-content">
        <p><strong>{t("best-practices-content.ccc.title")}</strong></p>

        <p>{t("best-practices-content.ccc.para1")}</p>

        <p>{t("best-practices-content.ccc.para2")}</p>

        <p>{t("best-practices-content.ccc.para3")}</p>

        <p>
          <Trans i18nKey="best-practices-content.ccc.para4">It is important to both <b>acknowledge and give space to student identities</b>. There are many ways to do this, both in content and delivery.</Trans>
        </p>

        {t("best-practices-content.ccc.para5.lead")}

        <ul>
          <li>{t("best-practices-content.ccc.para5.li1")}</li>
          <li>{t("best-practices-content.ccc.para5.li2.lead")}
            <ul>
              <li>{t("best-practices-content.ccc.para5.li2.li1")}</li>
              <li>{t("best-practices-content.ccc.para5.li2.li2")}</li>
            </ul>
          </li>
          <li>{t("best-practices-content.ccc.para5.li3.lead")}
            <ul>
              <li>{t("best-practices-content.ccc.para5.li3.li1")}</li>
              <li>{t("best-practices-content.ccc.para5.li3.li2")}</li>
            </ul>
          </li>
          <li>{t("best-practices-content.ccc.para5.li4")}</li>
          <li>{t("best-practices-content.ccc.para5.li5.lead")}
            <ul>
              <li>{t("best-practices-content.ccc.para5.li5.li1")}</li>
              <li>{t("best-practices-content.ccc.para5.li5.li2")}</li>
            </ul>
          </li>
        </ul>

        <p>{t("best-practices-content.ccc.para6")}</p>

        {
          appLocale === 'en' ? (
            <div className="thumnail">
              <img src={example3} alt={t("best-practices-content.ccc.img1-alt")} />
            </div>
          ) : null
        }


        <p>{t("best-practices-content.ccc.para7")}</p>

        <p><strong>{t("best-practices-content.ccc.para7a.lead")}</strong></p>
        <p>{t("best-practices-content.ccc.para7a.text")}</p>
        <div className="embeded-conatiner">
          {
            videoWidth !== 0 && (
              <Vimeo
                video="714319542"
                width={videoWidth}
                onPlay={() => onPlay()}
                onEnd={() => onEnd()}
              />
            )
          }
        </div>

        <p><strong>{t("best-practices-content.ccc.para8.lead")}</strong></p>
        <ul>
          <li>
            <a href={url1} target="_blank" rel="noopener noreferrer">{t("best-practices-content.ccc.para8.li1")}</a>
          </li>
          <li>{t("best-practices-content.ccc.para8.li2")}</li>
        </ul>
        <img src={genderneutral} alt={t("best-practices-content.ccc.img2-alt")} style={{ maxWidth: 600 }} />
      </div>

      <Commitment submitConfirm={commitment} type="creatively-customized-content" />
    </div>
  )
}

export default NavContent
