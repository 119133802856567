export const IS_WISHALL = false;

export const GradeLevels = [
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'Professional Development',
  'After School',
]

export const CurriculumGradeLevels = [
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
]

export const CurrentGradeLevels = [
  'JK',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'Administrator',
  'SSA',
]

export const SearchableGrades = [
  'JK',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'Professional Development'
]

export const WellBeingLinks = [
  'Arts & Recreation',
  'Culture, Language & Identity',
  'Food & Nutrition',
  'Hope For the Future',
  'Relationship with Staff & Authority',
  'Relationship with Students/Peers',
  'Parent & Family Involvement',
]

export const FBUSEREMAIL = 'connectednorth@user.com'
export const FBUSERPASSWORD = 'connectednorthuser'

export const BestPracticeLen = 5

export const BestPracticeLabels = {
  introduction: {
    name: 'Introduction',
    locale: 'best-practices-content.intro.title',
  },
  'meaningful-land-acknowledgement': {
    name: 'Meaningful Land Acknowledgement',
    locale: 'best-practices-content.mla.title',
  },
  'facilitation-of-student-interactivity': {
    name: 'Facilitation of Student Interactivity',
    locale: 'best-practices-content.fsi.title',
  },
  'indigenous-histories-contributions': {
    name: 'Indigenous Histories & Contributions',
    locale: 'best-practices-content.ihc.title',
  },
  'trauma-informed-approaches': {
    name: 'Trauma-informed Approaches',
    locale: 'best-practices-content.tfa.title',
  },
  'creatively-customized-content': {
    name: 'Creatively Customized Content',
    locale: 'best-practices-content.ccc.title',
  },
  'recording-practices': {
    name: 'Recording Practices',
    locale: 'best-practices-content.rp.title',
  },
}

export const BestPracticeReflectionFieldNames = {
  'meaningful-land-acknowledgement': 'ReflectionMLA',
  'facilitation-of-student-interactivity': 'ReflectionFSI',
  'indigenous-histories-contributions': 'ReflectionIHC',
  'trauma-informed-approaches': 'ReflectionTIA',
  'creatively-customized-content': 'ReflectionCCC',
}

export const culturalGroups = [
  'Algonquin',
  'Anishinaabe',
  'Cayuga',
  'Coast Salish',
  'Dakota',
  'Dene',
  'Kaska',
  'Lakota',
  "Mi'kmaq",
  'Mohawk',
  'Nakoda',
  'North Tutchone',
  'Odawa',
  'Oji-Cree',
  'Ojibwe',
  'Oneida',
  'Onondaga',
  'Plains Cree',
  'Potawatomi',
  'Saulteaux',
  'Seneca',
  'Sioux',
  'South Tutchone',
  'Swampy Cree',
  'Tagish',
  'Tlingit',
  'Tuscarora',
  'Woodlands Cree',
]

export const sortlist = [
  {
    id: 0,
    label: 'Indigenous Providers First',
    label_fr: 'Fournisseurs autochtones en premier',
    icon: 'sort-alpha-down',
    sort: [
      { field: 'Indigenous Provider?', direction: 'desc' },
      { field: 'Average Rating', direction: 'desc' },
    ],
  },
  {
    id: 1,
    label: 'Alphabetical (a-z)',
    label_fr: 'Ordre alphabétique (a-z)',
    icon: 'sort-alpha-down',
    sort: [{ field: 'Session Title', direction: 'asc' }],
  },
  {
    id: 2,
    label: 'Alphabetical (z-a)',
    label_fr: 'Ordre alphabétique (z-a)',
    icon: 'sort-alpha-up',
    sort: [{ field: 'Session Title', direction: 'desc' }],
  },
  {
    id: 3,
    label: 'Least Credits (0-9)',
    label_fr: 'Crédits requis (0-9)',
    icon: 'sort-numeric-up',
    sort: [{ field: 'Credits', direction: 'asc' }],
  },
  {
    id: 4,
    label: 'Most Credits (9-0)',
    label_fr: 'Crédits requis (9-0)',
    icon: 'sort-numeric-down',
    sort: [{ field: 'Credits', direction: 'desc' }],
  },
]

export const cancellationReasons = [
  "Class Absence",
  "Class Behaviour",
  "Materials Delay",
  "Provider Illness / Absence",
  "Provider Reschedule",
  "School Closure - Emergency",
  "School Closure - Funeral",
  "School Closure - Holiday",
  "School Closure - Strike",
  "School Closure - Weather",
  "School Schedule Change",
  "Teacher Illness / Absence",
  "Teacher Reschedule",
  "Technical Issues (GN)",
  "Technical Issues (Provider)",
  "Technical Issues (School)",
  "Technical Issues (TIG)",
  "Other"
];

export const MAX_SUBJECT_NUM = 4;

export const DAY_LIMITATIONS = ["Mondays", "Tuesdays", "Wednesdays", "Thursday", "Fridays"];

export const SELECTBOX_STYLE = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: '#9de0f8'
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#ffffff',
      color: '#0e6ba8',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#0e6ba8',
        color: '#ffffff'
      }
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#0e6ba8'
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#ffffff'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    backgroundColor: '#0e6ba8',
    color: '#ffffff',
    ':hover': {
      backgroundColor: '#084269'
    },
  }),
}

export const DAY_LIMITATIONS_MAP = {
  "Mondays": 1,
  "Tuesdays": 2,
  "Wednesdays": 3,
  "Thursday": 4,
  "Fridays": 5
}

export const getDayRestrictionConfig = (supply) => {
  if (!supply) return 0;

  if (supply["Essential?"] === "Yes") {
    if (
      (supply.Status === "Sioux Lookout Storage" ||
        supply.Status === "Toronto Office Storage") &&
      supply["Quantity Remaining"] === 0
    ) {
      return 60;
    }
    return 30;
  }

  return 0;
};

export const DEFAULT_SESSION_FOR_GREET_SECTION = "recrMwvSKZ6Z6N5sU";
export const DEFAULT_SESSION_FOR_REVIEW_SECTION = "recqiRA1e0oD5f6ax";