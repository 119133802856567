import React, { useEffect, useRef, useState } from 'react';
import Commitment from '../components/Commitment';
import Vimeo from '@u-wave/react-vimeo';
import { updateVideoHistory } from '../videotrack';
import { useDispatch, useSelector } from 'react-redux';
import { setAppUserInfo } from '../../../redux/actions';
import { logEvent } from 'firebase/analytics'
import { Trans, useTranslation } from 'react-i18next';
import { analytics } from '../../../firebase';

const url1 = "https://thecanadianencyclopedia.ca/en/timeline/residential-schools";
const url2 = "https://ehprnh2mwo3.exactdn.com/wp-content/uploads/2021/01/Executive_Summary_English_Web.pdf";
const url3 = "https://empoweringthespirit.ca/wp-content/uploads/2017/05/First-Nation-Metis-and-Inuit-Historical-and-Contemporary-Contributions-.pdf";
const url4 = "https://indspire.ca/events/indspire-awards/laureates/";
const url5 = "https://www.un.org/development/desa/indigenouspeoples/declaration-on-the-rights-of-indigenous-peoples.html";

const NavContent = ({ commitment }) => {
  const ref = useRef(null);
  const [videoWidth, setVideoWidth] = useState(0);
  const [watchStarted, setWatchStarted] = useState(false);
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setVideoWidth(ref.current.offsetWidth);
  }, [])

  const trackVideo = async (type) => {
    logEvent(analytics, `video-${type}`, {
      viewer: userInfo['Name'],
      video: `sarah-robinson`
    })
  }
  const trackVideoEML = async (type) => {
    logEvent(analytics, `video-${type}`, {
      viewer: userInfo['Name'],
      video: `etienna-moostoos-lafferty`
    })
  }

  return (
    <div style={{ width: '100%', marginTop: 10 }} ref={ref}>
      <div className="main-content">
        <p><strong>{t("best-practices-content.ihc.title")}</strong></p>
        <p>{t("best-practices-content.ihc.para1")}</p>
        <p><strong>{t("best-practices-content.ihc.para2")}</strong></p>
        <p>{t("best-practices-content.ihc.para3.lead")}</p>
        <ul>
          <li>{t("best-practices-content.ihc.para3.li1")}</li>
          <li>{t("best-practices-content.ihc.para3.li2")}</li>
          <li>{t("best-practices-content.ihc.para3.li3")}</li>
          <li>{t("best-practices-content.ihc.para3.li4")}</li>
          <li>{t("best-practices-content.ihc.para3.li5")}</li>
        </ul>
        <div className="embeded-conatiner">
          {
            videoWidth !== 0 && (
              <Vimeo
                video="431328761"
                width={videoWidth}
                onPlay={async () => {
                  if (!watchStarted) {
                    setWatchStarted(true);
                    trackVideo('play');
                    const nUserInfo = await updateVideoHistory(userType, userInfo, "Sarah Robinson", "starting");
                    dispatch(setAppUserInfo(nUserInfo));
                  }
                }}
                onEnd={async () => {
                  trackVideo('full-watch');
                  const nUserInfo = await updateVideoHistory(userType, userInfo, "Sarah Robinson", "finished");
                  dispatch(setAppUserInfo(nUserInfo));
                }}
              />
            )
          }
        </div>
        <p><strong>{t("best-practices-content.ihc.para4")}</strong></p>
        <p>{t("best-practices-content.ihc.para5")}</p>
        <p>{t("best-practices-content.ihc.para6")}</p>
        <p><Trans i18nKey="best-practices-content.ihc.para7" values={{ url: url1 }}>Full timeline: <a href={url1} target="_blank" rel="noopener noreferrer">{{ url1 }}</a></Trans></p>
        <p className="quotecallout">"{t("best-practices-content.ihc.para8")}"</p>
        <p><strong>{t("best-practices-content.ihc.para9")}</strong></p>
        <p>{t("best-practices-content.ihc.para10")}</p>
        <p>{t("best-practices-content.ihc.para11")}</p>
        <p>{t("best-practices-content.ihc.para12")}</p>
        <p>
          <Trans i18nKey="best-practices-content.ihc.para13">The TRC carried out extensive research, including hearing from Residential Survivors, their families, members of their communities, former staff of residential schools and others and compiled the information into a <a href={url2} target="_blank" rel="noopener noreferrer">report outlining 94 calls to action</a>.</Trans>
        </p>
        <p><strong>{t("best-practices-content.ihc.para14")}</strong></p>
        <p>{t("best-practices-content.ihc.para15")}</p>
        <p>{t("best-practices-content.ihc.para16")}</p>
        <p>{t("best-practices-content.ihc.para17")}</p>
        <p>{t("best-practices-content.ihc.para18")}</p>
        <p>{t("best-practices-content.ihc.para20")}</p>
        <div className="embeded-conatiner">
          {
            videoWidth !== 0 && (
              <Vimeo
                video="https://vimeo.com/744608173/0414d62cae"
                width={videoWidth}
                onPlay={async () => {
                  if (!watchStarted) {
                    setWatchStarted(true);
                    trackVideoEML('play');
                    const nUserInfo = await updateVideoHistory(userType, userInfo, "Etienna Moostoos Lafferty", "starting");
                    dispatch(setAppUserInfo(nUserInfo));
                  }
                }}
                onEnd={async () => {
                  trackVideoEML('full-watch');
                  const nUserInfo = await updateVideoHistory(userType, userInfo, "Etienna Moostoos Lafferty", "finished");
                  dispatch(setAppUserInfo(nUserInfo));
                }}
              />
            )
          }
        </div>
        <br /><p><strong>{t("best-practices-content.ihc.para19.lead")}</strong></p>
        <ul>
          <li><Trans i18nKey="best-practices-content.ihc.para19.li1" values={{ url: url3 }}>Some historical Indigenous Contributions: <a href={url3} target="_blank" rel="noopener noreferrer">{{ url3 }}</a></Trans></li>
          <li><Trans i18nKey="best-practices-content.ihc.para19.li2" values={{ url: url3 }}>Check out this list and the profiles of other inspiring Indigenous professionals and youth from 1994 to the present: <a href={url4} target="_blank" rel="noopener noreferrer">{{ url4 }}</a></Trans></li>
          <li><Trans i18nKey="best-practices-content.ihc.para19.li3" values={{ url: url3 }}>United Nations Declaration on the Rights of Indigenous Peoples: <a href={url5} target="_blank" rel="noopener noreferrer">{{ url5 }}</a></Trans></li>
        </ul>
      </div>

      <Commitment submitConfirm={commitment} type="indigenous-histories-contributions" />
    </div>
  )
}

export default NavContent
