import { useEffect, useState } from 'react';
import airtable from '../../../airtables';

export const useTeacher = (schoolId) => {
    const [loading, setLoading] = useState(true);
    const [teachers, setTeachers] = useState([]);
    const [school, setSchool] = useState(null);

    useEffect(() => {
        reload();
    }, [schoolId]) // eslint-disable-line react-hooks/exhaustive-deps

    const reload = async () => {
        setLoading(true);

        try {
            const sc = await airtable.schools.select(schoolId);
            setSchool(sc);

            const ts = await airtable.teachers.getSchoolTeachers(sc.School);
            setTeachers(ts.map(r => ({
                id: r["id"],
                name: r["Teacher Name"],
                credits: r["Credit Balance"] ?? 0,
                email: r["Email"],
                daysago: r["Last E-mail Days"] ?? 0,
                allocatedCredits: 0,
                percentIndigenous: Math.round((r["Indigenous % TY"] ?? 0) * 100),
                upcomingSessions: r["Upcoming TY"] ?? 0,
                completedSessions: r["Completed TY"] ?? 0,
            })));
        } catch (error) {
            console.log("Error:", error)
        }
        setLoading(false);
    }

    return {
        loading,
        teachers,
        schoolInfo: school
    }
}
