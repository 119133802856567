import React from "react";
import Slider from "rc-slider";

const STEP = 10;
const StepSlider = ({ max, onChange, value, step }) => (
	<Slider
		min={0}
		max={max}
		step={10}
		dots
		onChange={onChange}
		marks={sliderMarksLabels(max, step ?? STEP)}
		value={value}
		trackStyle={{
			backgroundColor: "#0e6ba8",
		}}
		handleStyle={{
			borderColor: "#0e6ba8",
			backgroundColor: "#0e6ba8",
		}}
		activeDotStyle={{
			backgroundColor: "#0e6ba8",
			border: "#0e6ba8",
		}}
	/>
);

const sliderMarksLabels = (max, step) => {
	const dict = {};
	for (let i = 0; i <= max; i += step) {
		dict[i] = <div style={{ color: "black" }}>{i}</div>;
	}
	return dict;
};

export default StepSlider;
